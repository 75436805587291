import makeStyles from "@material-ui/core/styles/makeStyles";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import Checkbox from "@material-ui/core/Checkbox";
import FormLabel from "@material-ui/core/FormLabel";
import InputAdornment from "@material-ui/core/InputAdornment";
import TextField from "@material-ui/core/TextField";
import {useState} from "react";
import {Link} from "react-router-dom";
import GenericButton from "../../../components/GenericButton";
import Password from "../../../components/Password";
import PasswordHints from "../../../components/PasswordHints";
import {generateFontStyling} from "../../../Theme/theme";
import {FormHelperText} from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  select: {
    "& .MuiSelect-select:focus": {
      background: "transparent",
    },
  },
  options: {
    ...theme.typography.body1,
  },
  placeholder: {
    ...generateFontStyling("16px", "Lato", "19px", "#B0BEC5", 400),
    "& .MuiInputBase-input": {
      ...generateFontStyling("16px", "Lato", "19px", "#DEE4E7", 400),
    },
  },
  noMargin: {
    "& .MuiInputAdornment-positionStart": {
      marginRight: "0px",
    },
  },
  margin: {
    "& .MuiInputAdornment-positionStart": {
      marginRight: "3px",
    },
  },
  link: {
    ...theme.typography.subtitle2,
  },
}));
function User(props: any) {
  const classes = useStyles();
  const {user, handleChange, handleConfirm, handleNext, Location, countryCode} =
    props;
  const [placeholder, setPlaceholder] = useState(user.values.country === "");
  const [errorMessage, setErrorMessage] = useState("");
  console.log("dataa");
  const handlePasswordChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newPassword = e.target.value;
    let errorMessage = "";
    if (
      !/[A-Z]/.test(newPassword) ||
      !/[a-z]/.test(newPassword) ||
      !/\d/.test(newPassword) ||
      !/[!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?]/.test(newPassword) ||
      newPassword.length < 8
    ) {
      errorMessage =
        "Password is invalid. Please ensure it has an uppercase letter, a lowercase letter, a number, a special character, and at least 8 characters long.";
    }

    setErrorMessage(errorMessage);
  };
  return (
    <Grid container spacing={2}>
      <Grid item md={5} sm={12} xs={12}>
        <FormLabel required>First Name</FormLabel>
        <TextField
          id="firstname"
          type="text"
          name="firstName"
          style={{width: "100%"}}
          variant="outlined"
          autoComplete="No"
          placeholder="Enter your first name"
          value={user.values.firstName}
          error={Boolean(user.errors.firstName)}
          helperText={user.errors.firstName}
          onChange={handleChange}
        />
      </Grid>
      <Grid item md={5} sm={12} xs={12}>
        <FormLabel required>Last Name</FormLabel>
        <TextField
          id="lastname"
          type="text"
          name="lastName"
          style={{width: "100%"}}
          variant="outlined"
          autoComplete="No"
          placeholder="Enter your last name"
          value={user.values.lastName}
          error={Boolean(user.errors.lastName)}
          helperText={user.errors.lastName}
          onChange={handleChange}
        />
      </Grid>
      <Grid item md={5} sm={12} xs={12}>
        <FormLabel required>Country</FormLabel>
        <TextField
          style={{width: "100%"}}
          className={classes["select"]}
          variant="outlined"
          id="country"
          name="country"
          value={user.values.country}
          error={Boolean(user.errors.country)}
          helperText={user.errors.country}
          onChange={handleChange}
          onFocus={() => setPlaceholder(false)}
          SelectProps={{
            native: true,
          }}
          select
        >
          {}
          {Location.map((options: any) => {
            return (
              <option
                className={classes.options}
                id={options.isoCode}
                key={options.isoCode}
                value={options.name}
              >
                {options.name}
              </option>
            );
          })}
        </TextField>
      </Grid>
      <Grid item md={5} sm={12} xs={12}>
        <FormLabel required>Mobile Number</FormLabel>
        <TextField
          id="phonenumber"
          type="number"
          name="phoneNumber"
          className={
            classes[user.values.country === "" ? "noMargin" : "margin"]
          }
          style={{
            width: "100%",
          }}
          variant="outlined"
          autoComplete="No"
          placeholder="Eg: 1234567890"
          value={user.values.phoneNumber}
          error={Boolean(user.errors.phoneNumber)}
          helperText={user.errors.phoneNumber}
          onChange={handleChange}
          onInput={(e: any) => {
            if (user.values?.country === "Australia") {
              e.target.value = Math.max(0, parseInt(e.target.value))
                .toString()
                .slice(0, 9);
            } else {
              e.target.value = Math.max(0, parseInt(e.target.value))
                .toString()
                .slice(0, 10);
            }
          }}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">{countryCode}</InputAdornment>
            ),
          }}
        />
      </Grid>
      <Grid item md={10} sm={12} xs={12}>
        <FormLabel required>Email</FormLabel>
        <TextField
          id="email"
          type="email"
          name="email"
          style={{width: "100%"}}
          variant="outlined"
          autoComplete="No"
          placeholder="Eg : johndoe@email.com"
          value={user.values.email}
          error={Boolean(user.errors.email)}
          helperText={user.errors.email}
          onChange={handleChange}
        />
      </Grid>

      <Grid item md={10} sm={12} xs={12}>
        <FormLabel required>Password</FormLabel>
        <Password
          id="password"
          name="signupPassword"
          placeholder="Enter your password"
          value={user.values.signupPassword}
          error={user.errors.signupPassword}
          onChange={(event) => {
            handleChange(event);
            handlePasswordChange(event);
          }}
        />
        {errorMessage && <FormHelperText error>{errorMessage}</FormHelperText>}
      </Grid>
      <Box margin="2vh 0 1vh 0">
        <PasswordHints chip={user.chip} />
      </Box>
      <Grid item md={12} sm={12} xs={12} style={{margin: "0 -15px 0 -15px"}}>
        <Checkbox checked={user.confirm} onChange={handleConfirm} />{" "}
        <FormLabel required>
          I accept the{" "}
          <Link to="/terms" target="_blank" className={classes.link}>
            Terms of Use
          </Link>{" "}
          and the{" "}
          <Link to="/privacy" target="_blank" className={classes.link}>
            Privacy Policy
          </Link>
        </FormLabel>
      </Grid>
      <Grid item md={10} sm={12} xs={12}>
        <Box display="flex" justifyContent="flex-end">
          <GenericButton
            id="submit"
            type="submit"
            variant="contained"
            onClick={handleNext}
            disabled={!user.fieldsValidated || !user.confirm}
            background="blue"
          >
            Next
          </GenericButton>
        </Box>
      </Grid>
    </Grid>
  );
}

export default User;
