import FormControl from "@material-ui/core/FormControl";
import FormLabel from "@material-ui/core/FormLabel";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";
import IconWithTooltip from "../IconWithTooltip";

function Address(props: any) {
  const {
    id,
    label,
    error = null,
    onChange,
    value,
    onOptionsChange,
    required = false,
    options,
    tooltip,
  } = props;

  return (
    <FormControl
      variant="outlined"
      style={{ width: "100%", marginBottom: "20px" }}
      {...(required && { required: true })}
    >
      <FormLabel>{label}</FormLabel>
      <Grid container direction="row">
        <Autocomplete
          style={{ width: "80%" }}
          freeSolo
          id={id}
          options={options}
          value={value}
          getOptionLabel={(option: any) => {
            return option.mainString || value;
          }}
          onChange={onChange}
          renderOption={(option) => option?.description}
          renderInput={(params: any) => (
            <TextField
              variant="outlined"
              name={label}
              onChange={onOptionsChange}
              value={value}
              {...(error && { error: true, helperText: error })}
              {...(required && { required: true })}
              {...params}
            ></TextField>
          )}
        />
        {tooltip && <IconWithTooltip tooltip={tooltip} />}
      </Grid>
    </FormControl>
  );
}

export default Address;
