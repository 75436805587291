import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import makeStyles from "@material-ui/core/styles/makeStyles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import React, {useEffect, useState} from "react";
import {Redirect, Route, Switch} from "react-router-dom";
import Applications from "./Applications";
import applications from "../../assets/icons/applications.svg";
import reports from "../../assets/icons/reports.svg";
import Reporting from "./Reporting";
import {getActiveSubscription} from "../../api";
import LoadingScreen from "../../components/LoadingScreen";
import message from "../../assets/icons/message.svg";
import Messages from "./Messages";

const useStyles = makeStyles((theme) => ({
  tab: {
    cursor: "pointer",
    padding: "5% 0 5% 10%",
    "&:hover": {
      boxShadow: "inset 0 0 0 10em rgba(255, 255, 255, 0.3)",
    },
  },
}));
function Dashboard(props: any) {
  const classes = useStyles();
  const isAdmin =
    props.user?.attributes["custom:role"] === "Org_Admin" ||
    props.user?.attributes["custom:role"] === "Org_User";
    
  const userCountry = props.user?.attributes["custom:country"];
  const smallScreen = useMediaQuery((theme: any) =>
    theme.breakpoints.between(0, "xs")
  );
  const mediumScreen = useMediaQuery((theme: any) =>
    theme.breakpoints.between("xs", "sm")
  );
  const [activeTab, setActiveTab] = useState("");
  const [features, setFeatures] = useState<any>();
  const [loading, setLoading] = useState(true);
  const [plan, setPlan] = useState("");

  useEffect(() => {
    setActiveTab(
      props.location.pathname.split("/").pop() === "dashboard"
        ? "applications"
        : props.location.pathname.split("/").pop()
    );
    const orgId = props.user.attributes["custom:orgId"];
    const campusId = props.user.attributes["custom:campusId"];
    if (props.user.attributes["custom:role"] !== "Parent") {
      console.log("Inside If on dashboard");
      getActiveSubscription(orgId, campusId)
        .then((res) => {
          console.log("Subscription Result");
          console.log(res);
          setFeatures(res.data.features);
          setPlan(res.data.name);
          setLoading(false);
        })
        .catch((err) => {
          console.log("Subscription Error");
          console.log(err);
          setLoading(false);
        });
    } else {
      console.log("inside else on dashboard");
      setLoading(false);
    }
  }, []);
  return (
    <Box display="flex" {...(isAdmin && {style: {background: "#F3F6F8"}})}>
      {loading && <LoadingScreen />}
      {isAdmin &&
        (smallScreen ? (
          <Box
            position="fixed"
            bottom="0"
            zIndex={5}
            style={{background: "#3B3A3C"}}
            width="100%"
            height="80px"
            display="flex"
            justifyContent="space-around"
            borderRadius="50px 50px 0 0"
            alignItems="flex-end"
          >
            <Box
              onClick={() => {
                setActiveTab("applications");
                props.history.push("/dashboard/applications");
              }}
            >
              <img src={applications} alt="" />
              <Typography
                variant="h5"
                {...(activeTab !== "applications" && {
                  style: {marginBottom: "10%"},
                })}
              >
                Applications
              </Typography>
              {activeTab === "applications" && (
                <Box
                  style={{
                    background:
                      "linear-gradient(360deg, #D8F7F3 0%, #2FE3B8 100%)",
                    width: "100%",
                    height: "5px",
                    borderRadius: "8px 8px 0px 0px",
                    marginTop: "10%",
                  }}
                ></Box>
              )}
            </Box>
            {features?.reporting && (
              <Box
                onClick={() => {
                  setActiveTab("reports");
                  props.history.push("/dashboard/reports");
                }}
              >
                <img src={reports} alt="" />
                <Typography
                  variant="h5"
                  {...(activeTab !== "reports" && {
                    style: {marginBottom: "20%"},
                  })}
                >
                  Reports
                </Typography>
                {activeTab === "reports" && (
                  <Box
                    style={{
                      background:
                        "linear-gradient(360deg, #D8F7F3 0%, #2FE3B8 100%)",
                      width: "100%",
                      height: "5px",
                      borderRadius: "8px 8px 0px 0px",
                      marginTop: "10%",
                    }}
                  ></Box>
                )}
              </Box>
            )}
            {(plan === "Premium" || plan === "Custom") && (
              <Box
                onClick={() => {
                  setActiveTab("messages");
                  props.history.push("/dashboard/messages");
                }}
              >
                <img src={message} alt="" />
                <Typography
                  variant="h5"
                  {...(activeTab !== "messages" && {
                    style: {marginBottom: "20%"},
                  })}
                >
                  Messages
                </Typography>
                {activeTab === "messages" && (
                  <Box
                    style={{
                      background:
                        "linear-gradient(360deg, #D8F7F3 0%, #2FE3B8 100%)",
                      width: "100%",
                      height: "5px",
                      borderRadius: "8px 8px 0px 0px",
                      marginTop: "10%",
                    }}
                  ></Box>
                )}
              </Box>
            )}
          </Box>
        ) : (
          <Box
            style={{background: "#3B3A3C"}}
            width={mediumScreen ? "16vw" : "10vw"}
          >
            <Box
              margin="5vh 0 5vh 0"
              display="flex"
              {...(activeTab !== "applications" && {className: classes.tab})}
              onClick={() => {
                setActiveTab("applications");
                props.history.push("/dashboard/applications");
              }}
            >
              {activeTab === "applications" && (
                <Box
                  style={{
                    background:
                      "linear-gradient(360deg, #D8F7F3 0%, #2FE3B8 100%)",
                    width: "3%",
                    borderRadius: "0px 8px 8px 0px",
                    marginRight: "7%",
                    cursor: "pointer",
                  }}
                ></Box>
              )}
              <Box style={{cursor: "pointer"}}>
                <img src={applications} alt="" />
                <Typography variant="h5">Applications</Typography>
              </Box>
            </Box>
            {features?.reporting && (
              <Box
                margin="5vh 0 5vh 0"
                display="flex"
                {...(activeTab !== "reports" && {className: classes.tab})}
                onClick={() => {
                  setActiveTab("reports");
                  props.history.push("/dashboard/reports");
                }}
              >
                {activeTab === "reports" && (
                  <Box
                    style={{
                      background:
                        "linear-gradient(360deg, #D8F7F3 0%, #2FE3B8 100%)",
                      width: "3%",
                      borderRadius: "0px 8px 8px 0px",
                      marginRight: "7%",
                      cursor: "pointer",
                    }}
                  ></Box>
                )}
                <Box style={{cursor: "pointer"}}>
                  <img src={reports} alt="" />
                  <Typography variant="h5">Reports</Typography>
                </Box>
              </Box>
            )}
            {(plan === "Premium" || plan === "Custom") && (
              <Box
                margin="5vh 0 5vh 0"
                display="flex"
                {...(activeTab !== "messages" && {className: classes.tab})}
                onClick={() => {
                  setActiveTab("messages");
                  props.history.push("/dashboard/messages");
                }}
              >
                {activeTab === "messages" && (
                  <Box
                    style={{
                      background:
                        "linear-gradient(360deg, #D8F7F3 0%, #2FE3B8 100%)",
                      width: "3%",
                      borderRadius: "0px 8px 8px 0px",
                      marginRight: "7%",
                      cursor: "pointer",
                    }}
                  ></Box>
                )}
                <Box style={{cursor: "pointer"}}>
                  <img src={message} alt="" />
                  <Typography variant="h5">Messages</Typography>
                </Box>
              </Box>
            )}
          </Box>
        ))}
      <Box width="100%" height="100%">
        <Switch>
          <Route
            path="/dashboard/applications"
            render={(routerProps) => (
              <Applications
                {...routerProps}
                {...props}
                features={features}
                userCountry={userCountry}
              />
            )}
          />
          <Route
            path="/dashboard/reports"
            render={(routerProps) => <Reporting {...routerProps} {...props} />}
          />
          <Route
            path="/dashboard/messages"
            render={(routerProps) => (
              <Messages {...routerProps} {...props} userCountry={userCountry} />
            )}
          />
          <Redirect to="/dashboard/applications" />
        </Switch>
      </Box>
    </Box>
  );
}

export default Dashboard;
