import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormLabel from "@material-ui/core/FormLabel";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import makeStyles from "@material-ui/styles/makeStyles";
import React, { useState } from "react";
import GenericButton from "../../../components/GenericButton";
import { State, City } from "country-state-city";
import { generateFontStyling } from "../../../Theme/theme";
import LoadingScreen from "../../../components/LoadingScreen";
const useStyles = makeStyles((theme: any) => ({
  select: {
    "& .MuiSelect-select:focus": {
      background: "transparent",
    },
  },
  options: {
    ...theme.typography.body1,
  },
  placeholder: {
    ...generateFontStyling("16px", "Lato", "19px", "#B0BEC5", 400),
    "& .MuiInputBase-input": {
      ...generateFontStyling("16px", "Lato", "19px", "#DEE4E7", 400),
    },
  },
}));
function Campus(props: any) {
  const classes = useStyles();
  const {
    campus,
    country,
    handleChange,
    handleNext,
    handlePrevious,
    handleAdmissions,
    loading,
    handleSameCampusOrg,
  } = props;
  const [statePlaceholder, setStatePlaceholder] = useState(
    campus.values.state === ""
  );
  const [cityPlaceholder, setCityPlaceholder] = useState(
    campus.values.city === ""
  );
  const States = State.getStatesOfCountry(country);
  const state = States.find(
    (state) => state.name === campus.values.state
  )?.isoCode;
  const Cities = City.getCitiesOfState(country, state || "");
  return (
    <>
      {loading && <LoadingScreen />}
      <Grid container spacing={3}>
        <Grid item md={12} sm={12} xs={12}>
          <FormControlLabel
            control={
              <Checkbox
                checked={campus.sameAsOrg}
                onChange={handleSameCampusOrg}
              />
            }
            label="Same as organisation"
          />
        </Grid>
        {!campus.sameAsOrg && (
          <>
            <Grid item md={9} xs={12} sm={12}>
              <FormLabel required>Campus Name</FormLabel>
              <TextField
                id="campusName"
                type="text"
                name="campusName"
                style={{ width: "100%" }}
                variant="standard"
                autoComplete="No"
                placeholder="Enter your campus name"
                value={campus.values.campusName}
                error={Boolean(campus.errors.campusName)}
                helperText={campus.errors.campusName}
                onChange={handleChange}
              />
            </Grid>
            <Grid item md={9} xs={12} sm={12}>
              <FormLabel required>Address</FormLabel>
              <TextField
                id="address"
                type="text"
                name="address"
                style={{ width: "100%" }}
                variant="standard"
                autoComplete="No"
                placeholder="Enter your campus address"
                value={campus.values.address}
                error={Boolean(campus.errors.address)}
                helperText={campus.errors.address}
                onChange={handleChange}
              />
            </Grid>
            <Grid item md={4} sm={4} xs={12}>
              <FormLabel required>State</FormLabel>
              <TextField
                style={{ width: "100%" }}
                className={classes[statePlaceholder ? "placeholder" : "select"]}
                variant="standard"
                id="state"
                name="state"
                value={campus.values.state}
                error={Boolean(campus.errors.state)}
                helperText={campus.errors.state}
                onChange={handleChange}
                onFocus={() => setStatePlaceholder(false)}
                onMouseOut={() => {
                  setStatePlaceholder(
                    campus.values.state === ""
                  );
                }}
                onBlur={() => {
                  setStatePlaceholder(
                    campus.values.state === ""
                  );
                }}
                SelectProps={{
                  native: true,
                }}
                select
              >
                <option
                  className={classes.placeholder}
                  {...(!statePlaceholder && { disabled: true })}
                >
                  Select State
                </option>
                {!statePlaceholder && <option />}
                {States.map((options: any, index: any) => {
                  return (
                    <option
                      className={classes.options}
                      id={options.isoCode}
                      key={options.isoCode}
                      value={options.name}
                    >
                      {options.name}
                    </option>
                  );
                })}
              </TextField>
            </Grid>
            <Grid item md={4} sm={4} xs={12}>
              <FormLabel required>City</FormLabel>
              <TextField
                style={{ width: "100%" }}
                className={classes[cityPlaceholder ? "placeholder" : "select"]}
                variant="standard"
                id="city"
                name="city"
                value={campus.values.city}
                error={Boolean(campus.errors.city)}
                helperText={campus.errors.city}
                onChange={handleChange}
                onFocus={() => setCityPlaceholder(false)}
                onMouseOut={() => {
                  setCityPlaceholder(campus.values.city === "");
                }}
                onBlur={() => {
                  setCityPlaceholder(campus.values.city === "");
                }}
                SelectProps={{
                  native: true,
                }}
                select
              >
                <option
                  className={classes.placeholder}
                  {...(!cityPlaceholder && { disabled: true })}
                >
                  Select City
                </option>
                {!cityPlaceholder && <option />}
                {Cities.map((options: any, index: any) => {
                  return (
                    <option
                      className={classes.options}
                      id={options.name}
                      key={index}
                      value={options.name}
                    >
                      {options.name}
                    </option>
                  );
                })}
              </TextField>
            </Grid>
            <Grid item md={4} sm={4} xs={12}>
              <FormLabel required>Pincode</FormLabel>
              <TextField
                id="pincode"
                type="number"
                name="pincode"
                style={{ width: "100%" }}
                variant="standard"
                autoComplete="No"
                placeholder="Enter Pincode"
                value={campus.values.pincode}
                error={Boolean(campus.errors.pincode)}
                helperText={campus.errors.pincode}
                onChange={handleChange}
                onInput={(e: any) => {
                  if (country === "AU") {
                    e.target.value = Math.max(0, parseInt(e.target.value))
                      .toString()
                      .slice(0, 4);
                  } else {
                    e.target.value = Math.max(0, parseInt(e.target.value))
                      .toString()
                      .slice(0, 6);
                  }
                }}
              />
            </Grid>
          </>
        )}
        <Grid item md={12} sm={12} xs={12}>
          <FormControlLabel
            control={
              <Checkbox
                checked={campus.admissions}
                onChange={handleAdmissions}
              />
            }
            label="We are accepting admissions"
          />
        </Grid>
        <Grid
          item
          md={12}
          sm={12}
          xs={12}
          style={{
            display: "flex",
            justifyContent: "space-between",
            marginTop: "2vh",
          }}
        >
          <GenericButton
            variant="contained"
            id="prevCampus"
            type="submit"
            onClick={handlePrevious}
            background="blue"
          >
            Previous
          </GenericButton>
          <GenericButton
            id="nextCampus"
            type="submit"
            variant="contained"
            onClick={handleNext}
            disabled={!campus.fieldsValidated}
            background="blue"
          >
            Next
          </GenericButton>
        </Grid>
      </Grid>
    </>
  );
}

export default Campus;
