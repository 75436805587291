import FormControl from "@material-ui/core/FormControl";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormGroup from "@material-ui/core/FormGroup";
import FormLabel from "@material-ui/core/FormLabel";
import FormHelperText from "@material-ui/core/FormHelperText";
import MuiCheckbox from "@material-ui/core/Checkbox";
import makeStyles from "@material-ui/core/styles/makeStyles";

import React from "react";
import IconWithTooltip from "../IconWithTooltip";
const useStyles = makeStyles((theme) => ({
  
  checkbox: {
    marginLeft: theme.spacing(1),
  },
}));
function Checkbox(props: any) {
  const {
    id,
    label,
    error = null,
    onChange,
    required = false,
    options,
    checked,
    tooltip,
  } = props;
  const classes = useStyles();
  return (
    <FormControl
      component="fieldset"
      variant="outlined"
      style={{ width: "100%",marginBottom:"10px" }}
      {...(error && { error: true })}
      {...(required && { required: true })}
    >
      <FormLabel component="legend">
        {label}
        {tooltip && <IconWithTooltip tooltip={tooltip} />}
      </FormLabel>

      <FormGroup className={classes.checkbox} id={id}>
        {options.map((options: any) => {
          return (
            <FormControlLabel
              label={options.Label}
              key={options.Id}
              control={
                <MuiCheckbox
                  id={options.Id}
                  value={options.Label}
                  name={options.Label}
                  onChange={onChange}
                  checked={checked[options.Label]}
                />
              }
            ></FormControlLabel>
          );
        })}
      </FormGroup>
      {error && <FormHelperText>{error}</FormHelperText>}
    </FormControl>
  );
}

export default Checkbox;
