import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import FileSaver from "file-saver";
import { useState } from "react";
import { downloadDocument } from "../../api";
import GenericButton from "../../components/GenericButton";
import ViewOnlyStepper from "./ViewOnlyStepper";
import useStyles from "./styles";

const ignoreList = [
  "save",
  "saveLoading",
  "submitLoading",
  "page",
  "activeStep",
  "completed",
  "disabled",
  "formErrors",
  "formValues",
  "Eligibility and Checklist",
  "error",
  "success",
  "openModal",
  "paymentCompleted",
  "paymentError",
  "paymentInfo",
  "paymentId",
];
function checkAvailability(arr: any, val: any) {
  return arr.some(function (arrVal: any) {
    return val === arrVal;
  });
}
function ApplicationDetails(props: any) {
  const classes = useStyles();
  let SideMenu: any[] = [];
  console.log("Application Details Props: ");
  console.log(props);
  const data = props.data.applicationDetails;
  const customerId = props.data.customerId;
  for (const key in data) {
    if (!checkAvailability(ignoreList, key)) {
      SideMenu.push(key);
  }
  
  }
  const [state, setState] = useState({
    page: SideMenu[0],
    activeStep: 0,
  });
  const handleStep = (page: any, step: any) => {
    setState({ page: page, activeStep: step });
  };
  const handleNext = () => {
    const index = SideMenu.indexOf(state.page);
    const newPage = SideMenu[index + 1];
    setState({ page: newPage, activeStep: state.activeStep + 1 });
  };
  const handlePrevious = () => {
    const index = SideMenu.indexOf(state.page);
    const newPage = SideMenu[index - 1];
    setState({ page: newPage, activeStep: state.activeStep - 1 });
  };
  const values = data[state.page].formValues;
  return (
    <>
      <ViewOnlyStepper
        state={state}
        handleStep={handleStep}
        SideMenu={SideMenu}
      />
      <Box margin="2% 5% 2% 5%">
        <Box className={classes.main}>
          {Object.keys(values).map((detail, index) => {
            const key = detail.split("_")[0];
            const value = values[detail];
            return (
              <Box
                key={index}
                marginBottom="40px"
                width={
                  key.length > 30 ||
                  value.length > 30 ||
                  detail.split("_").pop() === "display"
                    ? "100%"
                    : "50%"
                }
                style={{ wordBreak: "break-word" }}
              >
                {detail.split("_").pop() === "display" ? (
                  <Box className={classes.title}>{key}</Box>
                ) : (
                  <Typography
                    variant="subtitle2"
                    style={{ marginBottom: "15px" }}
                  >
                    {key}
                  </Typography>
                )}
                {Array.isArray(value) && (
                  <Typography variant="caption">
                    {value.length === 0 ? "---" : value.join(", ")}
                  </Typography>
                )}
                {typeof value !== "object" &&
                  detail.split("_").pop() !== "display" && (
                    <Typography variant="caption">
                      {value === "" ? "---" : value}
                    </Typography>
                  )}
                {detail.split("_").pop() === "address" && (
                  <Typography variant="caption">
                    {value.value === "" ? "---" : value.value}
                  </Typography>
                )}
                {detail.split("_").pop() === "radio" &&
                  (Object.keys(value).length > 1 ? (
                    <>
                      <Typography variant="caption">
                        {value.value === "" ? "---" : value.value}
                      </Typography>
                      <Box display="flex" flexWrap="wrap">
                        {Object.keys(value).map((val, index) => {
                          const key2 = val.split("_")[0];
                          const value2 = value[val];
                          if (val !== "value")
                            return (
                              (value2 !== "" ||
                                val.split("_").pop() === "display") && (
                                <Box
                                  key={index}
                                  marginTop={value.value === "Yes" && "40px"}
                                  width={
                                    key2.length > 30 ||
                                    value2.length > 30 ||
                                    val.split("_").pop() === "display"
                                      ? "100%"
                                      : "50%"
                                  }
                                  style={{ wordBreak: "break-word" }}
                                >
                                  {val.split("_").pop() === "display" ? (
                                    value.value === "Yes" && (
                                      <Box className={classes.title}>
                                        {key2}
                                      </Box>
                                    )
                                  ) : (
                                    <Typography
                                      variant="subtitle2"
                                      style={{ marginBottom: "15px" }}
                                    >
                                      {key2}
                                    </Typography>
                                  )}
                                  {typeof value2 !== "object" &&
                                    val.split("_").pop() !== "display" && (
                                      <Typography variant="caption">
                                        {value2 === "" ? "---" : value2}
                                      </Typography>
                                    )}
                                </Box>
                              )
                            );
                          else return null;
                        })}
                      </Box>
                    </>
                  ) : (
                    <Typography variant="caption">
                      {value.value === "" ? "---" : value.value}
                    </Typography>
                  ))}
                {detail.split("_").pop() === "file" &&
                (value.value === "" || Object.keys(value).length === 0) ? (
                  <Typography variant="caption">---</Typography>
                ) : (
                  <Box
                    className={classes.filename}
                    onClick={async () => {
                      await downloadDocument(customerId, value.fileId).then(
                        async (res: any) => {
                          console.log(res);
                          const file = new File(
                            [new Uint8Array(res)],
                            value.fileName
                          );
                          FileSaver.saveAs(file);
                        }
                      );
                    }}
                  >
                    {value.fileName}
                  </Box>
                )}
              </Box>
            );
          })}
        </Box>
        <Box
          display="flex"
          width="100%"
          justifyContent="space-between"
          marginTop="25px"
        >
          {state.activeStep !== 0 ? (
            <GenericButton
              id="previous"
              variant="contained"
              background="light"
              onClick={handlePrevious}
            >
              Previous
            </GenericButton>
          ) : (
            <Box></Box>
          )}
          {state.activeStep !== SideMenu.length - 1 ? (
            <GenericButton
              id="next"
              variant="contained"
              background="light"
              onClick={handleNext}
            >
              Next
            </GenericButton>
          ) : (
            <Box></Box>
          )}
        </Box>
      </Box>
    </>
  );
}

export default ApplicationDetails;
