import { makeStyles } from "@material-ui/core";
import { generateFontStyling } from "../../Theme/theme";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    zIndex: 2,
    position: "relative",
    height: "100%",
    background: "#FFFFFF",
  },
  headerContainer: {
    background: "#FFFFFF",
    padding: "10px 50px",
    zIndex: 2,
  },
  actionContainer: {
    display: "flex",
    [theme.breakpoints.down("xs")]: {
      justifyContent: "space-around",
    },
  },
  signup: {
    marginRight: "1vw",
    [theme.breakpoints.down("xs")]: {
      marginRight: "0",
    },
  },
  title: {
    ...generateFontStyling("60px", "Lato", "72px", "#3B3A3C", 700),
    width: "55vw",
    [theme.breakpoints.between("xs", "sm")]: {
      ...generateFontStyling("50px", "Lato", "55px", "#3B3A3C", 700),
    },
    [theme.breakpoints.down("xs")]: {
      ...generateFontStyling("35px", "Lato", "40px", "#3B3A3C", 700),
      width: "100%",
    },
  },
  sub: {
    ...generateFontStyling("24px", "Lato", "29px", "#607D8B", 400),
    marginTop: "5vh",
    width: "55vw",
    [theme.breakpoints.down("xs")]: {
      ...generateFontStyling("17px", "Lato", "20px", "#607D8B", 400),
      width: "100%",
      marginTop: "3vh",
    },
  },
  header: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    [theme.breakpoints.down("xs")]: {
      justifyContent: "center",
    },
  },
  background: {
    width: "100vw",
    height: "100vh",
    position: "fixed",
    left: "0px",
    top: "25px",
    zIndex: -1,
    [theme.breakpoints.down("xs")]: {
      top: "100px",
    },
  },
  container: {
    position: "absolute",
    left: "3vw",
    right: "3vw",
    top: "20vh",
    zIndex: 1,
    [theme.breakpoints.between("xs", "sm")]: {
      top: "18vh",
    },
    [theme.breakpoints.down("xs")]: {
      top: "15vh",
    },
  },
  lady: {
    position: "absolute",
    top: "-5vh",
    right: "2vw",
    width: "35vw",
    height: "50vh",
    [theme.breakpoints.between("xs", "sm")]: {
      width: "35vw",
      height: "40vh",
    },
  },

  blueTitle: {
    ...generateFontStyling("20px", "Merriweather", "25px", "#009AAA", 700),
    [theme.breakpoints.down("xs")]: {
      ...generateFontStyling("18px", "Merriweather", "20px", "#009AAA", 700),
    },
  },
  content: {
    ...generateFontStyling("17px", "Lato", "25px", "#3B3A3C", 500),
    marginTop: "20px",
    [theme.breakpoints.down("xs")]: {
      ...generateFontStyling("16px", "Lato", "18px", "#3B3A3C", 500),
      marginTop: "10px",
    },
  },
  bottomContainer: {
    marginTop: "15vh",
    [theme.breakpoints.between("xs", "sm")]: {
      marginTop: "10vh",
    },
    [theme.breakpoints.down("xs")]: {
      marginTop: "3vh",
    },
  },
  footer: {
    position: "absolute",
    left: "-3vw",
    right: "-3vw",
    marginTop: "2vh",
  },
}));

export default useStyles;
