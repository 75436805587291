import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import React from "react";
import error from "../assets/emptyState/error.svg";
import connect from "../assets/icons/connect.svg";
import tryagain from "../assets/icons/tryagain.svg";
import mail from "../assets/icons/mail.svg";
import { logError } from "../api";

class ErrorBoundary extends React.Component<any, any> {
  constructor(props: any) {
    super(props);
    this.state = { hasError: false };
  }
  static getDerivedStateFromError(error: any) {
    return { hasError: true };
  }
  componentDidCatch(error: any, errorInfo: any) {
    const username = this.props.user.attributes.email;
    const userId = this.props.user.attributes["custom:userName"];
    const role = this.props.user.attributes["custom:role"];
    const log = {
      username: username,
      userId: userId,
      role: role,
      error: error.message,
      errorInfo: errorInfo.componentStack,
    };
    console.log(log);
    logError(log).then((res) => console.log(res));
  }
  render() {
    console.log(this.state.hasError);
    if (this.state.hasError) {
      return (
        <Box
          display={"flex"}
          alignItems={"center"}
          flexDirection={"column"}
          minHeight={"80vh"}
          style={{ background: "#F3F6F8" }}
        >
          <Box marginTop={"60px"} marginBottom={"30px"}>
            <img src={error} alt="" />
          </Box>
          <Typography variant="h3">Well, this is embarrasing...</Typography>
          <Box marginTop={"25px"} marginBottom={"50px"} width="48vw" textAlign={"center"}>
            <Typography variant="caption">
              Sorry, we’re having trouble loading this page. We now know about this mistake and are
              working to fix it. In the meanwhile, here’s what you can do :
            </Typography>
          </Box>
          <Box>
            <Box display={"flex"} alignItems={"center"} marginBottom={"10px"}>
              <img src={connect} alt="" style={{ marginRight: "10px" }} />
              <Typography variant="subtitle1">
                Make sure you’re connected to the Internet
              </Typography>
            </Box>
            <Box display={"flex"} alignItems={"center"} marginBottom={"10px"}>
              <img src={tryagain} alt="" style={{ marginRight: "10px" }} />
              <Typography variant="subtitle1">Try again in 5 minutes</Typography>
            </Box>
            <Box display={"flex"} alignItems={"center"}>
              <img src={mail} alt="" style={{ marginRight: "10px" }} />
              <Typography variant="subtitle1">Email us at support@digienrol.com.au</Typography>
            </Box>
          </Box>
        </Box>
      );
    }
    return this.props.children;
  }
}

export default ErrorBoundary;
