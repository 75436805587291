import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import withStyles from "@material-ui/core/styles/withStyles";
import Link from "@material-ui/core/Link";
import Modal from "@material-ui/core/Modal";
import {Component} from "react";
import LoadingScreen from "../../components/LoadingScreen";
import {
  getAllAdminApplications,
  getAllApplications,
  getAllChildren,
  getActiveSubscription,
  getAllCampusAnalytics,
} from "../../api";
import useStyles from "./style";
import ApplicationDetail from "./ApplicationDetail";
import ChildDetail from "../../components/ChildDetail";
import TableHeader from "../../components/TableHeader";
import GenericButton from "../../components/GenericButton";
import filter from "../../assets/icons/filter.svg";
import searchIcon from "../../assets/icons/search.svg";
import SearchBar from "material-ui-search-bar";
import applicationEmptyState from "../../assets/emptyState/application.svg";
import adminEmptyState from "../../assets/emptyState/admin.svg";
import searchEmptyState from "../../assets/emptyState/search.svg";
import ApplicationDetailAdmin from "./ApplicationDetailAdmin";
import {Cookies, withCookies} from "react-cookie";
import {instanceOf} from "prop-types";
import Filter from "./Filter";
import ReactGA from "react-ga";
import configFile from "../../config/root-config";
const TRACKING_ID = configFile.googleAnalyticsTrackingId; // OUR_TRACKING_ID
ReactGA.initialize(TRACKING_ID);

class Applications extends Component<any, any> {
  static propTypes = {
    cookies: instanceOf(Cookies).isRequired,
  };
  constructor(props: any) {
    super(props);
    this.state = {
      details: [],
      populateData: {},
      childDetails: [],
      loadingChild: false,
      loading: true,
      error: false,
      viewAllApplications: false,
      searchValue: "",
      filter: {status: "All", classes: []},
      isFilterModalOpen: false,
      loadingMaxApps: false,
      maxAppModal: false,
      schoolName: "school",
    };
    this.handleClick = this.handleClick.bind(this);
    this.handleTable = this.handleTable.bind(this);
    this.handleFilter = this.handleFilter.bind(this);
    this.handleTableAdmin = this.handleTableAdmin.bind(this);
    this.toggleFilterModal = this.toggleFilterModal.bind(this);
    this.modalClose = this.modalClose.bind(this);
  }
  toggleFilterModal() {
    this.setState({
      ...this.state,
      isFilterModalOpen: !this.state.isFilterModalOpen,
    });
  }
  handleClick() {
    ReactGA.event({
      category: "Solvitude",
      action: "New Application",
      label: "label",
    });
    const {cookies} = this.props;
    if (cookies.get("school")) {
      this.state.childDetails.length === 0
        ? this.props.history.push(`/newApplication/${cookies.get("school")}`, {
            childDetails: {},
            populateData: this.state.populateData,
          })
        : this.props.history.push("/newApplication/selectchild", {
            childDetails: this.state.childDetails,
            populateData: this.state.populateData,
            organisationName: cookies.get("school").replace(/_/g, " "),
          });
    } else
      this.props.history.push("/newApplication/selectorganisation", {
        childDetails: this.state.childDetails,
        populateData: this.state.populateData,
      });
  }
  async handleTable(
    applicationId: any,
    orgName: any,
    childId: any,
    orgId: any,
    campusId: any
  ) {
    this.setState({loading: true, loadingMaxApps: true});
    let maxApps = 0;
    let numOfApps = 0;
    let status = "";
    this.setState({schoolName: orgName});
    await getActiveSubscription(orgId, campusId)
      .then((res) => {
        console.log("Subscription Result");
        console.log(res.data);
        maxApps = res.data.features.maxApps;
        status = res.data.status;
        console.log("Max Apps", maxApps);
        this.setState({loadingMaxApps: false});
      })
      .catch((err) => {
        console.log("Subscription Error");
        console.log(err);
        this.setState({loadingMaxApps: false});
      });
    await getAllCampusAnalytics(campusId)
      .then((res) => {
        console.log("Campus Analytics");
        console.log(res.data.classes.all);
        const data = res.data.classes.all;
        numOfApps = data.total - data.Draft;
        console.log("Num of Apps", numOfApps);
        this.setState({loading: false});
      })
      .catch((err) => {
        console.log(err);
        this.setState({loading: false});
      });
    if (numOfApps >= maxApps || status !== "Active") {
      this.setState({maxAppModal: true});
    } else {
      this.props.history.push(
        `/newApplication/${orgName.replace(/\s/g, "_")}/${applicationId}`,
        {
          childDetails: this.state.childDetails.find(
            (detail: any) => detail.childId === childId
          ),
          populateData: this.state.populateData,
        }
      );
    }
  }
  handleTableAdmin(applicationId: any) {
    this.props.history.push(`/viewApplication/${applicationId}`, {
      data: this.state.details.find(
        (detail: any) => detail.applicationId === applicationId
      ),
    });
  }
  handleFilter(classes: any, status: string, clear?: any) {
    this.setState({
      filter: {
        status: status,
        classes: classes,
      },
      ...(!clear && {
        isFilterModalOpen: false,
      }),
    });
  }
  componentDidMount() {
    window.scrollTo(0, 0);
    const customerId = this.props.user.attributes["custom:userName"];
    if (
      this.props.user?.attributes["custom:role"] === "Org_Admin" ||
      this.props.user?.attributes["custom:role"] === "Org_User"
    )
      getAllAdminApplications(this.props.user?.attributes["custom:campusId"])
        .then((res) => {
          console.log(res);
          const data = res.data;
          this.setState({details: data, loading: false});
        })
        .catch((err: any) => {
          console.log(err);
          this.setState({loading: false, error: true});
          if (err.response) {
            console.log(err.response.status);
            if (err.response.status === 401) this.props.handleLogout();
          }
        });
    else {
      this.setState({loadingChild: true});
      getAllApplications(customerId)
        .then((res) => {
          console.log(res);
          const data = res.data;
          this.setState({details: data, loading: false});
        })
        .catch((err) => {
          console.log(err);
          this.setState({loading: false, error: true});
          if (err.response) {
            console.log(err.response.status);
            if (err.response.status === 401) this.props.handleLogout();
          }
        });

      getAllChildren(customerId)
        .then((res) => {
          console.log(res.data);
          if (Object.keys(res.data).length !== 0)
            this.setState({
              populateData: res.data,
              childDetails: res.data.childList,
              loadingChild: false,
            });
          else {
            this.setState({
              loadingChild: false,
              populateData: {
                parentDetails: {},
              },
            });
          }
        })
        .catch((err) => {
          this.setState({loadingChild: false});
          if (err.response) {
            console.log(err.response.status);
            if (err.response.status === 401) this.props.handleLogout();
          }
        });
    }
  }

  modalClose() {
    this.setState({maxAppModal: false});
  }

  render() {
    const paper = {
      position: "absolute" as "absolute",
      left: "50%",
      top: "45%",
      transform: "translate(-50%, -50%)",
      width: "50%",
      backgroundColor: "#FFF",
      border: "0px solid #000",
      borderRadius: "15px",
      padding: "10px 30px 30px 30px",
    };
    const {classes} = this.props;
    console.log("Applications Props");
    console.log(this.props);
    const isParent = !(
      this.props.user?.attributes["custom:role"] === "Org_Admin" ||
      this.props.user?.attributes["custom:role"] === "Org_User"
    );
    const name = this.props.user.attributes["custom:firstName"];

    const listOfApplications = isParent
      ? this.state.details
          .sort(function (a: any, b: any) {
            var dateA: any = new Date(a.auditDetails.createdAt),
              dateB: any = new Date(b.auditDetails.createdAt);
            return dateB - dateA; //sort by date ascending
          })
          .map((data: any, index: any) => {
            return (
              <ApplicationDetail
                isParent={isParent}
                data={data}
                handleTable={this.handleTable}
                key={index}
              />
            );
          })
      : this.state.details
          .filter((data: any) => {
            if (data.status !== "Draft") return data;
            else return null;
          })
          .filter((data: any) => {
            if (this.state.searchValue === "") {
              return data;
            } else if (
              data.applicantDetails.firstName
                .toLowerCase()
                .includes(this.state.searchValue.toLowerCase()) ||
              data.applicantDetails.lastName
                .toLowerCase()
                .includes(this.state.searchValue.toLowerCase()) ||
              (
                data.applicantDetails.firstName +
                " " +
                data.applicantDetails.lastName
              )
                .toLowerCase()
                .includes(this.state.searchValue.toLowerCase())
            ) {
              return data;
            } else return null;
          })
          .filter((data: any) => {
            if (
              this.state.filter.status === "All" &&
              this.state.filter.classes.length === 0
            ) {
              return data;
            } else if (
              this.state.filter.status === "All" &&
              this.state.filter.classes.length !== 0
            ) {
              if (
                this.state.filter.classes.includes(data.additionalInfo?.class)
              )
                return data;
              else return null;
            } else if (
              this.state.filter.status !== "All" &&
              this.state.filter.classes.length === 0
            ) {
              if (this.state.filter.status === data.status) return data;
              else return null;
            } else {
              if (
                this.state.filter.classes.includes(
                  data.additionalInfo?.class
                ) &&
                this.state.filter.status === data.status
              ) {
                return data;
              } else return null;
            }
          })
          .sort(function (a: any, b: any) {
            var dateA: any = new Date(a.auditDetails.createdAt),
              dateB: any = new Date(b.auditDetails.createdAt);
            return dateB - dateA;
          })
          .map((data: any, index: any) => {
            return (
              <ApplicationDetailAdmin
                isParent={isParent}
                data={data}
                handleTableAdmin={this.handleTableAdmin}
                key={index}
              />
            );
          });
    const shortListOfApplications = listOfApplications.slice(0, 4);

    const title =
      (listOfApplications.length === 0 ? "No" : listOfApplications.length) +
      "   Applications";
    return (
      <Box
        {...(this.state.isFilterModalOpen && {
          style: {
            maxHeight: "90vh",
            overflow: "hidden",
          },
        })}
      >
        {this.state.isFilterModalOpen && (
          <Filter
            filter={this.state.filter}
            toggleFilterModal={this.toggleFilterModal}
            handleFilter={this.handleFilter}
            userCountry={this.props.userCountry}
          />
        )}
        <Modal open={this.state.maxAppModal}>
          <div style={paper}>
            <h2>Error</h2>
            <hr />
            <Typography
              variant="subtitle2"
              style={{
                fontSize: "16px",
                marginBottom: "25px",
                marginTop: "25px",
              }}
            >
              Sorry, school is not accepting any more application for this year.
              Please contact {this.state.schoolName} for more details.
            </Typography>
            <Grid container spacing={2} style={{marginTop: "25px"}}>
              <Grid item xs="auto">
                <GenericButton
                  id="ok"
                  variant="contained"
                  onClick={this.modalClose}
                  background="white"
                >
                  OK
                </GenericButton>
              </Grid>
            </Grid>
          </div>
        </Modal>
        <Typography
          variant="h1"
          className={classes.title}
          {...(!isParent && {style: {color: "#333333"}})}
        >
          Hello, {name}!
        </Typography>

        <Grid container className={classes.container}>
          {(this.state.loading ||
            this.state.loadingChild ||
            this.state.loadingMaxApps) && (
            <LoadingScreen
              user={this.props.user}
              history={this.props.history}
            />
          )}
          <Grid
            item
            {...(isParent && {md: 9, sm: 12, xs: 12})}
            {...((!isParent ||
              (listOfApplications.length === 0 &&
                this.state.childDetails.length === 0)) && {
              md: 12,
              sm: 12,
              xs: 12,
            })}
          >
            <Box className={classes.applications}>
              <TableHeader
                isParent={isParent}
                title={title}
                button="New Application"
                onClick={this.handleClick}
                showExport={this.props.features?.exporting}
                data={this.state.details
                  .filter((data: any) => {
                    if (data.status !== "Draft") return data;
                    else return null;
                  })
                  .filter((data: any) => {
                    if (this.state.searchValue === "") {
                      return data;
                    } else if (
                      data.applicantDetails.firstName
                        .toLowerCase()
                        .includes(this.state.searchValue.toLowerCase()) ||
                      data.applicantDetails.lastName
                        .toLowerCase()
                        .includes(this.state.searchValue.toLowerCase()) ||
                      (
                        data.applicantDetails.firstName +
                        " " +
                        data.applicantDetails.lastName
                      )
                        .toLowerCase()
                        .includes(this.state.searchValue.toLowerCase())
                    ) {
                      return data;
                    } else return null;
                  })
                  .filter((data: any) => {
                    if (
                      this.state.filter.status === "All" &&
                      this.state.filter.classes.length === 0
                    ) {
                      return data;
                    } else if (
                      this.state.filter.status === "All" &&
                      this.state.filter.classes.length !== 0
                    ) {
                      if (
                        this.state.filter.classes.includes(
                          data.additionalInfo?.class
                        )
                      )
                        return data;
                      else return null;
                    } else if (
                      this.state.filter.status !== "All" &&
                      this.state.filter.classes.length === 0
                    ) {
                      if (this.state.filter.status === data.status) return data;
                      else return null;
                    } else {
                      if (
                        this.state.filter.classes.includes(
                          data.additionalInfo?.class
                        ) &&
                        this.state.filter.status === data.status
                      ) {
                        return data;
                      } else return null;
                    }
                  })}
              />
              {!isParent && (
                <Grid
                  container
                  spacing={3}
                  style={{marginTop: "15px", marginBottom: "10px"}}
                >
                  <Grid item md={11} sm={10} xs={9}>
                    <SearchBar
                      value={this.state.searchValue}
                      onChange={(newValue: any) =>
                        this.setState({searchValue: newValue})
                      }
                      placeholder="Search by Student Name"
                      style={{borderRadius: "25px", background: "#F3F6F8"}}
                      searchIcon={<img src={searchIcon} alt="" />}
                      onCancelSearch={() => this.setState({searchValue: ""})}
                    />
                  </Grid>
                  {}
                  <Grid item md={1} sm={2} xs={3}>
                    <GenericButton
                      background="white"
                      variant="outlined"
                      fullWidth={true}
                      onClick={this.toggleFilterModal}
                    >
                      <img src={filter} alt="" style={{marginRight: "0.5em"}} />
                      Filter
                    </GenericButton>
                  </Grid>
                </Grid>
              )}
              {listOfApplications.length ? (
                this.state.viewAllApplications ? (
                  <>
                    {listOfApplications}
                    <Box
                      marginTop="1em"
                      key="box"
                      display="flex"
                      justifyContent="center"
                      alignItems="center"
                    >
                      <Link
                        component="button"
                        variant="body2"
                        onClick={() => {
                          this.setState({viewAllApplications: false});
                        }}
                      >
                        View Less
                      </Link>
                    </Box>
                  </>
                ) : (
                  <>
                    {shortListOfApplications}
                    {listOfApplications.length > 4 && (
                      <Box
                        marginTop="1em"
                        key="box"
                        display="flex"
                        justifyContent="center"
                        alignItems="center"
                      >
                        <Link
                          component="button"
                          variant="body2"
                          onClick={() => {
                            this.setState({viewAllApplications: true});
                          }}
                        >
                          View All
                        </Link>
                      </Box>
                    )}
                  </>
                )
              ) : (
                !(
                  this.state.loading ||
                  this.state.loadingChild ||
                  this.state.loadingMaxApps
                ) && (
                  <Box
                    display="flex"
                    flexDirection="column"
                    justifyContent="center"
                    alignItems="center"
                  >
                    <img
                      src={
                        isParent
                          ? applicationEmptyState
                          : this.state.searchValue ||
                            this.state.filter.status !== "All" ||
                            this.state.filter.classes.length !== 0
                          ? searchEmptyState
                          : adminEmptyState
                      }
                      alt=""
                    />
                    {!isParent && (
                      <Box marginTop="10px" className={classes.emptyTitle}>
                        {this.state.searchValue ||
                        this.state.filter.status !== "All" ||
                        this.state.filter.classes.length !== 0
                          ? "No Results Found"
                          : "No Applications Yet"}
                      </Box>
                    )}
                    <Box textAlign="center" className={classes.emptyState}>
                      {isParent
                        ? "Click on ‘New Application’ to start a new application and get started!"
                        : this.state.searchValue ||
                          this.state.filter.status !== "All" ||
                          this.state.filter.classes.length !== 0
                        ? "Try adjusting your search or filter to find what you’re looking for."
                        : "All applications received by your school will appear here. "}
                    </Box>
                  </Box>
                )
              )}
            </Box>
          </Grid>
          {isParent && this.state.childDetails.length !== 0 && (
            <Grid item md={3} sm={12} xs={12}>
              <Box className={classes.children}>
                <Typography
                  variant="h2"
                  style={{marginTop: "0.4em", marginBottom: "1em"}}
                >
                  Applicants
                </Typography>
                <Box className={classes.childContainer}>
                  {this.state.childDetails.map((data: any, index: any) => {
                    return <ChildDetail data={data} key={index} />;
                  })}
                </Box>
              </Box>
            </Grid>
          )}
        </Grid>
      </Box>
    );
  }
}
export default withStyles(useStyles, {withTheme: true})(
  withCookies(Applications)
);
