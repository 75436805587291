const useStyles: any = (theme: any) => ({
  root: {
    display: "flex",
    flexDirection: "row",
  },
  container: {
    [theme.breakpoints.between(0, "xs")]: {
    },
  },
  searchBar: {
    width: "80%",
    boxSizing: "border-box",
    [theme.breakpoints.between("xs", "sm")]: {
      width: "76%",
    },
    [theme.breakpoints.between("0", "xs")]: {
      width: "56%",
    },
  },
  filters: {
    width: "8%",
    boxSizing: "border-box",
    [theme.breakpoints.between("xs", "sm")]: {
      width: "10%",
    },
    [theme.breakpoints.between("0", "xs")]: {
      width: "20%",
    },
  },
  emptyState: {
    ...theme.typography.emptyState,
    margin: "10px 0 10px 0",
    [theme.breakpoints.between("xs", "sm")]: {},
  },
  applications: {
    backgroundColor: theme.palette.background.paper,
    borderRadius: "16px",
    marginLeft: theme.spacing(4),
    marginBottom: theme.spacing(4),
    marginRight: theme.spacing(4),
    minHeight: "50vh",
    [theme.breakpoints.between("xs", "sm")]: {
      marginRight: theme.spacing(2),
      marginLeft: theme.spacing(2),
      marginBottom: theme.spacing(2),
      padding: theme.spacing(2),
      minHeight: "70vh",
    },
    [theme.breakpoints.between("0", "xs")]: {
      marginRight: "0.8em",
      marginLeft: "0.8em",
      marginBottom: "0.8em",
      padding: "0.8em",
      minHeight: "60vh",
    },
    padding: theme.spacing(2),
  },
  children: {
    backgroundColor: theme.palette.background.paper,
    borderRadius: "16px",
    marginLeft: theme.spacing(-2),
    marginRight: theme.spacing(4),
    marginBottom: theme.spacing(4),
    [theme.breakpoints.between("xs", "sm")]: {
      marginRight: theme.spacing(2),
      marginLeft: theme.spacing(2),
      marginBottom: theme.spacing(2),
      padding: theme.spacing(2),
    },
    [theme.breakpoints.between("0", "xs")]: {
      marginRight: "0.8em",
      marginLeft: "0.8em",
      marginBottom: "0.8em",
      padding: "0.8em",
    },
    padding: theme.spacing(2),
    position: "sticky",
    left: 0,
    top: 10,
  },
  childContainer: {
    [theme.breakpoints.between("xs", "sm")]: {
      display: "flex",
      flexWrap: "wrap",
    },
  },
  title: {
    margin: theme.spacing(4),
    [theme.breakpoints.between("xs", "sm")]: {
      margin: theme.spacing(2),
    },
    [theme.breakpoints.between("0", "xs")]: {
      marginLeft: "0.4em",
      marginTop: theme.spacing(2),
      marginBottom: theme.spacing(2),
    },
  },
  emptyTitle: {
    ...theme.typography.emptyTitle,
  },
});
export default useStyles;
