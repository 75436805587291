import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import IconButton from "@material-ui/core/IconButton";
import React, {useState} from "react";
import closeIcon from "../../assets/icons/x.svg";
import Controls from "../../components/controls/Controls";
import GenericButton from "../../components/GenericButton";

function Filter(props: any) {
  const [status, setStatus] = useState(props.filter.status);
  const [classes, setClasses] = React.useState<string[]>(props.filter.classes);
  const handleChange = (event: any) => {
    const {
      target: {value},
    } = event;
    setClasses(typeof value === "string" ? value.split(",") : value);
  };
  const smallScreen = useMediaQuery((theme: any) =>
    theme.breakpoints.between("0", "xs")
  );
  const mediumScreen = useMediaQuery((theme: any) =>
    theme.breakpoints.between("xs", "sm")
  );
  var indianOptions: string[] = [
    "Nursery",
    "KG",
    "LKG",
    "UKG",
    "Class 1",
    "Class 2",
    "Class 3",
    "Class 4",
    "Class 5",
    "Class 6",
    "Class 7",
    "Class 8",
    "Class 9",
    "Class 10",
  ];

  var australianOptions: string[] = [
    "Kinder Garden",
    "Prep",
    "Grade 1",
    "Grade 2",
    "Grade 3",
    "Grade 4",
    "Grade 5",
    "Grade 6",
    "Grade 7",
    "Grade 8",
    "Grade 9",
    "Grade 10",
    "Grade 11",
    "Grade 12",
  ];

  return (
    <Box
      width={smallScreen ? "100%" : mediumScreen ? "58%" : "26%"}
      position="absolute"
      right={0}
      zIndex={1}
      padding="25px"
      overflow="scroll"
      maxHeight="90vh"
      minHeight="90vh"
      style={{background: "#FFFFFF"}}
    >
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        marginBottom="25px"
      >
        <Typography variant="h3">Filter {"&"} Sort</Typography>
        <IconButton onClick={props.toggleFilterModal}>
          <img src={closeIcon} alt="" />
        </IconButton>
      </Box>
      <Box marginBottom="10px">
        <Controls.MultiSelect
          id="class"
          label="Class"
          name="class"
          value={classes}
          width="100%"
          onChange={handleChange}
          options={australianOptions}
        />
      </Box>

      <Controls.Select
        id="status"
        label="Status"
        name="status"
        value={status}
        width="100%"
        placeholder="Select Status"
        onChange={(event: any) => setStatus(event.target.value)}
        options={[
          {
            Id: "1",
            Label: "All",
          },
          {
            Id: "2",
            Label: "Submitted",
          },
          {
            Id: "3",
            Label: "Interview Scheduled",
          },
          {
            Id: "4",
            Label: "Post-interview Review",
          },
          {
            Id: "5",
            Label: "Admission Granted",
          },
          {
            Id: "6",
            Label: "Rejected",
          },
        ]}
      />
      <Box marginTop="150px">
        <GenericButton
          fullWidth={true}
          background="blue"
          id="apply"
          onClick={() => props.handleFilter(classes, status)}
        >
          Apply
        </GenericButton>
      </Box>
      <Box marginTop="10px">
        <GenericButton
          fullWidth={true}
          background="light"
          id="clear"
          onClick={() => {
            setClasses([]);
            setStatus("All");
            props.handleFilter([], "All", "clear");
          }}
        >
          Clear all filters
        </GenericButton>
      </Box>
    </Box>
  );
}

export default Filter;
