import FormControl from "@material-ui/core/FormControl";
import FormLabel from "@material-ui/core/FormLabel";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import moment from "moment";
import IconWithTooltip from "../IconWithTooltip";

function Input(props: any) {
  const smallScreen = useMediaQuery((theme: any) =>
    theme.breakpoints.between("0", "xs")
  );
  const {
    id,
    type,
    label,
    name,
    value,
    error = null,
    onChange,
    required = false,
    width,
    tooltip,
    variant,
    maxlength,
    placeholder,
  } = props;

  return (
    <FormControl
      variant={variant || "outlined"}
      style={{
        width: width || (smallScreen ? "100%" : "50%"),
        marginBottom: "20px",
      }}
      {...(required && { required: true })}
    >
      <FormLabel>{label}</FormLabel>
      <Grid
        container
        direction="row"
        style={{ width: (width && "100%") || (smallScreen ? "94%" : "88%") }}
      >
        <TextField
          style={{ width: (width && "100%") || "100%" }}
          size="small"
          onInput={(e: any) => {
            if (type === "number") {
              if (name.includes("Percentage")) {
                if (e.target.value > 100)
                  e.target.value = e.target.value.toString().slice(0, 2);
              } else
                e.target.value = e.target.value.toString().slice(0, maxlength);
            }
          }}
          variant={variant || "outlined"}
          placeholder={placeholder}
          InputLabelProps={{ shrink: true }}
          autoComplete="No"
          id={id}
          type={type}
          name={name}
          value={value}
          onChange={onChange}
          inputProps={{ maxLength: maxlength }}
          {...(error && { error: true, helperText: error })}
          {...(required && { required: true })}
          {...(name.split("_")[0].toLowerCase().includes("birth") && {
            inputProps: {
              max: moment().subtract(3, "years").toISOString().slice(0, 10),
            },
          })}
          {...(name.split("_")[0].toLowerCase().includes("issue") && {
            inputProps: {
              max: moment().subtract(0, "days").toISOString().slice(0, 10),
            },
          })}
        />
        {tooltip && <IconWithTooltip tooltip={tooltip} />}
      </Grid>
    </FormControl>
  );
}

export default Input;
