import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import makeStyles from "@material-ui/core/styles/makeStyles";
import Grid from "@material-ui/core/Grid";
import { useState } from "react";
import { patchBankInfo } from "../../api";
import Controls from "../../components/controls/Controls";
import GenericButton from "../../components/GenericButton";
import LoadingScreen from "../../components/LoadingScreen";
import Alert from "@material-ui/lab/Alert";
import AlertTitle from "@material-ui/lab/AlertTitle";
import successIcon from "../../assets/icons/success.svg";
import errorIcon from "../../assets/icons/error.svg";

const useStyles = makeStyles((theme: any) => ({
  address: {
    "& .MuiOutlinedInput-root": {
      minHeight: "80px",
    },
    "& .MuiOutlinedInput-root.Mui-focused": {
      minHeight: "80px",
    },
    "& .MuiInputBase-root": {
      display: "flex",
      alignItems: "flex-start",
    },
  },

  navTitle: {
    ...theme.typography.profileNav,
  },

  divider: {
    background: "#E5E7E8",
    mixBlendMode: "soft-light",
    opacity: 0.8,
    margin: theme.spacing(3, -1, 3, -1),
  },

  divider2: {
    background: "#E5E7E8",
    margin: theme.spacing(4, 0, 3, 0),
    [theme.breakpoints.between("0", "xs")]: {
      margin: theme.spacing(2, 0, 2, 0),
    },
  },
  alert: {
    background: "#ECEFF1",
    borderRadius: "10px",
    display: "flex",
    alignItems: "center",
    position: "relative",
    "& .MuiAlert-action": {
      display: "flex",
      alignSelf: "flex-start",
      position: "absolute",
      right: "20px",
      top: "15px",
    },
    "& .MuiIconButton-label": {
      border: "1px solid #607D8B",
      borderRadius: "50%",
      width: "20px",
      height: "20px",
    },
    "& .MuiSvgIcon-fontSizeSmall": {
      fontSize: "15px",
      color: "#607D8B",
    },
    "& .MuiAlert-icon": {
      [theme.breakpoints.between("xs", "sm")]: {
        width: "15vw",
        height: "15vw",
        marginLeft: "-10px",
      },
      [theme.breakpoints.between("0", "xs")]: {
        width: "20vw",
        height: "20vw",
        marginLeft: "-10px",
      },
    },
  },
  icon: {
    [theme.breakpoints.between("xs", "sm")]: {
      width: "100%",
      height: "100%",
    },
    [theme.breakpoints.between("0", "xs")]: {
      width: "100%",
      height: "100%",
    },
  },
  alertTitle: {
    ...theme.typography.displayTitle,
    marginBottom: "10px",
    [theme.breakpoints.between("0", "xs")]: {
      fontSize: "13px",
      lineHeight: "17px",
      marginBottom: "5px",
    },
  },
}));

function BankDetails(props: any) {
  const classes = useStyles();
  const [success, setSuccess] = useState(false);
  const [error, setError]: any = useState("");
  const country = props.user.attributes["custom:country"] || "India";
  const [details, setDetails] = useState(
    props.details ||
      (country === "India"
        ? {
            accountHolderName: "",
            accountType: "",
            bank: "",
            upiId: "",
            accountNo: "",
            ifsc: "",
            mobileNo: "",
          }
        : {
            accountHolderName: "",
            bsb: "",
            accountNo: "",
          })
  );
  const [errors, setErrors] = useState(
    country === "India"
      ? {
          accountHolderName: "",
          accountType: "",
          bank: "",
          upiId: "",
          accountNo: "",
          ifsc: "",
          mobileNo: "",
        }
      : {
          accountHolderName: "",
          bsb: "",
          accountNo: "",
        }
  );
  const [loading, setLoading] = useState(false);
  const handleChange = (event: any) => {
    setDetails({ ...details, [event.target.name]: event.target.value });
    if (!event.target.value)
      setErrors({ ...errors, [event.target.name]: "Please enter a value" });
    else if (
      event.target.name === "upiId" &&
      !/[a-zA-Z0-9.\-_]{2,256}@[a-zA-Z]{2,64}/.test(event.target.value)
    )
      setErrors({
        ...errors,
        [event.target.name]: "Please enter the id in standard format",
      });
    else if (
      event.target.name === "mobileNo" &&
      event.target.value.length !== 10
    )
      setErrors({
        ...errors,
        [event.target.name]: "Please enter a 10 digit mobile number",
      });
      else if (
        event.target.name === "bsb" &&
        event.target.value.length !== 6
      )
        setErrors({
          ...errors,
          [event.target.name]: "Please enter a 6 digit BSB code",
        });
    else setErrors({ ...errors, [event.target.name]: "" });
  };
  const hanldeSave = () => {
    setLoading(true);
    setSuccess(false);
    setError("");
    const orgId = props.user.attributes["custom:orgId"];
    const campusId = props.user.attributes["custom:campusId"];
    const bankInfo = {
      country: country,
      bankData: details,
    };
    patchBankInfo(orgId, campusId, bankInfo)
      .then((res) => {
        console.log(res);
        setLoading(false);
        props.updateBankInfo(details);
        props.toggleEdit();
        props.updateProfile("bankDetails");
        setSuccess(true);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
        setError("Error");
      });
  };
  if (country === "India")
    return props.edit || props.details === undefined ? (
      <>
        {loading && <LoadingScreen />}
        {error && (
          <Box marginBottom="25px">
            <Alert
              icon={<img className={classes.icon} src={errorIcon} alt="" />}
              className={classes.alert}
              onClose={() => {
                setError("");
              }}
            >
              <AlertTitle
                className={classes.alertTitle}
                style={{ color: "#F9347B" }}
              >
                Update Failed
              </AlertTitle>
              <Typography variant="caption">Please try again later.</Typography>
            </Alert>
          </Box>
        )}
        <Grid container spacing={2}>
          <Grid item md={6} sm={8} xs={8}>
            <Controls.Input
              width="100%"
              id="name"
              type="text"
              label="Bank Account Name"
              name="accountHolderName"
              value={details.accountHolderName}
              onChange={handleChange}
              error={errors.accountHolderName}
            />
          </Grid>
        </Grid>
        <Grid container spacing={2}>
          <Grid item md={3} sm={6} xs={12}>
            <Controls.Input
              width="100%"
              id="bank"
              type="text"
              label="Bank"
              name="bank"
              value={details.bank}
              onChange={handleChange}
              error={errors.bank}
            />
          </Grid>
          <Grid item md={3} sm={6} xs={12}>
            <Controls.Input
              width="100%"
              id="accType"
              type="text"
              label="Account Type"
              name="accountType"
              value={details.accountType}
              onChange={handleChange}
              error={errors.accountType}
            />
          </Grid>
        </Grid>
        <Grid container spacing={2}>
          <Grid item md={3} sm={6} xs={12}>
            <Controls.Input
              width="100%"
              id="accNo"
              type="number"
              label="Account Number"
              name="accountNo"
              value={details.accountNo}
              onChange={handleChange}
              error={errors.accountNo}
              maxlength={17}
            />
          </Grid>
          <Grid item md={3} sm={6} xs={12}>
            <Controls.Input
              width="100%"
              id="ifsc"
              type="text"
              label="IFSC Code"
              name="ifsc"
              value={details.ifsc}
              onChange={handleChange}
              error={errors.ifsc}
            />
          </Grid>
        </Grid>
        <Grid container spacing={2}>
          <Grid item md={3} sm={6} xs={12}>
            <Controls.Input
              width="100%"
              id="upiId"
              type="text"
              label="UPI ID"
              name="upiId"
              value={details.upiId}
              onChange={handleChange}
              error={errors.upiId}
            />
          </Grid>
          <Grid item md={3} sm={6} xs={12}>
            <Controls.Input
              width="100%"
              id="mobileNo"
              type="number"
              label="Mobile Number"
              name="mobileNo"
              value={details.mobileNo}
              onChange={handleChange}
              error={errors.mobileNo}
              maxlength={10}
            />
          </Grid>
        </Grid>
        <Box display="flex" justifyContent="flex-end">
          {props.details !== undefined && (
            <Box marginRight="25px">
              <GenericButton
                background="light"
                onClick={() => {
                  props.toggleEdit();
                  setDetails(props.details);
                  setError("");
                  setSuccess(false);
                }}
              >
                Cancel
              </GenericButton>
            </Box>
          )}
          <GenericButton
            background="blue"
            disabled={
              Object.values(errors).every((x) => x === "") ? false : true
            }
            onClick={hanldeSave}
          >
            Save
          </GenericButton>
        </Box>
      </>
    ) : (
      <>
        {success && (
          <Box marginBottom="25px">
            <Alert
              icon={<img className={classes.icon} src={successIcon} alt="" />}
              onClose={() => {
                setError("");
                setSuccess(false);
              }}
              className={classes.alert}
            >
              <AlertTitle className={classes.alertTitle}>
                Details Updated Successfully
              </AlertTitle>
              {}
            </Alert>
          </Box>
        )}
        <Grid container spacing={4}>
          <Grid item md={6} sm={12} xs={12}>
            <Typography variant="subtitle2" style={{ marginBottom: "8px" }}>
              Bank Account Name
            </Typography>
            <Typography variant="caption">
              {details.accountHolderName}
            </Typography>
          </Grid>
        </Grid>
        <Grid container spacing={4}>
          <Grid item md={3} sm={6} xs={12}>
            <Typography variant="subtitle2" style={{ marginBottom: "8px" }}>
              Bank
            </Typography>
            <Typography variant="caption">{details.bank}</Typography>
          </Grid>
          <Grid item md={3} sm={6} xs={12}>
            <Typography variant="subtitle2" style={{ marginBottom: "8px" }}>
              Account Type
            </Typography>
            <Typography variant="caption">{details.accountType}</Typography>
          </Grid>
        </Grid>
        <Grid container spacing={4}>
          <Grid item md={3} sm={6} xs={12}>
            <Typography variant="subtitle2" style={{ marginBottom: "8px" }}>
              Account Number
            </Typography>
            <Typography variant="caption">{details.accountNo}</Typography>
          </Grid>
          <Grid item md={3} sm={6} xs={12}>
            <Typography variant="subtitle2" style={{ marginBottom: "8px" }}>
              IFSC Code
            </Typography>
            <Typography variant="caption">{details.ifsc}</Typography>
          </Grid>
        </Grid>
        <Grid container spacing={4}>
          <Grid item md={3} sm={6} xs={12}>
            <Typography variant="subtitle2" style={{ marginBottom: "8px" }}>
              UPI ID
            </Typography>
            <Typography variant="caption">{details.upiId}</Typography>
          </Grid>
          <Grid item md={3} sm={6} xs={12}>
            <Typography variant="subtitle2" style={{ marginBottom: "8px" }}>
              Mobile Number
            </Typography>
            <Typography variant="caption">{details.mobileNo}</Typography>
          </Grid>
        </Grid>
      </>
    );
  return props.edit || props.details === undefined ? (
    <>
      {loading && <LoadingScreen />}
      {error && (
        <Box marginBottom="25px">
          <Alert
            icon={<img className={classes.icon} src={errorIcon} alt="" />}
            className={classes.alert}
            onClose={() => {
              setError("");
            }}
          >
            <AlertTitle
              className={classes.alertTitle}
              style={{ color: "#F9347B" }}
            >
              Update Failed
            </AlertTitle>
            <Typography variant="caption">Please try again later.</Typography>
          </Alert>
        </Box>
      )}
      <Grid container spacing={2}>
        <Grid item md={6} sm={8} xs={8}>
          <Controls.Input
            width="100%"
            id="name"
            type="text"
            label="Bank Account Name"
            name="accountHolderName"
            value={details.accountHolderName}
            onChange={handleChange}
            error={errors.accountHolderName}
          />
        </Grid>
      </Grid>
      <Grid container spacing={2}>
        <Grid item md={3} sm={6} xs={12}>
          <Controls.Input
            width="100%"
            id="bsb"
            type="number"
            label="BSB"
            name="bsb"
            value={details.bsb}
            onChange={handleChange}
            error={errors.bsb}
            maxlength={6}
          />
        </Grid>
        <Grid item md={3} sm={6} xs={12}>
          <Controls.Input
            width="100%"
            id="accNo"
            type="number"
            label="Account Number"
            name="accountNo"
            value={details.accountNo}
            onChange={handleChange}
            error={errors.accountNo}
            maxlength={10}
          />
        </Grid>
      </Grid>
      <Box display="flex" justifyContent="flex-end">
        {props.details !== undefined && (
          <Box marginRight="25px">
            <GenericButton background="light">Cancel</GenericButton>
          </Box>
        )}
        <GenericButton
          background="blue"
          disabled={Object.values(errors).every((x) => x === "") ? false : true}
          onClick={hanldeSave}
        >
          Save
        </GenericButton>
      </Box>
    </>
  ) : (
    <>
      {success && (
        <Box marginBottom="25px">
          <Alert
            icon={<img className={classes.icon} src={successIcon} alt="" />}
            onClose={() => {
              setError("");
              setSuccess(false);
            }}
            className={classes.alert}
          >
            <AlertTitle className={classes.alertTitle}>
              Details Updated Successfully
            </AlertTitle>
          </Alert>
        </Box>
      )}
      <Grid container spacing={4}>
        <Grid item md={6} sm={12} xs={12}>
          <Typography variant="subtitle2" style={{ marginBottom: "8px" }}>
            Bank Account Name
          </Typography>
          <Typography variant="caption">{details.accountHolderName}</Typography>
        </Grid>
      </Grid>
      <Grid container spacing={4}>
        <Grid item md={3} sm={6} xs={12}>
          <Typography variant="subtitle2" style={{ marginBottom: "8px" }}>
            BSB
          </Typography>
          <Typography variant="caption">{details.bsb}</Typography>
        </Grid>
        <Grid item md={3} sm={6} xs={12}>
          <Typography variant="subtitle2" style={{ marginBottom: "8px" }}>
            Account Number
          </Typography>
          <Typography variant="caption">{details.accountNo}</Typography>
        </Grid>
      </Grid>
    </>
  );
}

export default BankDetails;
