import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import makeStyles from "@material-ui/core/styles/makeStyles";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import FormLabel from "@material-ui/core/FormLabel";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import Divider from "@material-ui/core/Divider";
import Alert from "@material-ui/lab/Alert";
import AlertTitle from "@material-ui/lab/AlertTitle";
import {useState} from "react";
import GenericButton from "../../components/GenericButton";
import LoadingScreen from "../../components/LoadingScreen";
import {Validate} from "../../validations/ErrorHandlers";
import successIcon from "../../assets/icons/success.svg";
import errorIcon from "../../assets/icons/error.svg";
import {Country, State, City} from "country-state-city";
import {patchCampusDetails, patchOrgDetails} from "../../api";

const useStyles = makeStyles((theme: any) => ({
  container: {
    background: "#F6F8FA",
    borderRadius: "18px",
    padding: theme.spacing(3, 5, 3, 5),
    width: "auto",
    [theme.breakpoints.between("xs", "sm")]: {
      width: "100%",
    },
    [theme.breakpoints.between("0", "xs")]: {
      width: "100%",
      padding: theme.spacing(2),
    },
  },
  address: {
    "& .MuiOutlinedInput-root": {
      minHeight: "80px",
    },
    "& .MuiOutlinedInput-root.Mui-focused": {
      minHeight: "80px",
    },
    "& .MuiInputBase-root": {
      display: "flex",
      alignItems: "flex-start",
    },
  },

  navTitle: {
    ...theme.typography.profileNav,
  },

  divider: {
    background: "#E5E7E8",
    mixBlendMode: "soft-light",
    opacity: 0.8,
    margin: theme.spacing(3, -1, 3, -1),
  },

  divider2: {
    background: "#E5E7E8",
    margin: theme.spacing(4, 0, 3, 0),
    [theme.breakpoints.between("0", "xs")]: {
      margin: theme.spacing(2, 0, 2, 0),
    },
  },
  input: {
    "& input.Mui-disabled": {
      color: "#BBB",
    },
  },
  alert: {
    background: "#ECEFF1",
    borderRadius: "10px",
    display: "flex",
    alignItems: "center",
    position: "relative",
    "& .MuiAlert-action": {
      display: "flex",
      alignSelf: "flex-start",
      position: "absolute",
      right: "20px",
      top: "15px",
    },
    "& .MuiIconButton-label": {
      border: "1px solid #607D8B",
      borderRadius: "50%",
      width: "20px",
      height: "20px",
    },
    "& .MuiSvgIcon-fontSizeSmall": {
      fontSize: "15px",
      color: "#607D8B",
    },
    "& .MuiAlert-icon": {
      [theme.breakpoints.between("xs", "sm")]: {
        width: "15vw",
        height: "15vw",
        marginLeft: "-10px",
      },
      [theme.breakpoints.between("0", "xs")]: {
        width: "20vw",
        height: "20vw",
        marginLeft: "-10px",
      },
    },
  },
  icon: {
    [theme.breakpoints.between("xs", "sm")]: {
      width: "100%",
      height: "100%",
    },
    [theme.breakpoints.between("0", "xs")]: {
      width: "100%",
      height: "100%",
    },
  },
  alertTitle: {
    ...theme.typography.displayTitle,
    marginBottom: "10px",
    [theme.breakpoints.between("0", "xs")]: {
      fontSize: "13px",
      lineHeight: "17px",
      marginBottom: "5px",
    },
  },
}));

const boardOptions = [
  {
    id: "1",
    value: "State Board",
    label: "State Board",
  },
  {
    id: "2",
    value: "CBSE",
    label: "CBSE",
  },
  {
    id: "3",
    value: "ICSE",
    label: "ICSE",
  },
  {
    id: "4",
    value: "IB",
    label: "IB",
  },
];

function OrganisationDetails(props: any) {
  const classes = useStyles();
  const {
    role,
    edit,
    user,
    toggleEdit,
    orgDetails,
    campusData,
    updateOrgData,
    updateCampusData,
  } = props;

  const userCountry = user.attributes["custom:country"] || "India";
  const [organisation, setOrganisation] = useState({
    values: {
      orgName: orgDetails.orgName,
      address: orgDetails.orgDetails.address
        ? orgDetails.orgDetails.address
        : "",
      state: orgDetails.orgDetails.state ? orgDetails.orgDetails.state : "",
      city: orgDetails.orgDetails.city ? orgDetails.orgDetails.city : "",
      pincode: orgDetails.orgDetails.pincode
        ? orgDetails.orgDetails.pincode
        : "",
      board: orgDetails.orgDetails.board ? orgDetails.orgDetails.board : "",
      website: orgDetails.orgDetails.website
        ? orgDetails.orgDetails.website
        : "",
    },
    errors: {
      orgName: "",
      address: "",
      state: "",
      city: "",
      pincode: "",
      board: "",
      website: "",
    },
    fieldsValidated: false,
  });
  const resetOrg = {
    values: {
      orgName: orgDetails.orgName,
      address: orgDetails.orgDetails.address
        ? orgDetails.orgDetails.address
        : "",
      state: orgDetails.orgDetails.state ? orgDetails.orgDetails.state : "",
      city: orgDetails.orgDetails.city ? orgDetails.orgDetails.city : "",
      pincode: orgDetails.orgDetails.pincode
        ? orgDetails.orgDetails.pincode
        : "",
      board: orgDetails.orgDetails.board ? orgDetails.orgDetails.board : "",
      website: orgDetails.orgDetails.website
        ? orgDetails.orgDetails.website
        : "",
    },
    errors: {
      orgName: "",
      address: "",
      state: "",
      city: "",
      pincode: "",
      board: "",
      website: "",
    },
    fieldsValidated: false,
  };

  const additionalInfo = campusData.additionalInfo;
  const addr = campusData.campusAddress;
  let addressData = addr.split(",").reverse();
  let cityAddr = "";
  let pincodeAddr = "";
  let stateAddr = "";
  let countryAddr = "";
  let addressField = "";
  if (addressData.length > 3) {
    cityAddr = addressData[2].split("-")[0];
    pincodeAddr = addressData[2].split("-")[1];
    stateAddr = addressData[1];
    countryAddr = addressData[0];
    addressField = addressData.slice(3).reverse().join();
  } else {
    addressField = addr;
  }
  console.log("additional Info");
  console.log(additionalInfo);

  const [campus, setCampus] = useState({
    values: {
      campusName: campusData.campusName,
      address: addressField,
      state: stateAddr ? stateAddr : "",
      city: cityAddr ? cityAddr : "",
      pincode: pincodeAddr ? pincodeAddr : "",
      board: campusData.additionalInfo.board
        ? campusData.additionalInfo.board
        : "",
      website: campusData.additionalInfo.website
        ? campusData.additionalInfo.website
        : "",
    },
    errors: {
      campusName: "",
      address: "",
      state: "",
      city: "",
      pincode: "",
      board: "",
      website: "",
    },
    sameAsOrg: true,
    fieldsValidated: false,
  });

  const resetCampus = {
    values: {
      campusName: campusData.campusName,
      address: addressField,
      state: stateAddr ? stateAddr : "",
      city: cityAddr ? cityAddr : "",
      pincode: pincodeAddr ? pincodeAddr : "",
      board: campusData.additionalInfo.board
        ? campusData.additionalInfo.board
        : "",
      website: campusData.additionalInfo.website
        ? campusData.additionalInfo.website
        : "",
    },
    errors: {
      campusName: "",
      address: "",
      state: "",
      city: "",
      pincode: "",
      board: "",
      website: "",
    },
    sameAsOrg: true,
    fieldsValidated: false,
  };
  const [isButtonDisabled, setButtonDisabled] = useState(true);
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const [error, setError]: any = useState("");
  const [sameAsOrg, setSameAsOrg] = useState(false);
  const countries = Country.getAllCountries();
  console.log("Countries: ", countries);
  const country = countries.find(
    (country) => country.name === userCountry
  )?.isoCode;
  console.log("ISOCodes: ", country);

  const States = State.getStatesOfCountry(country || "");
  console.log("States");
  console.log(States);
  const setState = organisation.values.state ? organisation.values.state : "";
  console.log("SetState: ", setState);
  const state = States.find((state) => state.name === setState)?.isoCode;
  const Cities = City.getCitiesOfState(country || "", state || "");
  console.log("Cities");
  console.log(Cities);

  const stateCampus = States.find(
    (stateCampus) => stateCampus.name === campus.values.state
  )?.isoCode;
  const CitiesCampus = City.getCitiesOfState(country || "", stateCampus || "");
  const handleChange = (event: any) => {
    const updatedValues: any = {
      ...organisation,
      values: {
        ...organisation.values,
        [event.target.name]: event.target.value,
      },
    };
    const errors = Validate(event.target.name, updatedValues);
    updatedValues.errors = errors;

    setOrganisation({...updatedValues, fieldsValidated: true});

    handleButton(
      updatedValues.values,
      updatedValues.errors,
      campus.values,
      campus.errors
    );
  };

  const handleCampusChange = (event: any) => {
    const updatedCampusValues: any = {
      ...campus,
      values: {
        ...campus.values,
        [event.target.name]: event.target.value,
      },
    };

    const errors = Validate(event.target.name, updatedCampusValues);
    updatedCampusValues.errors = errors;

    setCampus({...updatedCampusValues, fieldsValidated: true});

    handleButton(
      organisation.values,
      organisation.errors,
      updatedCampusValues.values,
      updatedCampusValues.errors
    );
  };

  const handleButton = (
    orgValues: any,
    orgErrors: any,
    campusValues: any,
    campusErrors: any
  ) => {
    const validateOrgValues = Object.values(orgValues).every((x) => x !== "");
    console.log("OrgV:", validateOrgValues);
    const validateOrgErrors = Object.values(orgErrors).every((x) => x === "");
    console.log("Val:", validateOrgErrors);
    const validateCampusValues = Object.values(campusValues).every(
      (x) => x !== ""
    );
    console.log("OrgV:", validateOrgValues);
    const validateCampusErrors = Object.values(campusErrors).every(
      (x) => x === ""
    );
    console.log("Val:", validateCampusErrors);
    if (sameAsOrg) {
      if (validateOrgValues && validateOrgErrors) {
        setButtonDisabled(false);
        console.log(isButtonDisabled);
      } else setButtonDisabled(true);
    } else {
      if (
        validateOrgValues &&
        validateOrgErrors &&
        validateCampusValues &&
        validateCampusErrors
      ) {
        setButtonDisabled(false);
      } else setButtonDisabled(true);
    }
  };

  const handleSave = async () => {
    setLoading(true);
    setSuccess(false);
    setError("");
    const orgId = user.attributes["custom:orgId"];
    const campusId = user.attributes["custom:campusId"];
    console.log(campusId);
    if (sameAsOrg) {
      const data = {
        orgName: organisation.values.orgName,
        orgDetails: {
          address: organisation.values.address,
          state: organisation.values.state,
          city: organisation.values.city,
          pincode: organisation.values.pincode,
          board: organisation.values.board,
          website: organisation.values.website,
        },
      };
      const campusData = {
        campusName: organisation.values.orgName,
        campusAddress:
          organisation.values.address +
          "," +
          organisation.values.city +
          "-" +
          organisation.values.pincode +
          "," +
          organisation.values.state +
          "," +
          countryAddr,
        additionalInfo: {
          ...additionalInfo,
          address: organisation.values.address,
          state: organisation.values.state,
          city: organisation.values.city,
          pincode: organisation.values.pincode,
          board: organisation.values.board,
          website: organisation.values.website,
        },
      };
      patchOrgDetails(orgId, data)
        .then((res) => {
          console.log(res);
          updateOrgData(data);
          patchCampusDetails(orgId, campusId, campusData)
            .then((res) => {
              console.log(res);
              updateCampusData(campusData);
              setLoading(false);
              setSuccess(true);
              setError("");
            })
            .catch((err) => {
              console.log(err);
              setLoading(false);
              setError("Campus Update Failed!");
            });
        })
        .catch((err) => {
          console.log(err);
          setLoading(false);
          setError("Organisation Update Failed!");
        });
    } else {
      const data = {
        orgName: organisation.values.orgName,
        orgDetails: {
          address: organisation.values.address,
          state: organisation.values.state,
          city: organisation.values.city,
          pincode: organisation.values.pincode,
          board: organisation.values.board,
          website: organisation.values.website,
        },
      };
      const campusData = {
        campusName: campus.values.campusName,
        campusAddress:
          campus.values.address +
          "," +
          campus.values.city +
          "-" +
          campus.values.pincode +
          "," +
          campus.values.state +
          "," +
          countryAddr,
        additionalInfo: {
          ...additionalInfo,
          address: campus.values.address,
          state: campus.values.state,
          city: campus.values.city,
          pincode: campus.values.pincode,
          board: campus.values.board,
          website: campus.values.website,
        },
      };

      patchOrgDetails(orgId, data)
        .then((res) => {
          console.log(res);
          updateOrgData(data);
          patchCampusDetails(orgId, campusId, campusData)
            .then((res) => {
              console.log(res);
              updateCampusData(campusData);
              setLoading(false);
              setSuccess(true);
              setError("");
              toggleEdit();
            })
            .catch((err) => {
              console.log(err);
              setLoading(false);
              setError("Campus Update Failed");
            });
        })
        .catch((err) => {
          console.log(err);
          setLoading(false);
          setError("Organiisation Update Failed");
        });
    }
  };

  const handleSameCampusOrg = (event: any) => {
    if (event.target.checked) setSameAsOrg(true);
    else setSameAsOrg(false);
    handleButton(
      organisation.values,
      organisation.errors,
      campus.values,
      campus.errors
    );
  };

  return role === "Org_Admin" && edit ? (
    <>
      {loading && <LoadingScreen />}
      {error && (
        <Box marginBottom="25px">
          <Alert
            icon={<img className={classes.icon} src={errorIcon} alt="" />}
            className={classes.alert}
            onClose={() => {
              setError("");
            }}
          >
            <AlertTitle
              className={classes.alertTitle}
              style={{color: "#F9347B"}}
            >
              Update Failed
            </AlertTitle>
            <Typography variant="caption">Please try again later.</Typography>
          </Alert>
        </Box>
      )}
      <Box>
        <Grid
          container
          spacing={2}
          md={9}
          xs={12}
          className={classes.container}
        >
          <Grid item md={12} sm={12} xs={12}>
            <FormLabel required>School Name</FormLabel>
            <TextField
              style={{width: "100%", height: "50%"}}
              className={classes.input}
              variant={"outlined"}
              id="orgName"
              type="text"
              name="orgName"
              placeholder="Enter school name"
              value={organisation.values.orgName}
              error={Boolean(organisation.errors.orgName)}
              helperText={organisation.errors.orgName}
              onChange={handleChange}
              disabled={true}
            />
          </Grid>
          <Grid item md={12} sm={12} xs={12}>
            <FormLabel required>Address</FormLabel>
            <TextField
              className={classes.address}
              style={{width: "100%"}}
              variant={"outlined"}
              id="address"
              type="text"
              name="address"
              placeholder="Enter address"
              value={organisation.values.address}
              error={Boolean(organisation.errors.address)}
              helperText={organisation.errors.address}
              onChange={handleChange}
            />
          </Grid>
          <Grid item md={4} sm={4} xs={12}>
            <FormLabel required>State</FormLabel>
            <TextField
              style={{width: "100%", height: "50%"}}
              variant={"outlined"}
              id="state"
              name="state"
              value={organisation.values.state}
              error={Boolean(organisation.errors.state)}
              helperText={organisation.errors.state}
              onChange={handleChange}
              SelectProps={{
                native: true,
              }}
              select
            >
              <option value={""}>Select State</option>
              {}
              {States.map((options: any, index: any) => {
                return (
                  <option
                    id={options.isoCode}
                    key={options.isoCode}
                    value={options.name}
                  >
                    {options.name}
                  </option>
                );
              })}
            </TextField>
          </Grid>
          <Grid item md={4} sm={4} xs={12}>
            <FormLabel required>City</FormLabel>
            <TextField
              style={{width: "100%", height: "50%"}}
              variant="outlined"
              id="city"
              name="city"
              value={organisation.values.city}
              error={Boolean(organisation.errors.city)}
              helperText={organisation.errors.city}
              onChange={handleChange}
              SelectProps={{
                native: true,
              }}
              select
            >
              <option value={""}>Select City</option>
              {Cities.map((options: any, index: any) => {
                return (
                  <option id={options.name} key={index} value={options.name}>
                    {options.name}
                  </option>
                );
              })}
            </TextField>
          </Grid>
          <Grid item md={4} sm={4} xs={12}>
            <FormLabel required>Pincode</FormLabel>
            <TextField
              id="pincode"
              type="number"
              name="pincode"
              style={{width: "100%", height: "50%"}}
              variant="outlined"
              autoComplete="No"
              placeholder="Enter Pincode"
              value={organisation.values.pincode}
              error={Boolean(organisation.errors.pincode)}
              helperText={organisation.errors.pincode}
              onChange={handleChange}
              onInput={(e: any) => {
                e.target.value = Math.max(0, parseInt(e.target.value))
                  .toString()
                  .slice(0, 6);
              }}
            />
          </Grid>
          <Grid item md={6} sm={6} xs={12}>
            <FormLabel required>Board</FormLabel>
            <TextField
              style={{width: "100%", height: "50%"}}
              variant="outlined"
              id="board"
              name="board"
              value={organisation.values.board}
              error={Boolean(organisation.errors.board)}
              helperText={organisation.errors.board}
              onChange={handleChange}
              SelectProps={{
                native: true,
              }}
              select
            >
              <option value={""}>Select Board</option>
              {boardOptions.map((options: any, index: any) => {
                return (
                  <option
                    id={options.id}
                    key={index}
                    value={options.value}
                  >
                    {options.label}
                  </option>
                );
              })}
            </TextField>
          </Grid>
          <Grid item md={6} sm={6} xs={12}>
            <FormLabel required>Website</FormLabel>
            <TextField
              id="website"
              //type="number"
              name="website"
              style={{width: "100%", height: "50%"}}
              variant="outlined"
              autoComplete="No"
              placeholder="Enter School Website"
              value={organisation.values.website}
              error={Boolean(organisation.errors.website)}
              helperText={organisation.errors.website}
              onChange={handleChange}
            />
          </Grid>
        </Grid>

        <Box width={"100%"} marginTop={"50px"}>
          <Typography variant="h2">Campus Details</Typography>
          <Divider className={classes.divider2} />
        </Box>
        <Grid
          container
          spacing={2}
          md={9}
          xs={12}
          className={classes.container}
        >
          <Grid item md={12} sm={12} xs={12}>
            <FormControlLabel
              control={
                <Checkbox checked={sameAsOrg} onChange={handleSameCampusOrg} />
              }
              label="Same as organisation"
            />
          </Grid>
          {!sameAsOrg && (
            <>
              <Grid item md={12} sm={12} xs={12}>
                <FormLabel required>Campus Name</FormLabel>
                <TextField
                  id="campusName"
                  type="text"
                  name="campusName"
                  style={{width: "100%", height: "50%"}}
                  className={classes.input}
                  variant="outlined"
                  autoComplete="No"
                  placeholder="Enter your campus name"
                  value={campus.values.campusName}
                  error={Boolean(campus.errors.campusName)}
                  helperText={campus.errors.campusName}
                  onChange={handleCampusChange}
                  disabled={true}
                />
              </Grid>
              <Grid item md={12} sm={12} xs={12}>
                <FormLabel required>Address</FormLabel>
                <TextField
                  id="address"
                  type="text"
                  name="address"
                  style={{width: "100%", height: "50%"}}
                  variant="outlined"
                  autoComplete="No"
                  placeholder="Enter your campus address"
                  value={campus.values.address}
                  error={Boolean(campus.errors.address)}
                  helperText={campus.errors.address}
                  onChange={handleCampusChange}
                />
              </Grid>
              <Grid item md={4} sm={4} xs={12}>
                <FormLabel required>State</FormLabel>
                <TextField
                  style={{width: "100%", height: "50%"}}
                  variant="outlined"
                  id="state"
                  name="state"
                  value={campus.values.state}
                  error={Boolean(campus.errors.state)}
                  helperText={campus.errors.state}
                  onChange={handleCampusChange}
                  SelectProps={{
                    native: true,
                  }}
                  select
                >
                  <option value={""}>Select State</option>
                  {}
                  {States.map((options: any) => (
                   <option id={options.isoCode} key={options.isoCode} value={options.name}>
                    {options.name}
                     </option>
                  ))};
                </TextField>
              </Grid>
              <Grid item md={4} sm={4} xs={12}>
                <FormLabel required>City</FormLabel>
                <TextField
                  style={{width: "100%", height: "50%"}}
                  variant="outlined"
                  id="city"
                  name="city"
                  value={campus.values.city}
                  error={Boolean(campus.errors.city)}
                  helperText={campus.errors.city}
                  onChange={handleCampusChange}
                  SelectProps={{
                    native: true,
                  }}
                  select
                >
                  <option value={""}>Select City</option>
                  {}

                  <select>
                {CitiesCampus.map((options: any, index: any) => (
               <option id={options.name} key={index} value={options.name}>
               {options.name}
              </option>
                ))}
                     </select>

                </TextField>
              </Grid>
              <Grid item md={4} sm={4} xs={12}>
                <FormLabel required>Pincode</FormLabel>
                <TextField
                  id="pincode"
                  type="number"
                  name="pincode"
                  style={{width: "100%", height: "50%"}}
                  variant="outlined"
                  autoComplete="No"
                  placeholder="Enter Pincode"
                  value={campus.values.pincode}
                  error={Boolean(campus.errors.pincode)}
                  helperText={campus.errors.pincode}
                  onChange={handleCampusChange}
                  onInput={(e: any) => {
                    e.target.value = Math.max(0, parseInt(e.target.value))
                      .toString()
                      .slice(0, 6);
                  }}
                />
              </Grid>
              <Grid item md={6} sm={6} xs={12}>
                <FormLabel required>Board</FormLabel>
                <TextField
                  style={{width: "100%", height: "50%"}}
                  variant="outlined"
                  id="board"
                  name="board"
                  value={campus.values.board}
                  error={Boolean(campus.errors.board)}
                  helperText={campus.errors.board}
                  onChange={handleCampusChange}
                  SelectProps={{
                    native: true,
                  }}
                  select
                >
                  <option value={""}>Select Board</option>
                  {}

                  {boardOptions.map(({ id, value, label }: any, index: number) => (
                <option key={index} id={id} value={value}>
              {label}
             </option>
                 ))};

                </TextField>
              </Grid>
              <Grid item md={6} sm={6} xs={12}>
                <FormLabel required>Website</FormLabel>
                <TextField
                  id="website"
                  name="website"
                  style={{width: "100%", height: "50%"}}
                  variant="outlined"
                  autoComplete="No"
                  placeholder="Enter School Website"
                  value={campus.values.website}
                  error={Boolean(campus.errors.website)}
                  helperText={campus.errors.website}
                  onChange={handleCampusChange}
                />
              </Grid>
            </>
          )}
        </Grid>

        {}

        <Grid container spacing={2} md={9} direction="row-reverse">
          <Box display="flex" justifyContent="flex-end" marginTop="20px">
            <GenericButton
              background="blue"
              variant="contained"
              id="save"
              onClick={handleSave}
              disabled={isButtonDisabled}
            >
              Save
            </GenericButton>
          </Box>
          <Box
            display="flex"
            justifyContent="flex-end"
            marginTop="20px"
            marginRight="25px"
          >
            <GenericButton
              background="light"
              variant="contained"
              id="cancel"
              onClick={() => {
                toggleEdit();
                setOrganisation(resetOrg);
                setCampus(resetCampus);
                setSuccess(false);
                setError("");
              }}
            >
              Cancel
            </GenericButton>
          </Box>
        </Grid>
      </Box>
    </>
  ) : (
    <>
      {success && (
        <Box marginBottom="25px">
          <Alert
            icon={<img className={classes.icon} src={successIcon} alt="" />}
            onClose={() => {
              setError("");
              setSuccess(false);
            }}
            className={classes.alert}
          >
            <AlertTitle className={classes.alertTitle}>
              Details Updated Successfully
            </AlertTitle>
          </Alert>
        </Box>
      )}
      <>
        <Box className={classes.container}>
          <Grid container spacing={4}>
            <Grid item md={6} sm={12} xs={12}>
              <Typography variant="subtitle2" style={{marginBottom: "8px"}}>
                School Name
              </Typography>
              <Typography variant="caption">{orgDetails.orgName}</Typography>
            </Grid>
          </Grid>
          <Grid container spacing={4}>
            <Grid item md={6} sm={12} xs={12}>
              <Typography variant="subtitle2" style={{marginBottom: "8px"}}>
                Address
              </Typography>
              <Typography variant="caption">
                {orgDetails.orgDetails.address}
              </Typography>
            </Grid>
          </Grid>

          <Grid container spacing={4}>
            <Grid item md={3} sm={6} xs={12}>
              <Typography variant="subtitle2" style={{marginBottom: "8px"}}>
                State
              </Typography>
              <Typography variant="caption">
                {orgDetails.orgDetails.state}
              </Typography>
            </Grid>
            <Grid item md={3} sm={6} xs={12}>
              <Typography variant="subtitle2" style={{marginBottom: "8px"}}>
                City
              </Typography>
              <Typography variant="caption">
                {orgDetails.orgDetails.city}
              </Typography>
            </Grid>
            <Grid item md={3} sm={6} xs={12}>
              <Typography variant="subtitle2" style={{marginBottom: "8px"}}>
                Pincode
              </Typography>
              <Typography variant="caption">
                {orgDetails.orgDetails.pincode}
              </Typography>
            </Grid>
          </Grid>
          <Grid container spacing={4}>
            <Grid item md={3} sm={6} xs={12}>
              <Typography variant="subtitle2" style={{marginBottom: "8px"}}>
                Board
              </Typography>
              <Typography variant="caption">
                {orgDetails.orgDetails.board}
              </Typography>
            </Grid>
            <Grid item md={3} sm={6} xs={12}>
              <Typography variant="subtitle2" style={{marginBottom: "8px"}}>
                Website
              </Typography>
              <Typography variant="caption">
                {orgDetails.orgDetails.website}
              </Typography>
            </Grid>
          </Grid>
        </Box>
      </>
      <>
        <Box width={"100%"} marginTop={"50px"}>
          <Typography variant="h2">Campus Details</Typography>
          <Divider className={classes.divider2} />
        </Box>
        <Box className={classes.container}>
          <Grid container spacing={4}>
            <Grid item md={6} sm={12} xs={12}>
              <Typography variant="subtitle2" style={{marginBottom: "8px"}}>
                Campus Name
              </Typography>
              <Typography variant="caption">{campusData.campusName}</Typography>
            </Grid>
          </Grid>
          <Grid container spacing={4}>
            <Grid item md={6} sm={12} xs={12}>
              <Typography variant="subtitle2" style={{marginBottom: "8px"}}>
                Address
              </Typography>
              <Typography variant="caption">
                {campusData.additionalInfo.address}
              </Typography>
            </Grid>
          </Grid>

          <Grid container spacing={4}>
            <Grid item md={3} sm={6} xs={12}>
              <Typography variant="subtitle2" style={{marginBottom: "8px"}}>
                State
              </Typography>
              <Typography variant="caption">
                {campusData.additionalInfo.state}
              </Typography>
            </Grid>
            <Grid item md={3} sm={6} xs={12}>
              <Typography variant="subtitle2" style={{marginBottom: "8px"}}>
                City
              </Typography>
              <Typography variant="caption">
                {campusData.additionalInfo.city}
              </Typography>
            </Grid>
            <Grid item md={3} sm={6} xs={12}>
              <Typography variant="subtitle2" style={{marginBottom: "8px"}}>
                Pincode
              </Typography>
              <Typography variant="caption">
                {campusData.additionalInfo.pincode}
              </Typography>
            </Grid>
          </Grid>
          <Grid container spacing={4} style={{marginBottom: "30px"}}>
            <Grid item md={3} sm={6} xs={12}>
              <Typography variant="subtitle2" style={{marginBottom: "8px"}}>
                Board
              </Typography>
              <Typography variant="caption">
                {campusData.additionalInfo.board}
              </Typography>
            </Grid>
            <Grid item md={3} sm={6} xs={12}>
              <Typography variant="subtitle2" style={{marginBottom: "8px"}}>
                Website
              </Typography>
              <Typography variant="caption">
                {campusData.additionalInfo.website}
              </Typography>
            </Grid>
          </Grid>
        </Box>
      </>
    </>
  );
}

export default OrganisationDetails;
