import React, { Component } from "react";
import { createInitialState } from "./FormHandlers";
import Page from "./Page";
import withStyles from "@material-ui/core/styles/withStyles";
import Stepper from "./Stepper";
import { page } from "../../Data/getPage";

const useStyles: any = (theme: any) => ({
  formContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    borderRadius: "16px",
    backgroundColor: theme.palette.background.paper,
    marginBottom: theme.spacing(4),
    [theme.breakpoints.between(0, "xs")]: {
      marginBottom: theme.spacing(15),
    },
  },
  appMain: {
    marginLeft: theme.spacing(10),
    marginRight: theme.spacing(10),
    marginBottom: theme.spacing(3),
    [theme.breakpoints.between("xs", "sm")]: {
      marginLeft: theme.spacing(6),
      marginRight: theme.spacing(6),
    },
    [theme.breakpoints.between(0, "xs")]: {
      marginLeft: theme.spacing(2),
      marginRight: theme.spacing(2),
    },
  },
});
let lastId = 0;
function newId() {
  return lastId++;
}

class FormInitial extends Component<any, any> {
  constructor(props: any) {
    super(props);
    this.state =
      this.props.newData?.additionalInfo?.pageState ||
      createInitialState(this.props.orgDetails.orgName.replace(/\s/g, "_"));
    this.nextStep = this.nextStep.bind(this);
    this.prevStep = this.prevStep.bind(this);
    this.handleStep = this.handleStep.bind(this);
  }
  handleStep(page: any, step: any) {
    window.scrollTo(0, 0);
    this.setState({ page: page });
    this.setState({ activeStep: step });
  }
  nextStep = (SideMenu: any) => {
    const { activeStep, page, completed, disabled } = this.state;
    completed[activeStep] = true;
    disabled[activeStep + 1] = false;
    this.setState({ activeStep: activeStep + 1, completed });
    const index = SideMenu.indexOf(page);
    const newPage = SideMenu[index + 1];
    this.setState({
      page: newPage,
    });
  };
  prevStep = (SideMenu: any) => {
    const { activeStep, page } = this.state;
    this.setState({ activeStep: activeStep - 1 });
    const index = SideMenu.indexOf(page);
    this.setState({
      page: SideMenu[index - 1],
    });
  };

  render() {
    const orgName = this.props.orgDetails.orgName.replace(/\s/g, "_")
    const SideMenu = page(orgName, "index");
    const formData: any[] = [];
    SideMenu.map((menu: any, index: any) => {
      const name = menu.replace(/\s/g, "_");
      formData[index] = page(orgName, name);
      return null;
    });
    const { classes } = this.props;
    return (
      <div className={classes.formContainer}>
        <Stepper
          state={this.state}
          handleStep={this.handleStep}
          SideMenu={SideMenu}
        />
        <div className={classes.appMain}>
          <Page
            formData={formData[SideMenu.indexOf(this.state.page)]}
            prevStep={this.prevStep}
            nextStep={this.nextStep}
            SideMenu={SideMenu}
            newId={newId}
            pageState={this.state}
            {...this.props}
          ></Page>
        </div>
      </div>
    );
  }
}

export default withStyles(useStyles, { withTheme: true })(FormInitial);
