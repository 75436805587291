import Box from "@material-ui/core/Box";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import makeStyles from "@material-ui/core/styles/makeStyles";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContentText from "@material-ui/core/DialogContentText";
import Divider from "@material-ui/core/Divider";
import CircularProgress from "@material-ui/core/CircularProgress";
import Typography from "@material-ui/core/Typography";
import GenericButton from "./GenericButton";
import ResultModal from "./ResultModal";
const useStyles = makeStyles((theme:any) => ({
  title: {
    ...theme.typography.confirmationTitle,
  },
}));
function VerificationModal(props: any) {
  const classes = useStyles();
  return (
    <Dialog
      PaperProps={{
        style: {
          borderRadius: "10px",
          boxShadow:
            "0px 16px 24px rgba(0, 0, 0, 0.14), 0px 6px 30px rgba(0, 0, 0, 0.12), 0px 8px 10px rgba(0, 0, 0, 0.2)",
          padding: "10px",
        },
      }}
      open={props.open}
      onClose={props.handleClose}
    >
      <DialogTitle id="title">
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <Box className={classes.title}>{props.title}</Box>
        </Box>
      </DialogTitle>
      <Divider style={{ background: "#E5E7E8", margin: "5px -10px 10px -10px" }} />

      <DialogContent>
        {props.error && (
          <ResultModal
            type="error"
            title="Verification Failed"
            content={props.error}
            handleClose={props.handleClose}
          />
        )}
        {props.success && (
          <ResultModal
            type="success"
            title="Verification Successful"
            content="You've been verified successfully. Hold on till we redirect you to LOGIN page........."
            handleClose={props.handleClose}
          />
        )}
        <DialogContentText>
          <Typography variant="caption">{props.content}</Typography>
        </DialogContentText>
        {props.children}
      </DialogContent>
      <DialogActions>
        <GenericButton background="light" onClick={props.handleClear} style={{ color: "#82889E" }}>
          Clear
        </GenericButton>
        <GenericButton
          background="blue"
          onClick={props.handleVerify}
          color="secondary"
          disabled={props.disable}
          autoFocus
        >
          {props.loading ? (
            <CircularProgress style={{ color: "#ffffff", width: "25px", height: "25px" }} />
          ) : (
            "Verify"
          )}
        </GenericButton>
      </DialogActions>
    </Dialog>
  );
}

export default VerificationModal;
