import localConfig from "./env-config/local-config";
import devConfig from "./env-config/dev-config";
import testConfig from "./env-config/test-config";
import sitConfig from "./env-config/sit-config";
import uatConfig from "./env-config/uat-config";
import prodConfig from "./env-config/prod-config";

const stage = process.env.REACT_APP_STAGE ?? "dev";
let configFile = localConfig;
if (stage === "dev") configFile = devConfig;
if (stage === "test") configFile = testConfig;
if (stage === "sit") configFile = sitConfig;
if (stage === "uat") configFile = uatConfig;
if (stage === "prod") configFile = prodConfig;

export default configFile;
