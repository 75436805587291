import makeStyles from "@material-ui/core/styles/makeStyles";
import Step from "@material-ui/core/Step";
import Box from "@material-ui/core/Box";
import StepButton from "@material-ui/core/StepButton";
import StepLabel from "@material-ui/core/StepLabel";
import MuiStepper from "@material-ui/core/Stepper";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import StepConnector from "@material-ui/core/StepConnector";
import withStyles from "@material-ui/core/styles/withStyles";
import DoneIcon from "@material-ui/icons/Done";
import clsx from "clsx";
import PropTypes from "prop-types";
import NavigateNextIcon from "@material-ui/icons/NavigateNext";
import NavigateBeforeIcon from "@material-ui/icons/NavigateBefore";
import { useRef } from "react";
import useStyles from "./styles";

const ColorlibConnector = withStyles({
  alternativeLabel: {
    top: 22,
  },
  active: {
    "& $line": {
      backgroundColor: "#009AAA",
    },
  },
  completed: {
    "& $line": {
      backgroundColor: "#009AAA",
    },
  },
  line: {
    height: 3,
    border: 0,
    backgroundColor: "#009AAA",
    borderRadius: 1,
  },
})(StepConnector);
const useColorlibStepIconStyles = makeStyles({
  root: {
    backgroundColor: "#ffffff",
    border: "1.7px solid #009AAA",
    zIndex: 1,
    color: "#009AAA",
    width: 50,
    height: 50,
    display: "flex",
    borderRadius: "50%",
    justifyContent: "center",
    alignItems: "center",
  },
  active: {
    backgroundColor: "#009AAA",
    border: "1.7px solid #009AAA",
    color: "#FFFFFF",
  },
});
const ColorlibStepIcon: any = (props: any) => {
  const classes = useColorlibStepIconStyles();
  const { active } = props;

  const icons: any = {
    1: props.completed ? <DoneIcon /> : 1,
    2: props.completed ? <DoneIcon /> : 2,
    3: props.completed ? <DoneIcon /> : 3,
    4: props.completed ? <DoneIcon /> : 4,
    5: props.completed ? <DoneIcon /> : 5,
    6: props.completed ? <DoneIcon /> : 6,
    7: props.completed ? <DoneIcon /> : 7,
    8: props.completed ? <DoneIcon /> : 8,
    9: props.completed ? <DoneIcon /> : 9,
    10: props.completed ? <DoneIcon /> : 10,
    11: props.completed ? <DoneIcon /> : 11,
    12: props.completed ? <DoneIcon /> : 12,
  };
  return (
    <div
      className={clsx(classes.root, {
        [classes.active]: active,
      })}
    >
      {icons[String(props.icon)]}
    </div>
  );
};
ColorlibStepIcon.propTypes = {
  /**
   * Whether this step is active.
   */
  active: PropTypes.bool,
  /**
   * Mark the step as completed. Is passed to child components.
   */
  /**
   * The label displayed in the step icon.
   */
  icon: PropTypes.node,
};

function ViewOnlyStepper(props: any) {
  const classes = useStyles();
  const smallScreen = useMediaQuery((theme: any) =>
    theme.breakpoints.between("0", "xs")
  );
  const ref: any = useRef();
  const handleNext = () => {
    ref.current.scrollLeft += 150;
  };
  const handlePrevious = () => {
    ref.current.scrollLeft -= 150;
  };
  return (
    <Box
      display="flex"
      alignItems="center"
      justifyContent="center"
      width="100%"
    >
      {smallScreen && (
        <NavigateBeforeIcon
          onClick={handlePrevious}
          style={{
            color: "#009AAA",
            height: "40px",
            width: "40px",
          }}
        />
      )}
      <MuiStepper
        style={{
          borderRadius: "16px  ",
          width: "100%",
          overflow: "scroll",
        }}
        alternativeLabel
        nonLinear
        activeStep={props.state.activeStep}
        connector={<ColorlibConnector />}
        ref={ref}
      >
        {props.SideMenu.map((text: any, index: any) => (
          <Step key={text}>
            <StepButton
              onClick={() => {
                props.handleStep(text, index);
              }}
            >
              <StepLabel
                className={classes.stepLabel}
                StepIconComponent={ColorlibStepIcon}
              >
                {text}
              </StepLabel>
            </StepButton>
          </Step>
        ))}
      </MuiStepper>
      {smallScreen && (
        <NavigateNextIcon
          onClick={handleNext}
          style={{
            color: "#009AAA",
            height: "40px",
            width: "40px",
          }}
        />
      )}
    </Box>
  );
}
export default ViewOnlyStepper;
