import FormControl from "@material-ui/core/FormControl";
import FormLabel from "@material-ui/core/FormLabel";
import Grid from "@material-ui/core/Grid";
import InputAdornment from "@material-ui/core/InputAdornment";
import TextField from "@material-ui/core/TextField";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import IconWithTooltip from "../IconWithTooltip";

function PhoneNumber(props: any) {
  const {
    id,
    label,
    name,
    value,
    error = null,
    onChange,
    required = false,
    width,
    tooltip,
    variant,
    country,
    maxlength,
  } = props;
  const smallScreen = useMediaQuery((theme: any) =>
    theme.breakpoints.between("0", "xs")
  );

  return (
    <FormControl
      variant={variant || "outlined"}
      style={{
        width: width || (smallScreen ? "100%" : "50%"),
        marginBottom: "20px",
      }}
      {...(required && {required: true})}
    >
      <FormLabel>{label}</FormLabel>
      <Grid
        container
        direction="row"
        style={{width: width || smallScreen ? "94%" : "88%"}}
      >
        <TextField
          style={{width: width || "100%"}}
          size="small"
          variant={variant || "outlined"}
          id={id}
          type="number"
          name={name}
          value={value}
          onChange={onChange}
          {...(error && {error: true, helperText: error})}
          {...(required && {required: true})}
          onInput={(e: any) => {
            e.target.value = e.target.value.toString().slice(0, maxlength);
            if (
              name.includes("Percentage") &&
              e.target.value.length === 3 &&
              e.target.value !== 100
            ) {
              e.target.value = e.target.value.toString().slice(0, 2);
            }
          }}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                {country === "India" ? "+91" : country === "Australia" && "+61"}
              </InputAdornment>
            ),
          }}
        />
        {tooltip && <IconWithTooltip tooltip={tooltip} />}
      </Grid>
    </FormControl>
  );
}

export default PhoneNumber;
