import Box from "@material-ui/core/Box";
import makeStyles from "@material-ui/core/styles/makeStyles";
import Typography from "@material-ui/core/Typography";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import GenericButton from "./GenericButton";
import addButton from "../assets/icons/addButton.svg";
import {CSVLink} from "react-csv";
import exportIcon from "../assets/icons/export.svg";
import moment from "moment";
const useStyles = makeStyles((theme: any) => ({
  head: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    marginBottom: "25px",
  },
  CSVWrapper: {
    flexDirection: "row",
    display: "flex",
    alignItems: "center",
    textDecoration: "none",
    borderRadius: "20px",
    color: "#009aaa",
    "&:hover": {
      backgroundColor: "#ECEFF1",
      color: "#3ec1d3",
    },

    "&:active": {
      backgroundColor: "#ECEFF1",
      color: "#009aaa",
    },
  },

  ExportCSV: {
    ...theme.typography.blueFont,
    marginLeft: "5px",
  },
  disabled: {
    color: "#B0BEC5",
    cursor: "not-allowed",
    backgroundColor: "transparent",
    pointerEvents: "none",

    "&:hover,&:active": {
      backgroundColor: "transparent",
      color: "#B0BEC5",
    },
  },
}));

function TableHeader(props: any) {
  const classes = useStyles();
  const smallScreen = useMediaQuery((theme: any) =>
    theme.breakpoints.between("0", "xs")
  );
  const headers = [
    {
      label: "Application Number",
      key: "appNo",
    },
    {
      label: "Child Name",
      key: "name",
    },
    {
      label: "Date Received",
      key: "date",
    },
    {
      label: "Branch",
      key: "branch",
    },
    {
      label: "Status",
      key: "status",
    },
    {
      label: "SchoolZone",
      key: "schoolzone",
    },
  ];
  const data = props.data
    .sort(function (a: any, b: any) {
      var dateA: any = new Date(a.auditDetails.createdAt),
        dateB: any = new Date(b.auditDetails.createdAt);
      return dateB - dateA; //sort by date ascending
    })
    .map((data: any) => {
      return {
        appNo: data.applicationId,
        name:
          data.applicantDetails.firstName +
          " " +
          data.applicantDetails.lastName,
        date: moment(data.auditDetails.createdAt).format("DD MMMM YYYY"),
        branch: data.orgDetails.orgName,
        status: data.status,
        schoolZone: data.applicantDetails.schoolZone
      };
    });
  return (
    <div className={classes.head}>
      <Box display="flex">
        <Typography variant="h2" style={{marginRight: "25px"}}>
          {props.title}
        </Typography>
        {!props.isParent && data.length !== 0 && props.showExport && (
          <CSVLink
            filename="Applications.csv"
            headers={headers}
            className={classes.CSVWrapper}
            data={data}
          >
            <img src={exportIcon} alt="Export" />
            <Typography className={classes.ExportCSV}>Export .csv</Typography>
          </CSVLink>
        )}
      </Box>
      {props.isParent &&
        (smallScreen ? (
          <img src={addButton} alt="" onClick={props.onClick} />
        ) : (
          <GenericButton
            background="blue"
            variant="outlined"
            onClick={props.onClick}
          >
            {props.button}
          </GenericButton>
        ))}
    </div>
  );
}
export default TableHeader;
