import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import FormHelperText from "@material-ui/core/FormHelperText";
import FormLabel from "@material-ui/core/FormLabel";
import TextField from "@material-ui/core/TextField";
import React, {Component} from "react";
import {Auth} from "aws-amplify";
import {onSubmitValidate, Validate} from "../../validations/ErrorHandlers";
import BasePage from "../../components/BasePage";
import {Link} from "react-router-dom";
import GenericButton from "../../components/GenericButton";
import Recaptcha from "react-recaptcha";
import Password from "../../components/Password";
import {History} from "history";
import {CognitoUser} from "amazon-cognito-identity-js";
import {v4 as uuid} from "uuid";
import configFile from "../../config/root-config";
import LoadingScreen from "../../components/LoadingScreen";
import {getSSM} from "../../common/aws/getSSM";
import MuiPhoneNumber from "material-ui-phone-number";
import ReactGA from "react-ga";

const TRACKING_ID = configFile.googleAnalyticsTrackingId; // OUR_TRACKING_ID
ReactGA.initialize(TRACKING_ID);
const {isCaptchaActive} = configFile;
type state = {
  values: {
    [username: string]: string;
    password: string;
  };
  errors: {
    [username: string]: string;
    password: string;
  };
  showPassword: boolean;
  fieldsValidated: boolean;
  errorMessage: string;
  isSubmitLoading: boolean;
  isCaptchaVerified: boolean;
  phone: any;
  phoneError: string;
  loginViaPhone: boolean;
};
type props = {
  history: History;
  updateUser: (user?: CognitoUser) => void;
};
class Login extends Component<any, state> {
  constructor(props: any) {
    super(props);
    this.state = {
      values: {
        username: "",
        password: "",
      },
      errors: {
        username: "",
        password: "",
      },
      showPassword: false,
      fieldsValidated: false,
      errorMessage: "",
      isSubmitLoading: false,
      isCaptchaVerified: !isCaptchaActive,
      phone: "",
      phoneError: "",
      loginViaPhone: false,
    };
    this.handleChange = this.handleChange.bind(this);
    this.handleLogin = this.handleLogin.bind(this);
  }

  handleChange(event: React.ChangeEvent<HTMLInputElement>) {
    const values = this.state.values;
    values[event.target.name] = event.target.value;
    this.setState({values});
    const errors = Validate(event.target.name, this.state);
    this.setState({errors: errors});
    const errors2 = onSubmitValidate(this.state);
    const validate = Object.values(errors2).every((x) => x === "");
    if (validate) {
      this.setState({fieldsValidated: true});
    } else {
      this.setState({fieldsValidated: false});
    }
  }
  handleLogin(event: React.MouseEvent<HTMLElement>) {
    event.preventDefault();
    ReactGA.event({
      category: "Solvitude",
      action: "Login",
      label: "label"
    });
    localStorage.removeItem("data");
    localStorage.removeItem("subId");
    localStorage.removeItem("accessToken");
    const phone_number = this.state.phone
      .replaceAll("-", "")
      .replaceAll(" ", "");
    const username = this.state.loginViaPhone
      ? phone_number
      : this.state.values.username.toLowerCase();
    this.setState({isSubmitLoading: true}, async () => {
      try {
        const user = await Auth.signIn(username, this.state.values.password);
        const stage = process.env.REACT_APP_STAGE || "dev";
        const backenduri =
          stage === "local"
            ? configFile.uri.backendURI
            : await getSSM(configFile.uri.backendURI);
        localStorage.setItem("backendUri", backenduri!);
        const appmnturi =
          stage === "local"
            ? configFile.uri.digitalServicesURI
            : await getSSM(configFile.uri.digitalServicesURI);
        localStorage.setItem("appmntUri", appmnturi!);
        const orgmnturi =
          stage === "local"
            ? configFile.uri.orgManagementURI
            : await getSSM(configFile.uri.orgManagementURI);
        localStorage.setItem("orgmntUri", orgmnturi!);
        const usermnturi =
          stage === "local"
            ? configFile.uri.userManagementURI
            : await getSSM(configFile.uri.userManagementURI);
        localStorage.setItem("usermntUri", usermnturi!);
        const bucketname =
          stage === "local"
            ? configFile.s3Config.bucket
            : await getSSM(configFile.s3Config.bucket);
        localStorage.setItem("bucket", bucketname!);
        localStorage.setItem("correlationId", uuid());
        localStorage.setItem("role", user.attributes["custom:role"]);
        await this.props.generateAccessToken(user.attributes["custom:role"]);
        localStorage.setItem(
          "userId",
          this.state.loginViaPhone
            ? user.attributes.phone_number
            : user.attributes.email
        );
        localStorage.setItem("customerId", user.attributes["custom:userName"]);
        localStorage.setItem("campusId", user.attributes["custom:campusId"]);
        localStorage.setItem("orgId", user.attributes["custom:orgId"]);
        localStorage.setItem(
          "loginType",
          this.state.loginViaPhone ? "phone" : "email"
        );
        this.setState({isSubmitLoading: false});
        this.props.updateUser(user);
        this.props.history.push("/dashboard");
        console.log("user");
        console.log(user);
      } catch (error: any) {
        console.log("error signing in", error);
        if (error.code === "NotAuthorizedException")
          this.setState({
            isSubmitLoading: false,
            errorMessage: "Email ID or Password is incorrect.",
          });
        else if (error.code === "UserNotConfirmedException")
          this.setState({
            isSubmitLoading: false,
            errorMessage:
              "User is not confirmed. Please click on the confirmation link sent to your email address.",
          });
        else if (error.code === "UserNotFoundException") {
          this.setState({
            isSubmitLoading: false,
            errors: {
              ...this.state.errors,
              username: error.message,
              
            },
          });
        } else if (error.code === "PasswordResetRequiredException") {
          try {
            const data = await Auth.forgotPassword(
              this.state.values.username.toLowerCase()
            );
            this.props.history.push("/resetpassword", {
              emailphone: this.state.values.username,
            });
          } catch (error) {
            console.log(error);
          }
        } else
          this.setState({
            isSubmitLoading: false,
            errorMessage: "Opss!! Something went wrong",
          });
      }
    });
  }
  render() {
    return (
      <>
        {this.state.isSubmitLoading && <LoadingScreen />}
        <BasePage title={"Welcome Back!"} schoolName={this.props.schoolName}>
          {this.state.loginViaPhone && (
            <Box marginBottom="4vh">
              <FormLabel required>Phone number</FormLabel>
              <MuiPhoneNumber
                defaultCountry={this.props.geolocation.country_code || "in"}
                id="phone"
                name="phone"
                type="tel"
                countryCodeEditable={false}
                style={{width: "100%"}}
                variant="outlined"
                autoComplete="No"
                value={this.state.phone}
                error={Boolean(this.state.phoneError)}
                helperText={this.state.phoneError}
                inputProps={TextField}
                onChange={(phone: any) => {
                  this.setState({phone});
                  var regex = new RegExp(
                    /^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\s\./0-9]*$/
                  );
                  if (!regex.test(phone) || phone.length < 12) {
                    const error = "Please enter valid phone number";
                    this.setState({phoneError: error});
                  } else {
                    const error = "";
                    this.setState({phoneError: error});
                  }
                }}
              />
            </Box>
          )}
          {!this.state.loginViaPhone && (
            <Box marginBottom="4vh">
              <FormLabel required>Email address</FormLabel>
              <TextField
                id="username"
                type="email"
                name="username"
                style={{width: "100%"}}
                variant="outlined"
                autoComplete="No"
                placeholder="Eg : johndoe@email.com"
                value={this.state.values.username}
                error={Boolean(this.state.errors.username)}
                helperText={this.state.errors.username}
                onChange={this.handleChange}
              />
            </Box>
          )}
          <Box marginBottom="4vh">
            <Box display="flex" justifyContent="space-between">
              <FormLabel required>Password</FormLabel>
              <Link to="/forgotpassword" style={{textDecoration: "none"}}>
                <Typography variant="body1">Forgot password?</Typography>
              </Link>
            </Box>
            <Password
              id="password"
              name="password"
              placeholder="Enter your password"
              error={this.state.errors.password}
              value={this.state.values.password}
              onChange={this.handleChange}
            />

            {this.state.errorMessage && (
              <FormHelperText
                style={{
                  fontSize: "14px",
                  lineHeight: "22px",
                  fontWeight: "normal",
                  fontStyle: "normal",
                  fontFamily: "Lato",
                  color: "#F9347B",
                }}
              >
                {this.state.errorMessage}!!
              </FormHelperText>
            )}
          </Box>
          {isCaptchaActive && (
            <Box
              marginBottom="4vh"
              display="flex"
              justifyContent="center"
              style={{transform: "scale(0.9)"}}
            >
              <Recaptcha
                sitekey="6LcIRiEbAAAAANzhWP4qexkT2MH9ZtXrNjU48XgN"
                render="explicit"
                verifyCallback={(response) => {
                  if (response) this.setState({isCaptchaVerified: true});
                }}
                expiredCallback={() =>
                  this.setState({
                    isCaptchaVerified: false,
                  })
                }
              />
            </Box>
          )}
          <Box marginBottom="2vh">
            <GenericButton
              id="submit"
              type="submit"
              fullWidth={true}
              onClick={this.handleLogin}
              disabled={
                this.state.isCaptchaVerified &&
                ((this.state.fieldsValidated && !this.state.loginViaPhone) ||
                  (!this.state.phoneError && this.state.loginViaPhone))
                  ? false
                  : true
              }
              background="blue"
            >
              Login
            </GenericButton>
          </Box>
          {}
          {}
          {this.state.loginViaPhone && (
            <Box marginBottom="2vh">
              <GenericButton
                id="emailLogin"
                type="submit"
                fullWidth={true}
                onClick={() => {
                  this.setState({loginViaPhone: false});
                }}
                background="blue"
              >
                <b>Or log in Using Email</b>
              </GenericButton>
            </Box>
          )}
          {!this.state.loginViaPhone && (
            <Box marginBottom="2vh">
              <GenericButton
                id="phoneLogin"
                type="submit"
                fullWidth={true}
                onClick={() => {
                  this.setState({loginViaPhone: true});
                }}
                background="blue"
              >
                <b>Or log in Using Phone</b>
              </GenericButton>
            </Box>
          )}
          <Link
            to="/user/signup"
            style={{
              textDecoration: "none",
              display: "flex",
              justifyContent: "center",
              marginBottom: "4vh",
            }}
          >
            <Typography variant="h6">
              Don't have an account?
              <span style={{color: "#006C77"}}> Sign up!</span>
            </Typography>
          </Link>
        </BasePage>
      </>
    );
  }
}

export default Login;
