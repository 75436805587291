import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import makeStyles from "@material-ui/core/styles/makeStyles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import moment from "moment";

const useStyles = makeStyles((theme) => ({
  messageDetails: {
    backgroundColor: theme.palette.background.default,
    borderRadius: "8px",
    marginBottom: theme.spacing(2),
    cursor: "pointer",
    "&.MuiAccordion-root:before": {
      backgroundColor: "white",
    },
  },
  summary: {
    overflow: "hidden",
  },
}));

function getText(html: string) {
  var divContainer = document.createElement("div");
  divContainer.innerHTML = html;
  return divContainer.textContent || divContainer.innerText || "";
}

function MessageDetail(props: any) {
  const { data } = props;
  const date = moment(data.auditDetails.createdAt).format("DD MMMM YYYY");
  const body = getText(data.emailContent.body);
  const classes = useStyles();
  const mediumScreen = useMediaQuery((theme: any) =>
    theme.breakpoints.between("xs", "sm")
  );
  useMediaQuery((theme: any) =>
    theme.breakpoints.between("0", "xs")
  );

  return (
    <Box
      display="flex"
      justifyContent="flex-start"
      alignItems="flex-start"
      flexWrap="wrap"
      className={classes.messageDetails}
      padding="1em 1.5em 1.5em 1.5em"
      onClick={() => {
        props.handleTableAdmin(data.broadcastId);
      }}
    >
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        width="100%"
      >
        <Box
          display="flex"
          flexDirection="column"
          flexGrow={1}
          height="100%"
          justifyContent="space-evenly"
          //   width="50%"
          style={{ wordBreak: "break-word" }}
          {...(mediumScreen && { width: "20vw" })}
        >
          <Typography
            variant="h4"
            style={{
              marginBottom: "0.5em",
              lineHeight: "22px",
              color: "#3B3A3C",
            }}
          >
            {data.emailContent.subject}
          </Typography>
        </Box>
        <Box
          display="flex"
          flexDirection="column"
          height="100%"
          justifyContent="flex-end"
          //   width="20%"
          style={{ wordBreak: "break-word" }}
          {...(mediumScreen && { width: "20vw" })}
        >
          <Typography
            variant="subtitle2"
            style={{
              marginBottom: "0.5em",
              lineHeight: "17px",
              fontSize: "12px",
            }}
          >
            {date}
          </Typography>
        </Box>
      </Box>
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        width="100%"
      >
        <Typography
          variant="body1"
          style={{
            marginBottom: "0.5em",
            lineHeight: "22px",
          }}
        >
          {body.length > 150 ? body.slice(0, 150).concat("...") : body}
        </Typography>
      </Box>
    </Box>
  );
}

export default MessageDetail;
