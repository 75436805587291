import {Component} from "react";
import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import withStyles from "@material-ui/core/styles/withStyles";
import PageHeadBase from "../../components/PageHeadBase";
import ChildDetail from "../../components/ChildDetail";

const useStyles: any = (theme: any) => ({
  childContainer: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
    background: theme.palette.background.paper,
    borderRadius: "16px",
    padding: "24px",
    [theme.breakpoints.between("xs", "sm")]: {
      padding: "40px",
    },
    [theme.breakpoints.between("xs", "sm")]: {
      padding: "20px",
    },
  },
});

class Children extends Component<any, any> {
  constructor(props: any) {
    super(props);
    this.state = {addChild: true};
    this.handleAddChild = this.handleAddChild.bind(this);
  }
  handleAddChild() {
    this.setState({addChild: !this.state.addChild});
  }
  componentDidMount() {
    window.scrollTo(0, 0);
  }
  render() {
    const {classes} = this.props;
    const childDetails = this.props.location.state.childDetails;
    const organisationName = this.props.location.state.organisationName;
    return (
      <>
        <PageHeadBase
          route="Select Applicant"
          title="Select Applicant"
          history={this.props.history}
        >
          <Box className={classes.childContainer}>
            <Grid container spacing={2}>
              {childDetails.map((data: any, index: any) => {
                return (
                  <Grid
                    item
                    md={3}
                    sm={6}
                    xs={12}
                    key={index}
                    onClick={() => {
                      this.props.history.push(
                        `/newApplication/${organisationName.replace(
                          /\s/g,
                          "_"
                        )}`,
                        {
                          childDetails: data,
                          populateData: this.props.location.state.populateData,
                        }
                      );
                    }}
                  >
                    <ChildDetail data={data} key={index} />
                  </Grid>
                );
              })}
              <Grid
                item
                md={3}
                sm={6}
                xs={12}
                onClick={() => {
                  this.props.history.push(
                    `/newApplication/${organisationName.replace(/\s/g, "_")}`,
                    {
                      childDetails: {},
                      populateData: this.props.location.state.populateData,
                    }
                  );
                }}
              >
                <ChildDetail />
              </Grid>
            </Grid>
          </Box>
        </PageHeadBase>
      </>
    );
  }
}
export default withStyles(useStyles, {withTheme: true})(Children);
