import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import Hidden from "@material-ui/core/Hidden";
import bg from "../../assets/landingBg.png";
import Footer from "../../components/Footer";
import lady from "../../assets/lady.png";
import mobileBg from "../../assets/landingBgMobile.png";
import Logo from "../../components/Logo";
import useStyles from "./styles";
import GenericButton from "../../components/GenericButton";
import MenuButton from "../../components/MenuButton";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";
import ReactGA from "react-ga";
import configFile from "../../config/root-config";
const TRACKING_ID = configFile.googleAnalyticsTrackingId;
ReactGA.initialize(TRACKING_ID);
ReactGA.event({
  category: "Solvitude",
  action: "As Parent",
  label: "label",
});
ReactGA.event({
  category: "Solvitude",
  action: "As Organisation",
  label: "label",
});
const Content = (props: any) => {
  const classes = useStyles();
  return (
    <Grid item md={6} sm={12} xs={12}>
      <Box display="flex" flexDirection="column">
        <Box className={classes.blueTitle}>{props.title}</Box>
        <Box className={classes.content}>{props.children}</Box>
      </Box>
    </Grid>
  );
};

const Actions = (props: any) => {
  const classes = useStyles();
  return (
    <Box className={classes.actionContainer}>
      <Box className={classes.signup}>
        <MenuButton
          icon={
            <GenericButton background="blue" variant="contained">
              Sign Up
              <KeyboardArrowDownIcon />
            </GenericButton>
          }
          items={[
            {
              Title: "As Parent",
              onClick: () => props.history.push("/user/signup"),
            },
            {
              Title: "As Organisation",
              onClick: () => props.history.push("/admin/signup"),
            },
          ]}
          history={props.history}
        />
      </Box>
      <GenericButton
        background="white"
        variant="contained"
        onClick={() => props.history.push("/login")}
      >
        Login
        <ArrowForwardIosIcon
          style={{ width: "15px", height: "15px", marginLeft: "2px" }}
        />
      </GenericButton>
    </Box>
  );
};

function Home(props: any) {
  const classes = useStyles();
  const smallScreen = useMediaQuery((theme: any) =>
    theme.breakpoints.down("xs")
  );
  return (
    <Box className={classes.root}>
      <Box className={classes.background}>
        <img
          src={smallScreen ? mobileBg : bg}
          alt=""
          width="100%"
          height="100%"
        />
      </Box>
      <Box className={classes.headerContainer}>
        <Box className={classes.header}>
          <Logo />
          <Hidden xsDown>
            <Actions history={props.history} />
          </Hidden>
        </Box>
      </Box>

      <Box className={classes.container}>
        <Box className={classes.title}>
          Move beyond the limitations of paper forms.
        </Box>
        <Box className={classes.sub}>
          Eliminate messy paper forms, back-and-forth emails, and time-consuming
          phone calls by making school applications using DigiEnrol.{" "}
        </Box>
        <Hidden xsDown>
          <img src={lady} alt="" className={classes.lady} />
        </Hidden>
        <Hidden smUp>
          <Box marginTop="3vh">
            <Actions history={props.history} />
          </Box>
        </Hidden>
        <Grid container spacing={2} className={classes.bottomContainer}>
          <Content title="For Parent">
            A quick, hassle free process for parents to fill application forms
            by re-purposing data from previous applications. Rest easy knowing
            confidential records are secure without the fear of leaked data.
          </Content>
          <Content title="For Schools">
            A seamless, error-free process for tracking enrolment data, payments
            and any updates to child data, consents, emergency / contact
            details, etc. Export data to any format of your choice. Rapidly
            deliver the data students, administrators and parents need.
          </Content>
        </Grid>
        <Box className={classes.footer}>
          <Footer />
        </Box>
      </Box>
    </Box>
  );
}

export default Home;
