import Backdrop from "@material-ui/core/Backdrop";
import CircularProgress from "@material-ui/core/CircularProgress";

function LoadingScreen(props: any) {
  return (
    <>
      <Backdrop style={{ zIndex: 10000 }} open={true}>
        <CircularProgress />
      </Backdrop>
    </>
  );
}

export default LoadingScreen;
