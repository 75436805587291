import { useEffect, useState } from "react";
import { Redirect } from "react-router-dom";
import LoadingScreen from "./LoadingScreen";

const DelayRedirect = (props: any) => {
  let timeout;
  const [timeToRedirect, setTimeToRedirect] = useState(false);
  useEffect(() => {
    timeout = setTimeout(() => {
      setTimeToRedirect(true);
    }, props.delay);
  }, []);
  return timeToRedirect ? <Redirect to={props.to} /> : <LoadingScreen/>;
};

export default DelayRedirect;
