import Box from "@material-ui/core/Box";
import TextField from "@material-ui/core/TextField";
import makeStyles from "@material-ui/core/styles/makeStyles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import Divider from "@material-ui/core/Divider";
import FormControl from "@material-ui/core/FormControl";
import FormLabel from "@material-ui/core/FormLabel";
import Grid from "@material-ui/core/Grid"
import { useEffect, useState } from "react";
import {
  getAllCampusAnalytics,
  getAllAdminApplications,
} from "../../api";
import Analytics from "../../components/Analytics";
import LoadingScreen from "../../components/LoadingScreen";

const useStyles = makeStyles((theme: any) => ({
  title: {
    ...theme.typography.reportingTitle,
  },
  value: {
    ...theme.typography.pageTitle,
  },
  textfield: {
    ...theme.typography.reportingContent,
  },
  select: {
    "& .MuiSelect-select:focus": {
      background: "transparent",
    },
    "& .MuiOutlinedInput-root": {
      height: "40px",
    },
  },
  options: {
    ...theme.typography.body1,
  },
}));
const colors: any = {
  submitted: "#F8CB2C",
  draft: "#607D8B",
  interviewScheduled: "#FF9E45",
  postInterviewReview: "#2DB6F5",
  admissionGranted: "#009AAA",
  rejected: "#F9347B",
};
function Reporting(props: any) {
  const classes = useStyles();
  const campusId = props.user.attributes["custom:campusId"];
  const [data, setData]: any = useState();
  const [loading, setLoading] = useState(true);
  const [loadingApp, setLoadingApp] = useState(true);
  const [year, setYear] = useState("All");
  const [allApplications, setAllApplications]: any = useState();
  const smallScreen = useMediaQuery((theme: any) =>
    theme.breakpoints.between(0, "xs")
  );
  const mediumScreen = useMediaQuery((theme: any) =>
    theme.breakpoints.between("xs", "sm")
  );
  const updateYear = (event: any) => {
    console.log(event.target.value);
    setYear(event.target.value);
    setLoading(true);
    if (event.target.value === "All") {
      getAllCampusAnalytics(campusId)
        .then((res) => {
          console.log(res);
          setData(res.data);
          setLoading(false);
        })
        .catch((err) => {
          console.log(err);
          setLoading(false);
        });
    } else {
      getAllCampusAnalytics(campusId, event.target.value)
        .then((res) => {
          console.log(res);
          setData(res.data);
          setLoading(false);
        })
        .catch((err) => {
          console.log(err);
          setLoading(false);
        });
    }
  };
  useEffect(() => {
    getAllCampusAnalytics(campusId)
      .then((res) => {
        console.log(res);
        setData(res.data);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });

    getAllAdminApplications(campusId)
      .then((res) => {
        console.log(res);
        const data = res.data;
        setAllApplications(data);
        setLoadingApp(false);
      })
      .catch((err: any) => {
        console.log(err);
        setLoadingApp(false);
      });
  }, []);
  return loading || loadingApp ? (
    <Box height="100vh">
      <LoadingScreen />
    </Box>
  ) : (
    <Box margin="24px">
      <FormControl
        style={{
          width: smallScreen ? "100%" : mediumScreen ? "50%" : "25%",
          marginBottom: "30px",
        }}
      >
        <FormLabel>Select a Year</FormLabel>
        <TextField
          className={classes.select}
          variant="outlined"
          id="year"
          name="year"
          value={year}
          onChange={updateYear}
          select
          SelectProps={{
            native: true,
          }}
        >
          <option className={classes.options} id="all" value="All">
            All
          </option>
          {data.listOfYears?.map((option: any, index: any) => {
            return (
              <option
                className={classes.options}
                id={index}
                key={index}
                value={option}
              >
                {option}
              </option>
            );
          })}
        </TextField>
      </FormControl>
      <Divider style={{ marginBottom: "20px" }} />
      <Box className={classes.title}>Performance Summary</Box>
      <Grid container spacing={2} style={{ margin: "20px 0 32px 0" }}>
        {data &&
          Object.keys(data.summary).map((key, index) => {
            const { value, textField } = data.summary[key];
            return (
              <Grid item xs={12} sm={6} md={3} key={index}>
                <Box
                  style={{
                    background: "#FFFFFF",
                    borderRadius: "8px",
                    padding: "16px",
                  }}
                >
                  <Box className={classes.value} style={{ color: colors[key] }}>
                    {value}
                  </Box>
                  <Box className={classes.textfield}>{textField}</Box>
                </Box>
              </Grid>
            );
          })}
      </Grid>

      <Box marginBottom="16px">
        <Analytics
          data={data}
          year={year}
          user={props.user}
          history={props.history}
          applications={allApplications}
        />
      </Box>
    </Box>
  );
}

export default Reporting;
