import FormControl from "@material-ui/core/FormControl";
import FormLabel from "@material-ui/core/FormLabel";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Radio from "@material-ui/core/Radio";
import MuiRadioGroup from "@material-ui/core/RadioGroup";
import FormHelperText from "@material-ui/core/FormHelperText";
import makeStyles from "@material-ui/core/styles/makeStyles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import Box from "@material-ui/core/Box";
import Controls from "./Controls";
const useStyles = makeStyles((theme) => ({
  radio: {
    marginLeft: theme.spacing(1),
  },
}));
function RadioGroup(props: any) {
  const {
    id,
    label,
    name,
    value,
    error = null,
    onChange,
    required,
    options,
    onHiddenChange,
    onChangeCheckbox,
    onChangeConfirmation,
    onSelectChange,
    onRadioChange,
    handleChangeForAddress,
    handleChangeOptions,
    formValues,
    country,
  } = props;
  const classes = useStyles();
  const smallScreen = useMediaQuery((theme: any) =>
    theme.breakpoints.between("0", "xs")
  );

  return (
    <FormControl
      {...(error && {error: true})}
      {...(required && {required: true})}
      variant="outlined"
      style={{width: smallScreen ? "94%" : "100%", marginBottom: "20px"}}
    >
      <FormLabel>{label}</FormLabel>
      <MuiRadioGroup
        className={classes.radio}
        row
        id={id}
        aria-label={name}
        name={name}
        value={value.value}
        onChange={onChange}
      >
        {options.map((options: any) => {
          return (
            <FormControlLabel
              id={options.Id}
              value={options.Label}
              key={options.Id}
              label={options.Label}
              control={<Radio id={id} {...(required && {required: true})} />}
            ></FormControlLabel>
          );
        })}
      </MuiRadioGroup>
      {options.map((opti: any, index: any) => {
        if (opti.Label === value.value) {
          return (
            <Box
              display="flex"
              flexDirection="row"
              flexWrap="wrap"
              width="100%"
            >
              {Object.keys(opti.Render[0]).length !== 0 &&
                opti.Render.map((opt: any, index: any) => {
                  if (opt.Type === "checkbox") {
                    return (
                      <Controls.Checkbox
                        id={opt.Id}
                        label={opt.FieldName}
                        key={index}
                        error={props.formErrors[opt.FieldName + "_" + opt.Id]}
                        required={opt.Required}
                        options={opt.Options}
                        checked={
                          value[`${opt.FieldName + "_" + opt.Id}_checkbox`]
                            .checkbox
                        }
                        onChange={onChangeCheckbox}
                      />
                    );
                  } else if (
                    opt.Type === "text" ||
                    opt.Type === "date" ||
                    opt.Type === "year" ||
                    opt.Type === "number"
                  ) {
                    return (
                      <Controls.Input
                        id={opt.Id}
                        key={index}
                        type={opt.Type}
                        label={opt.FieldName}
                        name={opt.FieldName}
                        value={value[opt.FieldName + "_" + opt.Id]}
                        error={props.formErrors[opt.FieldName + "_" + opt.Id]}
                        onChange={onHiddenChange}
                        required={opt.Required}
                        maxlength={opt.MaxLength}
                      />
                    );
                  } else if (opt.Type === "amountField") {
                    return (
                      <Controls.AmountField
                        id={opt.Id}
                        key={index}
                        type={opt.Type}
                        label={opt.FieldName}
                        name={opt.FieldName}
                        value={value[opt.FieldName + "_" + opt.Id]}
                        error={props.formErrors[opt.FieldName + "_" + opt.Id]}
                        onChange={onHiddenChange}
                        required={opt.Required}
                      />
                    );
                  } else if (opt.Type === "textarea") {
                    return (
                      <Controls.Textarea
                        key={index}
                        id={opt.Id}
                        label={opt.FieldName}
                        name={opt.FieldName}
                        value={value[opt.FieldName + "_" + opt.Id]}
                        onChange={onHiddenChange}
                        error={props.formErrors[opt.FieldName + "_" + opt.Id]}
                        required={opt.Required}
                        tooltip={opt.Tooltip}
                      />
                    );
                  } else if (opt.Type === "display") {
                    return (
                      <Controls.Display
                        key={index}
                        id={opt.Id}
                        text={opt.FieldName}
                        type={opt.Options[0].Type}
                      />
                    );
                  } else if (opt.Type === "confirmation") {
                    return (
                      <Controls.Confirmation
                        id={opt.Id}
                        key={index}
                        name={opt.FieldName}
                        error={props.formErrors[opt.FieldName + "_" + opt.Id]}
                        onChange={onChangeConfirmation}
                        checked={
                          value[`${opt.FieldName + "_" + opt.Id}_confirmation`]
                        }
                      />
                    );
                  } else if (opt.Type === "select") {
                    return (
                      <Controls.Select
                        key={index}
                        id={opt.Id}
                        label={opt.FieldName}
                        name={opt.FieldName}
                        value={value[opt.FieldName + "_" + opt.Id]}
                        error={props.formErrors[opt.FieldName + "_" + opt.Id]}
                        onChange={onSelectChange(
                          opt.Id,
                          name + "_" + id + "_radio"
                        )}
                        required={opt.Required}
                        options={opt.Options}
                      />
                    );
                  } else if (opt.Type === "radio") {
                    return (
                      <Controls.Radio
                        key={index}
                        id={opt.Id}
                        label={opt.FieldName}
                        name={opt.FieldName}
                        value={value[opt.FieldName + "_" + opt.Id]}
                        error={props.formErrors[opt.FieldName + "_" + opt.Id]}
                        onChange={onRadioChange(
                          opt.Id,
                          name + "_" + id + "_radio"
                        )}
                        required={opt.Required}
                        options={opt.Options}
                      />
                    );
                  } else if (
                    opt.Type === "address" &&
                    formValues[`${opt.FieldName + "_" + opt.Id}_address`] &&
                    formValues[`${opt.FieldName + "_" + opt.Id}_address`]
                      .options
                  ) {
                    return (
                      <Controls.Address
                        key={index}
                        id={opt.Id ?? ""}
                        label={opt.FieldName ?? ""}
                        value={value[opt.FieldName + "_" + opt.Id]}
                        error={props.formErrors[opt.FieldName + "_" + opt.Id]}
                        onChange={handleChangeForAddress(
                          `${opt.FieldName + "_" + opt.Id}_address`
                        )}
                        onOptionsChange={handleChangeOptions}
                        required={opt.Required}
                        options={
                          formValues[`${opt.FieldName + "_" + opt.Id}_address`]
                            .options
                        }
                        tooltip={opt.Tooltip}
                      />
                    );
                  } else if (
                    opt.Type === "countryDropdown" ||
                    opt.Type === "stateDropdown" ||
                    opt.Type === "cityDropdown"
                  ) {
                    console.log(opt);
                    return (
                      <Controls.LocationDropdown
                        key={index}
                        id={opt.Id}
                        type={opt.Type}
                        label={opt.FieldName}
                        name={opt.FieldName}
                        value={value[opt.FieldName + "_" + opt.Id]}
                        error={props.formErrors[opt.FieldName + "_" + opt.Id]}
                        onChange={onSelectChange(opt.Id)}
                        required={opt.Required}
                        tooltip={opt.Tooltip}
                        country={country}
                      />
                    );
                  } else return null;
                })}
            </Box>
          );
        }
      })}

      {error && <FormHelperText>{error}</FormHelperText>}
    </FormControl>
  );
}

export default RadioGroup;
