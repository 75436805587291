import { Button, emphasize, makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme:any) => ({
  blue: {
    height: "40px",
    color: "#ffffff",
    borderRadius: "50px",
    backgroundColor: theme.palette.primary.main,
    "&:hover": {
      backgroundColor: emphasize(theme.palette.primary.main, 0.1),
    },
    "&:disabled": {
      backgroundColor: "#ECEFF1",
      color: "#000000",
    },
    textTransform: "none",
    ...theme.typography.h5,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  white: {
    border: theme.palette.thinBorder,

    height: "40px",
    borderRadius: "50px",
    backgroundColor: theme.palette.background.paper,
    textTransform: "none",
    ...theme.typography.blueFont,
    color: "#009AAA",
    "&:hover": {
      backgroundColor: emphasize(theme.palette.background.paper, 0.1),
    },
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  light: {
    height: "40px",
    borderRadius: "50px",
    backgroundColor: theme.palette.lightBackground,
    textTransform: "none",
    "&:hover": {
      backgroundColor: emphasize(theme.palette.lightBackground, 0.1),
    },
    ...theme.typography.blueFont,
    color: "#009AAA",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
}));
function GenericButton(props: any) {
  const classes: any = useStyles();
  return (
    <Button
      id={props.id}
      type={props.type}
      fullWidth={props.fullWidth}
      variant={props.variant}
      onClick={props.onClick}
      disabled={props.disabled}
      className={classes[props.background]}
      color={props.color}
      endIcon={props.endIcon}
    >
      {props.children}
    </Button>
  );
}
export default GenericButton;
