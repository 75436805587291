import Backdrop from "@material-ui/core/Backdrop";
import Snackbar from "@material-ui/core/Snackbar";
import Alert from "@material-ui/lab/Alert";

function SuccessScreen(props: any) {
  return (
    <>
      <Backdrop style={{ zIndex: 10000 }} open={true}>
        <Snackbar 
            anchorOrigin={{vertical: 'top', horizontal: 'center'}}
            open={true}
            >
                <Alert severity="success">
                    Subscription Successful!
                </Alert>
            </Snackbar>
      </Backdrop>
    </>
  );
}

export default SuccessScreen;
