import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import makeStyles from "@material-ui/core/styles/makeStyles";
import Typography from "@material-ui/core/Typography";
import errorIcon from "../assets/icons/error.svg";
import close from "../assets/icons/close.svg";
import successIcon from "../assets/icons/success.svg";

const useStyles = makeStyles((theme:any) => ({
  title: {
    ...theme.typography.confirmationTitle,
  },
}));
function ResultModal(props: any) {
  const classes = useStyles();
  const smallScreen = useMediaQuery((theme: any) => theme.breakpoints.between("0", "xs"));
  return (
    <Box
      display="flex"
      alignItems="center"
      marginBottom="10px"
      style={{ background: "#ECEFF1", borderRadius: "10px", padding: "10px" }}
    >
      {!smallScreen && (
        <img
          src={props.type === "error" ? errorIcon : successIcon}
          alt=""
          width="25%"
          height="25%"
        />
      )}
      <Grid container spacing={2}>
        <Grid item md={10} sm={10} xs={10}>
          <Box marginLeft="10px" display="flex" flexDirection="column">
            <Box
              className={classes.title}
              marginBottom="10px"
              style={{ color: props.type === "error" ? "#F9347B" : "#009AAA" }}
            >
              {props.title}
            </Box>
            <Typography variant="caption">{props.content}</Typography>
          </Box>
        </Grid>
        <Grid item md={2} sm={2} xs={2}>
          <Box display="flex" justifyContent="center">
            <img src={close} alt="" style={{ cursor: "pointer" }} onClick={props.handleClose} />
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
}

export default ResultModal;
