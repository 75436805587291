import Box from "@material-ui/core/Box";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import withStyles from "@material-ui/core/styles/withStyles";

import React, { Component } from "react";

const StyledMenu: any = withStyles({
  paper: {
    marginTop: "10px",
    borderRadius: "5px",
    width: "auto",
    height: "auto",
    paddingRight: "10px",
    boxShadow: "0px 10px 20px rgba(32, 37, 38, 0.1), 0px 20px 50px rgba(32, 37, 38, 0.1)",
    background: "#3B3A3C",
    overflow: "visible",
    "&:before": {
      content: '""',
      display: "block",
      position: "absolute",
      top: -10,
      right: 14,
      width: 10,
      height: 10,
      background: "#3B3A3C",
      transform: "translateY(50%) rotate(135deg)",
      zIndex: 10,
    },
  },
})((props: any) => (
  <Menu
    getContentAnchorEl={null}
    anchorOrigin={{
      vertical: "bottom",
      horizontal: "right",
    }}
    transformOrigin={{
      vertical: "top",
      horizontal: "right",
    }}
    {...props}
  />
));
const StyledMenuItem = withStyles({
  root: {
    width: "auto",
    "&:hover": {
      background: "linear-gradient(to right, #3EC1D3 0%,#3EC1D3 4% ,#214C54 4%,#214C54 100%);",
      borderRadius: "0px 34px 34px 0px",
    },
  },
})(MenuItem);
class MenuButton extends Component<any, any> {
  constructor(props: any) {
    super(props);
    this.state = { anchorE1: null, open: false };
    this.handleClick = this.handleClick.bind(this);
    this.handleClose = this.handleClose.bind(this);
  }

  handleClick = (event: any) => {
    this.setState({ anchorE1: event.currentTarget });
    this.setState({ open: !this.state.open });
  };
  handleClose = () => {
    this.setState({ open: !this.state.open });
  };

  render() {
    return (
      <div
        onClick={this.handleClick}
        style={{
          display: "flex",
        }}
      >
        {this.props.icon}
        <StyledMenu
          id="simple-menu"
          anchorEl={this.state.anchorE1}
          keepMounted
          open={this.state.open}
          onClose={this.handleClose}
        >
          {this.props.items.map((item: any, index: any) => {
            return (
              <StyledMenuItem
                key={index}
                style={{
                  fontWeight: 400,
                  fontFamily: "Merriweather",
                  fontSize: "14px",
                  lineHeight: "18px",
                  color: "#FFFFFF",
                  padding: "10px",
                }}
                onClick={item.onClick}
              >
                <Box marginLeft="5px">{item.Title}</Box>
              </StyledMenuItem>
            );
          })}
        </StyledMenu>
      </div>
    );
  }
}

export default MenuButton;
