import { makeStyles, Button as MuiButton } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  root: {
    margin: theme.spacing(3),
  },
  label: {
    textTransform: "none",
  },
}));

function Button(props: any) {
  const { text, size, color, variant, id, onClick, ...other } = props;
  const classes = useStyles()
  return (
    <MuiButton
      variant={variant || "contained"}
      size={size || "large"}
      color={color || "default"}
      id={id}
      onClick={onClick}
      {...other}
      classes={{root:classes.root,label:classes.label}}
    >
      {text}
    </MuiButton>
  );
}

export default Button;
