import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import IconButton from "@material-ui/core/IconButton";
import {useState} from "react";
import closeIcon from "../../assets/icons/x.svg";
import Controls from "../../components/controls/Controls";
import GenericButton from "../../components/GenericButton";
import Grid from "@material-ui/core/Grid";
import Divider from "@material-ui/core/Divider";
import makeStyles from "@material-ui/core/styles/makeStyles";
import {TextField} from "@material-ui/core";
import {createTheme, ThemeProvider} from "@mui/material/styles";
import MUIRichTextEditor from "mui-rte";
import {stateToHTML} from "draft-js-export-html";
import Icon from "@material-ui/core/Icon";
import {getBroadcastDetails, broadcastEmail} from "../../api";
import LoadingScreen from "../../components/LoadingScreen";

const useStyles = makeStyles((theme: any) => ({
  divider: {
    background: "##E7EAED",
    margin: theme.spacing(0, 0, 0, 0),
    [theme.breakpoints.between("0", "xs")]: {
      margin: theme.spacing(2, 0, 2, 0),
    },
  },
  subject: {
    "&::placeholder": {
      color: "#000",
    },
    margin: theme.spacing(0.5, 0, 0.5, 0),
  },
}));

const myTheme = createTheme({
  // Set up your custom MUI theme here
});

function MessageModal(props: any) {
  console.log("Message Modal Props: ");
  console.log(props);
  const styleClasses = useStyles();
  const [classes, setClasses] = useState<string[]>([]);
  const [status, setStatus] = useState<string[]>([]);
  const [subject, setSubject] = useState("");
  const [message, setMessage] = useState<any>();
  const [loading, setLoading] = useState(false);
  let editorState: any = null;

  const handleChange = (event: any) => {
    const {
      target: {value},
    } = event;
    setClasses(typeof value === "string" ? value.split(",") : value);
  };

  const handleStatusChange = (event: any) => {
    const {
      target: {value},
    } = event;
    setStatus(typeof value === "string" ? value.split(",") : value);
  };

  const handleSend = () => {
    setLoading(true);
    const orgId = props.user.attributes["custom:orgId"];
    const campusId = props.user.attributes["custom:campusId"];
    const senderEmail = props.user.attributes.email;

    const data = {
      senderEmail: senderEmail,
      recipient: {
        recipientGroup: {
          class: classes,
          appStatus: status,
        },
      },
      content: {
        subject: subject,
        body: message,
      },
      documentReferences: [],
    };
    console.log(data);
    broadcastEmail(orgId, campusId, data)
      .then((res) => {
        getBroadcastDetails(orgId, campusId)
          .then((res) => {
            props.setData(res.data);
            setLoading(false);
            props.setOpenMessageModal(false);
            props.setSuccess(true);
          })
          .catch((err) => {
            console.log(err);
          });
      })
      .catch((err) => {
        setLoading(false);
        console.log("Failed");
        console.log(err);
        props.setError(true);
      });
  };

  const smallScreen = useMediaQuery((theme: any) =>
    theme.breakpoints.between("0", "xs")
  );
  const mediumScreen = useMediaQuery((theme: any) =>
    theme.breakpoints.between("xs", "sm")
  );

  var indianOptions: string[] = [
    "Nursery",
    "KG",
    "LKG",
    "UKG",
    "Class 1",
    "Class 2",
    "Class 3",
    "Class 4",
    "Class 5",
    "Class 6",
    "Class 7",
    "Class 8",
    "Class 9",
    "Class 10",
  ];

  var australianOptions: string[] = [
    "Kinder Garden",
    "Prep",
    "Grade 1",
    "Grade 2",
    "Grade 3",
    "Grade 4",
    "Grade 5",
    "Grade 6",
    "Grade 7",
    "Grade 8",
    "Grade 9",
    "Grade 10",
    "Grade 11",
    "Grade 12",
  ];

  return loading ? (
    <Box height="90vh">
      <LoadingScreen />
    </Box>
  ) : (
    <>
      <Box
        width={smallScreen ? "100%" : mediumScreen ? "58%" : "50%"}
        position="absolute"
        right={0}
        top={73}
        zIndex={1}
        padding="25px"
        overflow="scroll"
        maxHeight="96vh"
        minHeight="96vh"
        style={{background: "#FFFFFF"}}
      >
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          marginBottom="25px"
        >
          <Typography variant="h3">Write a message</Typography>
          <IconButton onClick={props.toggleMessageModal}>
            <img src={closeIcon} alt="" />
          </IconButton>
        </Box>
        <Typography
          variant="h5"
          style={{marginBottom: "15pt", color: "#000000"}}
        >
          To Recipients
        </Typography>
        <Grid container spacing={1}>
          <Grid item md={6} sm={6} xs={12}>
            <Controls.MultiSelect
              id="class"
              label="Class"
              name="class"
              value={classes}
              width="100%"
              onChange={handleChange}
              options={australianOptions}
            />
          </Grid>

          <Grid item md={6} sm={6} xs={12}>
            <Controls.MultiSelect
              id="status"
              label="Status"
              name="class"
              value={status}
              width="100%"
              onChange={handleStatusChange}
              options={[
                "Submitted",
                "Interview Scheduled",
                "Post-interview Review",
                "Admission Granted",
                "Rejected",
              ]}
            />
          </Grid>
          <Grid item md={12} sm={12} xs={12}>
            <Divider className={styleClasses.divider} />
          </Grid>
          <Grid item md={12} sm={12} xs={12}>
            <TextField
              fullWidth
              id="subject"
              type="text"
              placeholder="Subject"
              name="subject"
              value={subject}
              onChange={(event: any) => setSubject(event.target.value)}
              InputProps={{
                disableUnderline: true,
                classes: {input: styleClasses.subject},
              }}
            />
          </Grid>
          <Grid item md={12} sm={12} xs={12}>
            <Divider className={styleClasses.divider} />
          </Grid>
          <Grid item md={12} sm={12} xs={12}>
            <Box>
              <ThemeProvider theme={myTheme}>
                <MUIRichTextEditor
                  label="Write a message"
                  controls={["bold", "italic", "underline", "bulletList"]}
                  onChange={(state) => {
                    editorState = state;
                    setMessage(stateToHTML(editorState.getCurrentContent()));
                  }}
                />
              </ThemeProvider>
            </Box>
          </Grid>
          {}
        </Grid>
        <Box
          position="absolute"
          right={0}
          bottom={0}
          padding="25px"
          alignContent={"flex-end"}
        >
          <GenericButton
            background="blue"
            variant="outlined"
            onClick={handleSend}
            endIcon={<Icon>send</Icon>}
          >
            Send
          </GenericButton>
        </Box>
      </Box>
    </>
  );
}

export default MessageModal;
