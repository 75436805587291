import React, {Component} from "react";
import FormInitial from "./FormInitial";
import LoadingScreen from "../../components/LoadingScreen";
import {getAllChildren, getApplicationDetails} from "../../api";
import {Box, withStyles, Divider} from "@material-ui/core";
import FormTitle from "./FormTitle";
import PageHeadBase from "../../components/PageHeadBase";
import ApplicationDetails from "../ViewApplication/ApplicationDetails";
import {page} from "../../Data/getPage";
import ResultModal from "../../components/ResultModal";
import {useEffect, useState} from "react";
import Dialog from "@material-ui/core/Dialog";

const useStyles: any = (theme: any) => ({
  divider: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(2),
  },
  main: {
    marginLeft: theme.spacing(20),
    marginRight: theme.spacing(20),
    [theme.breakpoints.between("0", "xs")]: {
      marginLeft: 0,
      marginRight: 0,
    },
    [theme.breakpoints.between("xs", "sm")]: {
      marginLeft: 0,
      marginRight: 0,
    },
  },
  main2: {
    marginLeft: theme.spacing(10),
    marginRight: theme.spacing(10),
    [theme.breakpoints.between("0", "xs")]: {
      marginLeft: 0,
      marginRight: 0,
    },
    [theme.breakpoints.between("xs", "sm")]: {
      marginLeft: 0,
      marginRight: 0,
    },
  },
  view: {
    background: theme.palette.background.paper,
    borderRadius: "16px",
    padding: theme.spacing(2),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
  },
});

class Main extends Component<any, any> {
  constructor(props: any) {
    super(props);
    this.handleClose = this.handleClose.bind(this);
    this.state = {newData: {}, loading: true, populateData: {}};
  }

  handleClose = () => {
    this.setState({isModalOpen: false});
  };

  componentDidMount() {
    window.scrollTo(0, 0);
    const {
      match: {params},
    } = this.props;
    if (params.id) {
      const applicationId = params.id;
      getApplicationDetails(applicationId)
        .then((res) => {
          console.log(res);
          if (this.props.location.state === undefined) {
            this.setState({newData: res.data});
          } else {
            this.setState({newData: res.data, loading: false});
          }
          if (this.props.location.state === undefined) {
            getAllChildren(res.data.customerId)
              .then((res) => {
                if (Object.keys(res.data).length !== 0)
                  this.setState({
                    loading: false,
                    populateData: res.data,
                  });
              })
              .catch((err) => {
                if (err.response) {
                  console.log(err.response.status);
                  if (err.response.status === 401) this.props.handleLogout();
                }
              });
          }
        })
        .catch((err) => {
          this.props.history.push("/dashboard");
          if (err.response) {
            console.log(err.response.status);
            if (err.response.status === 401) this.props.handleLogout();
          }
        });
    } else this.setState({loading: false});
  }

  render() {
    console.log("Main Props");
    console.log(this.props);
    const {classes, orgDetails} = this.props;
    const orgName = orgDetails.orgName.replace(/\s/g, "_");
    const SideMenu = page(orgName, "index");
    let path, title;
    if (this.state.loading) {
      path = "Dashboard -> Application No. :";
    } else if (Object.keys(this.state.newData).length !== 0)
      path = `Dashboard -> Application No. : ${this.state.newData.applicationId}`;
    else path = "Select School -> New Application Form";
    if (this.state.loading) title = "";
    else if (Object.keys(this.state.newData).length !== 0) {
      if (this.state.newData.status === "Draft") title = "New Application";
    } else title = "New Application";

    return (
      <Box
        style={{
          background:
            "linear-gradient(to bottom, #3B3A3C 0%,#3B3A3C 60vh ,#F3F6F8 60vh,#F3F6F8 100vh)",
          backgroundRepeat: "no-repeat",
          backgroundAttachment: "scrollable",
        }}
      >
        {this.state.loading ? (
          <LoadingScreen />
        ) : (
          <PageHeadBase route={path} title={title} history={this.props.history}>
            {title && <Divider className={classes.divider} />}
            <Box
              className={classes[SideMenu.length > 8 ? "main2" : "main"]}
              display="flex"
              flexDirection="column"
            >
              <FormTitle
                childDetails={
                  this.props.location.state !== undefined
                    ? this.props.location.state.childDetails
                    : this.state.populateData.childList.find(
                        (child: any) =>
                          child.childId ===
                          this.state.newData.applicantDetails.childId
                      )
                }
                organisationName={orgName}
              />
              {!this.state.loading &&
              [
                "Submitted",
                "Interview Scheduled",
                "Post-interview Review",
                "Admission Granted",
                "Rejected",
              ].includes(this.state.newData.status) ? (
                <Box className={classes.view}>
                  {(this.props.user.attributes["custom:role"] === "Parent" &&
                    (this.state.newData.auditDetails.createdBy ===
                      this.props.user.attributes?.email ||
                      this.state.newData.auditDetails.createdBy ===
                        this.props.user.attributes?.phone_number)) ||
                  (["Org_Admin", "Org_User"].includes(
                    this.props.user.attributes["custom:role"]
                  ) &&
                    this.props.user.attributes["custom:campusId"] ===
                      this.state.newData.campusId) ? (
                    <ApplicationDetails data={this.state.newData} />
                  ) : (
                    <Dialog
                      PaperProps={{
                        style: {
                          borderRadius: "10px",
                          background: "#ECEFF1",
                        },
                      }}
                      open={Boolean(this.props.history.push)}
                    >
                      <ResultModal
                        type="error"
                        title="Error"
                        content="Access to this application is restricted."
                        handleClose={() => {
                          this.props.history.push("/dashboard/applications");
                        }}
                      />
                    </Dialog>
                  )}
                </Box>
              ) : (
                <FormInitial
                  handleLogout={this.props.handleLogout}
                  newData={this.state.newData}
                  orgDetails={orgDetails}
                  user={this.props.user}
                  updateUser={this.props.updateUser}
                  history={this.props.history}
                  match={this.props.match}
                  childDetails={
                    this.props.location.state !== undefined
                      ? this.props.location.state.childDetails
                      : this.state.populateData.childList.find(
                          (child: any) =>
                            child.childId ===
                            this.state.newData.applicantDetails.childId
                        )
                  }
                  populateData={
                    this.props.location.state !== undefined
                      ? this.props.location.state.populateData
                      : this.state.populateData
                  }
                />
              )}
            </Box>
          </PageHeadBase>
        )}
      </Box>
    );
  }
}

export default withStyles(useStyles, {withTheme: true})(Main);
