import {useEffect, useState} from "react";
import {
  cancelSubscription,
  checkout,
  getSubscriptionPlans,
  subCampusLinkage,
  getActiveSubscription,
} from "../../api";
import GenericButton from "../../components/GenericButton";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import makeStyles from "@material-ui/core/styles/makeStyles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import Modal from "@material-ui/core/Modal";
import Grid from "@material-ui/core/Grid";
import CardHeader from "@material-ui/core/CardHeader";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import LoadingScreen from "../../components/LoadingScreen";
import Pricing from "../../components/Pricing";
import application from "../../assets/icons/Application.svg";
import user from "../../assets/icons/User.svg";
import Alert from "@material-ui/lab/Alert";
import AlertTitle from "@material-ui/lab/AlertTitle";
import successIcon from "../../assets/icons/success.svg";
import errorIcon from "../../assets/icons/error.svg";

const useStyles = makeStyles((theme: any) => ({
  cardHeader: {
    paddingTop: theme.spacing(1),
    color: "#000",
    textAlign: "center",
  },
  card: {
    borderColor: "black",
    borderRadius: "16px",
    boxShadow: "3px 3px 20px rgba(96, 125, 139, 0.5)",
    padding: theme.spacing(3, 3, 3, 3),
    [theme.breakpoints.between("xs", "sm")]: {
      padding: theme.spacing(1.5, 1.5, 1.5, 1.5),
    },
    margin: "30px 0 30px 0",
  },
  cardContent: {
    paddingTop: "0px",
  },
  box: {
    paddingTop: "27px",
    textAlign: "center",
  },
  price: {
    textAlign: "center",
    color: "#000",
    fontSize: "30px",
    paddingTop: "16px",
  },
  alert: {
    background: "#ECEFF1",
    borderRadius: "10px",
    display: "flex",
    alignItems: "center",
    position: "relative",
    "& .MuiAlert-action": {
      display: "flex",
      alignSelf: "flex-start",
      position: "absolute",
      right: "20px",
      top: "15px",
    },
    "& .MuiIconButton-label": {
      border: "1px solid #607D8B",
      borderRadius: "50%",
      width: "20px",
      height: "20px",
    },
    "& .MuiSvgIcon-fontSizeSmall": {
      fontSize: "15px",
      color: "#607D8B",
    },
    "& .MuiAlert-icon": {
      [theme.breakpoints.between("xs", "sm")]: {
        width: "15vw",
        height: "15vw",
        marginLeft: "-10px",
      },
      [theme.breakpoints.down("xs")]: {
        width: "20vw",
        height: "20vw",
        marginLeft: "-10px",
      },
    },
  },
  icon: {
    [theme.breakpoints.between("xs", "sm")]: {
      width: "100%",
      height: "100%",
    },
    [theme.breakpoints.down("xs")]: {
      width: "100%",
      height: "100%",
    },
  },
  alertTitle: {
    ...theme.typography.displayTitle,
    marginBottom: "10px",
    [theme.breakpoints.down("xs")]: {
      fontSize: "13px",
      lineHeight: "17px",
      marginBottom: "5px",
    },
  },
}));

function Subscription(props: any) {
  const classes = useStyles();
  const [loading, setLoading] = useState(false);
  const [loadingFreeSub, setLoadingFreeSub] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [openCustomModal, setOpenCustomModal] = useState(false);
  const [planData, setPlanData] = useState([]);
  const [features, setFeatures] = useState({
    customURL: props.subData?.features?.customURL || "",
    exporting: props.subData?.features?.exporting || "",
    maxApps: props.subData?.features?.maxApps || "",
    maxUsers: props.subData?.features?.maxUsers || "",
    reporting: props.subData?.features?.reporting || "",
    support: props.subData?.features?.support || "",
    broadcast: props.subData?.features?.broadcast || "",
    checkZone: props.subData?.features?.checkZone || "",
  });
  const [subscriptionObj, setSubscriptionObj] = useState({
    subscriptionId: props.subData?.extSubId || "",
    amount: props.subData?.price || "",
    plan: props.subData?.name || "",
    paymentInterval: "",
    createdAt: "",
    currentPeriodStart: props.subData?.startDate || "",
    currentPeriodEnd: props.subData?.endDate || "",
    status: props.subData?.status || "",
    canceledAt: props.subData?.canceledAt || "",
  });
  useEffect(() => {
    if (props.subscriptionHeader === "Free") {
      console.log("Subscription Header: ", props.subscriptionHeader);
      setLoadingFreeSub(true);
      getActiveSubscription(
        String(localStorage.getItem("orgId")),
        String(localStorage.getItem("campusId"))
      )
        .then((activeSubData) => {
          console.log("Sub", activeSubData.data);
          props.setSubData(activeSubData.data);
          setSubscriptionObj({
            subscriptionId: activeSubData?.data?.extSubId || "",
            amount: activeSubData?.data?.price || "",
            plan: activeSubData?.data?.name || "",
            paymentInterval: "",
            createdAt: "",
            currentPeriodStart: activeSubData?.data?.startDate || "",
            currentPeriodEnd: activeSubData?.data?.endDate || "",
            status: activeSubData?.data?.status || "",
            canceledAt: "",
          });
          setFeatures({
            customURL: activeSubData?.data?.features?.customURL || "",
            exporting: activeSubData?.data?.features?.exporting || "",
            maxApps: activeSubData?.data?.features?.maxApps || "",
            maxUsers: activeSubData?.data?.features?.maxUsers || "",
            reporting: activeSubData?.data?.features?.reporting || "",
            support: activeSubData?.data?.features?.support || "",
            broadcast: activeSubData?.data?.features?.broadcast || "",
            checkZone: activeSubData?.data?.features?.checkZone || "",
          });
          setLoadingFreeSub(false);
        })
        .catch((err: any) => {
          console.log(err);
          if (err.response) {
            console.log(err.response.status);
          }
          setLoadingFreeSub(false);
        });
    }

    if (!subscriptionObj.plan) {
      setLoading(true);
      getSubscriptionPlans()
        .then((plans) => {
          console.log("Plans", plans.data);
          setPlanData(plans.data);
          setLoading(false);
        })
        .catch((err: any) => {
          console.log(err);
          if (err.response) {
            console.log(err.response.status);
          }
        });
    }
  }, []);
  const handleBasic = (plan: any) => {
    const lineItem = [
      {
        price: plan.priceId.price_list[0].priceId,
        quantity: 1,
      },
    ];
    localStorage.setItem("subId", plan.subId);
    handleSub(lineItem);
  };
  const handlePremium = (plan: any) => {
    const lineItem = [
      {
        price: plan.priceId.price_list[0].priceId,
        quantity: 1,
      },
    ];
    localStorage.setItem("subId", plan.subId);
    handleSub(lineItem);
  };

  const handleFree = (plan: any) => {
    console.log(plan);
    setLoading(true);
    props.setLoadingSub(true);
    subCampusLinkage(
      props.orgDetails?.orgId,
      props.campusData?.campusId,
      plan.subId
    )
      .then((subLinkData) => {
        console.log("Linked Campus", subLinkData.data);
        props.setSubData(subLinkData.data?.data);
        setSubscriptionObj({
          subscriptionId: subLinkData.data?.data?.extSubId || "",
          amount: subLinkData.data?.data?.price || "",
          plan: subLinkData.data?.data?.name || "",
          paymentInterval: "",
          createdAt: "",
          currentPeriodStart: subLinkData.data?.data?.startDate || "",
          currentPeriodEnd: subLinkData.data?.data?.endDate || "",
          status: subLinkData.data?.data?.status || "",
          canceledAt: "",
        });
        setFeatures({
          customURL: props.subData?.features?.customURL || "",
          exporting: props.subData?.features?.exporting || "",
          maxApps: props.subData?.features?.maxApps || "",
          maxUsers: props.subData?.features?.maxUsers || "",
          reporting: props.subData?.features?.reporting || "",
          support: props.subData?.features?.support || "",
          broadcast: props.subData?.features?.broadcast || "",
          checkZone: props.subData?.features?.checkZone || "",
        });
        props.setSubscriptionSuccess(true);

        setLoading(false);
        props.setLoadingSub(false);
      })
      .catch((err: any) => {
        setLoading(false);
        props.setSubscriptionError(true);
        props.setLoadingSub(false);
        console.log(err);
        if (err.response) {
          console.log(err.response.status);
          if (err.response.status === 401) props.handleLogout();
        }
      });
  };

  const handleCancelation = async () => {
    setLoading(true);
    props.setSubscriptionSuccess(false);
    props.setSubscriptionError(false);
    const canceledSubscription = await cancelSubscription(
      props.orgDetails?.orgId,
      props.campusData?.campusId,
      props.subData?.subId
    );
    setSubscriptionObj({
      ...subscriptionObj,
      status: canceledSubscription.data?.data?.status,
      canceledAt: canceledSubscription.data?.data?.endDate || "",
    });
    props.setSubData({
      ...props.subData,
      status: canceledSubscription.data?.data?.status,
      canceledAt: canceledSubscription.data?.data?.endDate || "",
    });
    props.updateProfile("cancelSub");
    props.updateHistory(props.orgDetails?.orgId, props.campusData?.campusId);
    setOpenModal(false);
    setLoading(false);
  };

  const handleNewSubscription = async () => {
    setLoading(true);
    props.setSubscriptionSuccess(false);
    props.setSubscriptionError(false);
    setSubscriptionObj({
      subscriptionId: "",
      amount: "",
      plan: "",
      paymentInterval: "",
      createdAt: "",
      currentPeriodStart: "",
      currentPeriodEnd: "",
      status: "",
      canceledAt: "",
    });
    getSubscriptionPlans()
      .then((plans) => {
        console.log("Plans", plans.data);
        setPlanData(plans.data);
        setLoading(false);
      })
      .catch((err: any) => {
        console.log(err);
        if (err.response) {
          console.log(err.response.status);
          if (err.response.status === 401) props.handleLogout();
        }
      });
  };

  const titleCase = (word: string) => {
    return word.charAt(0).toUpperCase() + word.slice(1);
  };

  const handleSub = async (
    lineItem:
      | {price: string; quantity: number}[]
      | {
          price_data: {
            currency: string;
            product: string;
            unit_amount: number;
            recurring: {interval: string};
          };
          quantity: number;
        }[]
  ) => {
    const session = await checkout(lineItem, props.location);
    console.log(session);
  };

  console.log("Data", planData);
  let handleName = function () {};
  const smallScreen = useMediaQuery((theme: any) =>
    theme.breakpoints.between(0, "xs")
  );
  const paper = {
    position: "absolute" as "absolute",
    left: smallScreen ? "37%" : "50%",
    top: "45%",
    transform: "translate(-50%, -50%)",
    width: "50%",
    backgroundColor: "#FFF",
    border: "0px solid #000",
    borderRadius: "15px",
    padding: "10px 30px 30px 30px",
  };
  return (
    <>
      {subscriptionObj.plan === "" && (
        <>
          <Modal open={openCustomModal}>
            <div style={paper}>
              <h2>Custom Subscription</h2>
              <hr />
              <Typography
                variant="subtitle2"
                style={{
                  fontSize: "16px",
                  marginBottom: "25px",
                  marginTop: "25px",
                }}
              >
                Please contact <b>admin@solvitude.com.au</b> to subscribe to
                custom plan. Till then you can subscribe to our free plan.
              </Typography>
              <Grid container spacing={2} style={{marginTop: "25px"}}>
                <Grid item xs="auto">
                  <GenericButton
                    id="ok"
                    variant="contained"
                    onClick={() => setOpenCustomModal(false)}
                    background="white"
                  >
                    Ok
                  </GenericButton>
                </Grid>
              </Grid>
            </div>
          </Modal>
          {smallScreen ? (
            <>
              {Object.keys(props.disableButton).length > 0 && (
                <Box marginTop={"7px"} color="#009AAA">
                  <label>
                    <i>Note: You can only subscribe to a plan once per day.</i>
                  </label>
                </Box>
              )}
              <Box
                margin="10px"
                color="#009AAA"
                display="flex"
                flexDirection="column"
              >
                {planData.length &&
                  planData.map((plan: any, index: any) => {
                    if (plan.name === "Free")
                      handleName = () => handleFree(plan);
                    else if (plan.name === "Basic")
                      handleName = () => handleBasic(plan);
                    else if (plan.name === "Premium")
                      handleName = () => handlePremium(plan);
                    return (
                      <Pricing
                        handleClick={handleName}
                        data={plan}
                        key={index}
                        disableButton={props.disableButton}
                      />
                    );
                  })}
              </Box>
              <Card variant="outlined" className={classes.card}>
                <CardHeader
                  title={"Custom"}
                  titleTypographyProps={{variant: "subtitle1"}}
                  className={classes.cardHeader}
                />
                <CardContent className={classes.cardContent}>
                  <Typography gutterBottom={true} className={classes.price}>
                    {"$ XX.XX"}
                  </Typography>
                  <Box px={1} className={classes.box}>
                    <Typography color="textSecondary" component="p">
                      You can customise
                    </Typography>
                    <Typography color="textSecondary" component="p">
                      every feature
                    </Typography>
                    <Typography color="textSecondary" component="p">
                      according to your
                    </Typography>
                    <Typography color="textSecondary" component="p">
                      requirements in
                    </Typography>
                    <Typography
                      color="textSecondary"
                      component="p"
                      paragraph={true}
                    >
                      the same plan.
                    </Typography>
                  </Box>
                  <Box style={{display: "flex", justifyContent: "center"}}>
                    <GenericButton
                      background="blue"
                      onClick={() => setOpenCustomModal(true)}
                      variant="contained"
                    >
                      Subscribe
                    </GenericButton>
                  </Box>
                </CardContent>
              </Card>
            </>
          ) : (
            <>
              {Object.keys(props.disableButton).length > 0 && (
                <Box marginTop={"7px"} color="#009AAA">
                  <label>
                    <i>Note: You can only subscribe to a plan once per day.</i>
                  </label>
                </Box>
              )}
              <Box
                margin="10px"
                color="#009AAA"
                display="flex"
                alignItems="center"
                justifyContent="space-around"
              >
                {planData.length &&
                  planData.map((plan: any, index: any) => {
                    if (plan.name === "Free")
                      handleName = () => handleFree(plan);
                    if (plan.name === "Basic")
                      handleName = () => handleBasic(plan);
                    else if (plan.name === "Premium")
                      handleName = () => handlePremium(plan);
                    return (
                      <Pricing
                        handleClick={handleName}
                        data={plan}
                        key={index}
                        disableButton={props.disableButton}
                      />
                    );
                  })}
                <Card variant="outlined" className={classes.card}>
                  <CardHeader
                    title={"Custom"}
                    titleTypographyProps={{variant: "subtitle1"}}
                    className={classes.cardHeader}
                  />
                  <CardContent className={classes.cardContent}>
                    <Typography gutterBottom={true} className={classes.price}>
                      {"$ XX.XX"}
                    </Typography>
                    <Box px={1} className={classes.box}>
                      <Typography color="textSecondary" component="p">
                        You can customise
                      </Typography>
                      <Typography color="textSecondary" component="p">
                        every feature
                      </Typography>
                      <Typography color="textSecondary" component="p">
                        according to your
                      </Typography>
                      <Typography color="textSecondary" component="p">
                        requirements in
                      </Typography>
                      <Typography
                        color="textSecondary"
                        component="p"
                        paragraph={true}
                      >
                        the same plan.
                      </Typography>
                    </Box>
                    <Box style={{display: "flex", justifyContent: "center"}}>
                      <GenericButton
                        background="blue"
                        onClick={() => setOpenCustomModal(true)}
                        variant="contained"
                      >
                        Subscribe
                      </GenericButton>
                    </Box>
                  </CardContent>
                </Card>
              </Box>
            </>
          )}
        </>
      )}

      {loading || loadingFreeSub ? (
        <LoadingScreen />
      ) : (
        <>
          {props.subscriptionError && (
            <Box marginBottom="25px">
              <Alert
                icon={<img className={classes.icon} src={errorIcon} alt="" />}
                className={classes.alert}
                onClose={() => {
                  props.setSubscriptionError(false);
                }}
              >
                <AlertTitle
                  className={classes.alertTitle}
                  style={{color: "#F9347B"}}
                >
                  Subscription Failed
                </AlertTitle>
                <Typography variant="caption">
                  Please try again later.
                </Typography>
              </Alert>
            </Box>
          )}
          {props.subscriptionSuccess && (
            <Box marginBottom="25px">
              <Alert
                icon={<img className={classes.icon} src={successIcon} alt="" />}
                onClose={() => {
                  props.setSubscriptionSuccess(false);
                }}
                className={classes.alert}
              >
                <AlertTitle className={classes.alertTitle}>
                  Subscribed Successfully
                </AlertTitle>
                {}
              </Alert>
            </Box>
          )}
          {subscriptionObj.plan && (
            <>
              <Box className={props.classes.container}>
                {}

                <Modal open={openModal}>
                  <div style={paper}>
                    <h2>Cancel Subscription</h2>
                    <hr />
                    <Typography
                      variant="subtitle2"
                      style={{
                        fontSize: "16px",
                        marginBottom: "25px",
                        marginTop: "25px",
                      }}
                    >
                      Your Subscription will be canceled and your Organisation
                      won't be available to the users.
                    </Typography>
                    <Grid container spacing={2} style={{marginTop: "25px"}}>
                      <Grid item xs="auto">
                        <GenericButton
                          id="save"
                          variant="contained"
                          onClick={handleCancelation}
                          background="blue"
                        >
                          Cancel Subscription
                        </GenericButton>
                      </Grid>
                      <Grid item xs="auto">
                        <GenericButton
                          id="cancel"
                          variant="contained"
                          onClick={() => setOpenModal(false)}
                          background="white"
                        >
                          Back
                        </GenericButton>
                      </Grid>
                    </Grid>
                  </div>
                </Modal>
                {}
                <Box display={"flex"} alignItems="center">
                  <Box flexGrow={1}>
                    <Typography variant="h2">Your Plan</Typography>
                  </Box>
                  {subscriptionObj.status === "Active" && (
                    <Box>
                      <GenericButton
                        id="cancel"
                        color="primary"
                        onClick={() => setOpenModal(true)}
                      >
                        Cancel Plan
                      </GenericButton>
                    </Box>
                  )}
                  {subscriptionObj.status === "Inactive" && (
                    <Box>
                      <GenericButton
                        id="new"
                        color="primary"
                        onClick={handleNewSubscription}
                      >
                        Start New Plan
                      </GenericButton>
                    </Box>
                  )}
                </Box>
                <Box
                  style={{
                    background: "#FFFFFF",
                    borderRadius: "8px",
                    padding: "16px",
                    margin: "10px 0 32px 0",
                  }}
                >
                  <Grid container spacing={2}>
                    <Grid item md={2} sm={3} xs={12}>
                      <Typography
                        variant="subtitle2"
                        style={{marginBottom: "8px"}}
                      >
                        Plan
                      </Typography>
                      <Typography variant="caption">
                        {subscriptionObj.plan}
                      </Typography>
                    </Grid>

                    <Grid item md={2} sm={3} xs={12}>
                      <Typography
                        variant="subtitle2"
                        style={{marginBottom: "8px"}}
                      >
                        Amount
                      </Typography>
                      <Typography variant="caption">
                        {subscriptionObj.amount}
                      </Typography>
                    </Grid>

                    {subscriptionObj.paymentInterval && (
                      <Grid item md={2} sm={3} xs={12}>
                        <Typography
                          variant="subtitle2"
                          style={{marginBottom: "8px"}}
                        >
                          Payment Interval
                        </Typography>
                        <Typography variant="caption">
                          {titleCase(subscriptionObj.paymentInterval)}
                        </Typography>
                      </Grid>
                    )}

                    {subscriptionObj.createdAt && (
                      <Grid item md={2} sm={3} xs={12}>
                        <Typography
                          variant="subtitle2"
                          style={{marginBottom: "8px"}}
                        >
                          Created At
                        </Typography>
                        <Typography variant="caption">
                          {subscriptionObj.createdAt}
                        </Typography>
                      </Grid>
                    )}

                    <Grid item md={2} sm={3} xs={12}>
                      <Typography
                        variant="subtitle2"
                        style={{marginBottom: "8px"}}
                      >
                        Start Date
                      </Typography>
                      <Typography variant="caption">
                        {subscriptionObj.currentPeriodStart}
                      </Typography>
                    </Grid>

                    {subscriptionObj.currentPeriodEnd && (
                      <Grid item md={6} sm={6} xs={12}>
                        <Typography
                          variant="subtitle2"
                          style={{marginBottom: "8px"}}
                        >
                          Current Period End
                        </Typography>
                        <Typography variant="caption">
                          {subscriptionObj.currentPeriodEnd}
                        </Typography>
                      </Grid>
                    )}

                    <Grid item md={2} sm={3} xs={12}>
                      <Typography
                        variant="subtitle2"
                        style={{marginBottom: "8px"}}
                      >
                        Status
                      </Typography>
                      <Typography variant="caption">
                        {titleCase(subscriptionObj.status)}
                      </Typography>
                    </Grid>

                    {subscriptionObj.canceledAt && (
                      <Grid item md={2} sm={3} xs={12}>
                        <Typography
                          variant="subtitle2"
                          style={{marginBottom: "8px"}}
                        >
                          Canceled At
                        </Typography>
                        <Typography variant="caption">
                          {subscriptionObj.canceledAt}
                        </Typography>
                      </Grid>
                    )}
                  </Grid>
                </Box>

                <Box display={"flex"} alignItems="center">
                  <Box flexGrow={1}>
                    <Typography variant="h2">Features</Typography>
                  </Box>
                </Box>
                <Box
                  style={{
                    background: "#FFFFFF",
                    borderRadius: "8px",
                    padding: "16px",
                    margin: "10px 0 32px 0",
                  }}
                >
                  <Grid container spacing={2}>
                    <Grid item md={2} sm={3} xs={12}>
                      <Typography
                        variant="subtitle2"
                        style={{marginBottom: "8px"}}
                      >
                        Applications per year
                      </Typography>
                      <Typography variant="caption">
                        {features.maxApps}
                      </Typography>
                    </Grid>

                    <Grid item md={2} sm={3} xs={12}>
                      <Typography
                        variant="subtitle2"
                        style={{marginBottom: "8px"}}
                      >
                        Users
                      </Typography>
                      <Typography variant="caption">
                        {features.maxUsers}
                      </Typography>
                    </Grid>

                    <Grid item md={2} sm={3} xs={12}>
                      <Typography
                        variant="subtitle2"
                        style={{marginBottom: "8px"}}
                      >
                        Analytics
                      </Typography>
                      <Typography variant="caption">
                        {features.reporting ? "Active" : "Inactive"}
                      </Typography>
                    </Grid>

                    {subscriptionObj.paymentInterval && (
                      <Grid item md={2} sm={3} xs={12}>
                        <Typography
                          variant="subtitle2"
                          style={{marginBottom: "8px"}}
                        >
                          Data Export
                        </Typography>
                        <Typography variant="caption">
                          {features.exporting ? "Active" : "Inactive"}
                        </Typography>
                      </Grid>
                    )}

                    <Grid item md={2} sm={3} xs={12}>
                      <Typography
                        variant="subtitle2"
                        style={{marginBottom: "8px"}}
                      >
                        Custom URL
                      </Typography>
                      <Typography variant="caption">
                        {features.customURL ? "Active" : "Inactive"}
                      </Typography>
                    </Grid>

                    <Grid item md={2} sm={3} xs={12}>
                      <Typography
                        variant="subtitle2"
                        style={{marginBottom: "8px"}}
                      >
                        Support
                      </Typography>
                      <Typography variant="caption">
                        {features.support ? "Active" : "Inactive"}
                      </Typography>
                    </Grid>
                    <Grid item md={2} sm={3} xs={12}>
                      <Typography
                        variant="subtitle2"
                        style={{marginBottom: "8px"}}
                      >
                        Email Broadcasting
                      </Typography>
                      <Typography variant="caption">
                        {features.broadcast ? "Active" : "Inactive"}
                      </Typography>
                    </Grid>
                    <Grid item md={2} sm={3} xs={12}>
                      <Typography
                        variant="subtitle2"
                        style={{marginBottom: "8px"}}
                      >
                        Check Zone
                      </Typography>
                      <Typography variant="caption">
                        {features.checkZone
                          ? features.checkZone === "true"
                            ? "Active"
                            : "Inactive"
                          : "Inactive"}
                      </Typography>
                    </Grid>
                  </Grid>
                </Box>

                <Box display={"flex"} alignItems="center">
                  <Box flexGrow={1}>
                    <Typography variant="h2">Subscription Usage</Typography>
                  </Box>
                </Box>
                <Grid
                  container
                  style={{margin: "20px 0 32px 0", padding: "0 0 0 0"}}
                >
                  <Grid item xs={12} sm={6} md={6} key={"apps"}>
                    <Box
                      style={{
                        background: "#FFFFFF",
                        borderRadius: "8px",
                        padding: "16px",
                        marginRight: smallScreen ? "0px" : "15px",
                        marginBottom: smallScreen ? "15px" : "0px",
                      }}
                    >
                      {}
                      <Box
                        className={props.classes.textfield}
                        style={{color: "#758797"}}
                      >
                        APPLICATIONS USED
                      </Box>
                      <Box display={"flex"} p={1} m={1} alignItems="center">
                        <Box
                          className={props.classes.value}
                          style={{color: "#016D78"}}
                        >
                          {props.numOfApplications || 0}
                        </Box>
                        <Box
                          className={props.classes.textfield}
                          marginLeft={"15px"}
                          flexGrow={1}
                        >
                          Applications of {features.maxApps} used
                        </Box>
                        <Box>
                          <img
                            src={application}
                            alt="Applications Logo"
                            style={{height: 50, fill: "#016D78"}}
                          />
                        </Box>
                      </Box>
                    </Box>
                  </Grid>
                  <Grid item xs={12} sm={6} md={6} key={"users"}>
                    <Box
                      style={{
                        background: "#FFFFFF",
                        borderRadius: "8px",
                        padding: "16px",
                        marginLeft: smallScreen ? "0px" : "15px",
                        marginTop: smallScreen ? "15px" : "0px",
                      }}
                    >
                      {}
                      <Box
                        className={props.classes.textfield}
                        style={{color: "#758797"}}
                      >
                        USERS
                      </Box>
                      <Box display={"flex"} p={1} m={1} alignItems="center">
                        <Box
                          className={props.classes.value}
                          style={{color: "#016D78"}}
                        >
                          {props.numOfActiveUsers}
                        </Box>
                        <Box
                          className={props.classes.textfield}
                          marginLeft={"15px"}
                          flexGrow={1}
                        >
                          Users of {features.maxUsers} used
                        </Box>
                        <Box>
                          <img
                            src={user}
                            alt="User Logo"
                            style={{height: 50}}
                          />
                        </Box>
                      </Box>
                    </Box>
                  </Grid>
                </Grid>
              </Box>
            </>
          )}
        </>
      )}
    </>
  );
}

export default Subscription;
