import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import CircularProgress from "@material-ui/core/CircularProgress";
import Divider from "@material-ui/core/Divider";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import DateFnsUtils from "@date-io/date-fns";
import {
  MuiPickersUtilsProvider,
  TimePicker,
  DatePicker,
} from "@material-ui/pickers";
import moment from "moment";
import { useState } from "react";
import { updateApplication, sendStatusEmail } from "../../api";
import Controls from "../../components/controls/Controls";
import GenericButton from "../../components/GenericButton";
import editHistory from "../../assets/icons/editHistory.svg";
import AdminStatus from "./AdminStatus";
import useStyles from "./styles";

function ApplicationSummary(props: any) {
  const classes = useStyles();
  const { data, updateData } = props;
  const date = moment(data.auditDetails.createdAt).format("DD MMMM YYYY");
  const comment =
    data.notes.length > 0 ? data.notes[data.notes.length - 1].note : "NA";
  let intrvwDate: any = null;
  let intrvwStartTime: any = null;
  let intrvwEndTime: any = null;
  if (data.notes.length > 0) {
    intrvwDate = data.notes[data.notes.length - 1].interviewDate;
    let startTime = data.notes[data.notes.length - 1].interviewStartTime;
    let endTime = data.notes[data.notes.length - 1].interviewEndTime;
    intrvwStartTime = new Date(`${intrvwDate}T${startTime}:00.000Z`);
    intrvwEndTime = new Date(`${intrvwDate}T${endTime}:00.000Z`);
  }

  const [inEditMode, setEditMode] = useState(false);
  const [fieldValues, setFieldValues] = useState({
    status: data.status,
    interviewDate:
      data.notes[data.notes.length - 1]?.interviewDate === null
        ? null
        : intrvwDate,
    interviewStartTime:
      data.notes[data.notes.length - 1]?.interviewStartTime === null
        ? null
        : intrvwStartTime,
    interviewEndTime:
      data.notes[data.notes.length - 1]?.interviewEndTime === null
        ? null
        : intrvwEndTime,
    comment: data.notes === null ? "NA" : comment,
    onlineInterview: data.notes[data.notes.length - 1]?.onlineInterview
      ? data.notes[data.notes.length - 1]?.onlineInterview
      : false,
    interviewLink: data.notes[data.notes.length - 1]?.interviewLink
      ? data.notes[data.notes.length - 1]?.interviewLink
      : "",
  });
  const [onlineChecked, setOnlineChecked] = useState(
    fieldValues.onlineInterview
  );
  const [loading, setLoading] = useState(false);
  const [selectedDate, handleDateChange] = useState(fieldValues.interviewDate);
  console.log(selectedDate);
  const [selectedStartTime, handleStartTimeChange] = useState(
    fieldValues.interviewStartTime
  );
  const [selectedEndTime, handleEndTimeChange] = useState(
    fieldValues.interviewEndTime
  );
  const role = props.user.attributes["custom:role"];
  const type = data.auditDetails.createdBy?.includes("@") ? "email" : "phone";

  const toggleEdit = () => {
    setEditMode((prev) => !prev);
  };

  const handleChange = (event: any) => {
    if (event.target.name === "status")
      setFieldValues({
        ...fieldValues,
        status: event.target.value,
        comment: "",
      });
    else if (event.target.name === "comment")
      setFieldValues({ ...fieldValues, comment: event.target.value });
    else if (event.target.name === "online") {
      setOnlineChecked(event.target.checked);
      if (!onlineChecked)
        setFieldValues({
          ...fieldValues,
          interviewLink: "",
          onlineInterview: true,
        });
    } else if (event.target.name === "interviewLink")
      setFieldValues({
        ...fieldValues,
        interviewLink: event.target.value,
      });
  };
  const handleSave = () => {
    setLoading(true);
    const fullName =
      props.user.attributes["custom:firstName"] +
      " " +
      props.user.attributes["custom:lastName"];

    const startTime = new Date(fieldValues.interviewStartTime).toUTCString();
    const endTime = new Date(fieldValues.interviewEndTime).toUTCString();

    const notes = [
      {
        note: fieldValues.comment,
        status: fieldValues.status,
        fullName: fullName,
        interviewDate:
          fieldValues.status === "Interview Scheduled"
            ? fieldValues.interviewDate
            : null,
        interviewStartTime:
          fieldValues.status === "Interview Scheduled" ? startTime : null,
        interviewEndTime:
          fieldValues.status === "Interview Scheduled" ? endTime : null,
        onlineInterview:
          fieldValues.status === "Interview Scheduled"
            ? fieldValues.onlineInterview
            : false,
        interviewLink:
          fieldValues.status === "Interview Scheduled"
            ? fieldValues.interviewLink
            : null,
      },
    ];
    if (
      fieldValues.status !== data.status ||
      (fieldValues.comment !== comment && fieldValues.comment !== "") ||
      fieldValues.interviewDate !== intrvwDate ||
      fieldValues.interviewStartTime !== intrvwStartTime ||
      fieldValues.interviewEndTime !== intrvwEndTime
    ) {
      updateApplication(data.applicationId, {
        status: fieldValues.status,
        notes: notes,
        additionalInfo: data.additionalInfo,
      })
        .then((res) => {
          if (fieldValues.status === "Interview Scheduled") {
            const iDate = new Date(fieldValues.interviewDate);
            const interviewDate = moment(iDate).format("DD MMMM YYYY");
            const startTime = moment(fieldValues.interviewStartTime).format(
              "hh:mm A"
            );
            const endTime = moment(fieldValues.interviewEndTime).format(
              "hh:mm A"
            );
            const onlineLink = onlineChecked ? fieldValues.interviewLink : null;
            const interviewSchedule = type === "phone"
              ? `${interviewDate}, ${startTime}`
              : onlineChecked
                ?`Interview has been scheduled on ${interviewDate} between ${startTime} and ${endTime}. Here are additional details regarding the online interview: ${onlineLink}`
                : `Interview has been scheduled on ${interviewDate} between ${startTime} and ${endTime}.`;

            sendStatusEmail(
              data,
              data.auditDetails.createdBy,
              data.applicationId,
              data.orgDetails.orgName,
              fieldValues.status,
              interviewSchedule
            );
          } else if (
            fieldValues.status !== data.status &&
            fieldValues.status !== "Interview Scheduled"
          ) {
            sendStatusEmail(
              data,
              data.auditDetails.createdBy,
              data.applicationId,
              data.orgDetails.orgName,
              fieldValues.status,
            );
          }
          updateData(res.data);
          setLoading(false);
          toggleEdit();
        })
        .catch((err) => {
          console.log(err);
          setLoading(false);
        });
    } else {
      setLoading(false);
      toggleEdit();
    }
  };
  const handleCancel = () => {
    setFieldValues({
      ...fieldValues,
      status: data.status,
      comment: data.notes === null ? "NA" : comment,
      interviewDate: data.notes[data.notes.length - 1]?.interviewDate,
      interviewStartTime: data.notes[data.notes.length - 1]?.interviewStartTime,
      interviewEndTime: data.notes[data.notes.length - 1]?.interviewEndTime,
    });
    toggleEdit();
  };
  let options: any[] = [];
  if (data.status === "Submitted")
    options = [
      {
        Id: "sub",
        Label: "Submitted",
      },
      {
        Id: "is",
        Label: "Interview Scheduled",
      },
    ];
  else if (data.status === "Interview Scheduled")
    options = [
      {
        Id: "is",
        Label: "Interview Scheduled",
      },
      {
        Id: "pis",
        Label: "Post-interview Review",
      },
      {
        Id: "ag",
        Label: "Admission Granted",
      },
      {
        Id: "rej",
        Label: "Rejected",
      },
    ];
  else if (data.status === "Post-interview Review")
    options = [
      {
        Id: "pis",
        Label: "Post-interview Review",
      },
      {
        Id: "ag",
        Label: "Admission Granted",
      },
      {
        Id: "rej",
        Label: "Rejected",
      },
    ];
  else {
    options = [
      {
        Id: "ag",
        Label: "Admission Granted",
      },
      {
        Id: "rej",
        Label: "Rejected",
      },
    ];
  }
  const smallScreen = useMediaQuery((theme: any) =>
    theme.breakpoints.down("sm")
  );

  return (
    <Box
      display="flex"
      flexDirection="column"
      width={smallScreen ? "100%" : "28%"}
      {...(smallScreen && { marginBottom: "30px" })}
    >
      <Box className={classes.overview} style={{ marginBottom: "30px" }}>
        <Box className={classes.summaryTitle}>Application Overview</Box>
        <Box display="flex" marginTop="25px" marginBottom="20px">
          <Box display="flex" flexDirection="column" marginRight="20px">
            <Typography variant="subtitle2" style={{ marginBottom: "0.5em" }}>
              Application No.
            </Typography>
            <Typography variant="caption">{data.applicationId}</Typography>
          </Box>
          <Box display="flex" flexDirection="column">
            <Typography variant="subtitle2" style={{ marginBottom: "0.5em" }}>
              Date Received
            </Typography>
            <Typography variant="caption">{date}</Typography>
          </Box>
        </Box>
        <Box display="flex" flexDirection="column" marginBottom="20px">
          <Typography variant="subtitle2" style={{ marginBottom: "0.5em" }}>
            Child Name
          </Typography>
          <Typography variant="caption">
            {data.applicantDetails.firstName +
              " " +
              data.applicantDetails.lastName}
          </Typography>
        </Box>
        <Box display="flex" flexDirection="column">
          <Typography variant="subtitle2" style={{ marginBottom: "0.5em" }}>
            Branch
          </Typography>
          <Typography variant="caption">{data.orgDetails.orgName} </Typography>
        </Box>
      </Box>
      <Box className={classes.overview}>
        <Box
          display="flex"
          alignItems="center"
          justifyContent="space-between"
          marginBottom="25px"
        >
          <Box className={classes.summaryTitle}>Application Progress</Box>
          {role === "Org_Admin" ? (
            <GenericButton
              background="light"
              id="edit"
              variant="contained"
              onClick={toggleEdit}
            >
              Edit
            </GenericButton>
          ) : null}
        </Box>
        <Box display="flex" flexDirection="column" marginBottom="25px">
          <Typography variant="subtitle2" style={{ marginBottom: "0.8em" }}>
            Status
          </Typography>
          {inEditMode ? (
            <Controls.Select
              id="status"
              value={fieldValues.status}
              name="status"
              width="100%"
              onChange={handleChange}
              options={options}
            />
          ) : (
            <AdminStatus status={data.status} />
          )}
        </Box>
        {fieldValues.status === "Interview Scheduled" ? (
          <>
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <Box display="flex" flexDirection="column" marginBottom="25px">
                <Typography
                  variant="subtitle2"
                  style={{ marginBottom: "0.8em" }}
                >
                  Date
                </Typography>
                {inEditMode ? (
                  <DatePicker
                    id="interviewDate"
                    name="interviewDate"
                    inputVariant="outlined"
                    style={{
                      width: "100%",
                      height: "19px",
                      color: "#3B3A3C",
                    }}
                    format={"dd MMMM yyyy"}
                    minDate={new Date()}
                    minDateMessage="Invalid minimum date"
                    disabled={fieldValues.status !== "Interview Scheduled"}
                    value={
                      fieldValues.interviewDate
                        ? fieldValues.interviewDate
                        : null
                    }
                    onChange={(date) => {
                      handleDateChange(date);
                      setFieldValues({
                        ...fieldValues,
                        interviewDate: date,
                      });
                    }}
                  />
                ) : (
                  <Typography variant="caption">
                    {data.notes[data.notes.length - 1]?.interviewDate
                      ? moment(
                          data.notes[data.notes.length - 1]?.interviewDate
                        ).format("DD MMMM yyyy")
                      : "NA"}
                  </Typography>
                )}
              </Box>

              <Box
                display="flex"
                flexDirection="row"
                marginBottom={inEditMode ? "55px" : "25px"}
                marginTop={inEditMode ? "55px" : "25px"}
              >
                <Box>
                  <Typography
                    variant="subtitle2"
                    style={{ marginBottom: "0.8em" }}
                  >
                    Start Time
                  </Typography>
                  {inEditMode ? (
                    <TimePicker
                      id="interviewStartTime"
                      name="interviewStartTime"
                      inputVariant="outlined"
                      style={{
                        width: "90%",
                        height: "19px",
                        color: "#3B3A3C",
                      }}
                      disabled={fieldValues.status !== "Interview Scheduled"}
                      value={
                        fieldValues.interviewStartTime
                          ? fieldValues.interviewStartTime
                          : null
                      }
                      onChange={(time) => {
                        handleStartTimeChange(time);
                        setFieldValues({
                          ...fieldValues,
                          interviewStartTime: time,
                        });
                      }}
                    />
                  ) : (
                    <Typography variant="caption">
                      {fieldValues.interviewStartTime
                        ? moment(fieldValues.interviewStartTime).format(
                            "hh:mm A"
                          )
                        : "NA"}
                    </Typography>
                  )}
                </Box>
                <Box marginLeft="10px">
                  <Typography
                    variant="subtitle2"
                    style={{ marginBottom: "0.8em" }}
                  >
                    End Time
                  </Typography>
                  {inEditMode ? (
                    <TimePicker
                      id="interviewEndTime"
                      name="interviewEndTime"
                      inputVariant="outlined"
                      style={{
                        width: "90%",
                        height: "19px",
                        color: "#3B3A3C",
                      }}
                      disabled={fieldValues.status !== "Interview Scheduled"}
                      value={
                        fieldValues.interviewEndTime
                          ? fieldValues.interviewEndTime
                          : null
                      }
                      error={
                        fieldValues.interviewEndTime <=
                          fieldValues.interviewStartTime &&
                        fieldValues.interviewEndTime !== null
                      }
                      helperText={
                        fieldValues.interviewEndTime <=
                          fieldValues.interviewStartTime &&
                        fieldValues.interviewEndTime !== null
                          ? "End time cannot be less than Start time."
                          : ""
                      }
                      onChange={(time) => {
                        handleEndTimeChange(time);
                        //console.log(time);
                        setFieldValues({
                          ...fieldValues,
                          interviewEndTime: time,
                        });
                      }}
                    />
                  ) : (
                    <Typography variant="caption">
                      {fieldValues.interviewEndTime
                        ? moment(fieldValues.interviewEndTime).format("hh:mm A")
                        : "NA"}
                    </Typography>
                  )}
                </Box>
              </Box>
            </MuiPickersUtilsProvider>
            <Box display="flex" flexDirection="column" marginBottom="20px">
              {inEditMode ? (
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={onlineChecked}
                      onChange={handleChange}
                      name="online"
                      color="primary"
                    />
                  }
                  label="Online"
                />
              ) : null}
            </Box>
            {onlineChecked ? (
              <Box display="flex" flexDirection="column" marginBottom="20px">
                <Typography
                  variant="subtitle2"
                  style={{ marginBottom: "0.5em" }}
                >
                  Link for the Online Interview
                </Typography>
                {inEditMode ? (
                  <Controls.Textarea
                    id="interviewLink"
                    width="100%"
                    name="interviewLink"
                    value={fieldValues.interviewLink}
                    onChange={handleChange}
                  />
                ) : (
                  <Typography
                    variant="caption"
                    style={{ wordBreak: "break-all" }}
                  >
                    {fieldValues.interviewLink}
                  </Typography>
                )}
              </Box>
            ) : null}
          </>
        ) : null}

        {/* <div style={{ marginTop: 20 }}>{JSON.stringify(fieldValues)}</div> */}
        <Box display="flex" flexDirection="column" marginBottom="20px">
          <Typography variant="subtitle2" style={{ marginBottom: "0.5em" }}>
            Comments
          </Typography>
          {inEditMode ? (
            <Controls.Textarea
              id="comment"
              width="100%"
              name="comment"
              value={fieldValues.comment}
              onChange={handleChange}
            />
          ) : (
            <Typography variant="caption" style={{ wordBreak: "break-all" }}>
              {data.notes === null ? "NA" : comment}
            </Typography>
          )}
        </Box>
        {inEditMode && (
          <Box display="flex" justifyContent="flex-end">
            <Box marginRight="20px">
              <GenericButton
                id="cancel"
                background="light"
                variant="contained"
                onClick={handleCancel}
              >
                Cancel
              </GenericButton>
            </Box>
            <GenericButton
              id="submit"
              background="blue"
              variant="contained"
              disabled={
                fieldValues.status === "Interview Scheduled" &&
                fieldValues.interviewDate &&
                fieldValues.interviewStartTime &&
                fieldValues.interviewEndTime &&
                fieldValues.interviewEndTime > fieldValues.interviewStartTime
                  ? false :!(fieldValues.status === "Interview Scheduled")
              }
              onClick={handleSave}
            >
              {loading ? (
                <CircularProgress
                  style={{ color: "#FFFFFF", width: "25px", height: "25px" }}
                />
              ) : (
                "Save"
              )}
            </GenericButton>
          </Box>
        )}
        {data.notes.length !== 0 && (
          <>
            <Divider
              style={{ background: "#E5E7E8", margin: "20px 0 20px 0" }}
            />
            <Box className={classes.history} marginBottom="30px">
              Application History
            </Box>
            {/* <div style={{ marginTop: 20 }}>{JSON.stringify(data.notes)}</div> */}

            <Box className={classes.historyContainer}>
              {data.notes.reverse().map((history: any, index: any) => {
                const date = moment(history.createdAt).format("DD MMMM YYYY");

                const iDate = new Date(history.interviewDate);
                const interviewDate = moment(iDate).format("DD MMMM YYYY");

                const sTime = new Date(
                  `${history.interviewDate}T${history.interviewStartTime}:00.000Z`
                );
                const startTime = moment(sTime).format("hh:mm A");

                const eTime = new Date(
                  `${history.interviewDate}T${history.interviewEndTime}:00.000Z`
                );
                const endTime = moment(eTime).format("hh:mm A");

                return (
                  <Box display="flex" key={index}>
                    <Box
                      display="flex"
                      flexDirection="column"
                      marginRight="10px"
                    >
                      <Box
                        borderRadius="50%"
                        width="20px"
                        height="25px"
                        display="flex"
                        justifyContent="center"
                        alignItems="center"
                        style={{ background: "#C7E8E4" }}
                      >
                        <img src={editHistory} alt="" />
                      </Box>

                      <Box
                        display="flex"
                        justifyContent="center"
                        width="20px"
                        height="100%"
                      >
                        <Divider
                          orientation="vertical"
                          style={{ width: "2px", background: "#E5E7E8" }}
                        />
                      </Box>
                    </Box>
                    <Box display="flex" flexDirection="column" width="100%">
                      <Box display="flex" justifyContent="space-between">
                        <Typography variant="subtitle1">
                          {history.fullName}
                        </Typography>
                        <AdminStatus status={history.status} />
                      </Box>
                      <Box
                        borderRadius="15px"
                        marginTop="10px"
                        padding="10px"
                        style={{
                          background: "#F3F6F8",
                          wordBreak: "break-all",
                        }}
                      >
                        {history.status === "Interview Scheduled" ? (
                          <Typography variant="caption">
                            {`Interview scheduled on ${interviewDate} between ${startTime} and ${endTime}. ${history.note}`}
                          </Typography>
                        ) : (
                          <Typography variant="caption">
                            {history.note}
                          </Typography>
                        )}
                      </Box>
                      <Box margin="10px 0 20px 0" className={classes.date}>
                        {date}
                      </Box>
                    </Box>
                  </Box>
                );
              })}
            </Box>
          </>
        )}
      </Box>
    </Box>
  );
}

export default ApplicationSummary;
