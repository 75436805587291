import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import makeStyles from "@material-ui/core/styles/makeStyles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import moment from "moment";
import FileSaver from "file-saver";
import childFemale from "../../assets/icons/childFemale.svg";
import childMale from "../../assets/icons/childMale.svg";
import download from "../../assets/icons/download.svg";
import xmlDownload from "../../assets/icons/xmlDownload.svg";
import downloadDisabled from "../../assets/icons/downloadDisabled.svg";
import downArrow from "../../assets/icons/downArrow.svg";
import {downloadApplication, downloadXmlApplication} from "../../api";
import { useState } from "react";
import CircularProgress from "@material-ui/core/CircularProgress";

const useStyles = makeStyles((theme) => ({
  applicationDetails: {
    backgroundColor: theme.palette.background.default,
    borderRadius: "8px",
    marginTop: theme.spacing(2),
    cursor: "pointer",
    "&.MuiAccordion-root:before": {
      backgroundColor: "white",
    },
  },
}));

function ApplicationDetailAdmin(props: any) {
  const {data, handleTableAdmin} = props;
  const date = moment(data.auditDetails.createdAt).format("DD MMMM YYYY");
  const classes = useStyles();
  const smallScreen = useMediaQuery((theme: any) =>
    theme.breakpoints.between("0", "xs")
  );
  const [loading, setLoading] = useState(false);
  if (smallScreen)
    return (
      <Accordion classes={{root: classes.applicationDetails}} elevation={0}>
        <AccordionSummary expandIcon={<img src={downArrow} alt="" />}>
          <Box
            display="flex"
            flexDirection="column"
            onClick={(event) => {
              event.stopPropagation();
              handleTableAdmin(data.applicationId);
            }}
            onFocus={(event) => event.stopPropagation()}
          >
            <Typography variant="subtitle1" style={{marginBottom: "0.5em"}}>
              {data.orgDetails.orgName}
            </Typography>
            <Box display="flex" alignItems="center">
              <img
                width="32px"
                height="32px"
                src={
                  data.applicantDetails.gender === "Male"
                    ? childMale
                    : childFemale
                }
                alt=""
                style={{marginRight: "1em"}}
              />
              <Typography variant="caption">
                {data.applicantDetails.firstName +
                  data.applicantDetails.lastName}
              </Typography>
            </Box>
          </Box>
        </AccordionSummary>
        <AccordionDetails>
          <Box
            display="flex"
            justifyContent="space-between"
            width="100%"
            onClick={() => {
              handleTableAdmin(data.applicationId);
            }}
          >
            <Box display="flex" flexDirection="column">
              <Typography variant="subtitle2" style={{marginBottom: "0.5em"}}>
                Application No.
              </Typography>
              <Typography variant="caption">{data.applicationId}</Typography>
            </Box>
            <Box display="flex" flexDirection="column">
              <Typography variant="subtitle2" style={{marginBottom: "0.5em"}}>
                Date
              </Typography>
              <Typography variant="caption">{date}</Typography>
            </Box>
            <Box display="flex" flexDirection="column">
              <Typography variant="subtitle2" style={{marginBottom: "0.5em"}}>
                Status
              </Typography>
              <Box
                borderRadius="11px"
                textAlign="center"
                style={{backgroundColor: "#FFF9E3"}}
              >
                <Typography
                  variant="caption"
                  style={{
                    color:
                      data.status === "Submitted"
                        ? "#607D8B"
                        : data.status === "Interview Scheduled"
                        ? "#FF9E45"
                        : data.status === "Post-interview Review"
                        ? "#2DB6F5"
                        : data.status === "Admission Granted"
                        ? "#009AAA"
                        : "#F9347B",
                    backgroundColor:
                      data.status === "Submitted"
                        ? "#E5E7E8"
                        : data.status === "Interview Scheduled"
                        ? "#FFEACB"
                        : data.status === "Post-interview Review"
                        ? "#D8F5FC"
                        : data.status === "Admission Granted"
                        ? "#C8FFF8"
                        : "#FFD6E4",
                    borderRadius: "11px",
                    padding: "2px 10px 2px 10px",
                  }}
                >
                  {data.status}
                </Typography>
              </Box>
            </Box>
          </Box>
        </AccordionDetails>
      </Accordion>
    );
  return (
    <Box
      display="flex"
      justifyContent="space-around"
      alignItems="center"
      className={classes.applicationDetails}
      padding="1.5em 0em 1.5em 0em"
    >
      <Box
        display="flex"
        alignItems="center"
        justifyContent="space-between"
        width="85%"
        onClick={() => {
          handleTableAdmin(data.applicationId);
        }}
      >
        <Box display="flex" flexDirection="column">
          <Typography variant="subtitle2" style={{marginBottom: "0.5em"}}>
            Application No.
          </Typography>
          <Typography variant="caption">{data.applicationId}</Typography>
        </Box>
        <Box
          display="flex"
          alignItems="center"
          width="15%"
          style={{wordBreak: "break-all"}}
        >
          <img
            width="32px"
            height="32px"
            src={
              data.applicantDetails.gender === "Male" ? childMale : childFemale
            }
            alt=""
            style={{marginRight: "0.5em"}}
          />
          <Typography variant="caption">
            {data.applicantDetails.firstName +
              " " +
              data.applicantDetails.lastName}
          </Typography>
        </Box>
        <Box display="flex" flexDirection="column">
          <Typography variant="subtitle2" style={{marginBottom: "0.5em"}}>
            Date Received
          </Typography>
          <Typography variant="caption">{date}</Typography>
        </Box>
        <Box display="flex" flexDirection="column" width="15%">
          <Typography variant="subtitle2" style={{marginBottom: "0.5em"}}>
            Branch & Form Name
          </Typography>
          <Typography variant="caption">
            {data.additionalInfo.formReference
              ? data.orgDetails.orgName +
                " - " +
                data.additionalInfo.formReference?.name
              : data.orgDetails.orgName}
          </Typography>
        </Box>

        <Box display="flex" flexDirection="column" width="20%">
          <Typography variant="subtitle2" style={{marginBottom: "0.5em"}}>
            Status
          </Typography>

          <Typography variant="caption">
            <span
              style={{
                color:
                  data.status === "Submitted"
                    ? "#607D8B"
                    : data.status === "Interview Scheduled"
                    ? "#FF9E45"
                    : data.status === "Post-interview Review"
                    ? "#2DB6F5"
                    : data.status === "Admission Granted"
                    ? "#009AAA"
                    : "#F9347B",
                backgroundColor:
                  data.status === "Submitted"
                    ? "#E5E7E8"
                    : data.status === "Interview Scheduled"
                    ? "#FFEACB"
                    : data.status === "Post-interview Review"
                    ? "#D8F5FC"
                    : data.status === "Admission Granted"
                    ? "#C8FFF8"
                    : "#FFD6E4",
                borderRadius: "11px",
                padding: "2px 10px 2px 10px",
              }}
            >
              {data.status}
            </span>
          </Typography>
        </Box>
        <Box display="flex" flexDirection="column" width="20%">
          <Typography variant="subtitle2" style={{marginBottom: "0.5em"}}>
            School Zone
          </Typography>

          <Typography variant="caption">
            <span
              style={{
                color:
                  data.status === "Submitted"
                    ? "#607D8B"
                    : data.status === "Interview Scheduled"
                    ? "#FF9E45"
                    : data.status === "Post-interview Review"
                    ? "#2DB6F5"
                    : data.status === "Admission Granted"
                    ? "#009AAA"
                    : "#F9347B",
                backgroundColor:
                  data.status === "Submitted"
                    ? "#E5E7E8"
                    : data.status === "Interview Scheduled"
                    ? "#FFEACB"
                    : data.status === "Post-interview Review"
                    ? "#D8F5FC"
                    : data.status === "Admission Granted"
                    ? "#C8FFF8"
                    : "#FFD6E4",
                borderRadius: "11px",
                padding: "2px 10px 2px 10px",
              }}
            >
              {data.applicantDetails.schoolZone}
            </span>
          </Typography>
        </Box>
      </Box>
      {(data.additionalInfo?.xmlUploaded && data.orgDetails?.source?.sourceName === "HITS") && loading ? <CircularProgress /> : <img
        src={data.status === "Draft" ? downloadDisabled : xmlDownload}
        alt="Download XML"
        onClick={() => {
          setLoading(true);
          downloadXmlApplication(data).then((res: any) => {
            console.log(res);
            const file = new File(
              [new Uint8Array(res)],
              "Application_" + data.applicationId + ".xml",
              {
                type: "text/xml",
              }
            );
            FileSaver.saveAs(file);
            setLoading(false);
          });
        }}
      />}
      <img
        src={data.status === "Draft" ? downloadDisabled : download}
        alt="Download PDF"
        onClick={() => {
          downloadApplication(
            data.documentReferences[0].docId,
            data.orgDetails.orgName,
            data.applicationId
          ).then((res: any) => {
            console.log(res);
            const file = new File(
              [new Uint8Array(res)],
              "Application_" + data.applicationId + ".pdf",
              {
                type: "application/pdf",
              }
            );
            FileSaver.saveAs(file);
          });
        }}
      />
    </Box>
  );
}

export default ApplicationDetailAdmin;
