import Box from "@material-ui/core/Box";
import makeStyles from "@material-ui/styles/makeStyles";
import childMale from "../../assets/icons/childMale.svg";
import childFemale from "../../assets/icons/childFemale.svg";
import config from "../../config/root-config";
import avatar from "../../assets/icons/avatar.svg";

const useStyles = makeStyles((theme:any) => ({
  head: {
    marginBottom: theme.spacing(2),
    [theme.breakpoints.between(0, "xs")]: {
      flexDirection: "column",
      alignItems: "flex-start",
      height: "100px",
    },
  },
  formTitle: {
    ...theme.typography.formTitle,
  },
}));
function FormTitle(props: any) {
  const classes = useStyles();
  console.log("props");
  console.log(props);
  console.log("props.childDetails")
  console.log(props.childDetails)
  let gender = "";
  if (Object.keys(props.childDetails).length !== 0) {
    console.log("Inside If");
    gender = props.childDetails?.childDetails["Gender_applicant_radio"]?.value || props.childDetails?.childDetails["Gender_child_radio"]?.value;
    console.log("gender inside if: ", gender);
  }
  else
    gender = "Avatar";
  const logo = `https://${config.s3StaticBucket}.s3.ap-southeast-2.amazonaws.com/${props.organisationName}.png`;
  return (
    <Box
      display="flex"
      className={classes.head}
      justifyContent="space-between"
      alignItems="center"
    >
      <Box display="flex" alignItems="center">
        <img width="50px" height="50px" src={logo} alt="" />
        <Box marginLeft="0.5em" className={classes.formTitle}>
          {props.organisationName.replace(/_/g, " ")}
        </Box>
      </Box>
      {Object.keys(props.childDetails).length !== 0 && (
        <Box display="flex" alignItems="center">
          <img src={gender === "Male" ? childMale : gender === "Female" ? childFemale : avatar} alt="" />
          <Box className={classes.formTitle} marginLeft="0.5em">
            {props.childDetails.fullName}
          </Box>
        </Box>
      )}
    </Box>
  );
}
export default FormTitle;
