import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import makeStyles from "@material-ui/core/styles/makeStyles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import backArrow2 from "../../assets/icons/backArrow2.svg";
import moment from "moment";
import parse from "html-react-parser";

const useStyles = makeStyles((theme) => ({
  messageDetails: {
    backgroundColor: theme.palette.background.default,
    borderRadius: "8px",
    marginBottom: theme.spacing(2),
    cursor: "pointer",
    "&.MuiAccordion-root:before": {
      backgroundColor: "white",
    },
  },
  backArrow: {
    cursor: "pointer",
  },
}));

function getText(html: string) {
  var divContainer = document.createElement("div");
  divContainer.innerHTML = html;
  return divContainer.textContent || divContainer.innerText || "";
}

function ViewMessage(props: any) {
  const { data } = props;
  const date = moment(data.auditDetails.createdAt).format("DD MMMM YYYY");
  console.log("Data...");
  console.log(data);
  const body = parse(data.emailContent.body);
  const appStatus = data.recipient.recipientGroup.appStatus.filter(
    (status: any) => {
      return status !== null;
    }
  );
  const cls = data.recipient.recipientGroup.class.filter((status: any) => {
    return status !== null;
  });
  console.log(appStatus);
  const classes = useStyles();
  const mediumScreen = useMediaQuery((theme: any) =>
    theme.breakpoints.between("xs", "sm")
  );
  const smallScreen = useMediaQuery((theme: any) =>
    theme.breakpoints.between("0", "xs")
  );

  return (
    <>
      <Box display={"flex"} padding="1em 1.5em 1.5em 1.5em">
        <Box padding="0 1.5em 0 0">
          <img
            className={classes.backArrow}
            src={backArrow2}
            alt="Go Back"
            onClick={() => props.setViewMessage(false)}
          />
        </Box>
        <Box padding="0 1.5em 0 1.5em" flexGrow={1}>
          <Typography
            variant="h2"
            style={{
              marginBottom: "0.5em",
              lineHeight: "22px",
              color: "#3B3A3C",
              fontSize: "25px",
            }}
          >
            {data.emailContent.subject}
          </Typography>
        </Box>
        <Box padding="0 1.5em 0 0">
          <Typography
            variant="subtitle2"
            style={{
              marginBottom: "0.5em",
              lineHeight: "19px",
              fontSize: "14px",
            }}
          >
            {date}
          </Typography>
        </Box>
      </Box>
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        width="100%"
        padding="0 1.5em 0 1.5em"
      >
        <Typography
          variant="body1"
          style={{
            marginBottom: "0.5em",
            lineHeight: "22px",
            fontWeight: "700",
          }}
        >
          Sent to: [{appStatus.join(", ")}] {cls.join(", ")}
        </Typography>
      </Box>
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        width="100%"
        padding="0 1.5em 0 1.5em"
      >
        <Typography
          variant="body1"
          style={{
            marginBottom: "0.5em",
            lineHeight: "22px",
            fontWeight: "500",
          }}
        >
          {body}
        </Typography>
      </Box>
    </>
  );
}

export default ViewMessage;
