import FormControl from "@material-ui/core/FormControl";
import FormLabel from "@material-ui/core/FormLabel";
import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";

function Datalist(props: any) {
  const {
    id,
    label,
    error = null,
    onChange,
    onChangeText,
    required = false,
    options,
  } = props;

  return (
    <FormControl
      component="fieldset"
      variant="outlined"
      style={{ width: "97%" }}
      autocomplete="No"
      {...(required && { required: true })}
      {...(error && { error: true })}
    >
      <FormLabel component="legend">{label}</FormLabel>
      <Autocomplete
        freeSolo
        id={id}
        options={options}
        getOptionLabel={(option: any) => {
          if (typeof option === "string") return option;
          if (option.inputValue) return option.inputValue;
          return option.Label;
        }}
        onChange={onChange}
        renderOption={(option) => option.Label}
        renderInput={(params: any) => (
          <TextField
            variant="outlined"
            name={label}
            id={id}
            onChange={onChangeText}
            {...(error && { error: true, helperText: error })}
            {...(required && { required: true })}
            {...params}
          ></TextField>
        )}
      />
    </FormControl>
  );
}

export default Datalist;
