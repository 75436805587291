import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import makeStyles from "@material-ui/core/styles/makeStyles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import Typography from "@material-ui/core/Typography";
import React from "react";
import {Link} from "react-router-dom";
import baseBg from "../assets/baseBg.png";
import baseBgMobile from "../assets/baseBgMob.png";
import Footer from "./Footer";
import BackArrow from "../assets/icons/backArrow.svg";
import {History} from "history";
import Logo from "./Logo";
import config from "../config/root-config";
import ReactGA from "react-ga";
import configFile from "../config/root-config";
const TRACKING_ID = configFile.googleAnalyticsTrackingId; // OUR_TRACKING_ID
ReactGA.initialize(TRACKING_ID);
ReactGA.event({
  category: "Solvitude",
  action: "home",
  label: "label",
});
const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.paper,
  },
  formContainer: {
    marginTop: "3vh",
    width: "25vw",
    [theme.breakpoints.between(0, "xs")]: {
      width: "80vw",
    },
    [theme.breakpoints.between("xs", "sm")]: {
      width: "80vw",
    },
  },
}));
type props = {
  arrowBack?: boolean;
  title?: string;
  subTitle?: string;
  children: React.ReactNode;
  history?: History;
  schoolName?: string;
};
function BasePage(props: props) {
  const classes = useStyles();
  const smallScreen = useMediaQuery((theme: any) =>
    theme.breakpoints.between(0, "sm")
  );

  const logo = `https://${config.s3StaticBucket}.s3.ap-southeast-2.amazonaws.com/${props.schoolName}.png`;

  return (
    <>
      <Grid container className={classes.root}>
        <Grid item md={6} sm={12} xs={12}>
          <img
            src={smallScreen ? baseBgMobile : baseBg}
            style={{width: "100%", height: "100%"}}
            alt=""
          ></img>
        </Grid>
        {props.title ? (
          <Grid
            container
            alignItems="center"
            direction="column"
            item
            xs={12}
            sm={12}
            md={6}
          >
            <Grid
              item
              style={{
                marginTop: "8vh",
                display: "flex",
                justifyContent: "flex-start",
              }}
            >
              <Box display="flex" justifyContent="center" alignItems="center">
                {props.schoolName && (
                  <Box>
                    <img width="70px" height="70px" src={logo} alt="" />
                  </Box>
                )}
                <Box marginLeft={props.schoolName ? "25px" : 0}>
                  <Link to="/home" style={{cursor: "pointer"}}>
                    <Logo />
                  </Link>
                </Box>
              </Box>

            </Grid>

            <Grid item className={classes.formContainer}>
              <Box display="flex">
                <Box flex={1}>
                  {props.arrowBack && (
                    <img
                      src={BackArrow}
                      alt="Back"
                      style={{cursor: "pointer"}}
                      onClick={() => {
                        props.history?.goBack();
                      }}
                    />
                  )}
                </Box>
                <Box>
                  <Typography variant="h3">{props.title}</Typography>
                </Box>
                <Box flex={1}></Box>
              </Box>
              {props.subTitle && (
                <Grid item className={classes.formContainer}>
                  <Typography variant="h6" style={{textAlign: "center"}}>
                    {props.subTitle}
                  </Typography>
                </Grid>
              )}
              <Box marginTop="4vh">{props.children}</Box>
            </Grid>
          </Grid>
        ) : (
          <Grid
            container
            direction="column"
            item
            xs={12}
            sm={12}
            md={6}
            style={{padding: "3%"}}
          >
            <Grid item>
              <Link to="/home" style={{cursor: "pointer"}}>
                <Logo />
              </Link>
            </Grid>
            <Grid item>{props.children}</Grid>
          </Grid>
        )}
      </Grid>
      <Footer />
    </>
  );
}
export default BasePage;
