import FormLabel from "@material-ui/core/FormLabel";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import makeStyles from "@material-ui/styles/makeStyles";
import React, { useState } from "react";
import GenericButton from "../../../components/GenericButton";
import { State, City } from "country-state-city";
import { generateFontStyling } from "../../../Theme/theme";

const useStyles = makeStyles((theme: any) => ({
  select: {
    "& .MuiSelect-select:focus": {
      background: "transparent",
    },
  },
  options: {
    ...theme.typography.body1,
  },
  placeholder: {
    ...generateFontStyling("16px", "Lato", "19px", "#B0BEC5", 400),
    "& .MuiInputBase-input": {
      ...generateFontStyling("16px", "Lato", "19px", "#DEE4E7", 400),
    },
  },
}));
function Organisation(props: any) {
  const classes = useStyles();
  const { organisation, country, handleChange, handleNext, handlePrevious } =
    props;
  const [statePlaceholder, setStatePlaceholder] = useState(
    !organisation.values.state
  );
  const [cityPlaceholder, setCityPlaceholder] = useState(
    !organisation.values.city
  );
  const States = State.getStatesOfCountry(country);
  console.log("States");
  console.log(country);
  const state = States.find(
    (state) => state.name === organisation.values.state
  )?.isoCode;
  const Cities = City.getCitiesOfState(country, state || "");
  return (
    <Grid container spacing={3}>
      <Grid item md={9} xs={12} sm={12}>
        <FormLabel required>Organisation Name</FormLabel>
        <TextField
          id="orgName"
          type="text"
          name="orgName"
          style={{ width: "100%" }}
          variant="standard"
          autoComplete="No"
          placeholder="Enter your organisation name"
          value={organisation.values.orgName}
          error={Boolean(organisation.errors.orgName)}
          helperText={organisation.errors.orgName}
          onChange={handleChange}
        />
      </Grid>
      <Grid item md={9} xs={12} sm={12}>
        <FormLabel required>Address</FormLabel>
        <TextField
          id="address"
          type="text"
          name="address"
          style={{ width: "100%" }}
          variant="standard"
          autoComplete="No"
          placeholder="Enter your organisation address"
          value={organisation.values.address}
          error={Boolean(organisation.errors.address)}
          helperText={organisation.errors.address}
          onChange={handleChange}
        />
      </Grid>
      <Grid item md={4} sm={4} xs={12}>
        <FormLabel required>State</FormLabel>
        <TextField
          style={{ width: "100%" }}
          className={classes[statePlaceholder ? "placeholder" : "select"]}
          variant="standard"
          id="state"
          name="state"
          value={organisation.values.state}
          error={Boolean(organisation.errors.state)}
          helperText={organisation.errors.state}
          onChange={handleChange}
          onFocus={() => setStatePlaceholder(false)}
          onMouseOut={() => {
            setStatePlaceholder(
              !organisation.values.state
            );
          }}
          onBlur={() => {
            setStatePlaceholder(
              organisation.values.state === "" 
            );
          }}
          SelectProps={{
            native: true,
          }}
          select
        >
          <option
            className={classes.placeholder}
            {...(!statePlaceholder && { disabled: true })}
          >
            Select State
          </option>
          {!statePlaceholder && <option />}
          {States.map((options: any, index: any) => {
            return (
              <option
                className={classes.options}
                id={options.isoCode}
                key={options.isoCode}
                value={options.name}
              >
                {options.name}
              </option>
            );
          })}
        </TextField>
      </Grid>
      <Grid item md={4} sm={4} xs={12}>
        <FormLabel required>City</FormLabel>
        <TextField
          style={{ width: "100%" }}
          className={classes[cityPlaceholder ? "placeholder" : "select"]}
          variant="standard"
          id="city"
          name="city"
          value={organisation.values.city}
          error={Boolean(organisation.errors.city)}
          helperText={organisation.errors.city}
          onChange={handleChange}
          onFocus={() => setCityPlaceholder(false)}
          onMouseOut={() => {
            setCityPlaceholder(organisation.values.city === "" );
          }}
          onBlur={() =>
            setCityPlaceholder(organisation.values.city === "" )
          }
          SelectProps={{
            native: true,
          }}
          select
        >
          <option
            className={classes.placeholder}
            {...(!cityPlaceholder && { disabled: true })}
          >
            Select City
          </option>
          {!cityPlaceholder && <option />}
          {Cities.map((options: any, index: any) => {
            return (
              <option
                className={classes.options}
                id={options.name}
                key={index}
                value={options.name}
              >
                {options.name}
              </option>
            );
          })}
        </TextField>
      </Grid>
      <Grid item md={4} sm={4} xs={12}>
        <FormLabel required>Pincode</FormLabel>
        <TextField
          id="pincode"
          type="number"
          name="pincode"
          style={{ width: "100%" }}
          variant="standard"
          autoComplete="No"
          placeholder="Enter Pincode"
          value={organisation.values.pincode}
          error={Boolean(organisation.errors.pincode)}
          helperText={organisation.errors.pincode}
          onChange={handleChange}
          onInput={(e: any) => {
            if (country === "AU") {
              e.target.value = Math.max(0, parseInt(e.target.value))
                .toString()
                .slice(0, 4);
            } else {
              e.target.value = Math.max(0, parseInt(e.target.value))
                .toString()
                .slice(0, 6);
            }
          }}
        />
      </Grid>
      <Grid
        item
        md={12}
        sm={12}
        xs={12}
        style={{
          display: "flex",
          justifyContent: "space-between",
          marginTop: "2vh",
        }}
      >
        <GenericButton
          variant="contained"
          id="prevOrg"
          type="submit"
          onClick={handlePrevious}
          background="blue"
        >
          Previous
        </GenericButton>
        <GenericButton
          id="nextOrg"
          type="submit"
          variant="contained"
          onClick={handleNext}
          disabled={!organisation.fieldsValidated}
          background="blue"
        >
          Next
        </GenericButton>
      </Grid>
    </Grid>
  );
}

export default Organisation;
