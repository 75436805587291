import FormControl from "@material-ui/core/FormControl";
import FormLabel from "@material-ui/core/FormLabel";
import makeStyles from "@material-ui/core/styles/makeStyles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";

const useStyles = makeStyles((theme) => ({
  select: {
    "& .MuiSelect-select:focus": {
      background: "transparent",
    },
    "& .MuiOutlinedInput-root": {
      height: "40px",
    },
  },
  options: {
    ...theme.typography.body1,
  },
}));
function Select(props: any) {
  const {
    id,
    label,
    name,
    value,
    error = null,
    onChange,
    width,
    required = false,
    options,
  } = props;
  const classes = useStyles();
  const smallScreen = useMediaQuery((theme: any) =>
    theme.breakpoints.between("0", "xs")
  );

  return (
    <FormControl
      variant="outlined"
      style={{
        width: width || (smallScreen ? "100%" : "50%"),
        marginBottom: "20px",
      }}
      {...(required && { required: true })}
    >
      <FormLabel>{label}</FormLabel>
      <Grid
        container
        direction="row"
        style={{ width: width || (smallScreen ? "94%" : "88%") }}
      >
        <TextField
          className={classes.select}
          style={{ width: width || "100%" }}
          variant="outlined"
          id={id}
          name={name}
          value={value}
          onChange={onChange}
          select
          SelectProps={{
            native: true,
          }}
          {...(error && { error: true, helperText: error })}
          {...(required && { required: true })}
        >
          {value === "" && <option />}
          {options.map((options: any) => {
            return (
              <option
                className={classes.options}
                id={options.Id}
                key={options.Id}
                value={options.Label}
              >
                {options.Label}
              </option>
            );
          })}
        </TextField>
      </Grid>
    </FormControl>
  );
}

export default Select;
