export const page = (orgName: string, fileName: string) => {
  try {
    const filestring = localStorage.getItem(
      `${orgName}-${localStorage.getItem("type")}`
    )!;
    const files = JSON.parse(filestring);
    const file = files[`${orgName}/${fileName}.json`];
    const data = JSON.parse(file);
    return data;
  } catch (err) {
    console.log(err);
  }
};
