import Box from "@material-ui/core/Box";
import makeStyles from "@material-ui/core/styles/makeStyles";
import Typography from "@material-ui/core/Typography";

const useStyles = makeStyles((theme:any) => ({
  root: {
    display: "flex",
    width: "94%",
    paddingBottom: "10px",
  },
  content: {
    color: "#607D8B",
    fontWeight: 300,
    fontSize: "16px",
    fontFamily: "Lato",
    paddingBottom: theme.spacing(2),
  },
  title: {
    color: "#607D8B",
    fontWeight: 500,
    fontSize: "16px",
    marginBottom: "10px",
    fontFamily: "Lato",
    marginRight:"5px"
  },
  displayTitle: {
    ...theme.typography.sectionTitle,
  },
  list: {
    color: "#607D8B",
    fontWeight: 300,
    marginLeft: theme.spacing(2),
    fontSize: "14px",
    fontFamily: "Lato",
  },
}));

function Display(props: any) {
  const classes: any = useStyles();
  const { text, type } = props;
  const className = classes[type];
  return (
    <>
      <Box className={classes.root}>
        {type === "list" && <Typography className={className}>•</Typography>}
        <Box className={className}>
          {text}
          {props.nestedList && (
            <Box marginTop="10px">
              {props.nestedList.map((list: any, index: any) => {
                return (
                  <Box key={index} className={classes.root}>
                    <Box className={className}>•</Box>
                    <Box className={className}>{list}</Box>
                  </Box>
                );
              })}
            </Box>
          )}
        </Box>
      </Box>
    </>
  );
}

export default Display;
