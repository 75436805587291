export function Validate(name: any, state: any) {
  const values = state.values;
  const errors: any = {...state.errors};
  const selectedCountry = state.country;
  console.log("Selected Country: ", selectedCountry);
  switch (name) {
    case "emailphone":
      if (values[name]) {
        errors[name] = validateEmailPhone(values[name], errors[name]);
      } else
        errors[name] = "Please enter your registered email or phone number";
      break;
    case "phoneNumber":
      if (values[name]) {
        errors[name] = validateNumber(
          values[name],
          errors[name],
          values.country
        );
      } else errors[name] = "Please enter your phone number";
      break;
    case "email":
      if (values[name])
        errors[name] = validateEmail(values[name], errors[name]);
      else errors[name] = "Please enter your email address";
      break;
    case "username":
      if (values[name])
        errors[name] = validateEmail(values[name], errors[name]);
      else errors[name] = "Please enter your email address";
      break;
    case "password":
      if (values[name]) errors[name] = "";
      else errors[name] = "Please enter your password";
      break;
    case "newPassword":
      if (values[name]) {
        errors[name] = "";
        if (values.currentPassword === values[name])
          errors[name] = "New Password cannot be same as the current password";
        else if (values.confirmPassword)
          errors.confirmPassword = confirmPassword(
            values.confirmPassword,
            errors.confirmPassword,
            values.newPassword
          );
      } else errors[name] = "Please enter your new password";
      break;
    case "confirmPassword":
      if (values[name]) {
        if (values.confirmPassword)
          errors.confirmPassword = confirmPassword(
            values.confirmPassword,
            errors.confirmPassword,
            values.newPassword
          );
      } else errors[name] = "Please confirm your password";
      break;
    case "firstName":
      if (values[name]) errors[name] = "";
      else errors[name] = "Please enter your first name";
      break;
    case "lastName":
      if (values[name]) errors[name] = "";
      else errors[name] = "Please enter your last name";
      break;
    case "board":
      if (values[name]) errors[name] = "";
      else errors[name] = "Please select a board";
      break;
    case "state":
      if (values[name]) errors[name] = "";
      else errors[name] = "Please select a state";
      break;
    case "city":
      if (values[name]) errors[name] = "";
      else errors[name] = "Please select a city";
      break;
    case "website":
      if (values[name]) {
        const pattern = new RegExp(
          /^(http(s?):\/\/)?(www\.)+[a-zA-Z0-9.\-_]+(\.[a-zA-Z]{2,3})+(\/[a-zA-Z0-9_\-\s./?%#&=]*)?$/
        );
        if (!pattern.test(values[name]))
          errors[name] = "Please enter valid webiste";
        else if (values[name] === "") errors[name] = "Please enter a value";
        else errors[name] = "";
      } else errors[name] = "Please enter valid webiste";
      break;
    case "code":
      if (values[name]) {
        if (!/.*\d.*/.test(values[name]))
          errors[name] = "Code should only include digits";
        else if (values[name].length !== 6)
          errors[name] = "Please enter a 6 digit code";
        else errors[name] = "";
      } else errors[name] = "Please enter the verification code";
      break;
    case "country":
      if (values[name]) {
        errors[name] = "";
        if (values.phoneNumber) {
          errors.phoneNumber = validateNumber(
            values.phoneNumber,
            errors.phoneNumber,
            values[name]
          );
        }
      } else {
        errors[name] = "Please select a value";
      }
      break;
    case "pincode":
      if (values[name]) {
        errors[name] = "";
        if (selectedCountry === "Australia") {
          if (values[name].toString().length !== 4)
            errors[name] = "Pincode should contain 4 digits";
          else errors[name] = "";
        } else {
          if (values[name].toString().length < 6)
            errors[name] = "Pincode should contain 6 digits";
          else errors[name] = "";
        }
      } else errors[name] = "Please enter a value";
      break;
    default:
      if (values[name]) errors[name] = "";
      else errors[name] = "Please enter a value";
      break;
  }

  return errors;
}

export function confirmPassword(value: string, error: string, matchTo: string) {
  if (value !== matchTo) error = "Passwords do not match";
  else error = "";
  return error;
}
export function validateNumber(value: string, error: string, country: string) {
  if (!/^\d+$/.test(value)) error = "Mobile number should only include digits";
  else if (country === "Australia") {
    if (!(value.startsWith("04") || value.startsWith("4"))) error = "Mobile number should start with 4";
    else if (value.length > 10) error = "Please enter valid mobile number";
    else error = "";
  } else if (country === "India") {
    if (value.length !== 10) error = "Please enter a 10 digit number";
    else error = "";
  } else error = "";
  return error;
}
export function validateEmailPhone(value: string, error: string) {
  const type = /[a-z]/i.test(value) ? "email" : "phone";
  if (type === "phone") {
    var regex = new RegExp(/^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\s\./0-9]*$/);
    if (value.split("")[0] !== "+") {
      error = "Mobile number should start with +";
      console.log(value);
    } else if (!regex.test(value) || value.length < 12) {
      error = "Please enter valid phone number with country code";
      console.log(value);
    } else error = "";
  } else {
    const pattern = new RegExp(
      /^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i
    );
    if (pattern.test(value)) error = "";
    else error = "Invalid Email Address";
  }
  return error;
}
export function updateChip(name: any, state: any) {
  const values = {...state.values};
  const chip = {...state.chip};
  for (const c in chip) {
    if (c === "letters") {
      /.*[A-Z].*/.test(values[name]) && /.*[a-z].*/.test(values[name])
        ? (chip[c] = "done")
        : (chip[c] = "");
    } else if (c === "digits") {
      /.*\d.*/.test(values[name]) ? (chip[c] = "done") : (chip[c] = "");
    } else if (c === "specialCharacters") {
      /.*\W.*/.test(values[name]) ? (chip[c] = "done") : (chip[c] = "");
    } else if (c === "long") {
      values[name].length >= 8 ? (chip[c] = "done") : (chip[c] = "");
    }
  }
  return chip;
}

export function onSubmitValidate(state: any) {
  const values = state.values;
  const errors = {...state.errors};
  const selectedCountry = state.country;
  for (const k in values) {
    switch (k) {
      case "phoneNumber":
        if (values[k]) {
          errors[k] = validateNumber(values[k], errors[k], values.country);
        } else errors[k] = "Please enter your mobile number";
        break;
      case "email":
        if (values[k]) {
          errors[k] = validateEmail(values[k], errors[k]);
        } else errors[k] = "Please enter your email address";
        break;

        // Define a function to handle the common logic
        const handleUsernameCase = (k: string, values: any, errors: any) => {
          if (values[k]) {
            errors[k] = validateEmail(values[k], errors[k]);
          } else {
            errors[k] = "Please enter your email address";
          }
        };

        // Use the function in both cases
        switch (k) {
          case "username":
            handleUsernameCase(k, values, errors);
            break;
          case "anotherCase":
            // Handle another case
            break;
          // ...
        }

      case "password":
        if (values[k]) errors[k] = "";
        else errors[k] = "Please enter your password";
        break;
      case "newPassword":
        if (values[k]) {
          if (values.currentPassword === values[k])
            errors[k] = "New Password cannot be same as the current password";
          else if (values.confirmPassword)
            errors.confirmPassword = confirmPassword(
              values.confirmPassword,
              errors.confirmPassword,
              values.newPassword
            );
          else if (
            !/^(?=.*\d)(?=.*[!@#$%^&*])(?=.*[a-z])(?=.*[A-Z]).{8,}$/.test(
              values[k]
            )
          )
            errors[k] = "Password contraints didn't match";
          else errors[k] = "";
        } else errors[k] = "Please enter your new password";
        break;
      case "signupPassword":
        if (values[k]) {
          if (
            !/^(?=.*\d)(?=.*[!@#$%^&*])(?=.*[a-z])(?=.*[A-Z]).{8,}$/.test(
              values[k]
            )
          )
            errors[k] = "Password contraints didn't match";
          else errors[k] = "";
        } else errors[k] = "Please enter your new password";
        break;
      case "confirmPassword":
        if (values[k]) {
          errors[k] = confirmPassword(values[k], errors[k], values.newPassword);
        } else errors[k] = "Please confirm your password";
        break;
      case "firstName":
        if (values[k]) errors[k] = "";
        else errors[k] = "Please enter your first name";
        break;
      case "lastName":
        if (values[k]) errors[k] = "";
        else errors[k] = "Please enter your last name";
        break;
      case "code":
        if (values[k]) {
          if (!/.*\d.*/.test(values[k]))
            errors[k] = "Code should only include digits";
          else if (values[k].length !== 6)
            errors[k] = "Please enter a 6 digit code";
          else errors[k] = "";
        } else errors[k] = "Please enter the verification code";
        break;
      case "country":
        if (values[k]) {
          errors[k] = "";
          if (values.phoneNumber) {
            errors.phoneNumber = validateNumber(
              values.phoneNumber,
              errors.phoneNumber,
              values[k]
            );
          }
        } else {
          errors[k] = "Please select a value";
        }
        break;
      case "admissions":
        continue;
      case "pincode":
        if (values[k]) {
          errors[k] = "";
          if (selectedCountry === "Australia") {
            if (values[k].toString().length !== 4)
              errors[k] = "Pincode should contain 4 digits";
            else errors[k] = "";
          } else {
            if (values[k].toString().length < 6)
              errors[k] = "Pincode should contain 6 digits";
            else errors[k] = "";
          }
        } else errors[k] = "Please enter a value";
        break;
      default:
        if (values[k]) errors[k] = "";
        else errors[k] = "Please enter a value";
    }
  }
  return errors;
}

export function validateEmail(value: string, error: string) {
  const pattern = new RegExp(
    /^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i
  );
  if (pattern.test(value)) error = "";
  else error = "Invalid Email Address";
  return error;
}
