
import NumberFormat from "react-number-format";
import FormControl from "@material-ui/core/FormControl";
import Grid from "@material-ui/core/Grid";
import FormLabel from "@material-ui/core/FormLabel";
import TextField from "@material-ui/core/TextField";
import useMediaQuery from "@material-ui/core/useMediaQuery";

function AmountField(props: any) {
  const smallScreen = useMediaQuery((theme: any) =>
    theme.breakpoints.between("0", "xs")
  );
  const {
    id,
    label,
    name,
    value,
    onChange,
    error = null,
    required,
    width,
  } = props;
  return (
    <FormControl
      variant={"outlined"}
      style={{
        width: width || (smallScreen ? "100%" : "50%"),
        marginBottom: "20px",
      }}
      {...(required && { required: true })}
    >
      <FormLabel>{label}</FormLabel>
      <Grid
        container
        direction="row"
        style={{ width: (width && "100%") || (smallScreen ? "94%" : "88%") }}
      >
        <NumberFormat
          style={{ width: (width && "100%") || "100%" }}
          variant="outlined"
          size="small"
          id={id}
          name={name}
          value={value}
          onChange={onChange}
          {...(error && { error: true, helperText: error })}
          {...(required && { required: true })}
          required
          thousandsGroupStyle="lakh"
          customInput={TextField}
          prefix="₹"
          decimalSeparator="."
          displayType="input"
          type="text"
          thousandSeparator={true}
          allowNegative={false}
        />
      </Grid>
    </FormControl>
  );
}
export default AmountField;
