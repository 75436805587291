import React from "react";
import Box from "@material-ui/core/Box";
import makeStyles from "@material-ui/core/styles/makeStyles";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import backRoute from "../assets/icons/backRoute.svg";
import GenericButton from "./GenericButton";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import {Button} from "@material-ui/core";
import MenuRoundedIcon from "@material-ui/icons/MenuRounded";

const useStyles = makeStyles((theme: any) => ({
  root: {
    marginLeft: theme.spacing(4),
    marginRight: theme.spacing(4),
    [theme.breakpoints.between("xs", "sm")]: {
      marginLeft: theme.spacing(3),
      marginRight: theme.spacing(3),
    },
    [theme.breakpoints.between(0, "xs")]: {
      marginLeft: theme.spacing(1),
      marginRight: theme.spacing(1),
    },
  },
  title: {
    ...theme.typography.pageTitle,
    [theme.breakpoints.between(0, "xs")]: {
      display: "none",
    },
  },
  container: {
    marginTop: theme.spacing(3),
  },
  route: {
    marginTop: theme.spacing(2),
  },
  select: {
    "& .MuiSelect-select:focus": {
      background: "transparent",
    },
    "& .MuiOutlinedInput-root": {
      height: "40px",
    },
  },
  options: {
    ...theme.typography.body1,
  },
}));
function PageHeadBase(props: any) {
  console.log("PageHeadBase Props");
  console.log(props);
  const classes = useStyles();
  const smallScreen = useMediaQuery((theme: any) =>
    theme.breakpoints.between("0", "xs")
  );
  const mediumScreen = useMediaQuery((theme: any) =>
    theme.breakpoints.between("xs", "sm")
  );
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <Box className={classes.root}>
      <Box className={classes.route} display="flex" alignItems="center">
        <Box>
          <img
            src={backRoute}
            alt=""
            style={{marginRight: "0.5em", cursor: "pointer"}}
            onClick={() => {
              props.history.goBack();
            }}
          />
        </Box>

        <Box flexGrow={1}>
          <Typography variant="h4">
            {smallScreen ? props.route.split("/").pop() : props.route}
          </Typography>
        </Box>
        {smallScreen && props.route === "My Profile" && (
          <Box>
            <>
              <Box display="flex" flexDirection="row-reverse" p={0}>
                <Button
                  aria-controls="simple-menu"
                  aria-haspopup="true"
                  onClick={handleClick}
                  style={{padding: 0}}
                >
                  <MenuRoundedIcon fontSize="large" htmlColor="white" />
                </Button>
              </Box>
              <Menu
                id="simple-menu"
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={handleClose}
              >
                <MenuItem
                  onClick={() => {
                    props.setCurrentTab("User Details");
                    setAnchorEl(null);
                  }}
                >
                  User Details
                </MenuItem>
                {props.role === "Org_Admin" && (
                  <>
                    <MenuItem
                      onClick={() => {
                        props.setCurrentTab("Bank Details");
                        setAnchorEl(null);
                      }}
                    >
                      Bank Details
                    </MenuItem>
                    <MenuItem
                      onClick={() => {
                        props.setCurrentTab("Subscription Details");
                        setAnchorEl(null);
                      }}
                    >
                      Subscription Details
                    </MenuItem>
                    <MenuItem
                      onClick={() => {
                        props.setCurrentTab("Application Fee");
                        setAnchorEl(null);
                      }}
                    >
                      Application Fee
                    </MenuItem>
                  </>
                )}
                <MenuItem
                  onClick={() => {
                    props.setCurrentTab("Password Settings");
                    setAnchorEl(null);
                  }}
                >
                  Password Settings
                </MenuItem>
                {props.role === "Org_Admin" && (
                  <>
                    <MenuItem
                      onClick={() => {
                        props.setCurrentTab("Organisation Details");
                        setAnchorEl(null);
                      }}
                    >
                      Organisation Details
                    </MenuItem>
                    <MenuItem
                      onClick={() => {
                        props.setCurrentTab("Manage Users");
                        setAnchorEl(null);
                      }}
                    >
                      Manage Users
                    </MenuItem>
                  </>
                )}
              </Menu>
            </>
          </Box>
        )}
      </Box>
      {/* {!smallScreen && !(props.route === "My Profile") && ( */}
      <Box
        className={classes.container}
        display="flex"
        justifyContent="space-between"
        alignItems="center"
      >
        <Box className={classes.title}>{props.title}</Box>
        <Box
          display="flex"
          justifyContent="space-between"
          width={
            smallScreen
              ? "100%"
              : mediumScreen
              ? props.filter
                ? "35%"
                : "25%"
              : props.filter
              ? "25%"
              : "15%"
          }
        >
          {props.cityDropdown && (
            <TextField
              className={classes.select}
              style={{
                width: smallScreen
                  ? props.filter
                    ? "60%"
                    : "100%"
                  : mediumScreen
                  ? props.filter
                    ? "50%"
                    : "100%"
                  : props.filter
                  ? "60%"
                  : "100%",
              }}
              variant="outlined"
              id="city"
              name={props.name}
              value={props.value}
              onChange={props.onChange}
              select
              SelectProps={{
                native: true,
              }}
            >
              {props.value === "" && (
                <option>
                  {props.country === "Australia"
                    ? "Select State"
                    : "Select City"}
                </option>
              )}
              {props.options.map((option: any, index: any) => {
                if (option)
                  return (
                    <option
                      className={classes.options}
                      id={index}
                      key={index}
                      value={option}
                    >
                      {option}
                    </option>
                  );
                else return null;
              })}
            </TextField>
          )}
          {props.button && props.filter && (
            <GenericButton
              background="white"
              variant="contained"
              onClick={props.onClick}
            >
              {props.button}
            </GenericButton>
          )}
        </Box>
      </Box>
      {/* )} */}
      {props.children}
    </Box>
  );
}
export default PageHeadBase;
