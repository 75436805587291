import Box from "@material-ui/core/Box";
import Dialog from "@material-ui/core/Dialog";
import FormLabel from "@material-ui/core/FormLabel";
import TextField from "@material-ui/core/TextField";
import {useEffect, useState} from "react";
import {useParams} from "react-router";
import {
  confirmInvitedUser,
  getInvitedUserDetails,
  updateInvitedUserDetails,
} from "../../api";
import Password from "../../components/Password";
import PasswordHints from "../../components/PasswordHints";
import ResultModal from "../../components/ResultModal";
import SignUp from "../../components/SignUp";
import VerificationModal from "../../components/VerificationModal";
import {
  onSubmitValidate,
  updateChip,
  Validate,
} from "../../validations/ErrorHandlers";

function UserSignUp(props: any) {
  const [data, setData] = useState({
    values: {
      firstName: "",
      lastName: "",
      signupPassword: "",
      phoneNumber: "",
      country: "",
    },
    errors: {
      firstName: "",
      lastName: "",
      phoneNumber: "",
      country: "",
    },
    chip: {
      letters: "",
      digits: "",
      specialCharacters: "",
      long: "",
    },
    signUp: false,
    isButtonLoading: false,
    fieldsValidated: false,
  });
  const [loading, setLoading] = useState(true);
  const {email}: any = useParams();
  const [confirm, setConfirm] = useState(false);
  const [code, setCode] = useState("");
  const [error, setError] = useState("");
  const [success, setSuccess] = useState(false);
  const [verifyLoading, setVerifyLoading] = useState(false);
  useEffect(() => {
    props.generateAccessToken("AnonyUser").then(() => {
      getInvitedUserDetails(email)
        .then((res) => {
          console.log(res);
          setLoading(false);
          setData({
            ...data,
            values: {
              ...data.values,
              firstName: res.data.firstName || "",
              lastName: res.data.lastName || "",
              phoneNumber: res.data.userDetails
                ? res.data.userDetails.phoneNumber || ""
                : "",
              country: res.data.userDetails
                ? res.data.userDetails.country || ""
                : "",
            },
          });
        })
        .catch((err) => {
          setLoading(false);
          console.log(err);
        });
    });
  }, []);
  const handleChange = (event: any) => {
    const updatedData: any = {...data};
    updatedData.values[event.target.name] = event.target.value;
    updatedData.errors = Validate(event.target.name, updatedData);
    if (event.target.name === "signupPassword")
      updatedData.chip = updateChip(event.target.name, updatedData);
    const errors2 = onSubmitValidate(updatedData);
    delete errors2["signupPassword"];
    delete errors2["phoneNumber"];
    const validate = Object.values(errors2).every((x) => x === "");
    if (validate) updatedData.fieldsValidated = true;
    else updatedData.fieldsValidated = false;
    setData(updatedData);
  };
  const handleSignUp = (event: any) => {
    event.preventDefault();
    setData({...data, isButtonLoading: true});
    const body = {
      firstName: data.values.firstName,
      lastName: data.values.lastName,
      userDetails: {
        firstName: data.values.firstName,
        lastName: data.values.lastName,
        phoneNumber: data.values.phoneNumber,
        country: data.values.country,
      },
    };
    updateInvitedUserDetails(email, body)
      .then((res) => {
        console.log(res);
        setData({...data, isButtonLoading: false});
        setConfirm(true);
      })
      .catch((err: any) => {
        console.log(err.response);
        setData({...data, isButtonLoading: false});
        setError(err.response.data.message);
      });
  };
  const handleVerify = () => {
    setVerifyLoading(true);
    confirmInvitedUser(email, code, data.values.signupPassword)
      .then((res) => {
        console.log(res);
        setVerifyLoading(false);
        setError("");
        setSuccess(true);
        setTimeout(() => {
          props.history.push("/login");
        }, 3000);
        localStorage.clear();
      })
      .catch((err) => {
        setVerifyLoading(false);
        setError(err.response.data.message);
      });
  };
  return (
    <>
      <SignUp
        invited={true}
        loading={loading}
        data={data}
        handleChange={handleChange}
        handleSignUp={handleSignUp}
        schoolName={props.schoolName}
      />
      {confirm && (
        <VerificationModal
          title="User Verification"
          handleClear={() => {
            setCode("");
          }}
          handleVerify={handleVerify}
          open={confirm}
          content={`Please enter the 6 digit verification code sent to your registered email address ${email}`}
          disable={

            !(code.length === 6 && /^(?=.*\d)(?=.*[!@#$%^&*])(?=.*[a-z])(?=.*[A-Z]).{8,}$/.test(data.values.signupPassword))
          }
          loading={verifyLoading}
          error={error}
          success={success}
          handleClose={() => {
            setError("");
            setSuccess(false);
          }}
        >
          <TextField
            id="code"
            type="number"
            name="code"
            style={{width: "100%", marginBottom: "10px"}}
            variant="standard"
            autoComplete="No"
            placeholder="Enter verification code"
            value={code}
            onChange={(event) => setCode(event.target.value)}
            onInput={(e: any) => {
              e.target.value = e.target.value.toString().slice(0, 6);
            }}
          />
          <Box>
            <Box marginBottom="3vh">
              <FormLabel>Password</FormLabel>
              <Password
                id="password"
                name="signupPassword"
                placeholder="Enter your password"
                value={data.values.signupPassword}
                onChange={handleChange}
                error=""
              />
            </Box>
            <Box marginBottom="1vh">
              <PasswordHints chip={data.chip} />
            </Box>
          </Box>
        </VerificationModal>
      )}
      {error && !confirm && (
        <Dialog
          PaperProps={{
            style: {
              borderRadius: "10px",
              background: "#ECEFF1",
            },
          }}
          open={Boolean(error)}
        >
          <ResultModal
            type="error"
            title="User Profile Updation Failed"
            content={error}
            handleClose={() => {
              setError("");
            }}
          />
        </Dialog>
      )}
    </>
  );
}
export default UserSignUp;
