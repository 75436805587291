import Dashboard from "../pages/Dashboard";
import React, {useState} from "react";
import {Redirect, Route, Switch} from "react-router-dom";
import Profile from "../pages/Profile";
import Footer from "./Footer";
import Box from "@material-ui/core/Box";
import {getAllCampus, getAllCampusAnalytics} from "../api";
import Header from "./Header/Header";
import {useCookies} from "react-cookie";
import {schoolForm} from "../Data/getSchoolForm";
import ErrorBoundary from "./ErrorBoundary";
import ViewApplication from "../pages/ViewApplication";
import SelectPage from "../pages/SelectPage";
import ReactGA from "react-ga";
import configFile from "../config/root-config";
const TRACKING_ID = configFile.googleAnalyticsTrackingId; // OUR_TRACKING_ID
ReactGA.initialize(TRACKING_ID);
ReactGA.event({
  category: "Solvitude",
  action: "dashboard",
  label: "label",
});

function Main(props: any) {
  const orgList: any = localStorage.getItem("orgList");
  const [organisationList, setOrganisationList] = useState(
    JSON.parse(orgList) || []
  );
  const [cookie, setCookie, removeCookie] = useCookies(["school", "type"]);
  const [bankDetails, setBankDetails] = useState(false);
  const [userInvite, setUserInvite] = useState(false);
  const [subscriptionActive, setSubscriptionActive] = useState(false);
  const [numOfApplications, setNumOfApplications] = useState(0);
  React.useEffect(() => {
    if (props.user.attributes["custom:role"] !== "Parent") {
      const campusId = props.user.attributes["custom:campusId"];
      getAllCampusAnalytics(campusId)
        .then((res) => {
          const data = res.data.classes.all;
          const numOfApps = data.total - data.Draft;
          setNumOfApplications(numOfApps);
        })
        .catch((err) => {
          console.log(err);
        });
    }
    if (props.user.attributes["custom:role"] === "Parent") {
      if (!localStorage.getItem("orgList"))
        getAllCampus().then((res: any) => {
          console.log(res);
          res.data.map(async (menu: any) => {
            const name = menu.orgName.replace(/\s/g, "_");
            const formDataUrl = JSON.parse(
              menu.campusList[0].customData.formData
            );
            schoolForm(name, formDataUrl);
            if (cookie.type) {
              localStorage.setItem("type", cookie.type);
            } else localStorage.setItem("type", "1");
          });
          setOrganisationList(res.data);
          localStorage.setItem("orgList", JSON.stringify(res.data));
          if (
            cookie.school &&
            res.data.filter((org: any) => {
              if (org.orgName === cookie.school.replace(/_/g, " ")) return org;
              else return null;
            }).length === 0
          )
            removeCookie("school", {path: "/"});
        });
    }
  }, []);

  const updateProfile = (updatedData: any) => {
    if (updatedData === "bankDetails") setBankDetails(true);
    else if (updatedData === "userInvite") setUserInvite(true);
    else if (updatedData === "subscription") setSubscriptionActive(true);
    else if (updatedData === "cancelSub") setSubscriptionActive(false);
  };

  return (
    <>
      <Header
        timer={props.timer}
        updateUser={props.updateUser}
        user={props.user}
        history={props.history}
        bankDetails={bankDetails}
        userInvite={userInvite}
        subscriptionActive={subscriptionActive}
        updateProfile={updateProfile}
        schoolName={cookie.school || ""}
      />

      <ErrorBoundary user={props.user}>

        <Box minHeight="90vh">
          <Switch>
            
            <Route
              path="/dashboard"
              render={(routerProps) => (
                <Dashboard
                  handleLogout={props.handleLogout}
                  {...routerProps}
                  user={props.user}
                  updateUser={props.updateUser}
                />
              )}
            />
            
            <Route
              path="/newApplication"
              render={(routerProps) => (
                <SelectPage
                  handleLogout={props.handleLogout}
                  organisationList={organisationList}
                  {...routerProps}
                  updateUser={props.updateUser}
                  user={props.user}
                  geolocation={props.geolocation}
                />
              )}
            />
            <Route
              path="/viewApplication/:id"
              render={(routerProps) => (
                <ViewApplication
                  handleLogout={props.handleLogout}
                  {...routerProps}
                  updateUser={props.updateUser}
                  user={props.user}
                />
              )}
            />
            <Route
              path="/myProfile"
              render={(routerProps) => (
                <Profile
                  handleLogout={props.handleLogout}
                  user={props.user}
                  updateUser={props.updateUser}
                  {...routerProps}
                  updateProfile={updateProfile}
                  numOfApplications={numOfApplications}
                />
              )}
            />
            <Redirect to="/dashboard" />
          </Switch>
        </Box>
      </ErrorBoundary>
      <Footer />
    </>
  );
}

export default Main;
