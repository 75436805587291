import Checkbox from "@material-ui/core/Checkbox";
import FormControl from "@material-ui/core/FormControl";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormHelperText from "@material-ui/core/FormHelperText";
import CheckBoxOutlineBlankIcon from "@material-ui/icons/CheckBoxOutlineBlank";
import React from "react";

function Confirmation(props: any) {
  const { id, name, error = null, onChange, checked, label, required } = props;
  return (
    <FormControl
      component="fieldset"
      variant="outlined"
      style={{ width: "100%", paddingBottom: "10px" }}
      {...(error && { error: true })}
    >
      <FormControlLabel
        label={label}
        control={
          <Checkbox
            icon={
              <CheckBoxOutlineBlankIcon
                style={{ width: "25px", height: "25px", borderRadius: "5px" }}
              />
            }
            {...(required && { required: true })}
            id={id}
            name={name}
            onChange={onChange}
            checked={checked}
          />
        }
      ></FormControlLabel>
      {error && <FormHelperText>{error}</FormHelperText>}
    </FormControl>
  );
}

export default Confirmation;
