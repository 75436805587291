import React from "react";
import IdleTimer from "react-idle-timer";
function IdleTimerContainer(props: any) {
  const idleTimerRef: any = React.useRef(null);
  const onIdle = () => {
    console.log("User is idle");
    props.handleLogout();
  };
  return (
    <div>
      <IdleTimer ref={idleTimerRef} timeout={10 * 60 * 1000} onIdle={onIdle}>
        {props.children}
      </IdleTimer>
    </div>
  );
}

export default IdleTimerContainer;
