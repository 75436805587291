import IconButton from "@material-ui/core/IconButton";
import InputAdornment from "@material-ui/core/InputAdornment";
import TextField from "@material-ui/core/TextField";
import Visibility  from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import React from "react";
type state = {
  showPassword: boolean
}
type props = {
  id: string,
  name: string,
  placeholder: string,
  value: string,
  error: string,
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void
}
class Password extends React.Component<props, state> {
  constructor(props: props) {
    super(props);
    this.state = {
      showPassword: false,
    };
    this.handleClickShowPassword = this.handleClickShowPassword.bind(this);
    this.handleMouseDownPassword = this.handleMouseDownPassword.bind(this);
  }
  handleClickShowPassword() {
    this.setState({ showPassword: !this.state.showPassword });
  }

  handleMouseDownPassword(event: React.MouseEvent<HTMLElement>) {
    event.preventDefault();
  }
  render() {
    const { id, name, placeholder, value, error, onChange } = this.props;
    return (
      <TextField
        id={id}
        type={this.state.showPassword ? "text" : "password"}
        name={name}
        variant="outlined"
        autoComplete="No"
        placeholder={placeholder}
        value={value}
        style={{ width: "100%" }}
        error={Boolean(error)}
        helperText={error}
        onChange={onChange}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <IconButton
                aria-label="toggle password visibility"
                onClick={this.handleClickShowPassword}
                onMouseDown={this.handleMouseDownPassword}
              >
                {this.state.showPassword ? (
                  <Visibility fontSize="small" />
                ) : (
                  <VisibilityOff fontSize="small" />
                )}
              </IconButton>
            </InputAdornment>
          ),
        }}
      />
    );
  }
}
export default Password;
