import Input from "./Input";
import RadioGroup from "./RadioGroup";
import Select from "./Select";
import Checkbox from "./Checkbox";
import Datalist from "./Datalist";
import Button from "./Button";
import Textarea from "./Textarea";
import File from "./File";
import Display from "./Display";
import Address from "./Address";
import Confirmation from "./Confirmation";
import PhoneNumber from "./PhoneNumber";
import LocationDropdown from "./LocationDropdown";
import AmountField from "./AmountField";
import MultiSelect from "./MultiSelect";
import YearPicker from "./YearPicker";
import Link from "./LinkComponent";
import Radio from "./Radio";
const Controls = {
  Input,
  RadioGroup,
  Radio,
  Select,
  Checkbox,
  Datalist,
  Button,
  Textarea,
  File,
  Display,
  Address,
  Confirmation,
  PhoneNumber,
  LocationDropdown,
  AmountField,
  MultiSelect,
  YearPicker,
  Link,
};

export default Controls;
