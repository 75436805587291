import axios, { AxiosRequestConfig } from "axios";
import * as rax from "retry-axios";
import config from "../config/root-config";
import {v4 as uuid} from "uuid";
import AWS from "aws-sdk";
import {getSSM} from "../common/aws/getSSM";
import {awsConfig} from "../config/env-config/config";
import moment from "moment";
axios.defaults.raxConfig = {
  noResponseRetries: 2,
  onRetryAttempt: (err) => {
    const cfg = rax.getConfig(err);
    console.log(`Retry attempt #${cfg?.currentRetryAttempt}`);
  },
};

AWS.config.update({
  region: awsConfig.aws_region,
  accessKeyId: awsConfig.aws_accessKeyID,
  secretAccessKey: awsConfig.aws_secretAccessKey,
});

const stage = process.env.REACT_APP_STAGE || "dev";
const usermnturi = async () => {
  return stage === "local"
    ? config.uri.userManagementURI
    : await getSSM(config.uri.userManagementURI);
};
const orgmnturi = async () => {
  return stage === "local"
    ? config.uri.orgManagementURI
    : await getSSM(config.uri.orgManagementURI);
};
const backenduri = async () => {
  return stage === "local"
    ? config.uri.backendURI
    : await getSSM(config.uri.backendURI);
};

export const getSubscription = async (subId: string) => {
  const orgUri = localStorage.getItem("orgmntUri")
    ? localStorage.getItem("orgmntUri")
    : await orgmnturi();
  return await axios.get(`${orgUri}/v1/stripe/subscription`, {
    headers: {
      correlationId: localStorage.getItem("correlationId"),
      userId: localStorage.getItem("userId"),
      authorization: localStorage.getItem("accessToken"),
    },
    params: {
      subscriptionId: subId,
    },
  });
};

export const getActiveSubscription = async (
  orgId: string,
  campusId: string
) => {
  return await axios.get(
    `${localStorage.getItem(
      "orgmntUri"
    )}/v1/organisation/${orgId}/campus/${campusId}/subscription/active`,
    {
      headers: {
        correlationId: localStorage.getItem("correlationId"),
        userId: localStorage.getItem("userId"),
        authorization: localStorage.getItem("accessToken"),
      },
    }
  );
};

export const getSessionDetails = async (sessionId: string) => {
  const orgUri = localStorage.getItem("orgmntUri")
    ? localStorage.getItem("orgmntUri")
    : await orgmnturi();
  return await axios.get(`${orgUri}/v1/stripe/subscription`, {
    headers: {
      correlationId: localStorage.getItem("correlationId"),
      userId: localStorage.getItem("userId"),
      authorization: localStorage.getItem("accessToken"),
    },
    params: {
      sessionId: sessionId,
    },
  });
};

export const setupConnectAccount = async (orgId: string, url: string) => {
  return await axios.post(
    `${localStorage.getItem(
      "orgmntUri"
    )}/v1/organisation/${orgId}/stripe/connect`,
    {
      url: url,
    },
    {
      headers: {
        correlationId: localStorage.getItem("correlationId") || uuid(),
        userId: localStorage.getItem("userId"),
        authorization: localStorage.getItem("accessToken"),
      },
    }
  );
};

export const setupApplicationFee = async (
  orgId: string,
  campusId: string,
  appFee: any
) => {
  return await axios.post(
    `${localStorage.getItem(
      "orgmntUri"
    )}/v1/organisation/${orgId}/campus/${campusId}/appfee/enable`,
    {
      fee: appFee.amount * 100,
      currency: appFee.currency,
    },
    {
      headers: {
        correlationId: localStorage.getItem("correlationId") || uuid(),
        userId: localStorage.getItem("userId"),
        authorization: localStorage.getItem("accessToken"),
      },
    }
  );
};

export const stripeAppFeeCheckoutSession = async (
  orgId: string,
  campusId: string,
  appId: string,
  url: string
) => {
  return await axios.post(
    `${localStorage.getItem(
      "appmntUri"
    )}/v1/application/${appId}/appfee/checkout`,
    {
      orgId: orgId,
      campusId: campusId,
      url: url,
    },
    {
      headers: {
        correlationId: localStorage.getItem("correlationId") || uuid(),
        userId: localStorage.getItem("userId"),
        authorization: localStorage.getItem("accessToken"),
      },
    }
  );
};

export const validateAppFeeCheckout = async (
  orgId: string,
  campusId: string,
  appId: string,
  sessionId: string
) => {
  return await axios.get(
    `${localStorage.getItem(
      "appmntUri"
    )}/v1/application/${appId}/appfee/${sessionId}/validate`,
    {
      headers: {
        correlationId: localStorage.getItem("correlationId") || uuid(),
        userId: localStorage.getItem("userId"),
        authorization: localStorage.getItem("accessToken"),
      },
      params: {
        orgId: orgId,
        campusId: campusId,
      },
    }
  );
};

export const subCampusLinkage = async (
  orgId: string,
  campusId: string,
  subId: string,
  sessionId?: string,
  email?: string
) => {
  const orgUri = localStorage.getItem("orgmntUri")
    ? localStorage.getItem("orgmntUri")
    : await orgmnturi();
  return await axios.post(
    `${orgUri}/v1/organisation/${orgId}/campus/${campusId}/subscription/activate`,
    {
      subId: subId,
      sessionId: sessionId,
    },
    {
      headers: {
        correlationId: localStorage.getItem("correlationId") || uuid(),
        userId: localStorage.getItem("userId") || email,
        authorization: localStorage.getItem("accessToken"),
      },
    }
  );
};

export const cancelSubscription = async (
  orgId: string,
  campusId: string,
  subId?: string
) => {
  const orgUri = localStorage.getItem("orgmntUri")
    ? localStorage.getItem("orgmntUri")
    : await orgmnturi();
  return await axios.post(
    `${orgUri}/v1/organisation/${orgId}/campus/${campusId}/subscription/cancel`,
    {
      subId: subId,
    },
    {
      headers: {
        correlationId: localStorage.getItem("correlationId"),
        userId: localStorage.getItem("userId"),
        authorization: localStorage.getItem("accessToken"),
      },
    }
  );
};

export const checkout = async (lineItem: any, url: string, email?: string) => {
  const orgUri = localStorage.getItem("orgmntUri")
    ? localStorage.getItem("orgmntUri")
    : await orgmnturi();
  const response = await axios.post(
    `${orgUri}/v1/stripe/checkout/session`,
    {
      lineItem: lineItem,
      url: url,
    },
    {
      headers: {
        correlationId: localStorage.getItem("correlationId") || uuid(),
        userId: localStorage.getItem("userId") || email,
        authorization: localStorage.getItem("accessToken"),
      },
    }
  );
  console.log(response);
  window.open(response.data, "_self");
};

export const getSubscriptionPlans = async () => {
  const orgUri = localStorage.getItem("orgmntUri")
    ? localStorage.getItem("orgmntUri")
    : await orgmnturi();
  return await axios.get(`${orgUri}/v1/subscription/plans`, {
    headers: {
      correlationId: localStorage.getItem("correlationId") || uuid(),
      userId:
        localStorage.getItem("userId") || "getSubscriptionPlans@solvitude.com",
      authorization: localStorage.getItem("accessToken"),
    },
  });
};

export const getInvitedUserDetails = async (email: string) => {
  const userurl = await usermnturi();
  return await axios.get(`${userurl}/v1/user/invited/${email}`, {
    headers: {
      correlationId: localStorage.getItem("correlationId") || uuid(),
      userId: localStorage.getItem("userId") || email,
      authorization: localStorage.getItem("accessToken"),
    },
  });
};
export const updateInvitedUserDetails = async (email: string, data: any) => {
  const userurl = await usermnturi();
  return await axios.patch(`${userurl}/v1/user/invited/${email}/update`, data, {
    headers: {
      correlationId: localStorage.getItem("correlationId") || uuid(),
      userId: localStorage.getItem("userId") || email,
      authorization: localStorage.getItem("accessToken"),
    },
  });
};
export const confirmInvitedUser = async (
  email: string,
  code: string,
  password: string
) => {
  const userurl = await usermnturi();
  return await axios.post(
    `${userurl}/v1/user/invited/${email}/confirm`,
    {
      confirmationCode: code,
      password: password,
    },
    {
      headers: {
        correlationId: localStorage.getItem("correlationId") || uuid(),
        userId: localStorage.getItem("userId") || email,
        authorization: localStorage.getItem("accessToken"),
      },
    }
  );
};
export const getAllCampus = async () => {
  return await axios.get(
    `${localStorage.getItem("orgmntUri")}/v1/organisation/campus/active`,
    {
      headers: {
        correlationId: localStorage.getItem("correlationId"),
        userId: localStorage.getItem("userId"),
        authorization: localStorage.getItem("accessToken"),
      },
    }
  );
};
export const getAllApplications = async (customerId: string) => {
  return await axios.get(
    `${localStorage.getItem(
      "appmntUri"
    )}/v1/application/customer/${customerId}`,
    {
      headers: {
        correlationId: localStorage.getItem("correlationId"),
        userId: localStorage.getItem("userId"),
        authorization: localStorage.getItem("accessToken"),
      },
    }
  );
};
export const uploadDocument = async (file: any) => {
  const filename = `${localStorage.getItem("customerId")}/${uuid()}`;
  console.log("Direct data: ", file);
  const params = {
    Bucket: localStorage.getItem("bucket")!,
    Key: filename,
    Body: file,
    ContentType: file.type,
    Metadata: {fieldName: file.name},
  };

  var upload = new AWS.S3.ManagedUpload({params});
  var data = await upload.promise();
  console.log(data.Key);
  return data.Key.split("/")[1];
};

export const downloadDocument = async (customerId: any, id: any) => {
  const filename = `${customerId}/${id}`;
  const s3 = new AWS.S3();
  const params = {
    Bucket: localStorage.getItem("bucket")!,
    Key: filename,
  };
  const data = await s3.getObject(params).promise();
  console.log(data.Body);
  return data.Body;
};
export const downloadApplication = async (
  id: any,
  orgName: any,
  appId: any
) => {
  const filename = `${orgName}/${appId}/${id}.pdf`;
  const s3 = new AWS.S3();
  const params = {
    Bucket: localStorage.getItem("bucket")!,
    Key: filename,
  };
  const data = await s3.getObject(params).promise();
  console.log(data.Body);
  return data.Body;
};

export const downloadXmlApplication = async (appData: any) => {
  const newXml = await axios.post(
    `${localStorage.getItem("appmntUri")}/v1/application/data/xml`, appData,
    {
      headers: {
        correlationId: localStorage.getItem("correlationId"),
        userId: localStorage.getItem("userId"),
        authorization: localStorage.getItem("accessToken"),
      },
    }
  );
  if(!newXml){
    console.error("New XML generation failed... Downloading previous version of XML");
  }
  const filename = `ApplicationXML/${appData.campusId}/${appData.additionalInfo.submittedOn}/${appData.applicationId}.xml`;
  const s3 = new AWS.S3();
  const params = {
    Bucket: localStorage.getItem("bucket")!,
    Key: filename,
  };
  const data = await s3.getObject(params).promise();
  console.log(data.Body);
  return data.Body;
};

export const getAllAdminApplications = async (campusId: string) => {
  return await axios.get(
    `${localStorage.getItem("appmntUri")}/v1/application/campus/${campusId}`,
    {
      headers: {
        correlationId: localStorage.getItem("correlationId"),
        userId: localStorage.getItem("userId"),
        authorization: localStorage.getItem("accessToken"),
      },
    }
  );
};

export const getAllCampusAnalytics = async (
  campusId: string,
  year?: string
) => {
  if (year)
    return await axios.get(
      `${localStorage.getItem(
        "appmntUri"
      )}/v1/application/campus/${campusId}/analytics`,
      {
        headers: {
          correlationId: localStorage.getItem("correlationId"),
          userId: localStorage.getItem("userId"),
          authorization: localStorage.getItem("accessToken"),
        },
        params: {
          year: year,
        },
      }
    );
  else
    return await axios.get(
      `${localStorage.getItem(
        "appmntUri"
      )}/v1/application/campus/${campusId}/analytics`,
      {
        headers: {
          correlationId: localStorage.getItem("correlationId"),
          userId: localStorage.getItem("userId"),
          authorization: localStorage.getItem("accessToken"),
        },
      }
    );
};

export const getAllApplicationsAnalytics = async (
  campusId: string,
  year?: string,
  classSelected?: string,
  status?: string
) => {
  console.log("Yearrr", year);
  console.log("Class", classSelected);
  console.log("status", status);
  if (year === "All")
    return await axios.get(
      `${localStorage.getItem(
        "appmntUri"
      )}/v1/application/campus/${campusId}/analytics/applications`,
      {
        headers: {
          correlationId: localStorage.getItem("correlationId"),
          userId: localStorage.getItem("userId"),
          authorization: localStorage.getItem("accessToken"),
        },
        params: {
          classSelected: classSelected,
        },
      }
    );
  else
    return await axios.get(
      `${localStorage.getItem(
        "appmntUri"
      )}/v1/application/campus/${campusId}/analytics/applications`,
      {
        headers: {
          correlationId: localStorage.getItem("correlationId"),
          userId: localStorage.getItem("userId"),
          authorization: localStorage.getItem("accessToken"),
        },
        params: {
          year: year,
          classSelected: classSelected,
        },
      }
    );
};

export const getStatusApplicationsAnalytics = async (
  campusId: string,
  year?: string,
  classSelected?: string,
  status?: string
) => {
  console.log("Yearrr", year);
  console.log("Class", classSelected);
  console.log("status", status);
  if (year === "All") {
    if (classSelected === "all") {
      return await axios.get(
        `${localStorage.getItem(
          "appmntUri"
        )}/v1/application/campus/${campusId}/analytics/applications`,
        {
          headers: {
            correlationId: localStorage.getItem("correlationId"),
            userId: localStorage.getItem("userId"),
            authorization: localStorage.getItem("accessToken"),
          },
          params: {
            status: status,
          },
        }
      );
    } else {
      return await axios.get(
        `${localStorage.getItem(
          "appmntUri"
        )}/v1/application/campus/${campusId}/analytics/applications`,
        {
          headers: {
            correlationId: localStorage.getItem("correlationId"),
            userId: localStorage.getItem("userId"),
            authorization: localStorage.getItem("accessToken"),
          },
          params: {
            classSelected: classSelected,
            status: status,
          },
        }
      );
    }
  } else {
    if (classSelected === "all") {
      return await axios.get(
        `${localStorage.getItem(
          "appmntUri"
        )}/v1/application/campus/${campusId}/analytics/applications`,
        {
          headers: {
            correlationId: localStorage.getItem("correlationId"),
            userId: localStorage.getItem("userId"),
            authorization: localStorage.getItem("accessToken"),
          },
          params: {
            year: year,
            status: status,
          },
        }
      );
    } else {
      return await axios.get(
        `${localStorage.getItem(
          "appmntUri"
        )}/v1/application/campus/${campusId}/analytics/applications`,
        {
          headers: {
            correlationId: localStorage.getItem("correlationId"),
            userId: localStorage.getItem("userId"),
            authorization: localStorage.getItem("accessToken"),
          },
          params: {
            year: year,
            classSelected: classSelected,
            status: status,
          },
        }
      );
    }
  }
};

export const sendChangePasswordConfirmation = async (
  email: string,
  accessToken?: string
) => {
  const backurl = await backenduri();
  return await axios.get(`${backurl}/changepassword/${email}`, {
    headers: {
      correlationId: localStorage.getItem("correlationId") || uuid(),
      userId: localStorage.getItem("userId") || "ForgotPasswordUser",
      authorization: localStorage.getItem("accessToken") || accessToken,
    },
  });
};

export const getApplicationDetails = async (applicationId: string) => {
  return await axios.get(
    `${localStorage.getItem("appmntUri")}/v1/application/${applicationId}`,
    {
      headers: {
        correlationId: localStorage.getItem("correlationId"),
        userId: localStorage.getItem("userId"),
        authorization: localStorage.getItem("accessToken"),
      },
    }
  );
};

export const parentSignUp = async (
  data: any,
  email: string,
  type: string,
  accessToken?: string
) => {
  const userurl = await usermnturi();
  return await axios.post(`${userurl}/v1/user`, data, {
    headers: {
      correlationId: localStorage.getItem("correlationId") || uuid(),
      userId: localStorage.getItem("userId") || email,
      authorization: accessToken || localStorage.getItem("accessToken"),
    },
    params: {
      type: type,
    },
  });
};

export const verifyOtp = async (
  data: any,
  phone: string,
  accesstoken?: string
) => {
  const userurl = await usermnturi();
  return await axios.post(`${userurl}/v1/user/code/verify`, data, {
    headers: {
      correlationId: localStorage.getItem("correlationId") || uuid(),
      userId: localStorage.getItem("userId") || phone,
      authorization: accesstoken || localStorage.getItem("accessToken"),
    },
  });
};

export const resendOtp = async (
  data: any,
  phone: string,
  accesstoken?: string
) => {
  const userurl = await usermnturi();
  return await axios.post(`${userurl}/v1/user/code/resend`, data, {
    headers: {
      correlationId: localStorage.getItem("correlationId") || uuid(),
      userId: localStorage.getItem("userId") || phone,
      authorization: accesstoken || localStorage.getItem("accessToken"),
    },
  });
};

export const saveUserDetails = async (
  data: any,
  email: string,
  accessToken?: string
) => {
  const userurl = await usermnturi();
  return await axios.post(`${userurl}/v1/user`, data, {
    headers: {
      correlationId: localStorage.getItem("correlationId") || uuid(),
      userId: localStorage.getItem("userId") || email,
      authorization: accessToken || localStorage.getItem("accessToken"),
    },
  });
};

export const adminSignup = async (
  data: any,
  email: string,
  accessToken: string
) => {
  const userurl = await usermnturi();
  return await axios.post(`${userurl}/v1/admin/signup`, data, {
    headers: {
      correlationId: localStorage.getItem("correlationId") || uuid(),
      userId: localStorage.getItem("userId") || email,
      authorization:
        accessToken || localStorage.getItem("accessToken") || accessToken,
    },
  });
};

export const updateUserDetails = async (
  data: any,
  email: string,
  customerId: any,
  role: string
) => {
  return await axios.patch(
    `${localStorage.getItem("usermntUri")}/v1/user/${customerId}`,
    data,
    {
      headers: {
        correlationId: localStorage.getItem("correlationId"),
        userId: localStorage.getItem("userId") || email,
        authorization: localStorage.getItem("accessToken"),
      },
      params: {
        role: role,
      },
    }
  );
};

export const fetchUserDetails = async (customerId: any, userid: any) => {
  return await axios.get(
    `${localStorage.getItem("usermntUri")}/v1/user/parent/${customerId}`,
    {
      headers: {
        correlationId: localStorage.getItem("correlationId"),
        userId: localStorage.getItem("userId") || userid,
        authorization: localStorage.getItem("accessToken"),
      },
    }
  );
};
export const fetchCampusDetails = async (orgId: any, campusId: any) => {
  return await axios.get(
    `${localStorage.getItem(
      "orgmntUri"
    )}/v1/organisation/${orgId}/campus/${campusId}`,
    {
      headers: {
        correlationId: localStorage.getItem("correlationId"),
        userId: localStorage.getItem("userId"),
        authorization: localStorage.getItem("accessToken"),
      },
    }
  );
};
export const patchBankInfo = async (
  orgId: any,
  campusId: any,
  bankInfo: any
) => {
  return await axios.patch(
    `${localStorage.getItem(
      "orgmntUri"
    )}/v1/organisation/${orgId}/campus/${campusId}/bank/info`,
    bankInfo,
    {
      headers: {
        correlationId: localStorage.getItem("correlationId"),
        userId: localStorage.getItem("userId"),
        authorization: localStorage.getItem("accessToken"),
      },
    }
  );
};
export const getAllChildren = async (parentId: string) => {
  return await axios.get(
    `${localStorage.getItem("usermntUri")}/v1/user/child/${parentId}`,
    {
      headers: {
        correlationId: localStorage.getItem("correlationId"),
        userId: localStorage.getItem("userId"),
        authorization: localStorage.getItem("accessToken"),
      },
    }
  );
};
export const updateApplication = async (applicationId: string, data: any) => {
  return await axios.patch(
    `${localStorage.getItem("appmntUri")}/v1/application/${applicationId}`,
    data,
    {
      headers: {
        correlationId: localStorage.getItem("correlationId"),
        userId: localStorage.getItem("userId"),
        authorization: localStorage.getItem("accessToken"),
      },
    }
  );
};
export const sendStatusEmail = async (
  data: any,
  contactId: string,
  appId: any,
  orgName: any,
  status: any,
  interviewSchedule?: any
) => {
  return await axios.post(
    `${localStorage.getItem("backendUri")}/status/update/${contactId}`,
    data,
    {
      headers: {
        correlationId: localStorage.getItem("correlationId"),
        userId: localStorage.getItem("userId"),
        authorization: localStorage.getItem("accessToken"),
      },
      params: {
        appId: appId,
        orgName: orgName,
        status: status,
        interviewSchedule: interviewSchedule,
      },
    }
  );
};

export const getAllInvitedUsers = async (campusId: string) => {
  return await axios.get(
    `${localStorage.getItem("usermntUri")}/v1/user/campus/${campusId}`,
    {
      headers: {
        correlationId: localStorage.getItem("correlationId"),
        userId: localStorage.getItem("userId"),
        authorization: localStorage.getItem("accessToken"),
      },
    }
  );
};

export const getUserRoles = async (campusId: string, status: string) => {
  return await axios.get(
    `${localStorage.getItem("usermntUri")}/v1/user/campus/${campusId}`,
    {
      headers: {
        correlationId: localStorage.getItem("correlationId"),
        userId: localStorage.getItem("userId"),
        authorization: localStorage.getItem("accessToken"),
      },
      params: {
        status: status,
      },
    }
  );
};

export const sendUserRoles = async (data: any, requestType: any) => {
  return await axios.post(
    `${localStorage.getItem("usermntUri")}/v1/users/invited`,
    data,
    {
      headers: {
        correlationId: localStorage.getItem("correlationId"),
        userId: localStorage.getItem("userId"),
        authorization: localStorage.getItem("accessToken"),
      },
      params: {
        requestType: requestType,
      },
    }
  );
};

export const disableUser = async (customerId: string, data: any) => {
  return await axios.post(
    `${localStorage.getItem("usermntUri")}/v1/user/${customerId}/disable`,
    data,
    {
      headers: {
        correlationId: localStorage.getItem("correlationId"),
        userId: localStorage.getItem("userId"),
        authorization: localStorage.getItem("accessToken"),
      },
    }
  );
};

export const deleteUser = async (customerId: string) => {
  return await axios.post(
    `${localStorage.getItem("usermntUri")}/v1/user/${customerId}/delete`,
    {},
    {
      headers: {
        correlationId: localStorage.getItem("correlationId"),
        userId: localStorage.getItem("userId"),
        authorization: localStorage.getItem("accessToken"),
      },
    }
  );
};

export const enableUser = async (customerId: string, data: any) => {
  return await axios.post(
    `${localStorage.getItem("usermntUri")}/v1/user/${customerId}/enable`,
    data,
    {
      headers: {
        correlationId: localStorage.getItem("correlationId"),
        userId: localStorage.getItem("userId"),
        authorization: localStorage.getItem("accessToken"),
      },
    }
  );
};

export const resendInvite = async (data: any) => {
  return await axios.post(
    `${localStorage.getItem("backendUri")}/resend/invite`,
    data,
    {
      headers: {
        correlationId: localStorage.getItem("correlationId"),
        userId: localStorage.getItem("userId"),
        authorization: localStorage.getItem("accessToken"),
      },
    }
  );
};

export const saveApplication = async (data: any) => {
  return await axios.post(
    `${localStorage.getItem("appmntUri")}/v1/application`,
    data,
    {
      headers: {
        correlationId: localStorage.getItem("correlationId"),
        userId: localStorage.getItem("userId"),
        authorization: localStorage.getItem("accessToken"),
      },
    }
  );
};

export const partialUpdateApplication = async (
  user: any,
  values: any,
  orgDetails: any,
  applicantDetails: any,
  docId: any,
  status: string,
  pageState: any,
  formReference: any,
  applicationId?: string
) => {
  const {paymentInfo, ...applicationInfo} = values;
  const date = new Date();
  const applicationData = {
    customerId: user.attributes["custom:userName"],
    applicantDetails: applicantDetails,
    orgId: orgDetails.orgId,
    campusId: orgDetails.campusList[0].campusId,
    status: status,
    orgDetails: {
      orgName: orgDetails.orgName,
      razorpayPayment: orgDetails.campusList[0].additionalInfo.razorpayPayment,
      amount: orgDetails.campusList[0].additionalInfo.amount,
      currency: orgDetails.campusList[0].additionalInfo.currency,
      source: orgDetails.campusList[0]?.source,
      orgEmail: orgDetails.campusList[0]?.auditDetails.createdBy,
    },
    applicationDetails: applicationInfo,
    paymentInfo: paymentInfo,
    documentReferences: [
      {
        docType: "Response File",
        docId: docId,
      },
    ],
    additionalInfo: {
      class: values["Applicant Details"].formValues["Class_applicant"],
      year: values["Applicant Details"].formValues["Year_applicant"],
      pageState: pageState,
      applicantType:
        orgDetails.orgName === "Burnpur Riverside School" ? "teacher" : "child",
      formReference: formReference,
      ...(status === "Submitted" && {
        submittedOn: moment(date).format("YYYY-MM-DD")}),
    },
    consents: [
      {
        type: "eligibility",
        value: true,
      },
    ],
  };
  if (applicationId) {
    const {data} = await updateApplication(applicationId, applicationData);
    return data;
  } else {
    const {data} = await saveApplication(applicationData);
    return data;
  }
};

export const getAddresses = async (value: any, paramType: any) => {
  let parameters = {};
  if (paramType === "search") parameters = {search: value};
  if (paramType === "placeId") parameters = {id: value};
  return await axios.get(`${localStorage.getItem("backendUri")}/address`, {
    headers: {
      correlationId: localStorage.getItem("correlationId"),
      userId: localStorage.getItem("userId"),
      authorization: localStorage.getItem("accessToken"),
    },
    params: parameters,
  });
};

export const validateSchoolZone = async (latitude: string, longitude: string, schoolName: string) => {
  let parameters = {};
  console.log("school name is this",schoolName);
  parameters = {latitude: latitude, longitude: longitude, schoolName: schoolName};
  return await axios.get(`${localStorage.getItem("backendUri")}/check/zone`, {
    headers: {
      correlationId: localStorage.getItem("correlationId"),
      userId: localStorage.getItem("userId"),
      authorization: localStorage.getItem("accessToken"),
    },
    params: parameters
  });
};

export const submitApplication = async (
  data: any,
  userId: any,
  id: any,
  orgName: any,
  campusId: any,
  type: any
) => {
  return await axios.post(
    `${localStorage.getItem("backendUri")}/submit`,
    data,
    {
      headers: {
        correlationId: localStorage.getItem("correlationId"),
        userId: localStorage.getItem("userId"),
        authorization: localStorage.getItem("accessToken"),
      },
      params: {
        contactId: userId,
        id: id,
        orgName: orgName,
        customerId: localStorage.getItem("customerId"),
        campusId: campusId,
        type: type,
      },
      timeout: 60000
    },
  );
};

export const getOrgDetails = async (orgId: string) => {
  return await axios.get(
    `${localStorage.getItem("orgmntUri")}/v1/organisation/${orgId}`,
    {
      headers: {
        correlationId: localStorage.getItem("correlationId"),
        userId: localStorage.getItem("userId"),
        authorization: localStorage.getItem("accessToken"),
      },
    }
  );
};

export const patchOrgDetails = async (orgId: string, data: any) => {
  return await axios.patch(
    `${localStorage.getItem("orgmntUri")}/v1/organisation/${orgId}`,
    data,
    {
      headers: {
        correlationId: localStorage.getItem("correlationId"),
        userId: localStorage.getItem("userId"),
        authorization: localStorage.getItem("accessToken"),
      },
    }
  );
};

export const patchCampusDetails = async (
  orgId: string,
  campusId: string,
  data: any
) => {
  const orgUri = localStorage.getItem("orgmntUri")
    ? localStorage.getItem("orgmntUri")
    : await orgmnturi();
  return await axios.patch(
    `${orgUri}/v1/organisation/${orgId}/campus/${campusId}`,
    data,
    {
      headers: {
        correlationId: localStorage.getItem("correlationId"),
        userId: localStorage.getItem("userId"),
        authorization: localStorage.getItem("accessToken"),
      },
    }
  );
};

export const logError = async (log: any) => {
  return await axios.post(
    `${localStorage.getItem("backendUri")}/frontend/logs`,
    log,
    {
      headers: {
        correlationId: localStorage.getItem("correlationId"),
        userId: localStorage.getItem("userId"),
        authorization: localStorage.getItem("accessToken"),
      },
    }
  );
};

export const getSubscriptionHistory = async (
  orgId: string,
  campusId: string
) => {
  return await axios.get(
    `${localStorage.getItem(
      "orgmntUri"
    )}/v1/organisation/${orgId}/campus/${campusId}/subscription/history`,
    {
      headers: {
        correlationId: localStorage.getItem("correlationId"),
        userId: localStorage.getItem("userId"),
        authorization: localStorage.getItem("accessToken"),
      },
    }
  );
};

export const getBroadcastDetails = async (orgId: string, campusId: string) => {
  return await axios.get(
    `${localStorage.getItem(
      "appmntUri"
    )}/v1/broadcast/${orgId}/email/${campusId}`,
    {
      headers: {
        correlationId: localStorage.getItem("correlationId"),
        userId: localStorage.getItem("userId"),
        authorization: localStorage.getItem("accessToken"),
      },
    }
  );
};

export const broadcastEmail = async (
  orgId: string,
  campusId: string,
  data: any
) => {
  const broadcastId = uuid();
  return await axios.post(
    `${localStorage.getItem(
      "appmntUri"
    )}/v1/broadcast/${orgId}/${campusId}/email/${broadcastId}`,
    data,
    {
      headers: {
        correlationId: localStorage.getItem("correlationId"),
        userId: localStorage.getItem("userId"),
        authorization: localStorage.getItem("accessToken"),
      },
    }
  );
};
