import Box from "@material-ui/core/Box";
import makeStyles from "@material-ui/core/styles/makeStyles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import Grid from "@material-ui/core/Grid";
import {useEffect, useState} from "react";
import LoadingScreen from "../../components/LoadingScreen";
import Typography from "@material-ui/core/Typography";
import addButton from "../../assets/icons/addButton.svg";
import GenericButton from "../../components/GenericButton";
import MessageModal from "./MessageModal";
import {getBroadcastDetails} from "../../api";
import Dialog from "@material-ui/core/Dialog";
import ResultModal from "../../components/ResultModal";
import adminEmptyState from "../../assets/emptyState/admin.svg";
import MessageDetail from "./MessageDetail";
import ViewMessage from "./ViewMessage";
import Link from "@material-ui/core/Link";

const useStyles = makeStyles((theme: any) => ({
  emptyState: {
    ...theme.typography.emptyState,
    margin: "10px 0 10px 0",
    [theme.breakpoints.between("xs", "sm")]: {},
  },
  emptyTitle: {
    ...theme.typography.emptyTitle,
  },
  title: {
    ...theme.typography.reportingTitle,
    fontSize: "26px",
  },
  value: {
    ...theme.typography.pageTitle,
  },
  textfield: {
    ...theme.typography.reportingContent,
  },
  select: {
    "& .MuiSelect-select:focus": {
      background: "transparent",
    },
    "& .MuiOutlinedInput-root": {
      height: "40px",
    },
  },
  options: {
    ...theme.typography.body1,
  },
  messages: {
    backgroundColor: theme.palette.background.paper,
    borderRadius: "16px",
    marginBottom: theme.spacing(4),
    width: "100%",
    minHeight: "50vh",
    [theme.breakpoints.between("xs", "sm")]: {
      marginRight: theme.spacing(2),
      marginLeft: theme.spacing(2),
      marginBottom: theme.spacing(2),
      padding: theme.spacing(2),
      minHeight: "70vh",
    },
    [theme.breakpoints.between("0", "xs")]: {
      marginRight: "0.8em",
      marginLeft: "0",
      marginBottom: "0.8em",
      padding: "0.8em",
      minHeight: "60vh",
    },
    padding: theme.spacing(2),
  },
  container: {
    marginTop: "5vh",
    [theme.breakpoints.between(0, "xs")]: {
    },
  },
}));

function Messages(props: any) {
  console.log("Message props");
  console.log(props);
  const classes = useStyles();
  const [data, setData]: any = useState([]);
  const [loading, setLoading] = useState(false);
  const [viewMessage, setViewMessage] = useState(false);
  const [openMessageModal, setOpenMessageModal] = useState(false);
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState(false);
  const [viewMessageData, setViewMessageData]: any = useState({});
  const [viewAllMessages, setViewAllMessages] = useState(false);

  const smallScreen = useMediaQuery((theme: any) =>
    theme.breakpoints.between(0, "xs")
  );
  const mediumScreen = useMediaQuery((theme: any) =>
    theme.breakpoints.between("xs", "sm")
  );

  const toggleMessageModal = () => {
    setOpenMessageModal(!openMessageModal);
  };
  useEffect(() => {
    const orgId = props.user.attributes["custom:orgId"];
    const campusId = props.user.attributes["custom:campusId"];
    console.log("On Message Page");
    setLoading(true);
    getBroadcastDetails(orgId, campusId)
      .then((res) => {
        console.log("Broadcasted Email List");
        console.log(res);
        setData(res.data);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  }, []);

  const handleTableAdmin = (broadcastId: any) => {
    const viewData = data.find(
      (details: any) => details.broadcastId === broadcastId
    );
    setViewMessageData(viewData);
    setViewMessage(true);
  };

  const listOfMessages = data
    .sort(function (a: any, b: any) {
      var dateA: any = new Date(a.auditDetails.createdAt),
        dateB: any = new Date(b.auditDetails.createdAt);
      return dateB - dateA; //sort by date ascending
    })
    .map((data: any, index: any) => {
      return (
        <MessageDetail
          data={data}
          handleTableAdmin={handleTableAdmin}
          key={index}
        />
      );
    });

  const shortListOfMessages = listOfMessages.slice(0, 5);

  return viewMessage ? (
    <Box
      margin="24px"
      {...(openMessageModal && {
        style: {
          maxHeight: "90vh",
          overflow: "hidden",
        },
      })}
    >
      <Grid container className={classes.container}>
        <Box className={classes.messages}>
          <ViewMessage data={viewMessageData} setViewMessage={setViewMessage} />
        </Box>
      </Grid>
    </Box>
  ) : (
    <Box
      margin="24px"
      {...(openMessageModal && {
        style: {
          maxHeight: "90vh",
          overflow: "hidden",
        },
      })}
    >
      {openMessageModal && (
        <MessageModal
          toggleMessageModal={toggleMessageModal}
          user={props.user}
          setOpenMessageModal={setOpenMessageModal}
          openMessageModal={openMessageModal}
          success={success}
          setSuccess={setSuccess}
          error={error}
          setError={setError}
          setData={setData}
          userCountry={props.userCountry}
        />
      )}
      <Box display="flex" alignItems="center" flexDirection="row">
        <Box flexGrow={1}>
          <Typography
            variant="h1"
            className={classes.title}
            style={{paddingBottom: "10px"}}
          >
            Messages
          </Typography>
        </Box>
        <Box>
          {smallScreen ? (
            <img src={addButton} alt="" onClick={toggleMessageModal} />
          ) : (
            <GenericButton
              background="blue"
              variant="outlined"
              onClick={toggleMessageModal}
            >
              Write a message
            </GenericButton>
          )}
        </Box>
      </Box>

      <Grid container className={classes.container}>
        {loading && (
          <Box height="100vh">
            <LoadingScreen />
          </Box>
        )}
        <Grid
          item
          md={12} //check this line by removing it
          {...(data.length === 0 && {
            md: 12,
            sm: 12,
            xs: 12,
          })}
        >
          {}
          <Box className={classes.messages}>
            {listOfMessages.length ? (
              viewAllMessages ? (
                <>
                  {listOfMessages}
                  <Box
                    marginTop="1em"
                    key="box"
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                  >
                    <Link
                      component="button"
                      variant="body2"
                      onClick={() => {
                        setViewAllMessages(false);
                      }}
                    >
                      View Less
                    </Link>
                  </Box>
                </>
              ) : (
                <>
                  {shortListOfMessages}
                  {listOfMessages.length > 4 && (
                    <Box
                      marginTop="1em"
                      key="box"
                      display="flex"
                      justifyContent="center"
                      alignItems="center"
                    >
                      <Link
                        component="button"
                        variant="body2"
                        onClick={() => {
                          setViewAllMessages(true);
                        }}
                      >
                        View All
                      </Link>
                    </Box>
                  )}
                </>
              )
            ) : (
              !loading && (
                <Box
                  display="flex"
                  flexDirection="column"
                  justifyContent="center"
                  alignItems="center"
                  paddingTop={"5vh"}
                >
                  <img src={adminEmptyState} alt="" />

                  <Box marginTop="5vh" className={classes.emptyTitle}>
                    No Emails Yet
                  </Box>
                </Box>
              )
            )}
          </Box>
        </Grid>
      </Grid>

      {success && (
        <Dialog
          PaperProps={{
            style: {
              borderRadius: "10px",
              background: "#ECEFF1",
            },
          }}
          open={Boolean(success)}
        >
          <ResultModal
            type="success"
            title="Success"
            content="Message sent successfully"
            handleClose={() => {
              setSuccess(false);
            }}
          />
        </Dialog>
      )}
      {error && (
        <Dialog
          PaperProps={{
            style: {
              borderRadius: "10px",
              background: "#ECEFF1",
            },
          }}
          open={Boolean(error)}
        >
          <ResultModal
            type="error"
            title="Failed"
            content="Message not delivered. Please try again in sometime."
            handleClose={() => {
              setError(false);
            }}
          />
        </Dialog>
      )}
    </Box>
  );
}

export default Messages;
