import React from "react";
import FormControl from "@material-ui/core/FormControl";
import FormLabel from "@material-ui/core/FormLabel";
import makeStyles from "@material-ui/core/styles/makeStyles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import Grid from "@material-ui/core/Grid";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
const useStyles = makeStyles((theme) => ({
  radioGroup: {
    "& .MuiRadio-root": {
      padding: "8px", // Adjust as needed
    },
  },
  options: {
    ...theme.typography.body1,
  },
}));

interface RadioComponentProps {
  id: string;
  label: string;
  name: string;
  value: string;
  error?: string | null;
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  width?: string | null;
  required?: boolean;
  options: Array<{Id: string; Label: string}>;
}

function RadioComponent(props: RadioComponentProps) {
  const {
    id,
    label,
    name,
    value,
    error = null,
    onChange,
    width,
    required = false,
    options,
  } = props;

  const classes = useStyles();
  const smallScreen = useMediaQuery((theme: any) =>
    theme.breakpoints.between("0", "xs")
  );
  console.log(options);

  return (
    <FormControl
      component="fieldset"
      style={{
        width: width || (smallScreen ? "100%" : "50%"),
        marginBottom: "20px",
      }}
      {...(required && {required: true})}
    >
      <FormLabel>{label}</FormLabel>
      <Grid
        container
        direction="row"
        style={{width: width || (smallScreen ? "94%" : "88%")}}
      >
        <RadioGroup
          className={classes.radioGroup}
          aria-label={label}
          name={name}
          value={value}
          onChange={onChange}
        >
          {options.map((options: any) => (
            <FormControlLabel
              id={options.Id}
              key={options.Id}
              value={options.Label}
              control={<Radio />}
              label={options.Label}
            />
          ))}
        </RadioGroup>
      </Grid>
    </FormControl>
  );
}

export default RadioComponent;
