import Typography from "@material-ui/core/Typography";

function AdminStatus(props: { status: string }) {
  const { status } = props;
  return (
    <Typography variant="caption">
      <span
        style={{
          color:
            status === "Submitted"
              ? "#607D8B"
              : status === "Interview Scheduled"
              ? "#FF9E45"
              : status === "Post-interview Review"
              ? "#2DB6F5"
              : status === "Admission Granted"
              ? "#009AAA"
              : "#F9347B",
          backgroundColor:
            status === "Submitted"
              ? "#E5E7E8"
              : status === "Interview Scheduled"
              ? "#FFEACB"
              : status === "Post-interview Review"
              ? "#D8F5FC"
              : status === "Admission Granted"
              ? "#C8FFF8"
              : "#FFD6E4",
          borderRadius: "11px",
          padding: "2px 10px 2px 10px",
        }}
      >
        {status}
      </span>
    </Typography>
  );
}

export default AdminStatus;
