import FormControl from "@material-ui/core/FormControl";
import FormLabel from "@material-ui/core/FormLabel";
import makeStyles from "@material-ui/core/styles/makeStyles";
import TextField from "@material-ui/core/TextField";

const useStyles = makeStyles((theme) => ({
  root: {
    "& .MuiOutlinedInput-root": {
      minHeight: "108px",
    },
    "& .MuiOutlinedInput-root.Mui-focused": {
      minHeight: "108px",
    },
    "& .MuiInputBase-root": {
      display: "flex",
      alignItems: "flex-start",
    },
  },
}));
function Textarea(props: any) {
  const { id, label, name, value, onChange, error = null, required, width } = props;
  const classes = useStyles();
  return (
    <FormControl
      variant="outlined"
      style={{ marginBottom: "20px", width: width || "80%" }}
      {...(required && { required: true })}
      {...(error && { error: true })}
    >
      <FormLabel>{label}</FormLabel>
      <TextField
        className={classes.root}
        variant="outlined"
        InputLabelProps={{ shrink: true }}
        id={id}
        name={name}
        value={value}
        onChange={onChange}
        multiline
        {...(error && { error: true, helperText: error })}
        {...(required && { required: true })}
      />
    </FormControl>
  );
}

export default Textarea;
