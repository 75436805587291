import Box from "@material-ui/core/Box";
import makeStyles from "@material-ui/styles/makeStyles";
import { Link } from "react-router-dom";
import FiberManualRecordIcon from "@material-ui/icons/FiberManualRecord";

const useStyles = makeStyles((theme: any) => ({
  root: {
    background: "#292A29",
    height: "15vh",
    padding: "10px 0 10px 100px",
    position: "fixed",
    width: "100%",
    boxShadow: "3px 3px 20px rgba(96, 125, 139, 0.5)",
    top: 0,
    display: "flex",
    alignItems: "center",
  },
  head: {
    padding: "25vh 0 20vh 0",
    background: "#3B3A3C",
  },
  main: {
    padding: "0px 100px 50px 100px",
  },
}));
const Title = (props: any) => {
  return (
    <Box color="#56AB2F" fontFamily="Lato" fontSize="20px" fontWeight={400} marginTop="50px">
      {props.children}
    </Box>
  );
};
const Content = (props: any) => {
  return (
    <Box
      marginTop="15px"
      marginBottom="15px"
      fontSize="16px"
      fontFamily="Lato"
      lineHeight="22px"
      color="#3B3A3C"
      fontWeight={400}
    >
      {props.children}
    </Box>
  );
};
const List = (props: any) => {
  return (
    <Box display="flex" marginLeft="20px">
      <FiberManualRecordIcon
        style={{
          color: "#3B3A3C",
          marginRight: "10px",
          marginTop: "22px",
          width: "10px",
          height: "10px",
        }}
      />
      <Content>{props.children}</Content>
    </Box>
  );
};
function PrivacyPolicy(props: any) {
  const classes = useStyles();
  return (
    <Box>
      <Box className={classes.root}>
        <Link to="/login" style={{ cursor: "pointer" }}>
          <img
            width="60%"
            src="https://static.wixstatic.com/media/6a1a43_38bc35d9382c4247ae8496fb4b94e8c4~mv2.png/v1/fill/w_300,h_98,al_c,q_85,usm_0.66_1.00_0.01/Logo%20Dark%402x.webp"
            alt=""
          ></img>
        </Link>
      </Box>
      <Box className={classes.head}>
        <Box color="#FFFFFF" textAlign="center" fontFamily="Lato" fontSize="40px" fontWeight={400}>
          Privacy Policy
        </Box>
        <Box display="flex" justifyContent="center" marginTop="5vh">
          <Box
            width="80%"
            textAlign="center"
            fontSize="20px"
            lineHeight="40px"
            fontFamily="Lato"
            color="#FFFFFF"
            fontWeight={400}
          >
            Solvitude Pty Limited’s (hereafter Solvitude) Privacy Policy sets out how we collect,
            handle and use information about our users. We reserve the right to update this Policy
            at any time without notice. We are subject to the privacy laws of the jurisdictions in
            which we operate, including the Australian Privacy Principles (APPs) under the act 1988
            (Cth). You agree to this Privacy Policy, in its entirety, when you - Access our Website;
            Agree to receive e-mail from us.
          </Box>
        </Box>
      </Box>
      <Box className={classes.main}>
        <Title>Purpose</Title>
        <Content>The purpose of this policy is to set to:</Content>
        <List>Set out the types of the information we may collect; and/or</List>
        <List>How the information will be used, handled, sorted & disclosed</List>
        <Title>Application</Title>
        <Content>
          This policy applies to Personal Information that we may collect about you in the manner
          outlined in this policy, including from all legal entities owned or controlled by us
          (across any jurisdiction).
        </Content>
        <Content>
          This policy does not apply to Personal Information that may be collected by a third party
          or how that third party may use, handle, store or disclose your Personal Information.
        </Content>
        <Title>Information and Collection Practices</Title>
        <Content>
          We collect personal information about you (including, but not limited to, the details
          outlined in the ‘what types of information do we collect?’ section below) that:{" "}
        </Content>
        <List>
          You give us directly: We collect information about you when you provide us with your
          information through forms, requests for information, applications for a school, use our
          service to store your data on{" "}
          <a href="https://www.solvitude.com.au" target="_blank" rel="noreferrer">
            www.solvitude.com.au
          </a>{" "}
          website, or through other communications and interactions you have with us via our
          website, email or telephone. When you submit information to us, you are doing so on a
          completely voluntary basis, at your sole discretion and will. Many times, the information
          you submit is required to obtain the benefit, assistance or service you are requesting or
          would like to obtain from us-for instance, to use our form completion services on{" "}
          <a href="https://www.solvitude.com.au" target="_blank" rel="noreferrer">
            www.solvitude.com.au
          </a>
          , or to obtain information about a school/institute. The information you may be requested
          to submit may include (but is not limited to)
          <List>Name</List>
          <List>Email Address</List>
          <List>Telephone Number</List>
          <List>Address</List>
          <List>Date of Birth</List>
          <List>Medical History</List>
          <List>Supporting Documents</List>
        </List>
        <List>
          We receive from other sources (for example Personal Information we receive from
          Institutions, business partners, sub-contractors in technical and delivery services,
          advertising networks, analytics providers, search information providers)
        </List>
        <List>
          We collect about you and your device (for example technical information, including the IP
          address used to connect your computer to the Internet, your login information, browser
          type and version, time zone setting, browser plug-in types and versions, cookies,
          operating system and platform, type of device. Additionally, as you browse through our
          platforms, websites and Suite of Products, we collect information about the individual web
          pages or products that you view, what websites or search terms referred you to across
          those platforms, and information about how you interact with our Suite of Products. We
          refer to this automatically collected information as “Device Information”).
        </List>
        <Content>We collect Device Information using the following technologies:</Content>
        <List>
          “Cookies” are data files that are placed on your device or computer and often include an
          anonymous unique identifier. For more information about cookies, and how to disable
          cookies, visit http://www.allaboutcookies.org
        </List>
        <List>
          "Log files” track actions occurring on our Suite of Products, and collect data including
          your IP address, browser type, Internet service provider, referring/exit pages, and
          date/time stamps;
        </List>
        <List>
          “Web beacons”, “tags”, and “pixels” are electronic files used to record information about
          how you browse our Suite of Products.
        </List>
        <Content>
          In some situations, you will have the option to deal with us anonymously or through a
          pseudonym, however, where you are requesting products or services from us or from an
          Institution where such contact is via us or one of our services, it may become
          impracticable to provide those products or services to we receive from other sources (for
          example Personal Information we receive from Institutions, business partners,
          sub-contractors in technical and delivery services, advertising networks, analytics
          providers, search information providers)
        </Content>
        <Content>
          We collect about you and your device (for example technical information, including the IP
          address used to connect your computer to the Internet, your login information, browser
          type and version, time zone setting, browser plug-in types and versions, cookies,
          operating system and platform, type of device. Additionally, as you browse the “About us,
          Services, websites”, we collect information about the individual web pages or products
          that you view, what websites or search terms referred you to across those platforms, and
          information about how you interact with our Suite of Products. We refer to this
          automatically collected information as “Device Information”).
        </Content>
        <Title>Types of information that we collect</Title>
        <Content>
          Personal Details: We collect the following types of information about you: full name,
          gender, date of birth, age, residential address, postal address, email address, phone
          number, facsimile number, and proof of identity information. We collect this information
          to identify you and conduct appropriate identity checks for, or on behalf of,
          Institutions; to create an account with us in order to interact with Institutions; to
          submit enrolment applications with an Institution; to send you updates about your account;
          to communicate about and provide you with our current and future products and services; to
          send you relevant news, promotion and marketing materials, either initiated by us or by
          Institutions; to respond to your requests, questions, comments and complaints; to register
          for conferences and special events; or any other reason allowed at law.
        </Content>
        <Content>
          Religious and political beliefs: We collect information or opinions about your racial or
          ethnic origin, political opinions, or memberships, religious beliefs or affiliations,
          philosophical beliefs, membership of a professional or trade association, membership of a
          trade union, sexual orientation, or criminal record. We collect this information: to the
          extent that we or an Institution consider it is necessary to provide our products and
          services to you or to improve the products, services and business activities that we
          undertake; to publish your reviews, forum posts or other content on our website or if an
          Institution chooses to do so; or any other reason allowed at law.
        </Content>
        <Content>
          Solvitude’s products and services: We collect information, communication or opinions about
          any of our products, services, transactions, payment history and business activities. We
          collect this information to improve the products, services and business activities that we
          or an Institution undertake; or any other reason allowed at law.
        </Content>
        <Content>
          Digital media: We collect digital media and content such as; video footage and audio. We
          collect this information: for internal training and quality purposes; or to ensure
          effective delivery of products and services; to resolve disputes or problems; or any other
          reason allowed at law.
        </Content>
        <Content>
          Proof of identity: We collect identifiers (such as tax file number and business number),
          citizenship and residency details, details regarding and information provided by your
          referees, details regarding and information provided by your guarantor(s) and business
          partner(s), financials/credit/criminal history checks, results of any pre-employment or
          profile tests, employment history, education history, identity documents, health
          information and next of kin details. We collect this information: to identify you; to
          identify your referees and guarantor(s); to conduct identity and criminal checks; to
          conduct credit checks; to assess your suitability for employment; to act as your agent; to
          process your application on behalf of Institutions; to register for conferences and
          special events; or any other reason allowed at law.
        </Content>
        <Content>
          Third parties: Information that may be collected by us or on our behalf via third parties
          including the date and time of your visit to our website, IP address, documents and pages
          you access, type of browser and setting, operating system, address of a recurring site you
          are about to visit; information you submit regarding payment particulars, device
          identifier, including UDID, device details, pages visited, language selections, cookies,
          tracking pixels, geographic area and location. We collect this information: to provide you
          with local information and alerts about our products and services on behalf of
          Institutions; to improve our website and services; to comply with local legal
          restrictions; to gather anonymous statistics; for analytical purposes; to ensure proper
          function of the website and online software; or any other reason allowed at law.
        </Content>
        <Title>Do not Track settings</Title>
        <Content>
          For some (but not all) services operated by us, the Do Not Track browser setting is
          accepted (which can be adjusted in your browser). Because a uniform technological standard
          has not yet been developed for Do Not Track, we do not currently respond to all Do Not
          Track signals. We continue to review new technologies and may adopt a standard once one is
          created.
        </Content>
        <Title>Storing your information</Title>
        <Content>
          We are a growing online business. In order to offer a consistent service to you we may
          store and manage data electronically or in paper form. Where data is stored
          electronically, it is done so by a third party cloud service provider that may store your
          Personal Information or a backup of your Personal Information in Australia, the United
          States of America or such other locations that the third party cloud service provider
          determines from time to time (see our list of third parties here for jurisdictions where
          your informed may be stored). The data that we collect from you may be transferred to, and
          stored to these servers or processed by staff operating in other countries, who work for
          us or an Institution.
        </Content>
        <Content>
          We will take all steps reasonably necessary to ensure that your information is secured
          from misuse, interference, loss, unauthorised access, unauthorised modification or
          unauthorised disclosure. Any Personal Information will be handled in accordance with this
          Policy and applicable privacy laws. Despite using all steps reasonably necessary, the
          transmission of information through the internet is not completely secure.
        </Content>
        <Content>
          Submission of any information to us is an acknowledgement that you agree to such use,
          storage and disclosure.
        </Content>
        <Title>Disclosing your information</Title>
        <Content>We may share your information with:</Content>
        <List>any and all of our affiliates</List>
        <List>
          third parties including Institutions, business partners, suppliers and subcontractors
        </List>
        <List>any prospective buyer of any part of our business or assets</List>
        <List>
          where we are required to disclose your information in order to comply with any legal
          obligation, or in order to enforce any agreements; or to protect the rights, property, or
          safety of us and our customers, or others. This includes, where relevant, exchanging
          information with Organisations for the purposes of fraud protection and credit risk
          reduction.
        </List>
        <Title>International data transfers</Title>
        <Content>
          We collect and store Personal Information globally from each jurisdiction we operate in
          and from each legal entity that is owned or operated by us in different international
          jurisdictions and may transfer, process and store your Personal Information outside of
          your country of residence, to wherever we or our third-party service providers operate for
          the purpose of providing you the Suite of Products and services provided by those Suite of
          Products. We have appointed Solvitude Pty. Ltd. to control and deal with all Personal
          Information that is collected globally by the Solvitude Group.
        </Content>
        <Title>Security</Title>
        <Content>
          Information we maintain about you is stored in a secure manner. We use security features
          to collect the information you provide to us via our Website in a secure manner. However,
          if you send us an email, that type of transmission of information is not secure, and is
          traveling through the Internet unprotected and can be intercepted. The security of your
          personal information is important to us. When you enter sensitive information on our
          registration or profile forms, we encrypt that information using secure socket layer
          technology (SSL). While perfect security on the Internet is impossible, we maintain
          physical, electronic, and procedural safeguards to protect the information you provide to
          us. We restrict access to this information to employees with a need to know this
          information to provide you with the benefit, assistance or service you request or would
          like to obtain, and to those employees who need the information to do their jobs.
        </Content>
        <Title>Accessing and correcting your information</Title>
        <Content>
          You may request access to Personal Information that we hold about you at any time by
          contacting our Privacy Officer using the details set out in this Policy. We will respond
          to any such request for access to Personal Information within a reasonable time frame and
          will provide you access to the Personal Information that we hold pertaining to you, unless
          we are authorised not to do so by law.
        </Content>
        <Content>
          Where permitted by law, we may charge you a reasonable fee for processing your request to
          access your Personal Information and should we decline you access to your Personal
          Information, a written explanation will be provided setting out the legal reasoning for
          doing so.
        </Content>
        <Content>
          If upon receiving your Personal Information, or at any other time, you believe the
          Personal Information that we hold about you is incorrect, out of date, incomplete,
          irrelevant or misleading, please notify our Privacy Officer using the details set out in
          this Policy.
        </Content>
        <Content>
          If we decline to correct your Personal Information as requested by you, a reason for
          refusal will be provided except to the extent that it is unreasonable to do so. In the
          event that we decline the request to correct Personal Information, you may request to
          associate a statement with the information.
        </Content>
        <Title>Your Privacy Choices</Title>
        <Content>
          We provide you the opportunity to ‘opt-out’ of having your personally identifiable
          information used for certain purposes, when we ask for this information. If you prefer
          that we not share information you have provided to us with third parties, you may opt out
          of some of these types of disclosures.
        </Content>
        <Content>
          If you no longer wish to receive our newsletter communications, you may opt-out of
          receiving email communication from us by following the instructions included in each
          newsletter or communication or by emailing us (refer to “unsubscribe” section below).
        </Content>
        <Content>
          In addition, to tell us not to share information you can also write to us at the following
          address: <a href="mailto:someone@example.com">admin@solvitude.com.au</a>
        </Content>
        <Title>Complaints</Title>
        <Content>
          Should you believe that we have not fulfilled our obligations under any relevant law or
          have not complied with the terms of this Policy or would like to appeal a decision made by
          us in relation to your Personal Information, you can make a complaint in writing to our
          Privacy Officer using the contact details set out in this Policy.
        </Content>
        <Content>
          We will respond to you within a reasonable period of time (or where a period is specified
          by any law, that period) to acknowledge your complaint and inform you of the next steps we
          will take in dealing with your complaint.
        </Content>
        <Title>Our external provider’s privacy policies</Title>
        <Content>
          We provide our Products & Services to various Institutions. These Products & Services have
          been specifically designed to appear as the relevant Institution, however, your
          interactions when using these Products & Services is with us and can be confirmed where
          the URL is: solvitude.com.au or designates ‘Solvitude’ as the publisher or seller in the
          relevant app store; or the terms with the relevant Institution app provide that the
          service is provided by a member of the Solvitude Group.
        </Content>
        <Content>
          We ‘hold’ your Personal Information on behalf of the Institution in which you are dealing
          with.
        </Content>
        <Content>
          Accordingly, where you are dealing with an Institution and you are concerned that there
          may have been a breach of this Policy by an independent third party associated with us
          and/or an Institution, please contact the relevant entity directly. Alternatively, you may
          contact the Privacy Officer at the details set out in this Policy.
        </Content>
        <Content>
          Primary Purpose: The primary purpose for which your Personal Information is collected by
          us:
        </Content>
        <List>
          is to facilitate various services on behalf of an Institution to you, including delivering
          any number of services in the Suite of Products
        </List>
        <List>for the Institution to communicate with you in a secure manner</List>
        <List>for you to make payments to an Institution</List>
        <List>for an Institution to market to you</List>
        <List>
          for an Institution to organise and store your Personal Information in a consistent manner
          for the Institution to better provide its services to you or a family member of yours
        </List>
        <List>
          and in any manner described or disclosed in the relevant privacy policy of an Institution.
        </List>
        <Content>
          (Secondary Purpose): The secondary purpose in which we will use your Personal Information,
          includes (but is not limited to):
        </Content>
        <List>providing analytics services, of any kind, to Institutions</List>
        <List>to provide any entity in the Solvitude Group</List>
        <List>
          disclosure is required or authorised by or under an Australian law or a court/tribunal
          order
        </List>
        <List>
          a permitted general situation exists in relation to the use or disclosure of the Personal
          Information by us
        </List>
        <List>
          and we reasonably believe that the use or disclosure of your Personal Information is
          necessary for one or more enforcement related activities conducted by, or on behalf of, an
          enforcement body
        </List>
        <Title>Unsubscribe</Title>
        <Content>
          To stop receiving future communications from us, please send us an email at{" "}
          <a href="mailto:someone@example.com">admin@solvitude.com.au</a>
        </Content>
        <Title>Changes in Privacy Policy</Title>
        <Content>
          If we decide to change our privacy policy, we will post those changes to this privacy
          statement, the home page, and other places we deem appropriate so that you are aware of
          what information we collect, how we use it, and under what circumstances, if any, we
          disclose it.
        </Content>
        <Content>
          The notice will tell you that we’ve changed our Privacy Policy and it will make available
          the prior Privacy Policy that applied (including, the dates that it applied). In addition,
          the updated Privacy Policy will list a new “Effective Date.” If you continue to use our
          website (as described above, i.e., when you: (1) access or use our Website or any of the
          web pages of our website, (2) agree to receive e-mail from us and/or (3) use any of
          Solvitude’s hosted online solutions, you are agreeing to be covered by the updated Privacy
          Policy.
        </Content>
        <Content>
          We reserve the right to modify this privacy statement at any time, so please review it
          frequently to keep you updated of the change.
        </Content>
        <Title>Minors</Title>
        <Content>
          Our website and Suite of Products are not intended for Data Subjects under the age of 16.
          However, due to the nature of the Suite of Products facilitating the enrolment and
          communication of minors that attend an Institution with their respect parents, you may
          submit Personal Information to us on behalf of a Data Subject under the age of 16. In such
          circumstances, we will seek your express consent to this submission and use of Personal
          Information of the Data Subject no yet 16 years of age
        </Content>
        <Title>Contact</Title>
        <Content>
          Your information, irrespective of which entity you submitted data to or contracted with,
          is controlled by Solvitude Pty Ltd and this entity has been appointed to deal with all
          privacy inquires on behalf of the Solvitude Group.
        </Content>
        <Content>
          If you have any comments, concerns or questions regarding this Policy or Personal
          Information that we hold about you, please contact our Privacy Officer by email{" "}
          <a href="mailto:someone@example.com">admin@solvitude.com.au</a>.
        </Content>
        <Content>
          Please note that where you have submitted any Personal Information to us via any
          Institution and contact us in accordance with this Policy, we will disclose and provide
          that request to the relevant Institution for their action and instruction. We suggest you
          contact the Institution to resolve any matter related to your Personal Information before
          contacting us.
        </Content>
      </Box>
    </Box>
  );
}

export default PrivacyPolicy;
