import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import TextField from "@material-ui/core/TextField";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Modal from "@material-ui/core/Modal";
import FormLabel from "@material-ui/core/FormLabel";
import Alert from "@material-ui/lab/Alert";
import AlertTitle from "@material-ui/lab/AlertTitle";
import GenericButton from "../../components/GenericButton";
import LoadingScreen from "../../components/LoadingScreen";
import MaterialTable from "material-table";
import {
  sendUserRoles,
  getUserRoles,
  disableUser,
  enableUser,
  resendInvite,
  deleteUser,
} from "../../api";
import React, { useState, useEffect } from "react";
const options = [
  {
    id: "1",
    value: "Org_Admin",
    label: "Admin",
  },
  {
    id: "2",
    value: "Org_User",
    label: "User",
  },
];

const initialState = [
  {
    firstName: "",
    lastName: "",
    email: "",
    role: "",
    firstNameError: "",
    lastNameError: "",
    emailError: "",
    roleError: "",
  },
];

const deleteUserInitial = {
  email: "",
  role: "",
  fullName: "",
  status: "",
  userId: "",
};

function AddUsers(props: any) {
  console.log("Add User Props: ");
  console.log(props);
  const [userList, setUserList] = useState([
    {
      email: "",
      role: "",
      fullName: "",
      status: "",
      userId: "",
    },
  ]);
  const [formData, setFormData] = useState(initialState);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState("");
  const [tab, setTab] = useState(0);
  const [success, setSuccess] = useState("");
  const [enableSave, setEnableSave] = useState(true);
  const [openInviteUserModal, setInviteUserModal] = useState(false);
  const [openDeleteUserModal, setDeleteUserModal] = useState(false);
  const [deleteUserData, setdeleteUserData] = useState(deleteUserInitial);
  const [status, setStatus] = useState("Active");

  const smallScreen = useMediaQuery((theme: any) =>
    theme.breakpoints.between(0, "xs")
  );
  const paper = {
    position: "absolute" as "absolute",
    left: smallScreen ? "37%" : "50%",
    top: "45%",
    transform: "translate(-50%, -50%)",
    width: "50%",
    backgroundColor: "#FFF",
    border: "0px solid #000",
    borderRadius: "15px",
    padding: "10px 30px 30px 30px",
  };

  useEffect(() => {
    const userStatus = "Active";
    getUserRoles(String(localStorage.getItem("campusId")), userStatus)
      .then((res: any) => {
        console.log("res:");
        console.log(res);
        const arr = res.data.userList;
        const newArr = arr.map(
          ({
            email,
            role,
            fullName,
            status,
            userId,
          }: {
            email: any;
            role: any;
            fullName: any;
            status: any;
            userId: any;
          }) => {
            return {
              email,
              role,
              fullName,
              status,
              userId,
            };
          }
        );

        console.log("createUser");
        console.log("newArr");
        console.log(newArr);
        setUserList(newArr);
        setLoading(false);
        setStatus("Active");
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  }, []);

  const getActiveUsers = () => {
    setLoading(true);
    const userStatus = "Active";
    getUserRoles(String(localStorage.getItem("campusId")), userStatus)
      .then((res: any) => {
        console.log("res:");
        console.log(res);
        const arr = res.data.userList;
        const newArr = arr.map(
          ({
            email,
            role,
            fullName,
            status,
            userId,
          }: {
            email: any;
            role: any;
            fullName: any;
            status: any;
            userId: any;
          }) => {
            return {
              email,
              role,
              fullName,
              status,
              userId,
            };
          }
        );
        console.log("newArr");
        console.log(newArr);
        setUserList(newArr);
        setLoading(false);
        setStatus("Active");
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  };

  const getInactiveUsers = () => {
    setLoading(true);
    const userStatus = "Inactive";
    getUserRoles(String(localStorage.getItem("campusId")), userStatus)
      .then((res: any) => {
        console.log("res:");
        console.log(res);
        const arr = res.data.userList;
        const newArr = arr.map(
          ({
            email,
            role,
            fullName,
            status,
            userId,
          }: {
            email: any;
            role: any;
            fullName: any;
            status: any;
            userId: any;
          }) => {
            return {
              email,
              role,
              fullName,
              status,
              userId,
            };
          }
        );
        console.log("newArr");
        console.log(newArr);
        setUserList(newArr);
        setLoading(false);
        setStatus("Inactive");
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  };

const getInvitedUsers = async () => {
  setLoading(true);
  const userStatus = "Invited";
  const campusId = String(localStorage.getItem("campusId"));
  await getUserRoles(campusId, userStatus)

      .then((res: any) => {
        console.log("res:");
        console.log(res);
        const arr = res.data.userList;
        const newArr = arr.map(
          ({
            email,
            role,
            fullName,
            status,
            userId,
          }: {
            email: any;
            role: any;
            fullName: any;
            status: any;
            userId: any;
          }) => {
            return {
              email,
              role,
              fullName,
              status,
              userId,
            };
          }
        );
        console.log("newArr");
        console.log(newArr);
        setUserList(newArr);
        setLoading(false);
        setStatus("Invited");
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  };

  function TabPanel(props: any) {
    const { children, value, index } = props;
    return <div>{value === index && <>{children}</>}</div>;
  }

  const handleFormChange = (e: any) => {
    const { name, value } = e.target;

    if (name === "email") {
      const pattern = new RegExp(
        /^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i
      );
      const temp: any = [...formData];
      if (pattern.test(value)) temp[0][name + "Error"] = "";
      else temp[0][name + "Error"] = "Error";
      setFormData(temp);
    }

    if (name === "firstName") {
      const pattern = new RegExp(/^[a-zA-Z\s]+$/);
      const temp: any = [...formData];
      if (pattern.test(value) || value === "") temp[0][name + "Error"] = "";
      else temp[0][name + "Error"] = "Error";
      setFormData(temp);
    }

    if (name === "lastName") {
      const pattern = new RegExp(/^[a-zA-Z\s]+$/);
      const temp: any = [...formData];
      if (pattern.test(value) || value === "") temp[0][name + "Error"] = "";
      else temp[0][name + "Error"] = "Error";
      setFormData(temp);
    }

    if (name === "role") {
      const temp: any = [...formData];
      if (value === "") temp[0][name + "Error"] = "Error";
      else temp[0][name + "Error"] = "";
      setFormData(temp);
    }

    const list: any = [...formData];
    list[0][name] = value;
    setFormData(list);

    const validateData: any = formData;
    let invalid = false;
    validateData.forEach((element: any) => {
      if (
        element.email === "" ||
        element.role === "" ||
        element.emailError !== "" ||
        element.roleError !== "" ||
        element.firstNameError !== "" ||
        element.lastNameError !== ""
      ) {
        invalid = true;
      } else invalid = false;
    });
    setEnableSave(invalid);
  };

  const handleAddUserClick = () => {
    setFormData([
      {
        firstName: "",
        lastName: "",
        email: "",
        role: "",
        firstNameError: "",
        lastNameError: "",
        emailError: "",
        roleError: "",
      },
    ]);
    setError("");
    setSuccess("");
    setInviteUserModal(true);
  };

  const handleTabs = (e: any, value: any) => {
    setTab(value);
  };

  const handleSave = () => {
    setLoading(true);
    const finalData = formData.map(
      ({
        email,
        role,
        firstName,
        lastName,
      }: {
        email: any;
        role: any;
        firstName: any;
        lastName: any;
      }) => {
        return {
          email,
          role,
          firstName,
          lastName,
        };
      }
    );

    const data = {
      campusId: localStorage.getItem("campusId"),
      orgId: localStorage.getItem("orgId"),
      listOfUsers: finalData,
    };
    const requestType = "CREATE";
    sendUserRoles(data, requestType)
      .then((res) => {
        const userStatus = status;

        getUserRoles(String(localStorage.getItem("campusId")), userStatus)
          .then((res: any) => {
            console.log("res:");
            console.log(res);
            const arr = res.data.userList;
            const newArr = arr.map(
              ({
                email,
                role,
                fullName,
                status,
                userId,
              }: {
                email: any;
                role: any;
                fullName: any;
                status: any;
                userId: any;
              }) => {
                return {
                  email,
                  role,
                  fullName,
                  status,
                  userId,
                };
              }
            );
            setUserList(newArr);
            props.updateProfile("userInvite");
            setLoading(false);
            setInviteUserModal(false);
            setSuccess("User Added Successfully.");
            setFormData(initialState);
          })
          .catch((err) => {
            console.log(err);
            setLoading(false);
            setInviteUserModal(false);
          });
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
        setInviteUserModal(false);
        setError("Couldn't invite user, please try again later.");
      });
  };

  const handleUpdate = (email: any, role: any) => {
    setLoading(true);
    setError("");
    setSuccess("");
    const newData = [{ email: email, role: role }];
    const data = {
      campusId: localStorage.getItem("campusId"),
      listOfUsers: newData,
    };
    const requestType = "UPDATE";
    sendUserRoles(data, requestType)
      .then((res) => {
        const userStatus = "Active";

        getUserRoles(String(localStorage.getItem("campusId")), userStatus)
          .then((res: any) => {
            console.log("res:");
            console.log(res);
            const arr = res.data.userList;
            const newArr = arr.map(
              ({
                email,
                role,
                fullName,
                status,
                userId,
              }: {
                email: any;
                role: any;
                fullName: any;
                status: any;
                userId: any;
              }) => {
                return {
                  email,
                  role,
                  fullName,
                  status,
                  userId,
                };
              }
            );
            setUserList(newArr);
            setLoading(false);
            setSuccess("User Updated Successfully.");
          })
          .catch((err) => {
            console.log(err);
            setLoading(false);
          });
      })
      .catch((err) => {
        console.log("err");
        console.log(err);
        setError("Couldn't update user, please try again later.");
        console.log("serr");
        console.log(error);
        setLoading(false);
      });
  };

  const disableUserUI = (rowData: any) => {
    setLoading(true);
    setError("");
    setSuccess("");
    const userId = rowData.userId;
    console.log(userId);
    const data = {
      campusId: localStorage.getItem("campusId"),
    };
    disableUser(userId, data)
      .then((res) => {
        const userStatus = "Active";
        getUserRoles(String(localStorage.getItem("campusId")), userStatus)
          .then((res: any) => {
            const arr = res.data.userList;
            const newArr = arr.map(
              ({
                email,
                role,
                fullName,
                status,
                userId,
              }: {
                email: any;
                role: any;
                fullName: any;
                status: any;
                userId: any;
              }) => {
                return {
                  email,
                  role,
                  fullName,
                  status,
                  userId,
                };
              }
            );
            setUserList(newArr);
            setLoading(false);
            setSuccess("User Disabled Successfully.");
          })
          .catch((err) => {
            console.log(err);
            setLoading(false);
          });
      })
      .catch((err) => {
        console.log("err");
        console.log(err);
        setError("Couldn't disable user, please try again later.");
        console.log("serr");
        console.log(error);
        setLoading(false);
      });
  };

  const handleDelete = (rowData: any) => {
    setError("");
    setSuccess("");
    setdeleteUserData({
      email: rowData.email,
      fullName: rowData.fullName,
      userId: rowData.userId,
      role: rowData.role,
      status: rowData.status
    });
    setDeleteUserModal(true);
  }

  const deleteUserUI = () => {
    setLoading(true);
    setError("");
    setSuccess("");
    const userId = deleteUserData.userId;
    console.log(userId);
    deleteUser(userId)
      .then((res) => {
        const userStatus = "Invited";
        getUserRoles(String(localStorage.getItem("campusId")), userStatus)
          .then((res: any) => {
            const arr = res.data.userList;
            const newArr = arr.map(
              ({
                email,
                role,
                fullName,
                status,
                userId,
              }: {
                email: any;
                role: any;
                fullName: any;
                status: any;
                userId: any;
              }) => {
                return {
                  email,
                  role,
                  fullName,
                  status,
                  userId,
                };
              }
            );
            setUserList(newArr);
            setLoading(false);
            setDeleteUserModal(false);
            setdeleteUserData(deleteUserInitial);
            setSuccess("User Deleted Successfully.");
          })
          .catch((err) => {
            console.log(err);
            setLoading(false);
            setDeleteUserModal(false);
            setdeleteUserData(deleteUserInitial);
          });
      })
      .catch((err) => {
        console.log("err");
        console.log(err);
        setError("Couldn't delete user, please try again later.");
        console.log("serr");
        console.log(error);
        setLoading(false);
        setDeleteUserModal(false);
        setdeleteUserData(deleteUserInitial);
      });
  };

  const enableUserUI = (rowData: any) => {
    setLoading(true);
    setError("");
    setSuccess("");
    const userId = rowData.userId;
    console.log(userId);
    const data = {
      campusId: localStorage.getItem("campusId"),
    };
    enableUser(userId, data)
      .then((res) => {
        const userStatus = "Inactive";
        getUserRoles(String(localStorage.getItem("campusId")), userStatus)
          .then((res: any) => {
            const arr = res.data.userList;
            const newArr = arr.map(
              ({
                email,
                role,
                fullName,
                status,
                userId,
              }: {
                email: any;
                role: any;
                fullName: any;
                status: any;
                userId: any;
              }) => {
                return {
                  email,
                  role,
                  fullName,
                  status,
                  userId,
                };
              }
            );
            setUserList(newArr);
            setLoading(false);
            setSuccess("User Enabled Successfully.");
          })
          .catch((err) => {
            console.log(err);
            setLoading(false);
          });
      })
      .catch((err) => {
        console.log("err");
        console.log(err);
        setError("Couldn't enable user, please try again later.");
        console.log("serr");
        console.log(error);
        setLoading(false);
      });
  };

  const resendInviteUI = (rowData: any) => {
    setLoading(true);
    setError("");
    setSuccess("");
    const email = rowData.email;
    console.log(email);
    const data = {
      campusId: localStorage.getItem("campusId"),
      email: email,
    };
    resendInvite(data)
      .then((res) => {
        setSuccess("Email Sent Successfully.");
        setLoading(false);
      })
      .catch((err) => {
        console.log("err");
        console.log(err);
        setError("Couldn't send email, please try again later.");
        console.log("serr");
        console.log(error);
        setLoading(false);
      });
  };

  const identifyUser = () => {
    if (props.role === "Org_User") {
      const editableRole: "always" | "onUpdate" | "onAdd" | "never" = "never";
      return editableRole;
    } else {
      const editableRole: "always" | "onUpdate" | "onAdd" | "never" =
        "onUpdate";
      return editableRole;
    }
  };
  const never: "always" | "onUpdate" | "onAdd" | "never" = "never";

  const columns = [
    {
      title: "User Name",
      field: "fullName",
      editable: never,
      emptyValue: () => <>NA</>,
      cellStyle: { width: "30%" },
    },
    {
      title: "Email",
      field: "email",
      editable: never,
      cellStyle: { width: "30%" },
    },
    {
      title: "Role",
      field: "role",
      lookup: { Org_User: "User", Org_Admin: "Admin" },
      editable: identifyUser(),
      cellStyle: { width: "30%" },
    },
  ];

  const tableData = userList;

  return (
    <>
      {error !== "" && (
        <Box marginBottom="25px">
          <Alert
            onClose={() => {
              setError("");
            }}
            severity="error"
          >
            <AlertTitle>Unsuccessful</AlertTitle>
            <strong>{error}</strong>
          </Alert>
        </Box>
      )}

      {success !== "" && (
        <Box marginBottom="25px">
          <Alert
            onClose={() => {
              setError("");
              setSuccess("");
            }}
            severity="success"
          >
            <AlertTitle>Successful</AlertTitle>
            <strong>{success}</strong>
          </Alert>
        </Box>
      )}
      {loading ? <LoadingScreen /> : null}
      <Modal open={openInviteUserModal}>
        <div style={paper}>
          <h2>Invite New User</h2>
          <hr />
          <Typography
            variant="subtitle2"
            style={{
              fontSize: "16px",
              marginBottom: "25px",
              marginTop: "25px",
            }}
          >
            Enter the user’s details below. We will notify them with a sign up
            link on their given email address and mobile number.
          </Typography>
          <Grid container spacing={2} style={{ marginRight: "8px" }}>
            <Grid item md={6} sm={6} xs={12}>
              <FormLabel>First Name</FormLabel>
              <TextField
                variant="outlined"
                style={{ marginTop: "8px", width: "100%" }}
                size="small"
                name={"firstName"}
                value={formData[0].firstName}
                label={"First Name"}
                error={!!formData[0].firstNameError}
                helperText={
                  formData[0].firstNameError
                    ? "First Name can only contain letters"
                    : ""
                }
                onChange={(e) => {
                  handleFormChange(e);
                }}
              />
            </Grid>
            <Grid item md={6} sm={6} xs={12}>
              <FormLabel>Last Name</FormLabel>
              <TextField
                variant="outlined"
                style={{ marginTop: "8px", width: "100%" }}
                size="small"
                name={"lastName"}
                value={formData[0].lastName}
                label={"Last Name"}
                error={!!formData[0].lastNameError}
                helperText={
                  formData[0].lastNameError
                    ? "Last Name can only contain letters"
                    : ""
                }
                onChange={(e) => {
                  handleFormChange(e);
                }}
              />
            </Grid>
          </Grid>
          <Grid
            container
            spacing={2}
            style={{ marginTop: "25px", marginRight: "8px" }}
          >
            <Grid item md={6} sm={6} xs={12}>
              <FormLabel>Email*</FormLabel>
              <TextField
                variant="outlined"
                style={{ marginTop: "8px", width: "100%" }}
                size="small"
                name={"email"}
                value={formData[0].email}
                label={"Email"}
                error={!!formData[0].emailError}
                helperText={
                  formData[0].emailError ? "Please insert a Valid email" : ""
                }
                onChange={(e) => {
                  handleFormChange(e);
                }}
              />
            </Grid>
            <Grid item md={6} sm={6} xs={12}>
              <FormLabel>Role*</FormLabel>
              <TextField
                select
                variant="outlined"
                style={{ marginTop: "8px", width: "100%" }}
                size="small"
                name={"role"}
                value={formData[0].role}
                label={"Select role"}
                error={!!formData[0].roleError}
                helperText={formData[0].roleError ? "Please select Role" : ""}
                onChange={(e) => handleFormChange(e)}
                onBlur={(e) => handleFormChange(e)}
                SelectProps={{
                  native: true,
                }}
              >
                <>
                  {formData[0].role === "" && <option />}
                  {options.map((option: any) => {
                    return (
                      <option
                        id={option.Id}
                        key={option.value}
                        value={option.value}
                      >
                        {option.label}
                      </option>
                    );
                  })}
                </>
              </TextField>
            </Grid>
          </Grid>
          <Grid container spacing={2} style={{ marginTop: "25px" }}>
            <Grid item xs="auto">
              <GenericButton
                id="save"
                variant="contained"
                onClick={handleSave}
                background="blue"
                disabled={enableSave}
              >
                Save
              </GenericButton>
            </Grid>
            <Grid item xs="auto">
              <GenericButton
                id="cancel"
                variant="contained"
                onClick={() => setInviteUserModal(false)}
                background="white"
              >
                Cancel
              </GenericButton>
            </Grid>
          </Grid>
        </div>
      </Modal>
      <Modal open={openDeleteUserModal}>
        <div style={paper}>
          <h2>Delete User</h2>
          <hr />
          <Typography
            variant="subtitle2"
            style={{
              fontSize: "16px",
              marginBottom: "25px",
              marginTop: "25px",
            }}
          >
            Are you sure you want to delete '{deleteUserData.fullName}' user?
          </Typography>
          <Grid container spacing={2} style={{ marginTop: "25px" }}>
            <Grid item xs="auto">
              <GenericButton
                id="delete"
                variant="contained"
                onClick={deleteUserUI}
                background="blue"
              >
                Delete
              </GenericButton>
            </Grid>
            <Grid item xs="auto">
              <GenericButton
                id="cancel"
                variant="contained"
                onClick={() => setDeleteUserModal(false)}
                background="white"
              >
                Cancel
              </GenericButton>
            </Grid>
          </Grid>
        </div>
      </Modal>
      {props.role === "Org_Admin" &&
      props.features?.features?.maxUsers > props.numOfActiveUsers ? (
        <Grid container direction={"row-reverse"} spacing={2}>
          <Grid item xs="auto">
            <GenericButton
              id="add"
              variant="contained"
              onClick={handleAddUserClick}
              background="blue"
            >
              + New User
            </GenericButton>
          </Grid>
        </Grid>
      ) : null}
      <Box style={{ borderBottom: 1, borderColor: "divider" }}>
        <Tabs value={tab} onChange={handleTabs}>
          <Tab label="Active Users" onClick={getActiveUsers} />
          <Tab label="Disabled Users" onClick={getInactiveUsers} />
          <Tab label="Invitations" onClick={getInvitedUsers} />
        </Tabs>
      </Box>

      <TabPanel value={tab} index={0}>
        <MaterialTable
          style={{ paddingTop: "20px" }}
          columns={columns}
          data={tableData}
          editable={{
            isEditable: (rowData) => rowData.email !== props.details.email,
            isEditHidden: () => props.role === "Org_User",
            onRowUpdate: (newRow, oldRow) =>
              new Promise((resolve, reject) => {
                if (newRow.role === oldRow?.role) {
                  setSuccess("");
                  setError("No change recorded");
                  reject("");
                } else {
                  handleUpdate(oldRow?.email, newRow.role);
                  resolve("");
                }
              }),
          }}
          localization={{
            header: {
              actions: "",
            },
          }}
          actions={[
            (rowData) => ({
              disabled: rowData.email === props.details.email,
              hidden: props.role === "Org_User",
              icon: () => (
                <button
                  style={{
                    backgroundColor:
                      rowData.email === props.details.email
                        ? "#BDBDBD"
                        : "#009AAA",
                    color: "#ffffff",
                    border: "none",
                    borderRadius: "50px",
                    height: "30px",
                    padding: "10px",
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  Disable
                </button>
              ),
              tooltip: "Disbale this user",
              onClick: (e, rowData) => {
                disableUserUI(rowData);
              },
            }),
          ]}
          options={{
            sorting: false,
            searchFieldAlignment: "left",
            searchFieldVariant: "outlined",
            searchFieldStyle: {
              width: "auto",
              borderRadius: "25px",
              height: 20,
              backgroundColor: "#f5f5f5",
            },
            showTitle: false,
            paging: false,
            actionsColumnIndex: -1,
            actionsCellStyle: {
              paddingRight: "40px",
              width: "10%",
            },
            minBodyHeight: "40vh",
            maxBodyHeight: "40vh",
          }}
        />
      </TabPanel>
      <TabPanel value={tab} index={1}>
        <MaterialTable
          columns={columns}
          data={tableData}
          localization={{
            header: {
              actions: "",
            },
          }}
          actions={[
            (rowData) => ({
              disabled: rowData.email === props.details.email,
              hidden: props.role === "Org_User",
              icon: () => (
                <button
                  style={{
                    backgroundColor: "#009AAA",
                    color: "#ffffff",
                    border: "none",
                    borderRadius: "50px",
                    height: "30px",
                    padding: "10px",
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  Enable
                </button>
              ),
              tooltip: "Enable this user",
              onClick: (e, rowData) => {
                enableUserUI(rowData);
              },
            }),
          ]}
          options={{
            sorting: false,
            searchFieldAlignment: "left",
            searchFieldVariant: "outlined",
            searchFieldStyle: {
              width: "auto",
              borderRadius: "25px",
              height: 20,
              backgroundColor: "#f5f5f5",
            },
            showTitle: false,
            paging: false,
            actionsColumnIndex: -1,
            actionsCellStyle: { paddingRight: "40px" },
            minBodyHeight: "40vh",
            maxBodyHeight: "40vh",
          }}
        />
      </TabPanel>
      <TabPanel value={tab} index={2}>
        <MaterialTable
          columns={columns}
          data={tableData}
          actions={[
            (rowData) => ({
              disabled: rowData.email === props.details.email,
              hidden: props.role === "Org_User",
              icon: () => (
                <button
                  style={{
                    backgroundColor: "#009AAA",
                    color: "#ffffff",
                    border: "none",
                    borderRadius: "50px",
                    height: "30px",
                    padding: "10px",
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  Resend Invite
                </button>
              ),
              tooltip: "Resend invitation email",
              onClick: (e, rowData) => {
                resendInviteUI(rowData);
              },
            }),
            (rowData) => ({
              disabled: rowData.email === props.details.email,
              hidden: props.role === "Org_User",
              icon: 'delete',
              tooltip: "Delete User",
              onClick: (e, rowData) => {
                handleDelete(rowData);
              },
            }),
          ]}
          localization={{
            header: {
              actions: "",
            },
          }}
          options={{
            sorting: false,
            searchFieldAlignment: "left",
            searchFieldVariant: "outlined",
            searchFieldStyle: {
              width: "auto",
              borderRadius: "25px",
              height: 20,
              backgroundColor: "#f5f5f5",
            },
            showTitle: false,
            paging: false,
            actionsColumnIndex: -1,
            actionsCellStyle: { paddingRight: "40px" },
            minBodyHeight: "40vh",
            maxBodyHeight: "40vh",
          }}
        />
      </TabPanel>

      {}
    </>
  );
}

export default AddUsers;
