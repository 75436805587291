const config = {
  uri: {
    digitalServicesURI: "http://localhost:3030/local-application",
    backendURI: "http://localhost:8000/local-backend",
    userManagementURI: "http://localhost:3032/local-student",
    docManagementURI: "http://localhost:3033/local-docmgmt",
    orgManagementURI: " http://localhost:3031/local-organisation",
  },
  authConfig: {
    url: `https://dep-dev-ng.auth.ap-southeast-2.amazoncognito.com/oauth2/token`,
    authorization: `Basic MXJqOGM4NGRrMGVmaGdnZmJlc2xvbjdpYjQ6ZjRvZGZwNGVxNzhoNHVnbDF0dTJvYmFmN2twanZoYmk5Y25jcmdxdTFzM3Nhb3Zxdmp0`,
    contentType: `application/x-www-form-urlencoded`,
    scope: {
      Parent: `https://dep-dev.solvitude.com.au/appfee.write https://dep-dev.solvitude.com.au/getCampusDetails.read https://dep-dev.solvitude.com.au/getAddress.read https://dep-dev.solvitude.com.au/logUIErrors.write https://dep-dev.solvitude.com.au/createUser.write https://dep-dev.solvitude.com.au/getAllOrgCampus.read https://dep-dev.solvitude.com.au/fetchCustomerApplications.read https://dep-dev.solvitude.com.au/getParentChildren.read https://dep-dev.solvitude.com.au/createApplication.write https://dep-dev.solvitude.com.au/updateUser.write https://dep-dev.solvitude.com.au/fetchApplicationDetails.read https://dep-dev.solvitude.com.au/updateApplication.write https://dep-dev.solvitude.com.au/formSubmission.write https://dep-dev.solvitude.com.au/getParentUser.read https://dep-dev.solvitude.com.au/changePassword.read https://dep-dev.solvitude.com.au/getCampusUsers.read`,
      Org_Admin: `https://dep-dev.solvitude.com.au/dataSync.write https://dep-dev.solvitude.com.au/appfee.write https://dep-dev.solvitude.com.au/subscription.write https://dep-dev.solvitude.com.au/fetchCampusAnalytics.read https://dep-dev.solvitude.com.au/fetchAnalyticsApplications.read https://dep-dev.solvitude.com.au/logUIErrors.write https://dep-dev.solvitude.com.au/getCampusDetails.read https://dep-dev.solvitude.com.au/updateApplication.write https://dep-dev.solvitude.com.au/updateBankInfo.write https://dep-dev.solvitude.com.au/getParentUser.read https://dep-dev.solvitude.com.au/changePassword.read https://dep-dev.solvitude.com.au/fetchCampusApplications.read https://dep-dev.solvitude.com.au/sendStatusMail.write https://dep-dev.solvitude.com.au/addInvitedUsers.write https://dep-dev.solvitude.com.au/getCampusUsers.read https://dep-dev.solvitude.com.au/disableUser.write https://dep-dev.solvitude.com.au/enableUser.write https://dep-dev.solvitude.com.au/resendInviteMail.write`,
      Org_User: `https://dep-dev.solvitude.com.au/dataSync.write https://dep-dev.solvitude.com.au/fetchCampusAnalytics.read https://dep-dev.solvitude.com.au/fetchAnalyticsApplications.read https://dep-dev.solvitude.com.au/logUIErrors.write https://dep-dev.solvitude.com.au/getParentUser.read https://dep-dev.solvitude.com.au/changePassword.read https://dep-dev.solvitude.com.au/fetchCampusApplications.read https://dep-dev.solvitude.com.au/getCampusUsers.read`,
      AnonyParent: `https://dep-dev.solvitude.com.au/logUIErrors.write https://dep-dev.solvitude.com.au/createUser.write`,
      AnonyAdmin: `https://dep-dev.solvitude.com.au/subscription.write https://dep-dev.solvitude.com.au/sendAdminEmail.write https://dep-dev.solvitude.com.au/logUIErrors.write https://dep-dev.solvitude.com.au/createOrganisation.write https://dep-dev.solvitude.com.au/createCampus.write https://dep-dev.solvitude.com.au/deleteOrgCampus.write https://dep-dev.solvitude.com.au/signupAdmin.write`,
      AnonyUser: `https://dep-dev.solvitude.com.au/logUIErrors.write https://dep-dev.solvitude.com.au/confirmInvitedUser.write https://dep-dev.solvitude.com.au/updateInvitedUser.write https://dep-dev.solvitude.com.au/getInvitedUser.read`,
      AnonyReset: `https://dep-dev.solvitude.com.au/logUIErrors.write https://dep-dev.solvitude.com.au/changePassword.read`,
    },
    grantType: `client_credentials`,
  },
  s3Config: {
    region: "ap-southeast-2",
    bucket: "dep-docmgmt-dev",
  },
  pool: {
    region: "ap-southeast-2",
    userPoolId: "ap-southeast-2_1RHjoASnu",
    userPoolWebClientId: "phoeehd09rer8mamg7mfccdfh",
    mandatorySignIn: false,
  },
  isCaptchaActive: false,
  s3StaticBucket: "dep-static-content-nonprod",
  liveChatUrl: "/config/dep/livechat/local/url",
  enableLiveChat: false,
  googleAnalyticsTrackingId:"G-PYN0HX64S8",
};

export default config;
