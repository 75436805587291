import { SSMClient, GetParameterCommand } from "@aws-sdk/client-ssm";
import AWS from "aws-sdk";
import { awsConfig } from "../../config/env-config/config";
export const getSSM = async (name: string) => {
  const cred = new AWS.Credentials({
    accessKeyId: awsConfig.aws_accessKeyID,
    secretAccessKey: awsConfig.aws_secretAccessKey,
  });
  const client = new SSMClient({ credentials: cred, region: awsConfig.aws_region });
  const command = new GetParameterCommand({
    Name: name,
    WithDecryption: true,
  });
  const response = await client.send(command);
  return response.Parameter?.Value;
}
