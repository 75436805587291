import Box from "@material-ui/core/Box";
import makeStyles from "@material-ui/core/styles/makeStyles";
import Typography from "@material-ui/core/Typography";
import childMale from "../assets/icons/childMale.svg";
import childFemale from "../assets/icons/childFemale.svg";
import avatar from "../assets/icons/avatar.svg";
const useStyles = makeStyles((theme) => ({
  childDetails: {
    backgroundColor: theme.palette.background.default,
    borderRadius: "8px",
    marginBottom: theme.spacing(2),
    cursor: "pointer",
    position: "relative",
    width: "100%",
  },
}));
const calculate_age = (dob: any) => {
  var today = new Date();
  var birthDate = new Date(dob); // create a date object directly from `dob1` argument
  var age_now = today.getFullYear() - birthDate.getFullYear();
  var m = today.getMonth() - birthDate.getMonth();
  if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
    age_now--;
  }
  return age_now;
};
function ChildDetail(props: any) {
  const classes = useStyles();
  const { data } = props;
  const gender = data
    ? data.childDetails["Gender_applicant_radio"]?.value ||
      data.childDetails["Gender_child_radio"].value
    : "";
  const dateOfBirth = data
    ? data.childDetails["Date of Birth_applicant"] ||
      data.childDetails["Date of Birth_child"]
    : "";
  return (
    <Box className={classes.childDetails} padding="1.5em 1em 3em 1em">
      <Box display="flex" alignItems="center">
        <img
          src={
            props.data ? (gender === "Male" ? childMale : childFemale) : avatar
          }
          style={{ marginRight: "0.5em" }}
          alt=""
        />
        {props.data ? (
          <Box
            display="flex"
            flexDirection="column"
            justifyContent="center"
            style={{ wordBreak: "break-all" }}
          >
            <Typography variant="caption" style={{ marginBottom: "0.5em" }}>
              {data.fullName}
            </Typography>
            <Typography variant="subtitle2">
              {gender} . {calculate_age(dateOfBirth)} years old
            </Typography>
          </Box>
        ) : (
          <Typography variant="caption">New Child</Typography>
        )}
      </Box>
    </Box>
  );
}
export default ChildDetail;
