import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";
import FormControl from "@material-ui/core/FormControl";
import FormHelperText from "@material-ui/core/FormHelperText";
import FormLabel from "@material-ui/core/FormLabel";
import TextField from "@material-ui/core/TextField";
import makeStyles from "@material-ui/core/styles/makeStyles";
import FileSaver from "file-saver";
import React, { useState } from "react";
import upload from "../../assets/icons/upload.svg";
import deleteIcon from "../../assets/icons/delete.svg";
import GestureIcon from "@material-ui/icons/Gesture";
import { downloadDocument, uploadDocument } from "../../api";
import ConfirmationModal from "../ConfirmationModal";
import GenericButton from "../GenericButton";
import SignatureModal from "../SignatureModal";
const useStyles = makeStyles((theme:any) => ({
  border: {
    "& .Mui-focused .MuiOutlinedInput-notchedOutline": {
      borderColor: "black",
    },
  },
  button: {
    ...theme.typography.blueFont,
    textTransform: "none",
    height: "40px",
    borderRadius: "50px",
    border: "1px solid #009AAA",
    background: "#FFFFFF",
    marginRight: "50px",
  },
  text: {
    ...theme.typography.blueFont,
    cursor: "pointer",
    textDecoration: "underline",
  },
  error: {
    "& .MuiFormHelperText-root": {
      color: "#F9347B",
    },
  },
  blueFont: {
    ...theme.typography.blueFont,
  },
}));

function Files(props: any) {
  const classes = useStyles();
  const { id,
    label, name, value, onChange, required, error } = props;
  let fileName = "";
  let file = value.file || undefined;
  const [loading, setLoading] = React.useState(false);
  const [modalOpen, setModalOpen] = React.useState(false);
  const [signModalOpen, setSignModalOpen] = React.useState(false);
  const [uploading, setUploading] = useState(false);
  const saveSign = async (blob: any) => {
    setUploading(true);
    setSignModalOpen(false);

    const file = new File([blob], `${name.replace(/\s/g, "_")}.png`);
    await uploadDocument(file).then((res) => {
      console.log(res);
      props.uploadFileDetails(props.name, props.id, res, file);
      setUploading(false);
    });
  };
  const uploadFile = async () => {
    await uploadDocument(value.file)
      .then((res) => {
        console.log(res);
        props.uploadFileDetails(name, id, res);
        setUploading(false);
      })
      .catch((err) => console.log(err));
  };
  const downloadFile = async () => {
    setLoading(true);
    await downloadDocument(localStorage.getItem("customerId"), value.fileId)
      .then(async (res: any) => {
        console.log(res);
        file = new File(
          [new Uint8Array(res)],
          value.fileName ? value.fileName : fileName
        );
        FileSaver.saveAs(file);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  };

  if (
    Object.keys(value).length !== 0 &&
    !Object.keys(value).includes("fileId") &&
    !uploading
  ) {
    fileName = value.file.name;
    setUploading(true);
    uploadFile();
  }
  const handleDelete = () => {
    toggleDelete();
    props.deleteFile(name, id);
  };
  const toggleDelete = () => {
    setModalOpen((prev) => !prev);
  };

  return (
    <FormControl
      className={classes.error}
      variant="outlined"
      style={{ width: "100%", marginBottom: "20px" }}
      {...(required && { required: true })}
    >
      <FormLabel>{label}</FormLabel>
      <Box display="flex" alignItems="center">
        <Box display="flex" alignItems="center">
          <TextField
            id={id}
            name={name}
            onChange={onChange}
            style={{ display: "none" }}
            type="file"
            inputProps={{
              accept: [".pdf", ".jpg", ".jpeg", ".png", ".docx", ".bmp"],
            }}
            disabled={
              Object.keys(value).length !== 0 || uploading

            }
          />
          <label htmlFor={id}>
            <Button
              disabled={
                Object.keys(value).length !== 0 || uploading
              }
              className={classes.button}
              variant="contained"
              component="span"
            >
              <img src={upload} alt="" style={{ marginRight: "5px" }} />
              Upload
            </Button>
          </label>

          {}
          {uploading && (
            <CircularProgress
              style={{
                width: "20px",
                height: "20px",
                marginLeft: "10px",
                color: "#009AAA",
              }}
            />
          )}
          {Object.keys(value).length !== 0 &&
            Object.keys(value).includes("fileId") && (
              <>
                <Box
                  onClick={() => {
                    downloadFile();
                  }}
                  className={classes.text}
                  marginRight="10px"
                >
                  {value.fileName ? value.fileName : fileName}
                </Box>
                <img
                  src={deleteIcon}
                  alt=""
                  style={{ cursor: "pointer" }}
                  onClick={toggleDelete}
                />
                {loading && (
                  <CircularProgress
                    style={{
                      width: "20px",
                      height: "20px",
                      marginLeft: "10px",
                      color: "#009AAA",
                    }}
                  />
                )}
              </>
            )}
          {error !== "" && !value.file && value.fileId ? null : error !== "" ? (
            <FormHelperText className={classes.error}>{error}</FormHelperText>
          ) : null}
        </Box>
        {label.includes("Signature") &&
          !Object.keys(value).includes("fileId") &&
          Object.keys(value).length === 0 &&
          !uploading && (
            <>
              <Box marginRight="50px" className={classes.blueFont}>
                OR
              </Box>

              <Box display="flex">
                <GenericButton
                  background="white"
                  variant="contained"
                  onClick={() => setSignModalOpen(true)}
                >
                  <GestureIcon style={{ marginRight: "5px" }} /> Draw
                </GenericButton>
              </Box>
            </>
          )}
      </Box>
      {label.split(" ").includes("Photograph") ||
      label.split(" ").includes("Signature") ? (
        <Box marginTop={"7px"} color="#009AAA">
          <label>
            <i>Note:Image should be of the format ".jpeg", ".png"</i>
          </label>
        </Box>
      ) : null}

      {signModalOpen && (
        <SignatureModal
          open={signModalOpen}
          saveSign={saveSign}
          handleClose={() => setSignModalOpen(false)}
        />
      )}
      {modalOpen && (
        <ConfirmationModal
          open={modalOpen}
          handleClose={toggleDelete}
          handleDelete={handleDelete}
          title="Deleting a document"
          content={`Are you sure you want to delete ${value.fileName}`}
        />
      )}
    </FormControl>
  );
}

export default Files;
