import makeStyles from "@material-ui/core/styles/makeStyles";

const useStyles = makeStyles((theme: any) => ({
  detailsContainer: {
    background: theme.palette.background.paper,
    borderRadius: "16px",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    marginBottom: "25px",
  },
  overview: {
    background: theme.palette.background.paper,
    borderRadius: "16px",
    padding: theme.spacing(2),
    marginRight: theme.spacing(2),
    [theme.breakpoints.down("sm")]: {
      marginRight: 0,
    },
  },
  summaryTitle: {
    ...theme.typography.overviewTitle,
  },
  history: {
    ...theme.typography.confirmationTitle,
  },
  date: {
    ...theme.typography.date,
  },
  historyContainer: {
    maxHeight: "75vh",
    overflow: "auto",
  },
  main: {
    padding: "6% 6% 6% 6%",
    backgroundColor: "#F6F8FA",
    borderRadius: "18px",
    display: "flex",
    flexWrap: "wrap",
  },
  title: {
    ...theme.typography.sectionTitle,
  },
  filename: {
    ...theme.typography.blueFont,
    cursor: "pointer",
    textDecoration: "underline",
  },
  stepLabel: {
    "& .MuiStepLabel-label.MuiStepLabel-active": {
      ...theme.typography.blueLato,
    },
    "& .MuiStepLabel-label.MuiStepLabel-completed": {
      ...theme.typography.blueLato,
    },
    "& .MuiStepLabel-label": {
      ...theme.typography.blueLato,
    },
  },
}));

export default useStyles;
