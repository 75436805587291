import {useEffect} from "react";
import {Switch, Route} from "react-router-dom";
import Children from "./Children";
import Main from "../MainForm/Main";
import OrganisationList from "./OrganisarionList";
import DelayRedirect from "../../components/DelayRedirect";
import FormList from "./FormList";

function SelectPage(props: any) {
  useEffect(() => {
    window.scrollTo(0, 0);
    console.log("Index Props: ");
    console.log(props);
  });
  return (
    <Switch>
      <Route
        exact
        path="/newApplication/selectorganisation"
        render={(routerProps) => (
          <OrganisationList
            handleLogout={props.handleLogout}
            organisationList={
              Object.keys(props.geolocation).length !== 0
                ? props.organisationList.filter((org: any) => {
                    const filterCampus = org.campusList.filter(
                      (campus: any) => {
                        if (
                          props.geolocation.country ===
                          campus.additionalInfo.country
                        )
                          return campus;
                        else return null;
                      }
                    );
                    if (filterCampus.length !== 0) return filterCampus;
                    else return null;
                  })
                : props.organisationList
            }
            {...routerProps}
            updateUser={props.updateUser}
            user={props.user}
            geolocation={props.geolocation}
          />
        )}
      />
      <Route
        exact
        path="/newApplication/selectform"
        render={(routerProps) => (
          <FormList
            handleLogout={props.handleLogout}
            organisationList={
              Object.keys(props.geolocation).length !== 0
                ? props.organisationList.filter((org: any) => {
                    const filterCampus = org.campusList.filter(
                      (campus: any) => {
                        if (
                          props.geolocation.country ===
                          campus.additionalInfo.country
                        )
                          return campus;
                        else return null;
                      }
                    );
                    if (filterCampus.length !== 0) return filterCampus;
                    else return null;
                  })
                : props.organisationList
            }
            {...routerProps}
            updateUser={props.updateUser}
            user={props.user}
            geolocation={props.geolocation}
          />
        )}
      />
      <Route
        path="/newApplication/selectchild"
        render={(routerProps) => (
          <Children
            handleLogout={props.handleLogout}
            {...routerProps}
            user={props.user}
            updateUser={props.updateUser}
          />
        )}
      />
      {props.organisationList.map((menu: any, index: any) => {
        const path = `/newApplication/${menu.orgName.replace(/\s/g, "_")}/:id?`;
        return (
          <Route
            key={index}
            path={path}
            render={(routerProps) => (
              <Main
                {...routerProps}
                handleLogout={props.handleLogout}
                orgDetails={menu}
                user={props.user}
                updateUser={props.updateUser}
              />
            )}
          />
        );
      })}
      <DelayRedirect to="/newApplication/selectorganisation" delay={5000} />
    </Switch>
  );
}

export default SelectPage;
