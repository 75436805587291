import { createMuiTheme } from "@material-ui/core";
import React from "react";
declare module "@material-ui/core/styles/createPalette" {
  interface Palette {
    lightBackground: string;
    thinBorder: React.CSSProperties["border"];
    lightGrey: React.CSSProperties["color"];
    header: {
      border: React.CSSProperties["border"];
      borderActive: React.CSSProperties["border"];
    };
    white: React.CSSProperties["color"];
  }
  interface PaletteOptions {
    lightBackground: string;
    thinBorder: React.CSSProperties["border"];
    lightGrey: React.CSSProperties["color"];
    header: {
      border: React.CSSProperties["border"];
      borderActive: React.CSSProperties["border"];
    };
    white: React.CSSProperties["color"];
  }
}

interface TFont {
  fontFamily: React.CSSProperties["fontFamily"];
  fontSize: React.CSSProperties["fontSize"];
  lineHeight: React.CSSProperties["lineHeight"];
  color: React.CSSProperties["color"];
  fontWeight: React.CSSProperties["fontWeight"];
}

declare module "@material-ui/core/styles/createTypography" {
  interface Typography {
    navbar: TFont;
    blueFont: TFont;
    pageTitle: TFont;
    formTitle: TFont;
    disabled: TFont;
    blueLato: TFont;
    sectionTitle: TFont;
    routeLight: TFont;
    emptyState: TFont;
    overviewTitle: TFont;
    profileNav: TFont;
    displayTitle: TFont;
    confirmationTitle: TFont;
    adminSignupTitle: TFont;
    emptyTitle: TFont;
    reportingTitle: TFont;
    reportingContent: TFont;
  }
  interface TypographyOptions {
    navbar: TFont;
    blueFont: TFont;
    pageTitle: TFont;
    formTitle: TFont;
    disabled: TFont;
    blueLato: TFont;
    sectionTitle: TFont;
    routeLight: TFont;
    emptyState: TFont;
    overviewTitle: TFont;
    profileNav: TFont;
    displayTitle: TFont;
    confirmationTitle: TFont;
    date: TFont;
    adminSignupTitle: TFont;
    emptyTitle: TFont;
    reportingTitle: TFont;
    reportingContent: TFont;
  }
}
const Merriweather = `Merriweather,'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif`;
const Lato = `Lato,'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif`;

export const generateFontStyling = (
  size: string,
  family: string,
  height: number | string,
  color: string,
  weight: number
) => {
  return {
    fontFamily: `${family}, sans-serif`,
    fontSize: size,
    lineHeight: height,
    color: color,
    fontWeight: weight,
  };
};

export const theme: any = createMuiTheme({
  palette: {
    primary: {
      main: "#009AAA",
    },
    secondary: {
      main: "#3B3A3C",
    },
    error: { main: "#F9347B" },
    white: "#FFFFFF",
    background: {
      default: "#F3F6F8",
      paper: "#FFFFFF",
    },
    lightGrey: "#B0BEC5",
    lightBackground: "#ECEFF1",
    thinBorder: "1px solid #009AAA",
    header: {
      border: "3px solid #3EC1D3",
      borderActive: "3px solid #3B3A3C",
    },
    divider: "#90A4AE",
  },

  typography: {
    h1: {
      ...generateFontStyling("36px", Merriweather, "45px", "#FFFFFF", 400),
    },
    h2: {
      ...generateFontStyling("20px", Merriweather, "25px", "#3B3A3C", 700),
    },
    h3: {
      ...generateFontStyling("20px", Merriweather, "25px", "#3B3A3C", 400),
    },
    h4: {
      ...generateFontStyling("16px", Merriweather, "20px", "#ffffff", 700),
    },
    h5: {
      ...generateFontStyling("14px", Merriweather, "18px", "#FFFFFF", 400),
    },
    h6: {
      ...generateFontStyling("12px", Merriweather, "15px", "#607D8B", 400),
    },
    body1: {
      ...generateFontStyling("14px", Lato, "17px", "#607D8B", 700),
    },
    caption: {
      ...generateFontStyling("14px", Lato, "17px", "#3B3A3C", 400),
    },
    subtitle2: {
      ...generateFontStyling("14px", Lato, "17px", "#607D8B", 400),
    },
    navbar: {
      ...generateFontStyling("16px", Lato, "19px", "#000000", 500),
    },
    subtitle1: {
      ...generateFontStyling("14px", Lato, "17px", "#3B3A3C", 700),
    },
    blueFont: {
      ...generateFontStyling("14px", Merriweather, "18px", "#009AAA", 400),
    },
    disabled: {
      ...generateFontStyling("14px", Lato, "17px", "#B0BEC5", 400),
    },
    blueLato: {
      ...generateFontStyling("14px", Lato, "17px", "#009AAA", 400),
    },
    pageTitle: {
      ...generateFontStyling("30px", Merriweather, "38px", "#FFFFFF", 400),
    },
    formTitle: {
      ...generateFontStyling("20px", Merriweather, "25px", "#FFFFFF", 400),
    },
    sectionTitle: {
      ...generateFontStyling("18px", Lato, "22px", "#009AAA", 400),
    },
    routeLight: {
      ...generateFontStyling("16px", Merriweather, "22px", "#B0BEC5", 400),
    },
    emptyState: {
      ...generateFontStyling("14px", Merriweather, "18px", "#607D8B", 400),
    },
    emptyTitle: {
      ...generateFontStyling("16px", Merriweather, "20px", "#607D8B", 700),
    },
    overviewTitle: {
      ...generateFontStyling("20px", Merriweather, "25px", "#009AAA", 400),
    },
    profileNav: {
      ...generateFontStyling("12px", Merriweather, "15px", "#ffffff", 400),
    },
    displayTitle: {
      ...generateFontStyling("16px", Merriweather, "20px", "#009AAA", 700),
    },
    confirmationTitle: {
      ...generateFontStyling("16px", Merriweather, "20px", "#3B3A3C", 700),
    },
    date: {
      ...generateFontStyling("10px", Lato, "12px", "#607D8B", 400),
    },
    adminSignupTitle: {
      ...generateFontStyling("15px", Lato, "17px", "#3B3A3C", 400),
    },
    reportingTitle: {
      ...generateFontStyling("20px", Merriweather, "28px", "#38424A", 400),
    },
    reportingContent: {
      ...generateFontStyling("12px", Lato, "16px", "#576673", 400),
    },
  },
  overrides: {
    MuiTextField: {
      root: {
        "& .MuiInput-underline:hover:not($disabled):not($error):before": {
          borderBottomColor: "#000000",
          borderBottom: "3px solid",
        },
        "& .MuiInput-underline:before": {
          borderBottom: "3px solid",
          borderBottomColor: "#B0BEC5",
        },
        "& .MuiInput-underline.Mui-error:after": {
          borderBottomColor: "#F9347B",
          borderBottom: "3px solid",
        },
        "& .MuiInput-underline:after": {
          borderBottomColor: "#3EC1D3",
          borderBottom: "3px solid",
        },
      },
    },
    MuiFormHelperText: {
      root: {
        "Mui-error": {
          color: "#F9347B",
        },
      },
    },
    MuiCssBaseline: {
      "@global": {
        body: {
          background:
            "linear-gradient(to bottom, #3B3A3C 0%,#3B3A3C 300px ,#F3F6F8 300px,#F3F6F8 100vh);",
          backgroundRepeat: "no-repeat",
          backgroundAttachment: "scrollable",
        },
      },
    },

    MuiInputBase: {
      input: {
        "&::placeholder": {
          ...generateFontStyling("16px", Lato, "19px", "#B0BEC5", 400),
        },
        ...generateFontStyling("16px", Lato, "19px", "#3B3A3C", 400),
      },
    },
    MuiOutlinedInput: {
      root: {
        borderRadius: "10px",
        minHeight: "40px",
        border: "#B0BEC5",
        backgroundColor: "#FFFFFF",
        padding: "0px",
      },
      input: {
        ...generateFontStyling("16px", Lato, "19px", "#3B3A3C", 400),
        "&::placeholder": {
          ...generateFontStyling("16px", Lato, "19px", "#B0BEC5", 400),
        },
      },
    },

    MuiRadio: {
      root: {
      },
      colorSecondary: {
        "&$checked": {
          color: "#009AAA",
        },
      },
    },

    MuiCheckbox: {
      root: {},
      colorSecondary: {
        "&$checked": {
          color: "#009AAA",
        },
      },
    },
    MuiFormLabel: {
      root: {
        ...generateFontStyling("14px", Lato, "17px", "#607D8B", 400),
        paddingBottom: "10px",
      },
    },
    MuiStepConnector: {
      completed: {
        color: "blue",
      },
    },
    MuiFormControlLabel: {
      label: {
        ...generateFontStyling("14px", Lato, "17px", "#607D8B", 400),
        verticalAlign: "middle",
      },
    },
  },
});
