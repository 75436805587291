import React from "react";
import OutlinedInput from "@material-ui/core/OutlinedInput";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import ListItemText from "@material-ui/core/ListItemText";
import Select from "@material-ui/core/Select";
import Checkbox from "@material-ui/core/Checkbox";
import Box from "@material-ui/core/Box";
import FormLabel from "@material-ui/core/FormLabel";
import IconButton from "@material-ui/core/IconButton";
import makeStyles from "@material-ui/core/styles/makeStyles";
import Typography from "@material-ui/core/Typography";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import Grid from "@material-ui/core/Grid";
import CloseIcon from "@material-ui/icons/Close";
const useStyles = makeStyles((theme: any) => ({
  select: {
    "& .MuiSelect-select:focus": {
      background: "transparent",
    },
    "& .MuiOutlinedInput-root": {
      height: "40px",
    },
  },
  options: {
    ...theme.typography.body1,
  },
}));

export default function MultiSelect(props: any) {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  let close = false;
  const smallScreen = useMediaQuery((theme: any) =>
    theme.breakpoints.between("0", "xs")
  );

  return (
    <div
      style={{
        width: props.width || (smallScreen ? "100%" : "50%"),
        marginBottom: "20px",
      }}
    >
      <FormControl style={{width: "100%"}} className={classes.select}>
        <FormLabel>{props.label}</FormLabel>
        <Grid
          container
          direction="row"
          style={{width: props.width || (smallScreen ? "94%" : "88%")}}
        >
          <Select
            labelId="demo-multiple-checkbox-label"
            id={props.id}
            name={props.name}
            multiple
            displayEmpty
            value={props.value}
            onChange={props.onChange}
            style={{width: "100%"}}
            input={
              <OutlinedInput
                onClick={() => {
                  setOpen(!close);
                  close = close ? false : close;
                }}
              />
            }
            onClose={() => {
              close = true;
            }}
            open={open}
            renderValue={(selected: any) => {
              return (
                <Box
                  style={{
                    fontFamily: "Lato",
                    fontWeight: "normal",
                    fontSize: "14px",
                    lineHeight: "17px",
                    color: "#78909C",
                  }}
                >
                  Select Item
                </Box>
              );
            }}
          >
            <Box
              display="flex"
              justifyContent="space-between"
              alignItems="center"
            >
              <Typography
                variant="body1"
                style={{fontSize: "20px", marginLeft: "15px"}}
              >
                Select Item
              </Typography>

              <IconButton
                onClick={() => {
                  close = true;
                }}
              >
                <CloseIcon style={{color: "#009AAA"}} />
              </IconButton>
            </Box>
            {props.options.map((name: any) => (
              <MenuItem key={name} value={name}>
                <Checkbox checked={props.value.indexOf(name) > -1} />
                <ListItemText primary={name} />
              </MenuItem>
            ))}
          </Select>
        </Grid>
      </FormControl>
      <Box
        marginTop="10px"
        marginBottom="20px"
        display="flex"
        flexWrap="wrap"
        style={{gap: 0.5}}
      >
        {props.value.join(", ")}
      </Box>
    </div>
  );
}
