import axios from "axios";
import JSZip from "jszip";
import config from "../config/root-config";

async function fetchItem(url: any) {
  try {
    const item = await axios.get(url, {
      responseType: "arraybuffer",
    });
    return item.data;
  } catch (err) {
    console.log(err);
    return null;
  }
}

export const schoolForm = (campusName: string, forms: any) => {
  forms.map(async (form: any) => {
    try {
      const data = await fetchItem(form.s3_url);
      let formData: { [k: string]: any } = {};
      const new_zip = new JSZip();
      const result = await new_zip.loadAsync(data);
      const fileName = Object.keys(result.files).filter((filename) => {
        return filename.endsWith(".json");
      });
      const res = await Promise.all(
        fileName.map((file) => result.files[file].async("string"))
      );
      fileName.forEach((name, idx) => (formData[name] = res[idx]));
      localStorage.setItem(
        `${campusName}-${form.type}`,
        JSON.stringify(formData)
      );
      localStorage.setItem(
        `${campusName}-${form.type}-formName`,
        form.formName
      );
    } catch (err) {
      console.log(err);
    }
  });
};
