import {Component} from "react";
import {
  onSubmitValidate,
  updateChip,
  Validate,
} from "../../validations/ErrorHandlers";
import {Country} from "country-state-city";
import {parentSignUp, verifyOtp, resendOtp} from "../../api";
import SignUpComponent from "../../components/SignUp";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import ResultModal from "../../components/ResultModal";

const Location = Country.getAllCountries();

type state = {
  values: {
    [firstName: string]: string;
    lastName: string;
    email: string;
    signupPassword: string;
    phoneNumber: string;
  };
  errors: {
    [firstName: string]: string;
    lastName: string;
    email: string;
    signupPassword: string;
    phoneNumber: string;
  };
  chip: {
    letters: string;
    digits: string;
    specialCharacters: string;
    long: string;
  };
  isButtonLoading: boolean;
  signUp: boolean;
  fieldsValidatedForPhone: boolean;
  fieldsValidatedForEmail: boolean;
  error: string;
  typeValidated: boolean;
  otpScreen: boolean;
  otp: any;
  userId: any;
  otpError: any;
  resentOtp: boolean;
  resentError: boolean;
  signUpPhone: boolean;
};

class SignUp extends Component<any, state> {
  constructor(props: any) {
    super(props);
    this.state = {
      values: {
        firstName: "",
        lastName: "",
        email: "",
        signupPassword: "",
        phoneNumber: "",
        country: "Australia",
      },
      errors: {
        firstName: "",
        lastName: "",
        email: "",
        signupPassword: "",
        phoneNumber: "",
        country: "",
      },
      chip: {
        letters: "",
        digits: "",
        specialCharacters: "",
        long: "",
      },
      signUp: false,
      isButtonLoading: false,
      fieldsValidatedForPhone: false,
      fieldsValidatedForEmail: false,
      error: "",
      typeValidated: false,
      otpScreen: false,
      otp: "",
      userId: "",
      otpError: "",
      resentOtp: false,
      resentError: false,
      signUpPhone: false,
    };
    this.handleChange = this.handleChange.bind(this);
    this.handleSignUp = this.handleSignUp.bind(this);
    this.handleSignUpPhone = this.handleSignUpPhone.bind(this);
    this.setOtp = this.setOtp.bind(this);
    this.resendOtp = this.resendOtp.bind(this);
    this.verifyCode = this.verifyCode.bind(this);
  }
  getCode() {
    const code = Location.filter((data) => {
      if (data.name === this.state.values.country) return data;
      else return null;
    });
    if (code[0]) return code[0].phonecode;
    else return "";
  }
  handleChange(event: any, signUpType: boolean) {
    console.log("Event:");
    console.log(event);
    console.log("Type: ", signUpType);
    const values = this.state.values;
    values[event.target.name] = event.target.value;
    this.setState({values});
    const errors = Validate(event.target.name, this.state);
    if (event.target.name === "signupPassword") {
      const chip = updateChip(event.target.name, this.state);
      this.setState({errors: errors, chip: chip});
    } else this.setState({errors: errors});
    const errors2 = onSubmitValidate(this.state);
    console.log("Errors: ");
    console.log(errors2);
    const {email, ...rest} = errors2;
    const {phoneNumber, country, ...rest2} = errors2;
    const validate = Object.values(rest).every((x) => x === "");
    const validate2 = Object.values(rest2).every((x) => x === "");
    console.log("Validate: ", validate);
    console.log("Validate2: ", validate2);
    if (validate && signUpType) {
      console.log("First Condition");
      this.setState({fieldsValidatedForPhone: true});
    } else if (validate2 && !signUpType) {
      console.log("Second Condition");
      this.setState({fieldsValidatedForEmail: true});
    } else {
      this.setState({
        fieldsValidatedForPhone: false,
        fieldsValidatedForEmail: false,
      });
    }
  }
  async handleSignUp(event: any) {
    event.preventDefault();
    this.setState({isButtonLoading: true});
    const password = this.state.values.signupPassword;
    const email = this.state.values.email.toLowerCase();
    const firstName = this.state.values.firstName;
    const lastName = this.state.values.lastName;
    const country = this.state.values.country;
    const accessToken = await this.props.generateAccessToken("AnonyParent");
    const data = {
      role: "Parent",
      fullName: firstName + " " + lastName,
      email: email,
      password: password,
      userDetails: {
        firstName: firstName,
        lastName: lastName,
        country: country,
      },
      documentReferences: [],
    };
    const type = "email";
    parentSignUp(data, email, type, accessToken)
      .then((res) => {
        console.log(res);
        this.setState({isButtonLoading: false, signUp: true});
      })
      .catch((error) => {
        if (
          error.response.data.message.includes("Invalid phone number format.")
        ) {
          this.setState({
            ...this.state,
            isButtonLoading: false,
            errors: {
              ...this.state.errors,
              phoneNumber: "Invalid phone number format.",
            },
          });
        } else if (
          error.response.data.message.includes("UsernameExistsException")
        ) {
          this.setState({
            ...this.state,
            isButtonLoading: false,
            errors: {
              ...this.state.errors,
              email: "An account with this email address already exists.",
            },
          });
        } else if (
          error.response.data.message.includes("InvalidParameterException")
        ) {
          this.setState({
            ...this.state,
            isButtonLoading: false,
            errors: {
              ...this.state.errors,
              phoneNumber: "Invalid phone number format.",
            },
          });
        } else
          this.setState({
            ...this.state,
            error: error.response.data.message,
            isButtonLoading: false,
          });
      });
  }

  async handleSignUpPhone(event: any) {
    event.preventDefault();
    this.setState({isButtonLoading: true});
    const password = this.state.values.signupPassword;
    const phone_number =
      (this.getCode().includes("+") ? this.getCode() : "+" + this.getCode()) +
      this.state.values.phoneNumber;
    const firstName = this.state.values.firstName;
    const lastName = this.state.values.lastName;
    const country = this.state.values.country;
    const accessToken = await this.props.generateAccessToken("AnonyParent");
    const data = {
      role: "Parent",
      fullName: firstName + " " + lastName,
      mobileNo: phone_number,
      password: password,
      userDetails: {
        firstName: firstName,
        lastName: lastName,
        country: country,
        mobileNo: phone_number,
      },
      documentReferences: [],
    };
    const type = "phone";
    parentSignUp(data, phone_number, type, accessToken)
      .then((res) => {
        console.log(res);
        const userId = res.data.customerId;
        this.setState({
          isButtonLoading: false,
          otpScreen: true,
          userId: userId,
        });
      })
      .catch((error) => {
        if (
          error.response.data.message.includes("Invalid phone number format.")
        ) {
          this.setState({
            ...this.state,
            isButtonLoading: false,
            errors: {
              ...this.state.errors,
              phoneNumber: "Invalid phone number format.",
            },
          });
        } else if (
          error.response.data.message.includes("Phone Number already exists")
        ) {
          this.setState({
            ...this.state,
            isButtonLoading: false,
            errors: {
              ...this.state.errors,
              phoneNumber: "An account with this Phone number already exists.",
            },
          });
        } else if (
          error.response.data.message.includes("InvalidParameterException")
        ) {
          this.setState({
            ...this.state,
            isButtonLoading: false,
            errors: {
              ...this.state.errors,
              phoneNumber: "Invalid phone number format.",
            },
          });
        } else
          this.setState({
            ...this.state,
            error: error.response.data.message,
            isButtonLoading: false,
          });
      });
  }

  async verifyCode(event: any) {
    event.preventDefault();
    this.setState({isButtonLoading: true});
    const userId = this.state.userId;
    const phone_number =
      (this.getCode().includes("+") ? this.getCode() : "+" + this.getCode()) +
      this.state.values.phoneNumber;
    const otp = this.state.otp;
    const accessToken = await this.props.generateAccessToken("AnonyParent");
    const data = {
      userId: userId,
      username: phone_number,
      confirmationCode: otp,
    };
    verifyOtp(data, phone_number, accessToken)
      .then((res) => {
        this.setState({isButtonLoading: false});
        console.log(res);
        if (res.data.status === "Verified") this.setState({signUpPhone: true});
      })
      .catch((error) => {
        console.log(error);
        this.setState({
          ...this.state,
          isButtonLoading: false,
          otpError: "Invalid OTP",
        });
      });
  }

  setOtp(value: any) {
    this.setState({otp: value});
    console.log("OTP is: ", value);
  }

  async resendOtp(event: any) {
    event.preventDefault();
    this.setState({isButtonLoading: true});
    const userId = this.state.userId;
    const phone_number =
      (this.getCode().includes("+") ? this.getCode() : "+" + this.getCode()) +
      this.state.values.phoneNumber;

    const accessToken = await this.props.generateAccessToken("AnonyParent");
    const data = {
      userId: userId,
      username: phone_number,
    };
    resendOtp(data, phone_number, accessToken)
      .then((res) => {
        console.log(res);
        this.setState({
          isButtonLoading: false,
          resentOtp: true,
          resentError: false,
        });
      })
      .catch((error) => {
        console.log(error);
        this.setState({
          ...this.state,
          isButtonLoading: false,
          resentError: true,
        });
      });
  }

  render() {
    return (
      <>
        <SignUpComponent
          data={this.state}
          handleChange={this.handleChange}
          handleSignUp={this.handleSignUp}
          handleSignUpPhone={this.handleSignUpPhone}
          setOtp={this.setOtp}
          resendOtp={this.resendOtp}
          verifyCode={this.verifyCode}
          schoolName={this.props.schoolName}
        />
        {this.state.signUp && (
          <Dialog
            PaperProps={{
              style: {
                borderRadius: "10px",
                background: "#ECEFF1",
              },
            }}
            open={Boolean(this.state.signUp)}
          >
            <ResultModal
              type="success"
              title="Verify Email Address"
              content="A verification link has been sent to your email address. In case you didn't received it, please do check your spam mailbox as well and click on the link to
        complete the signup process."
              handleClose={() => {
                this.setState({...this.state, signUp: false});
              }}
            />
            <DialogContent>
              <Box
                marginBottom="10px"
                marginRight="10px"
                display="flex"
                justifyContent="center"
                style={{cursor: "pointer"}}
              >
                <Typography variant="caption">
                  Once done you can login{" "}
                  <span
                    style={{
                      color: "#009AAA",
                      fontWeight: 700,
                      cursor: "pointer",
                    }}
                    onClick={() => this.props.history.push("/login")}
                  >
                    here
                  </span>
                </Typography>
              </Box>
            </DialogContent>
          </Dialog>
        )}
        {this.state.signUpPhone && (
          <Dialog
            PaperProps={{
              style: {
                borderRadius: "10px",
                background: "#ECEFF1",
              },
            }}
            open={Boolean(this.state.signUpPhone)}
          >
            <ResultModal
              type="success"
              title="Sign up Successful"
              content="You've successfully signed up to Digienrol!"
              handleClose={() => {
                this.setState({...this.state, signUpPhone: false});
              }}
            />
            <DialogContent>
              <Box
                marginBottom="10px"
                marginRight="10px"
                display="flex"
                justifyContent="center"
                style={{cursor: "pointer"}}
              >
                <Typography variant="caption">
                  You can now login{" "}
                  <span
                    style={{
                      color: "#009AAA",
                      fontWeight: 700,
                      cursor: "pointer",
                    }}
                    onClick={() => this.props.history.push("/login")}
                  >
                    here
                  </span>
                </Typography>
              </Box>
            </DialogContent>
          </Dialog>
        )}
        {this.state.error && (
          <Dialog
            PaperProps={{
              style: {
                borderRadius: "10px",
                background: "#ECEFF1",
              },
            }}
            open={Boolean(this.state.error)}
          >
            <ResultModal
              type="error"
              title="Signup Failed"
              content={this.state.error}
              handleClose={() => {
                this.setState({...this.state, error: ""});
              }}
            />
          </Dialog>
        )}
        {this.state.resentError && (
          <Dialog
            PaperProps={{
              style: {
                borderRadius: "10px",
                background: "#ECEFF1",
              },
            }}
            open={Boolean(this.state.resentError)}
          >
            <ResultModal
              type="error"
              title="Error"
              content="We couldn't send you the OTP. Please try again!"
              handleClose={() => {
                this.setState({...this.state, resentError: false});
              }}
            />
            <DialogContent>
              <Box
                marginBottom="10px"
                marginRight="10px"
                display="flex"
                justifyContent="center"
                style={{cursor: "pointer"}}
              >
                <a
                  style={{
                    color: "#009AAA",
                    fontWeight: 700,
                    cursor: "pointer",
                    textDecoration: "none",
                    display: "flex",
                    justifyContent: "center",
                    marginBottom: "2vh",
                  }}
                  onClick={this.resendOtp}
                >
                  Try Again
                </a>
              </Box>
            </DialogContent>
          </Dialog>
        )}
      </>
    );
  }
}

export default SignUp;
