import {useMediaQuery} from "@material-ui/core";
import makeStyles from "@material-ui/core/styles/makeStyles";

const useStyles = makeStyles((theme: any) => ({
  link: {
    color: "#607D8B",
    fontWeight: 500,
    fontSize: "16px",
    fontFamily: "Lato",
    marginLeft: "5px",
    marginRight: "5px",
    marginTop: "-2px",
    textAlign: "center", // Default alignment for larger screens
    [theme.breakpoints.between("0", "xs")]: {
      textAlign: "left", // Alignment for small screens
    },
  },
  container: {
    display: "flex",
    flexWrap: "nowrap",
  },
}));

function LinkComponent(props: any) {
  const classes: any = useStyles();
  const {id, pretext, posttext, linkhref, linktext} = props;
  const smallScreen = useMediaQuery((theme: any) =>
    theme.breakpoints.between("0", "xs")
  );

  return (
    <div className={classes.container}>
      <div
        style={{
          marginLeft: !smallScreen ? "2px" : undefined,
          color: "#607D8B",
          fontSize: "16px",
          whiteSpace: "nowrap", // Prevent line breaks
        }}
      >
        {pretext}
      </div>
      <a
        href={linkhref}
        id={id}
        className={classes.link}
        style={{
          textAlign: !smallScreen ? "center" : undefined,
          whiteSpace: "nowrap", // Prevent line breaks
        }}
      >
        {linktext}
      </a>
      <div
        style={{
          color: "#607D8B",
          fontSize: "16px",
          whiteSpace: "nowrap", // Prevent line breaks
        }}
      >
        {posttext}
      </div>
    </div>
  );
}

export default LinkComponent;
