import Grid from "@material-ui/core/Grid";
import makeStyles from "@material-ui/core/styles/makeStyles";
import Typography from "@material-ui/core/Typography";
import useMediaQuery from "@material-ui/core/useMediaQuery";

const useStyles = makeStyles((theme) => ({
  footerMain: {
    height: "10vh",
    backgroundColor: theme.palette.lightBackground,
  },
}));

function Footer() {
  const classes = useStyles();
  const smallScreen = useMediaQuery((theme: any) =>
    theme.breakpoints.between("0", "xs")
  );
  const mediumScreen = useMediaQuery((theme: any) =>
    theme.breakpoints.between("xs", "sm")
  );
  const largeScreen = useMediaQuery((theme: any) => theme.breakpoints.up("md"));
  return (
    <Grid
      container
      alignItems="center"
      {...(mediumScreen && {justify: "space-around"})}
      {...(largeScreen && {justify: "space-between"})}
      className={classes.footerMain}
    >
      <Grid
        item
        md={7}
        sm={12}
        xs={12}
        container
        {...(smallScreen && {style: {textAlign: "center"}})}
        {...(mediumScreen && {justify: "center"})}
        {...(largeScreen && {style: {paddingLeft: "5%"}})}
      >
        <Typography variant="h6">
          ©2022 - Solvitude Pty. Ltd. All Rights Reserved
        </Typography>
      </Grid>

      <Grid
        container
        direction={smallScreen ? "column" : "row"}
        {...(smallScreen && {style: {alignItems: "center", marginTop: "10px"}})}
        justify="space-around"
        item
        md={5}
        sm={12}
        xs={12}
      >
        <Grid item>
          <a
            href="https://www.digienrol.com/"
            target="_blank"
            rel="noreferrer"
            style={{textDecoration: "none"}}
          >
            <Typography variant="h6">Legal</Typography>
          </a>
        </Grid>

        <Grid item>
          <a
            href="https://www.digienrol.com"
            target="_blank"
            rel="noreferrer"
            style={{textDecoration: "none"}}
          >
            <Typography variant="h6">Contact Us</Typography>
          </a>
        </Grid>
        <Grid item>
          <a href="/terms" target="_blank" style={{textDecoration: "none"}}>
            <Typography variant="h6">Terms and Conditions</Typography>
          </a>
        </Grid>
        <Grid item>
          <a
            href="https://www.digienrol.com/privacy-policy"
            target="_blank"
            style={{textDecoration: "none"}}
          >
            <Typography variant="h6">Privacy</Typography>
          </a>
        </Grid>
      </Grid>
    </Grid>
  );
}
export default Footer;
