import Controls from "../../components/controls/Controls";
import Box from "@material-ui/core/Box";
import withStyles from "@material-ui/styles/withStyles";
import {Component} from "react";
import {setState} from "./FormHandlers";
import lodash from "lodash";
import GenericButton from "../../components/GenericButton";
import {page} from "../../Data/getPage";

const useStyles = (theme: any) => ({
  root: {
    padding: "6% 0 6% 6%",
    backgroundColor: "#F6F8FA",
    [theme.breakpoints.between(0, "xs")]: {
      flexDirection: "column",
    },
  },
});
class RenderForm extends Component<any, any> {
  render() {
    const {
      formData,
      state,
      handleChange,
      handleChangeForAddress,
      handleChangeForCheckbox,
      handleChangeForConfirmation,
      handleChangeForDatalist,
      handleChangeForSelect,
      handleChangeForRadio,
      handleChangeOptions,
      saveAndContinue,

      back,
      handleSubmit,
      newId,
      classes,
    } = this.props;
    let form = formData.map((formData: any, index: any) => {
      if (
        formData.Type === "text" ||
        formData.Type === "email" ||
        formData.Type === "password" ||
        formData.Type === "number" ||
        formData.Type === "range" ||
        formData.Type === "date" ||
        formData.Type === "url" ||
        formData.Type === "month" ||
        formData.Type === "time" ||
        formData.Type === "week"
      ) {
        return (
          <Controls.Input
            id={formData.Id}
            key={index}
            type={formData.Type}
            label={formData.FieldName}
            name={formData.FieldName}
            value={state.formValues[formData.FieldName + "_" + formData.Id]}
            error={state.formErrors[formData.FieldName + "_" + formData.Id]}
            onChange={handleChange()}
            required={formData.Required}
            tooltip={formData.Tooltip}
            maxlength={formData.MaxLength}
          />
        );
      } else if (formData.Type === "year") {
        return (
          <Controls.Select
            key={index}
            id={formData.Id}
            label={formData.FieldName}
            name={formData.FieldName}
            value={state.formValues[formData.FieldName + "_" + formData.Id]}
            error={state.formErrors[formData.FieldName + "_" + formData.Id]}
            onChange={handleChangeForSelect(formData.Id)}
            required={formData.Required}
            options={[
              {
                Id: "1",
                Label: new Date().getFullYear(),
              },
              {
                Id: "2",
                Label: new Date().getFullYear() + 1,
              },
              {
                Id: "3",
                Label: new Date().getFullYear() + 2,
              },
              {
                Id: "4",
                Label: new Date().getFullYear() + 3,
              },
              {
                Id: "5",
                Label: new Date().getFullYear() + 4,
              },
            ]}
            tooltip={formData.Tooltip}
          />
        );
      } else if (formData.Type === "tel") {
        return (
          <Controls.PhoneNumber
            id={formData.Id}
            key={index}
            label={formData.FieldName}
            name={formData.FieldName}
            value={state.formValues[formData.FieldName + "_" + formData.Id]}
            error={state.formErrors[formData.FieldName + "_" + formData.Id]}
            onChange={handleChange()}
            required={formData.Required}
            tooltip={formData.Tooltip}
            country={this.props.orgDetails.campusList[0].additionalInfo.country}
            maxlength={formData.MaxLength}
          />
        );
      } else if (formData.Type === "amountField") {
        return (
          <Controls.AmountField
            id={formData.Id}
            key={index}
            type={formData.Type}
            label={formData.FieldName}
            name={formData.FieldName}
            value={state.formValues[formData.FieldName + "_" + formData.Id]}
            error={state.formErrors[formData.FieldName + "_" + formData.Id]}
            onChange={handleChange()}
            required={formData.Required}
            tooltip={formData.Tooltip}
          />
        );
      } else if (formData.Type === "rerenderButton") {
        let render: any = "";
        if (state.click[formData.Id] === "") {
          render = (
            <Box
              key={index}
              width="100%"
              marginTop="40px"
              marginRight="40px"
              marginBottom="40px"
            >
              <GenericButton
                fullWidth={true}
                background="light"
                key={index}
                id={formData.Id}
                variant="contained"
                onClick={() => {
                  const name = formData.Options[0]?.Render;
                  const data = page(
                    this.props.orgDetails.orgName.replace(/\s/g, "_"),
                    name
                  );
                  const newData = lodash.cloneDeep(data);
                  state[formData.Id] = newData;
                  let num = Number(formData.Options[0]?.Number);
                  state[formData.Id].map((data: any, index: any) => {
                    if (data.Type === "display") {
                      const arr = data.FieldName.split(" ");
                      arr[arr.length - 1] = num.toString();
                      data.FieldName = arr.toString().replace(/,/g, " ");
                    } else if (data.Type === "rerenderButton") {
                      data.Options[0].Number = (++num).toString();
                    } else if (data.Type === "back_next") {
                      delete data.Type;
                    }
                    data.Id = newId().toString();
                    return null;
                  });
                  const newState = setState(state[formData.Id], {...state});
                  newState.click[formData.Id] = "clicked";
                  this.props.updateState(newState);
                }}
              >
                {formData.FieldName}
              </GenericButton>
            </Box>
          );
        } else {
          render = (
            <RenderForm
              key={index}
              formData={state[formData.Id]}
              state={state}
              handleChange={handleChange}
              handleChangeForAddress={handleChangeForAddress}
              handleChangeForCheckbox={handleChangeForCheckbox}
              handleChangeForConfirmation={handleChangeForConfirmation}
              handleChangeForDatalist={handleChangeForDatalist}
              handleChangeForSelect={handleChangeForSelect}
              handleChangeForRadio={handleChangeForRadio}
              handleChangeOptions={handleChangeOptions}
              saveAndContinue={saveAndContinue}
              back={back}
              handleSubmit={handleSubmit}
              newId={newId}
              updateState={this.props.updateState}
              organisationName={this.props.orgDetails.orgName.replace(
                /\s/g,
                "_"
              )}
              rerender={true}
              orgDetails={this.props.orgDetails}
            />
          );
        }
        return render;
      } else if (formData.Type === "radio") {
        return (
          <Controls.RadioGroup
            key={index}
            id={formData.Id}
            label={formData.FieldName}
            name={formData.FieldName}
            value={
              state.formValues[
                `${formData.FieldName + "_" + formData.Id}_radio`
              ]
            }
            error={state.formErrors[formData.FieldName + "_" + formData.Id]}
            formErrors={state.formErrors}
            onChange={handleChange()}
            required={formData.Required}
            options={formData.Options}
            onHiddenChange={handleChange(
              `${formData.FieldName + "_" + formData.Id}_radio`
            )}
            onChangeCheckbox={handleChangeForCheckbox(
              `${formData.FieldName + "_" + formData.Id}_radio`,
              formData.Options[0]?.Render
            )}
            onChangeConfirmation={handleChangeForConfirmation(
              `${formData.FieldName + "_" + formData.Id}_radio`
            )}
            tooltip={formData.Tooltip}
            onSelectChange={handleChangeForSelect}
            onRadioChange={handleChangeForRadio}
            handleChangeForAddress={handleChangeForAddress}
            handleChangeOptions={handleChangeOptions}
            formValues={state.formValues}
            country={this.props.orgDetails.campusList[0].additionalInfo.country}
          />
        );
      } else if (formData.Type === "select") {
        return (
          <Controls.Select
            key={index}
            id={formData.Id}
            label={formData.FieldName}
            name={formData.FieldName}
            value={state.formValues[formData.FieldName + "_" + formData.Id]}
            error={state.formErrors[formData.FieldName + "_" + formData.Id]}
            onChange={handleChangeForSelect(formData.Id)}
            required={formData.Required}
            options={formData.Options}
            tooltip={formData.Tooltip}
          />
        );
      } else if (formData.Type === "radio") {
        return (
          <Controls.Select
            key={index}
            id={formData.Id}
            label={formData.FieldName}
            name={formData.FieldName}
            value={state.formValues[formData.FieldName + "_" + formData.Id]}
            error={state.formErrors[formData.FieldName + "_" + formData.Id]}
            onChange={handleChangeForRadio(formData.Id)}
            required={formData.Required}
            options={formData.Options}
            tooltip={formData.Tooltip}
          />
        );
      } else if (formData.Type === "multiselect") {
        return (
          <Controls.MultiSelect
            key={index}
            id={formData.Id}
            label={formData.FieldName}
            name={formData.FieldName}
            value={state.formValues[formData.FieldName + "_" + formData.Id]}
            error={state.formErrors[formData.FieldName + "_" + formData.Id]}
            onChange={handleChangeForSelect(formData.Id)}
            required={formData.Required}
            options={formData.Options}
            tooltip={formData.Tooltip}
          />
        );
      } else if (formData.Type === "link") {
        return (
          <Controls.Link
            key={index}
            id={formData.Id}
            pretext={formData.PreText}
            posttext={formData.PostText}
            linkhref={formData.LinkHref}
            linktext={formData.LinkText}
            value={state.formValues[formData.FieldName + "_" + formData.Id]}
            error={state.formErrors[formData.FieldName + "_" + formData.Id]}
          />
        );
      } else if (
        formData.Type === "countryDropdown" ||
        formData.Type === "stateDropdown" ||
        formData.Type === "cityDropdown"
      ) {
        return (
          <Controls.LocationDropdown
            key={index}
            id={formData.Id}
            type={formData.Type}
            label={formData.FieldName}
            name={formData.FieldName}
            value={state.formValues[formData.FieldName + "_" + formData.Id]}
            error={state.formErrors[formData.FieldName + "_" + formData.Id]}
            onChange={handleChangeForSelect(formData.Id)}
            required={formData.Required}
            tooltip={formData.Tooltip}
            country={this.props.orgDetails.campusList[0].additionalInfo.country}
          />
        );
      } else if (formData.Type === "checkbox") {
        return (
          <Controls.Checkbox
            key={index}
            id={formData.Id}
            label={formData.FieldName}
            error={state.formErrors[formData.FieldName + "_" + formData.Id]}
            onChange={handleChangeForCheckbox(
              `${formData.FieldName + "_" + formData.Id}_checkbox`
            )}
            required={formData.Required}
            options={formData.Options}
            checked={
              state.formValues[
                `${formData.FieldName + "_" + formData.Id}_checkbox`
              ].checkbox
            }
            tooltip={formData.Tooltip}
          />
        );
      } else if (formData.Type === "confirmation") {
        return (
          <Controls.Confirmation
            key={index}
            id={formData.Id}
            name={formData.FieldName}
            label={formData.FieldName}
            error={state.formErrors[formData.FieldName + "_" + formData.Id]}
            onChange={handleChangeForConfirmation()}
            checked={
              state.formValues[
                `${formData.FieldName + "_" + formData.Id}_confirmation`
              ]
            }
            required={formData.Required}
            tooltip={formData.Tooltip}
          />
        );
      } else if (formData.Type === "datalist") {
        return (
          <Controls.Datalist
            key={index}
            id={formData.Id}
            label={formData.FieldName}
            error={state.formErrors[formData.FieldName + "_" + formData.Id]}
            onChange={handleChangeForDatalist(
              formData.FieldName + "_" + formData.Id
            )}
            onChangeText={handleChange()}
            required={formData.Required}
            options={formData.Options}
            tooltip={formData.Tooltip}
          />
        );
      } else if (formData.Type === "address") {
        return (
          <Controls.Address
            key={index}
            id={formData.Id}
            label={formData.FieldName}
            value={
              state.formValues[
                `${formData.FieldName + "_" + formData.Id}_address`
              ].value
            }
            error={state.formErrors[formData.FieldName + "_" + formData.Id]}
            onChange={handleChangeForAddress(
              `${formData.FieldName + "_" + formData.Id}_address`
            )}
            onOptionsChange={handleChangeOptions}
            required={formData.Required}
            options={
              state.formValues[
                `${formData.FieldName + "_" + formData.Id}_address`
              ].options
            }
            tooltip={formData.Tooltip}
          />
        );
      } else if (formData.Type === "textarea") {
        return (
          <Controls.Textarea
            key={index}
            id={formData.Id}
            label={formData.FieldName}
            name={formData.FieldName}
            value={state.formValues[formData.FieldName + "_" + formData.Id]}
            onChange={handleChange()}
            error={state.formErrors[formData.FieldName + "_" + formData.Id]}
            required={formData.Required}
            tooltip={formData.Tooltip}
          />
        );
      } else if (formData.Type === "file") {
        if (Object.keys(formData).includes("Check")) {
          if (
            formData.Check.Type === "radio"
              ? formData.Check.Values.includes(
                  this.props.values[formData.Check.Page].formValues[
                    formData.Check.Name + "_" + formData.Check.Id + "_radio"
                  ].value
                )
              : formData.Check.Type === "checkbox"
              ? formData.Check.Values.includes(
                  this.props.values[formData.Check.Page].formValues[
                    formData.Check.Name + "_" + formData.Check.Id + "_checkbox"
                  ].value
                )
              : formData.Check.Values.includes(
                  this.props.values[formData.Check.Page].formValues[
                    formData.Check.Name + "_" + formData.Check.Id
                  ]
                )
          ) {
            return (
              <Controls.File
                key={index}
                id={formData.Id}
                label={formData.FieldName}
                name={formData.FieldName}
                value={
                  state.formValues[
                    `${formData.FieldName + "_" + formData.Id}_file`
                  ]
                }
                onChange={handleChange()}
                error={state.formErrors[formData.FieldName + "_" + formData.Id]}
                required={formData.Required}
                tooltip={formData.Tooltip}
                uploadFileDetails={this.props.uploadFileDetails}
                deleteFile={this.props.deleteFile}
              />
            );
          }
        } else
          return (
            <Controls.File
              key={index}
              id={formData.Id}
              label={formData.FieldName}
              name={formData.FieldName}
              value={
                state.formValues[
                  `${formData.FieldName + "_" + formData.Id}_file`
                ]
              }
              onChange={handleChange()}
              error={state.formErrors[formData.FieldName + "_" + formData.Id]}
              required={formData.Required}
              tooltip={formData.Tooltip}
              uploadFileDetails={this.props.uploadFileDetails}
              deleteFile={this.props.deleteFile}
            />
          );
      } else if (formData.Type === "display") {
        return (
          <Controls.Display
            key={index}
            id={formData.Id}
            text={formData.FieldName}
            type={formData.Options[0].Type}
            {...(formData.Options[0].NestedList && {
              nestedList: formData.Options[0].NestedList,
            })}
          />
        );
      } else return null;
    });

    return (
      <Box
        display="flex"
        flexDirection="row"
        flexWrap="wrap"
        borderRadius="18px"
        {...(!this.props.rerender && {className: classes.root})}
      >
        {form}
      </Box>
    );
  }
}
export default withStyles(useStyles, {withTheme: true})(RenderForm);
