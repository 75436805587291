import {page} from "../../Data/getPage";

export function createInitialState(render: any) {
  const SideMenu = page(render, "index");
  const state: any = {};
  state.page = SideMenu[0];
  state.activeStep = 0;
  state.completed = {};
  state.disabled = {};
  for (let i = 0; i < SideMenu.length; i++) {
    state.completed[i] = false;
    state.disabled[i] = i !== 0;
  }
  return state;
}

export function createPageState(render: any, country: any) {
  const SideMenu = page(render, "index");
  const state: any = {};
  SideMenu.map((menu: any) => {
    const name = menu.replace(/\s/g, "_");
    const formData = page(render, name);
    state[menu] = {formValues: {}, formErrors: {}};
    state[menu].click = {};
    setState(formData, state[menu], country);
    return null;
  });
  return state;
}

export function setState(setData: any, state: any, country?: any) {
  setData.map((formData: any) => {
    let name = formData.FieldName;
    let id = formData.Id;
    const key = name + "_" + id;
    state.formErrors[key] = "";
    if (formData.Type === "checkbox") {
      state.formValues[`${key}_checkbox`] = {
        value: [],
        checkbox: {},
      };
      formData.Options.map((opt: any) => {
        state.formValues[`${key}_checkbox`].checkbox[opt.Label] = false;
        return null;
      });
      return state.formValues[`${key}_checkbox`];
    } else if (formData.Type === "rerenderButton") {
      return (state.click[formData.Id] = "");
    } else if (formData.Type === "file")
      return (state.formValues[`${key}_file`] = {});
    else if (
      formData.Type === "nextButton" ||
      formData.Type === "backButton" ||
      formData.Type === "submitButton" ||
      formData.Type === "back_next" ||
      formData.Type === "rerenderButton" ||
      formData.FieldName === "Back_Next"
    ) {
      if (formData.Type === "input") {
        console.log("Processing input form data:", formData);
      }
    } else if (formData.Type === "display") {
      if (formData.Options[0].Type === "displayTitle") {
        return (state.formValues[`${key}_display`] = "");
      }
    } else if (formData.Type === "confirmation")
      return (state.formValues[`${key}_confirmation`] = false);
    else if (formData.Type === "address")
      return (state.formValues[`${key}_address`] = {
        value: "",
        options: [],
      });
    else if (formData.Type === "radio") {
      state.formValues[`${key}_radio`] = {value: ""};
      radioState(formData, state.formValues[`${key}_radio`]);
      return state.formValues[`${key}_radio`];
    } else if (formData.Type === "countryDropdown") {
      return (state.formValues[key] = country);
    } else if (formData.Type === "multiselect") {
      return (state.formValues[key] = []);
    } else if (formData.Type === "link") {
      state.formValues[`${key}_link`] = "";
      return state.formValues[`${key}_link`];
    } else return (state.formValues[key] = "");
    return null;
  });
  return state;
}

function radioState(formData: any, state: any) {
  if (Object.keys(formData.Options[0].Render[0]).length !== 0) {
    formData.Options[0].Render.map((opt: any) => {
      const key2 = opt.FieldName + "_" + opt.Id;
      if (opt.Type === "checkbox") {
        state[`${key2}_checkbox`] = {
          value: [],
          checkbox: {},
        };
        opt.Options.map((opt: any) => {
          state[`${key2}_checkbox`].checkbox[opt.Label] = false;
          return null;
        });
      } else if (opt.Type === "file") state[`${key2}_file`] = {};
      else if (
        opt.Type === "nextButton" ||
        formData.Type === "backButton" ||
        formData.Type === "submitButton" ||
        formData.Type === "back_next"
      ) {
        if (formData.Type === "input") {
        } else if (formData.Type === "display") {
          console.log("Displaying form data:", formData);
        }
        if (opt.Options[0].Type === "displayTitle")
          state[`${key2}_display`] = "";
      } else if (opt.Type === "confirmation")
        state[`${key2}_confirmation`] = false;
      else if (opt.Type === "address")
        state[`${key2}_address`] = {
          value: "",
          options: [],
        };
      else {
        state[key2] = "";
      }
      return null;
    });
  }
}

export function createFormData(
  values: any,
  data: any,
  docRefs?: Array<string>
) {
  for (const k in values) {
    if (k.split("_").includes("checkbox") || k.split("_").includes("address")) {
      data.append(k, values[k].value);
    } else if (k.split("_").includes("radio")) {
      data.append(k, values[k].value);
      if (values[k].value === "Yes") {
        delete values[k].value;
        createFormData(values[k], data);
        values[k].value = "Yes";
      }
    } else if (k.split("_")[2] === "confirmation") {
      if (values[k]) {
        data.append(k, "Yes");
      } else {
        data.append(k, "No");
      }
    } else if (k.split("_")[2] === "file") {
      if (Object.keys(values[k]).length !== 0) {
        data.append(k, values[k].fileName);
      }
    } else data.append(k, values[k]);
  }
}
