import Chip from "@material-ui/core/Chip";
import makeStyles from "@material-ui/core/styles/makeStyles";
import DoneIcon from "@material-ui/icons/Done";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
  },
  chip: {
    disply: "flex",
    justifyContent: "flex-start",
    width: "100%",
    backgroundColor: "transparent",
    fontWeight: "normal",
    fontSize: "14px",
    lineHeight: "17px",
    color: "#BDBDBD",
    marginTop: "-15px",
    overflowWrap: "break-word"
  },
}));
type props = {
  chip: {
    [letters: string]: string,
    digits: string,
    specialCharacters: string,
    long: string
  }
}
function PasswordHints(props: props) {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <Chip
        label="Contains uppercase and lowercase letters"
        className={classes.chip}
        avatar={
          props.chip.letters === "" ? (
            <DoneIcon
              style={{
                width: "14px",
                height: "14px",
                color: "#B0BEC5",
                backgroundColor: "#ECEFF1",
                borderRadius: "50%",
              }}
            />
          ) : (
            <DoneIcon
              style={{
                width: "14px",
                height: "14px",
                color: "#009AAA",
                backgroundColor: "#C7E8E4",
                borderRadius: "50%",
              }}
            />
          )
        }
      />
      <Chip
        label="Contains Digits"
        className={classes.chip}
        avatar={
          props.chip.digits === "" ? (
            <DoneIcon
              style={{
                width: "14px",
                height: "14px",
                color: "#B0BEC5",
                backgroundColor: "#ECEFF1",
                borderRadius: "50%",
              }}
            />
          ) : (
            <DoneIcon
              style={{
                width: "14px",
                height: "14px",
                color: "#009AAA",
                backgroundColor: "#C7E8E4",
                borderRadius: "50%",
              }}
            />
          )
        }
      />
      <Chip
        label="Contains Special Characters"
        className={classes.chip}
        avatar={
          props.chip.specialCharacters === "" ? (
            <DoneIcon
              style={{
                width: "14px",
                height: "14px",
                color: "#B0BEC5",
                backgroundColor: "#ECEFF1",
                borderRadius: "50%",
              }}
            />
          ) : (
            <DoneIcon
              style={{
                width: "14px",
                height: "14px",
                color: "#009AAA",
                backgroundColor: "#C7E8E4",
                borderRadius: "50%",
              }}
            />
          )
        }
      />
      <Chip
        label="8 character long"
        className={classes.chip}
        avatar={
          props.chip.long === "" ? (
            <DoneIcon
              style={{
                width: "14px",
                height: "14px",
                color: "#B0BEC5",
                backgroundColor: "#ECEFF1",
                borderRadius: "50%",
              }}
            />
          ) : (
            <DoneIcon
              style={{
                width: "14px",
                height: "14px",
                color: "#009AAA",
                backgroundColor: "#C7E8E4",
                borderRadius: "50%",
              }}
            />
          )
        }
      />
    </div>
  );
}

export default PasswordHints;
