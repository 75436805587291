import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import Modal from "@material-ui/core/Modal";
import Grid from "@material-ui/core/Grid";
import {useEffect, useState} from "react";
import PageHeadBase from "../../components/PageHeadBase";
import searchEmptyState from "../../assets/emptyState/search.svg";
import {getActiveSubscription, getAllCampusAnalytics} from "../../api";
import LoadingScreen from "../../components/LoadingScreen";
import GenericButton from "../../components/GenericButton";
import useStyles from "./styles";

function OrganisationList(props: any) {
  const classes = useStyles();
  const smallScreen = useMediaQuery((theme: any) =>
    theme.breakpoints.between("0", "xs")
  );
  const [filter, setFilter] = useState(false);
  const [city, setCity] = useState("");
  const [filteredOrg, setFilteredOrg]: any = useState();
  const [loading, setLoading] = useState(false);
  const [loading2, setLoading2] = useState(false);
  const [maxAppModal, setMaxAppModal] = useState(false);
  const [schoolName, setSchoolName] = useState("school");
  const createFilteredOrg = (organisationList: any, city: any) => {
    const filter = organisationList.filter((org: any) => {
      const filterCampus = org.campusList.filter((campus: any) => {
        if (
          props.geolocation.country === "Australia" ||
          campus.additionalInfo.country === "Australia"
        ) {
          if (campus.additionalInfo.state === city) return campus;
          else return null;
        } else if (campus.additionalInfo.city === city) return campus;
        else return null;
      });
      if (filterCampus.length !== 0) return {...org, campusList: filterCampus};
      else return null;
    });
    if (filter.length === 0) {
      setCity("");
    }
    return filter;
  };

  useEffect(() => {
    if (Object.keys(props.geolocation).length !== 0) {
      setFilter(true);
      props.geolocation.country === "Australia"
        ? setCity(props.geolocation.state)
        : setCity(props.geolocation.city);
      setFilteredOrg(
        createFilteredOrg(
          props.organisationList,
          props.geolocation.country === "Australia"
            ? props.geolocation.state
            : props.geolocation.city
        )
      );
    }
  }, []);
  const cities = props.organisationList.map((org: any) => {
    const filterCampus = org.campusList.map((campus: any) => {
      if (Object.keys(props.geolocation).length !== 0) {
        if (campus.additionalInfo.country === props.geolocation.country) {
          if (props.geolocation.country === "Australia")
            return campus.additionalInfo.state;
          else return campus.additionalInfo.city;
        } else return null;
      } else {
        if (campus.additionalInfo.country === "Australia")
          return campus.additionalInfo.state;
        else return campus.additionalInfo.city;
      }
    });
    return filterCampus[0];
  });
  const titleCase = (str: any) => {
    return str.replace(/\w\S*/g, (t: any) => {
      return t.charAt(0).toUpperCase() + t.substr(1).toLowerCase();
    });
  };
  const fetchCampusInfo = async (orgId: any, campusId: any, menu: any) => {
    let maxApps = 0;
    let numOfApps = 0;
    let status = "";
    await getActiveSubscription(orgId, campusId)
      .then((res) => {
        console.log("Subscription Result");
        console.log(res.data);
        maxApps = res.data.features.maxApps;
        status = res.data.status;
        console.log("Max Apps", maxApps);
        setLoading(false);
      })
      .catch((err) => {
        console.log("Subscription Error");
        console.log(err);
        setLoading(false);
      });

    await getAllCampusAnalytics(campusId)
      .then((res) => {
        console.log("Campus Analytics");
        console.log(res.data.classes.all);
        const data = res.data.classes.all;
        console.log("Data:");
        console.log(data);
        numOfApps = data.total - data.Draft;
        console.log("Num of Apps", numOfApps);
        setLoading2(false);
      })
      .catch((err) => {
        console.log(err);
        setLoading2(false);
      });

    if (numOfApps >= maxApps || status !== "Active") {
      setMaxAppModal(true);
    } else {
      if (props.location.state.childDetails.length === 0) {
        props.history.push(
          `/newApplication/${menu.orgName.replace(/\s/g, "_")}`,
          {
            childDetails: {},
            populateData: props.location.state.populateData,
          }
        );
      } else {
        const formData = JSON.parse(menu.campusList[0].customData.formData);
        console.log("FormData...");
        console.log(formData.length);
        if (formData.length > 1) {
          props.history.push("/newApplication/selectform", {
            childDetails: props.location.state.childDetails,
            populateData: props.location.state.populateData,
            organisationName: menu.orgName,
            organisationAddress: menu.campusList[0].campusAddress,
            menu: menu,
          });
        } else {
          props.history.push("/newApplication/selectchild", {
            childDetails: props.location.state.childDetails,
            populateData: props.location.state.populateData,
            organisationName: menu.orgName,
            organisationAddress: menu.campusList[0].campusAddress,
          });
        }
      }
    }
  };

  const handleClick = async (menu: any) => {
    console.log(menu);
    const orgId = menu.orgId;
    const campusId = menu.campusList[0].campusId;
    console.log("OrgId: ", orgId);
    console.log("campusId: ", campusId);
    setLoading(true);
    setLoading2(true);
    setSchoolName(menu.orgName);
    await fetchCampusInfo(orgId, campusId, menu);
  };

  return (
    <>
      {(loading || loading2) && <LoadingScreen />}

      <PageHeadBase
        route="Select School"
        title="Select School"
        history={props.history}
        button="Clear Filter"
        filter={filter}
        onClick={() => {
          setFilter(false);
          setCity("");
        }}
        cityDropdown={true}
        name="city"
        value={city}
        onChange={(event: any) => {
          setCity(event?.target.value);
          setFilteredOrg(
            createFilteredOrg(props.organisationList, event.target.value)
          );
          setFilter(true);
        }}
        options={cities.filter((value: any, index: any, self: any) => {
          return self.indexOf(value) === index;
        })}
        country={props.geolocation.country}
      >
        <Modal open={maxAppModal}>
          <div className={classes.paper}>
            <h2>Error</h2>
            <hr />
            <Typography
              variant="subtitle2"
              style={{
                fontSize: "16px",
                marginBottom: "25px",
                marginTop: "25px",
              }}
            >
              Sorry, school is not accepting any more application for this year.
              Please contact {schoolName} for more details.
            </Typography>
            <Grid container spacing={2} style={{marginTop: "25px"}}>
              <Grid item xs="auto">
                <GenericButton
                  id="ok"
                  variant="contained"
                  onClick={() => setMaxAppModal(false)}
                  background="white"
                >
                  OK
                </GenericButton>
              </Grid>
            </Grid>
          </div>
        </Modal>
        <div className={classes.main}>
          {(
            filter
              ? filteredOrg.length !== 0
              : props.organisationList.length !== 0
          ) ? (
            (filter ? filteredOrg : props.organisationList).map(
              (menu: any, index: any) => {
                return (
                  <div
                    key={index}
                    className={classes.school}
                    onClick={() => {
                      handleClick(menu);
                    }}
                  >
                    {smallScreen ? (
                      <Box display="flex" flexDirection="column">
                        <Typography
                          variant="caption"
                          style={{marginBottom: "0.5em"}}
                        >
                          {titleCase(menu.orgName)}
                        </Typography>
                        <Typography variant="subtitle2">
                          {menu.campusList[0].campusAddress}
                        </Typography>
                      </Box>
                    ) : (
                      <Box display="flex" justifyContent="space-between">
                        <Box display="flex" flexDirection="column" width="70%">
                          <Typography
                            variant="caption"
                            style={{marginBottom: "0.5em"}}
                          >
                            {menu.orgName}
                          </Typography>
                          <Typography variant="subtitle2">
                            {menu.campusList[0].campusAddress}
                          </Typography>
                        </Box>
                      </Box>
                    )}
                  </div>
                );
              }
            )
          ) : (
            <Box
              display="flex"
              flexDirection="column"
              justifyContent="center"
              alignItems="center"
            >
              <img src={searchEmptyState} alt="" />

              <Box marginTop="10px" className={classes.emptyTitle}>
                {"No Organisations Found in " + props.geolocation.city}
              </Box>
              <Box textAlign="center" className={classes.emptyState}>
                Please select a different city or clear the filter applied to
                find what you’re looking for.
              </Box>
            </Box>
          )}
        </div>
      </PageHeadBase>
    </>
  );
}

export default OrganisationList;
