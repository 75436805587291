import FormLabel from "@material-ui/core/FormLabel";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import makeStyles from "@material-ui/core/styles/makeStyles";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import React from "react";
import {
  getStatusApplicationsAnalytics,
  getAllApplicationsAnalytics,
} from "../api";
import {PieChart} from "react-minimal-pie-chart";
import LoadingScreen from "./LoadingScreen";

import ReportApplications from "./ReportApplications";

const useStyles = makeStyles((theme: any) => ({
  title: {
    ...theme.typography.reportingTitle,
  },
  Container: {
    borderRadius: " 15px",
    alignItems: "center",
    justifyContent: "center",
    marginBottom: "20px",
    marginRight: "20px",
    marginTop: "15px",
  },
  Content: {
    flex: "1 0 auto",
    alignItems: "center",
    paddingLeft: "20px",
    paddingRight: "20px",
    paddingTop: "20px",
  },
  Heading: {
    fontFamily: "Merriweather",
    fontStyle: "normal",
    fontWeight: "bold",
    fontSize: "16px",
    lineHeight: "20px",
    color: "#3B3A3C",
    paddingBottom: "20px",
  },
  HeadingTitle: {
    fontFamily: "Merriweather",
    fontStyle: "normal",
    fontWeight: "bold",
    fontSize: "16px",
    lineHeight: "20px",
    color: "#3B3A3C",
  },
  Details: {
    display: "flex",
    justifyContent: "space-evenly",
    alignItems: "center",
  },
  Details2: {
    display: "inline-flex",
  },
  Details3: {
    display: "inline-flex",

    flexDirection: "row-reverse",
  },

  Line: {
    width: "auto",
    background: "#E5E7E8",
    height: "1px",
    margin: "5px",
  },
  Pie: {
    width: "231.2px",
    cursor: "pointer",
  },
  StudentsCount: {
    fontFamily: "Lato",
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: "30px",
    lineHeight: "36px",
    color: "#3B3A3C",
    paddingLeft: "18px",
    paddingRight: "5px",
  },
  Subheading: {
    fontFamily: "Merriweather",
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: "12px",
    lineHeight: "15px",
    color: "#78909C",
  },
  List: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
  },
  ListHeadings: {
    fontFamily: "Lato",
    fontStyle: "normal",
    fontWeight: "bold",
    fontSize: "16px",
    lineHeight: "19px",
    color: "#3B3A3C",
    paddingLeft: "0px",
    margin: "0px",
    "&::marker": {
      fontSize: "20px",
    },
    "&::before": {
      content: "",
      color: "red",
      borderRadius: "50",
    },
  },
  ListMuted: {
    fontFamily: "Merriweather",
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: "12px",
    lineHeight: "15px",
    color: "#78909C",
    listStyle: "none",
    paddingBottom: "15px",
  },
  RightContent: {
    flex: "1 0 auto",
    justifyContent: "center",
    alignItems: "center",
  },
  ListContainer: {
    paddingLeft: "38px",
  },
  dialog: {
    position: "fixed",
    right: 10,
    top: 50,
  },
  options: {
    ...theme.typography.body1,
  },
}));

const colors: any = {
  Nursery: "#FF71A4",
  LKG: "#5CC3F1",
  UKG: "#2FE3B8",
  KG: "#FAA543",
  "Class 1": "#58FF69",
  "Class 2": "#B132CC",
  "Class 3": "#F22259",
  "Class 4": "#229BFF",
  "Class 5": "#FFED64",
  "Class 6": "#80C242",
  "Class 7": "#A64E4E",
  "Class 8": "#66FEFF",
  "Class 9": "#F27830",
  "Class 10": "#8C92F2",
  "Kinder Garden": "#FF71A4",
  Prep: "#5CC3F1",
  "Grade 1": "#2FE3B8",
  "Grade 2": "#FAA543",
  "Grade 3": "#58FF69",
  "Grade 4": "#B132CC",
  "Grade 5": "#F22259",
  "Grade 6": "#229BFF",
  "Grade 7": "#FFED64",
  "Grade 8": "#80C242",
  "Grade 9": "#A64E4E",
  "Grade 10": "#66FEFF",
  "Grade 11": "#F27830",
  "Grade 12": "#8C92F2",
};

const statusColors: any = {
  "Admission Granted": "#009AAA",
  "Interview Scheduled": "#FF9E45",
  "Post-interview Review": "#2DB6F5",
  Rejected: "#F9347B",
  Submitted: "#F8CB2C",
};

function Analytics(props: any) {
  const classes = useStyles();
  const mediumScreen = useMediaQuery((theme: any) =>
    theme.breakpoints.between("xs", "sm")
  );
  const [classData, setClassData]: any = React.useState([]);
  const [finalStatusData, setFinalStatusData] = React.useState<any[]>([]);
  const [status, setStatus] = React.useState<any>("all");
  const [statusOptions, setStatusOptions] = React.useState<any>([]);
  const [maxClass, setMaxClass] = React.useState({
    class: "",
    value: "",
  });
  const [appData, setAppData]: any = React.useState();
  const [openModal, setOpenModal] = React.useState(false);
  const [loading, setLoading] = React.useState(false);

  React.useEffect(() => {
    console.log("Campus Analytics Data:");
    console.log(props.data.classes);
    const classObj = props.data.classes;
    for (const item in classObj) {
      console.log(item);
    }

    const newArr = Object.entries(classObj);
    console.log(newArr);
    let chartData: any = [];
    for (let i = 0; i < newArr.length; i++) {
      let title: any = newArr[i][0];
      let totalValue: any = newArr[i][1];

      if (title !== "null" && title !== "all") {
        let object = {
          title: title,
          value: totalValue.total - (totalValue.Draft ? totalValue.Draft : 0),
          color: colors[title],
        };
        console.log(title);
        chartData.push(object);
      }
    }
    console.log("chartData");
    console.log(chartData);
    setClassData(chartData);

    let maxValue: any = 0;
    let maxTitle: any = "";
    for (let i = 0; i < chartData.length; i++) {
      let title: any = chartData[i].title;
      let values: any = chartData[i].value;
      console.log("Values", values);
      if (values > maxValue) {
        maxValue = values;
        maxTitle = title;
      }
    }
    let maxObject = {
      class: maxTitle,
      value: maxValue,
    };
    console.log("maxtitle");
    console.log(maxTitle + " --" + maxValue);
    setMaxClass(maxObject);

    let statusData: any = {};
    let options: any = [];
    for (let i = 0; i < newArr.length; i++) {
      let name: any = newArr[i][0];
      let totalValue: any = newArr[i][1];

      let classObject: any = [];
      const newArray = Object.entries(totalValue);
      console.log("totalValue");

      console.log(newArray);

      for (let j = 0; j < newArray.length; j++) {
        let title: any = newArray[j][0];
        if (title !== "total" && title !== "Draft") {
          let object = {
            title: newArray[j][0],
            value: newArray[j][1],
            color: statusColors[title],
          };

          classObject.push(object);
        }
      }
      console.log("classObject");
      console.log(classObject);
      statusData[name] = classObject;
      options.push(name);
    }
    console.log("Status Data:");
    console.log(statusData);
    setFinalStatusData(statusData);
    console.log("Options:");
    console.log(options);
    setStatusOptions(options);
  }, []);

  const handleChange = (event: any) => {
    const updatedValue = event.target.value;
    console.log(updatedValue);
    setStatus(updatedValue);
  };

  const handleClassClick = (event: any, index: any) => {
    setLoading(true);
    const cls = classData[index].title;
    console.log("class: " + cls);
    const campusId = props.user.attributes["custom:campusId"];
    console.log("campusId Class: ", campusId);
    console.log("Year: ", props.year);

    getAllApplicationsAnalytics(campusId, props.year, cls)
      .then((res) => {
        console.log(res);
        setAppData(res.data);
        setLoading(false);
        setOpenModal(true);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  };

  const handleStatusClick = (event: any, index: any) => {
    setLoading(true);
    const sts = finalStatusData[status];
    const finalStatus = sts[index].title;
    console.log("status: " + finalStatus);
    const campusId = props.user.attributes["custom:campusId"];
    console.log("CampusId Status: ", campusId);

    getStatusApplicationsAnalytics(campusId, props.year, status, finalStatus)
      .then((res) => {
        console.log(res);
        setAppData(res.data);
        setLoading(false);

        setOpenModal(true);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  };

  const handleClose = () => {
    setOpenModal(false);
  };

  return (
    <>
      {loading ? <LoadingScreen /> : null}
      <Dialog
        open={openModal}
        aria-labelledby="simple-dialog-title"
        onClose={handleClose}
        scroll="paper"
        classes={{
          paper: classes.dialog,
        }}
      >
        <Box
          width={mediumScreen ? "80vw" : "30vw"}
          maxHeight={"150vw"}
          padding={"0em 1em 1em 1em"}
        >
          <DialogTitle id="simple-dialog-title">
            <Grid container justify="space-between" alignItems="center">
              <Typography className={classes.HeadingTitle}>
                Applications
              </Typography>
              <IconButton onClick={handleClose}>
                <CloseIcon />
              </IconButton>
            </Grid>
          </DialogTitle>
          {appData?.map((data: any, index: any) => (
            <ReportApplications
              data={data}
              key={index}
              history={props.history}
              applications={props.applications}
            ></ReportApplications>
          ))}
        </Box>
      </Dialog>

      <Grid container spacing={2}>
        <Grid item xs={12} sm={12} md={6}>
          <Box className={classes.title}>Application by class</Box>
          <Card className={classes.Container}>
            <CardContent className={classes.Content}>
              <Grid container spacing={4} style={{marginTop: "0px"}}>
                <Grid item xs={12} sm={12} md={12}>
                  <Typography className={classes.Subheading}>
                    Top Class
                  </Typography>
                </Grid>
                <Grid item md={9} xs={6} sm={9}>
                  <Typography className={classes.Heading}>
                    {maxClass.class}
                  </Typography>
                </Grid>
                <Grid item md={3} xs={6} sm={3}>
                  <Typography className={classes.Heading}>
                    {maxClass.value} Applications
                  </Typography>
                </Grid>
              </Grid>

              <hr className={classes.Line} />
              <div className={classes.Details}>
                <PieChart
                  className={classes.Pie}
                  data={classData}
                  radius={40}
                  segmentsShift={(index) => (index === 0 ? 7 : 0.5)}
                  onClick={handleClassClick}
                />
                <div className={classes.List}>
                  <CardContent className={classes.RightContent}>
                    <ul className={classes.ListContainer}>
                      {classData.map((value: any, index: any) => (
                        <Box key={index}>
                          <li
                            className={classes.ListHeadings}
                            style={{color: `${value.color}`}}
                          >
                            {value.title}
                          </li>
                          <li className={classes.ListMuted}>({value.value})</li>
                        </Box>
                      ))}
                    </ul>
                  </CardContent>
                </div>
                {}
                {}
              </div>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12} sm={12} md={6}>
          <Box className={classes.title}>Application by status</Box>
          <Card className={classes.Container}>
            <CardContent className={classes.Content}>
              <Grid container spacing={4} style={{marginTop: "30px"}}></Grid>
              <Grid item xs={12} sm={12} md={12}>
                <FormLabel className={classes.Subheading}>Class</FormLabel>
                <TextField
                  style={{width: "100%", height: "50%"}}
                  variant="outlined"
                  id="board"
                  name="board"
                  value={status}
                  onChange={handleChange}
                  SelectProps={{
                    native: true,
                  }}
                  select
                >
                  <option className={classes.options} id="all" value="all">
                    All
                  </option>
                  {statusOptions?.map((options: any, index: any) => {
                    return (
                      <>
                        {options !== "all" && (
                          <option
                            className={classes.options}
                            id={options}
                            key={index}
                            value={options}
                          >
                            {options}
                          </option>
                        )}
                      </>
                    );
                  })}
                </TextField>
              </Grid>
              <hr className={classes.Line} style={{marginTop: "10px"}} />
              <div className={classes.Details}>
                <PieChart
                  className={classes.Pie}
                  data={finalStatusData[status]}
                  radius={40}
                  segmentsShift={(index) => (index === 0 ? 7 : 0.5)}
                  onClick={handleStatusClick}
                />
                <div className={classes.List}>
                  <CardContent className={classes.RightContent}>
                    <ul className={classes.ListContainer}>
                      {finalStatusData[status]?.map(
                        (value: any, index: any) => (
                          <Box key={index}>
                            <li
                              className={classes.ListHeadings}
                              style={{color: `${value.color}`}}
                            >
                              {value.title}
                            </li>
                            <li className={classes.ListMuted}>
                              ({value.value})
                            </li>
                          </Box>
                        )
                      )}
                    </ul>
                  </CardContent>
                </div>
                {}

                {}
              </div>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </>
  );
}

export default Analytics;
