import Box from "@material-ui/core/Box";
import makeStyles from "@material-ui/core/styles/makeStyles";
import Typography from "@material-ui/core/Typography";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Divider from "@material-ui/core/Divider";
import DialogActions from "@material-ui/core/DialogActions"
import GenericButton from "./GenericButton";
import close from "../assets/icons/close.svg";

const useStyles = makeStyles((theme:any) => ({
  title: {
    ...theme.typography.confirmationTitle,
  },
}));
type TProps = {
  open: boolean;
  title: string;
  content: string;
  handleClose: () => void;
  handleDelete: () => void;
};
function ConfirmationModal(props: TProps) {
  const classes = useStyles();
  return (
    <Dialog
      PaperProps={{
        style: {
          borderRadius: "10px",
          boxShadow:
            "0px 16px 24px rgba(0, 0, 0, 0.14), 0px 6px 30px rgba(0, 0, 0, 0.12), 0px 8px 10px rgba(0, 0, 0, 0.2)",
          width: "450px",
          padding: "10px",
        },
      }}
      open={props.open}
      onClose={props.handleClose}
    >
      <DialogTitle id="title">
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <Box className={classes.title}>{props.title}</Box>
          <img src={close} alt="" style={{ cursor: "pointer" }} onClick={props.handleClose} />
        </Box>
      </DialogTitle>
      <Divider style={{ background: "#E5E7E8", margin: "5px -10px 10px -10px" }} />
      <DialogContent>
        <DialogContentText>
          <Typography variant="caption">{props.content}</Typography>
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <GenericButton background="light" onClick={props.handleDelete} style={{ color: "#82889E" }}>
          Yes
        </GenericButton>
        <GenericButton background="blue" onClick={props.handleClose} color="secondary" autoFocus>
          No
        </GenericButton>
      </DialogActions>
    </Dialog>
  );
}
export default ConfirmationModal;
