import makeStyles from "@material-ui/core/styles/makeStyles";

const useStyles: any = makeStyles((theme:any) => ({
  main: {
    backgroundColor: theme.palette.background.paper,
    borderRadius: "16px",
    padding: theme.spacing(3),
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
    minHeight: "60vh",
  },
  school: {
    backgroundColor: theme.palette.background.default,
    borderRadius: "6px",
    marginBottom: theme.spacing(2),
    padding: theme.spacing(2),
    cursor: "pointer",
  },
  forms: {
    backgroundColor: theme.palette.background.default,
    borderRadius: "6px",
    marginBottom: theme.spacing(2),
    padding: theme.spacing(2),
    cursor: "pointer",
    display: "flex",
    flexDirection:"row",
  },
  emptyState: {
    ...theme.typography.emptyState,
    margin: "10px 0 10px 0",
    [theme.breakpoints.between("xs", "sm")]: {},
  },
  emptyTitle: {
    ...theme.typography.emptyTitle,
  },
  paper: {
    position: "absolute",
    left: "50%",
    top: "45%",
    transform: "translate(-50%, -50%)",
    width: "50%",
    backgroundColor: "#FFF",
    border: "0px solid #000",
    borderRadius: "15px",
    padding: "10px 30px 30px 30px",
  }
}));

export default useStyles;
