import axios from "axios";

const success = (pos: any, updateLocation: any, state?: any) => {
  const crd = pos.coords;
  console.log("Your current position is:");
  console.log(`Latitude : ${crd.latitude}`);
  console.log(`Longitude: ${crd.longitude}`);
  console.log(`More or less ${crd.accuracy} meters.`);
  axios
    .get(
      "https://us1.locationiq.com/v1/reverse.php?key=pk.5a2945781fa71391231153de6c85fd9b&lat=" +
        crd.latitude +
        "&lon=" +
        crd.longitude +
        "&format=json"
    )
    .then((res) => {
      console.log(res);
      updateLocation(res.data.address);
      if (state) {
        window.location.replace(window.location.origin);
      }
    });
};
const errors = (err: any) => {
  console.warn(`ERROR(${err.code}): ${err.message}`);
};
const options = {
  enableHighAccuracy: true,
  timeout: 10000,
  maximumAge: 0,
};
export const geolocation = (updateLocation: any) => {
  if (navigator.geolocation) {
    navigator.permissions.query({ name: "geolocation" }).then(function (result) {
      if (result.state === "granted") {
        console.log(result.state);
        //If granted then you can directly call your function here
        navigator.geolocation.getCurrentPosition((pos) => success(pos, updateLocation));
      } else if (result.state === "prompt") {
        console.log(result.state);
        navigator.geolocation.getCurrentPosition(
          (pos) => {
            success(pos, updateLocation, "prompt");
          },
          errors,
          options
        );
      } else if (result.state === "denied") {
        //If denied then you have to show instructions to enable location
      }
      result.onchange = function () {
        console.log(result.state);
      };
    });
  } else {
    alert("Sorry Not available!");
  }
};
