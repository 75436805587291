import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import makeStyles from "@material-ui/core/styles/makeStyles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import Avatar from "@material-ui/core/Avatar";
import Divider from "@material-ui/core/Divider";
import {
  fetchCampusDetails,
  fetchUserDetails,
  getActiveSubscription,
  getOrgDetails,
  subCampusLinkage,
  getSubscriptionHistory,
  getAllInvitedUsers,
  setupConnectAccount,
} from "../../api";
import password from "../../assets/icons/password.svg";
import orgdetails from "../../assets/icons/orgdetails.svg";
import manageuser from "../../assets/icons/manageuser.svg";
import subscription from "../../assets/icons/subscription.svg";
import userdetails from "../../assets/icons/userdetails.svg";
import {useEffect, useState} from "react";
import LoadingScreen from "../../components/LoadingScreen";
import ParentDetails from "./ParentDetails";
import AddUsers from "./AddUsers";
import OrganisationDetails from "./OrganisationDetails";
import PasswordSettings from "./PasswordSettings";
import PageHeadBase from "../../components/PageHeadBase";
import bank from "../../assets/icons/bank.svg";
import BankDetails from "./BankDetails";
import GenericButton from "../../components/GenericButton";
import Subscription from "./Subscription";
import moment from "moment";
import ApplicationFee from "./ApplicationFee";
const useStyles = makeStyles((theme: any) => ({
  root: {
    margin: theme.spacing(4),
  },
  title: {
    ...theme.typography.pageTitle,
    marginBottom: theme.spacing(4),
  },
  nav: {
    background: "#3B3A3C",
    boxShadow: "3px 3px 20px rgba(96, 125, 139, 0.5)",
    borderRadius: "16px 0px 0px 16px",
    padding: theme.spacing(2),
    margin: "30px 0 30px 0",
  },
  divider: {
    background: "#E5E7E8",
    mixBlendMode: "soft-light",
    opacity: 0.8,
    margin: theme.spacing(3, -1, 3, -1),
  },
  divider2: {
    background: "#E5E7E8",
    margin: theme.spacing(4, 0, 3, 0),
    [theme.breakpoints.between("0", "xs")]: {
      margin: theme.spacing(2, 0, 2, 0),
    },
  },
  navTitle: {
    ...theme.typography.profileNav,
  },
  tab: {
    marginBottom: theme.spacing(4),
    cursor: "pointer",
  },
  active: {
    marginLeft: theme.spacing(-2),
    padding: theme.spacing(2),
    background:
      "linear-gradient(to right, #3EC1D3 0%,#3EC1D3 4% ,#214C54 4%,#214C54 100%);",
    borderRadius: "0px 30px 30px 0px",
    marginBottom: theme.spacing(4),
    cursor: "pointer",
  },
  main: {
    background: "#FFFFFF",
    borderRadius: "0px 16px 16px 0px",
    boxShadow: "3px 3px 20px rgba(96, 125, 139, 0.5)",
    padding: theme.spacing(3, 5, 3, 5),
    margin: "30px 0 30px 0",
    [theme.breakpoints.between("0", "xs")]: {
      borderRadius: "16px",
      width: "100%",
      marginBottom: "20px",
      padding: theme.spacing(2),
      marginTop: "20px",
    },
  },
  container: {
    background: "#F6F8FA",
    borderRadius: "18px",
    padding: theme.spacing(3, 5, 3, 5),
    width: "auto",
    [theme.breakpoints.between("xs", "sm")]: {
      width: "100%",
    },
    [theme.breakpoints.between("0", "xs")]: {
      width: "100%",
      padding: theme.spacing(2),
    },
  },
  containerTitle: {
    ...theme.typography.displayTitle,
    marginBottom: theme.spacing(4),
  },
  textfield: {
    ...theme.typography.reportingContent,
    fontSize: "14px",
  },
  value: {
    ...theme.typography.pageTitle,
  },
}));
function Profile(props: any) {
  const openTab = props.location.state?.tab;
  const subscriptionHeader = props.location.state?.updateFree;
  const classes = useStyles();
  const [loading, setLoading] = useState(true);
  const [loadingOrg, setLoadingOrg] = useState(true);
  const [loadingCampus, setLoadingCampus] = useState(true);
  const [details, setDetails]: any = useState();
  const [orgData, setOrgData]: any = useState();
  const [campusData, setCampusData]: any = useState();
  const [editDetails, setEditDetails] = useState(false);
  const [subData, setSubData]: any = useState();
  const [loadingSub, setLoadingSub] = useState(false);
  const [stripeData, setStripeData] = useState();
  const [loadingStripe, setLoadingStripe] = useState(false);
  const toggleEditDetails = () => setEditDetails((prev) => !prev);
  const [currentTab, setCurrentTab] = useState(openTab || "User Details");
  const [role, setRole] = useState("");
  const [disableButton, setDisableButton] = useState({});
  const updateOrgData = (data: any) => {
    setOrgData(data);
    setStripeData(data?.orgDetails?.stripe);
  };
  const updateCampusData = (data: any) => {
    setCampusData(data);
    setApplicationFee({
      currency: data.additionalInfo?.currency || "AUD",
      amount: data.additionalInfo?.amount / 100 || null,
    });
    setInitialFee((data?.additionalInfo?.amount ? false : true) || false);
  };
  const [bankInfo, setBankInfo] = useState();
  const [editBankInfo, setEditBankInfo] = useState(false);
  const [initialFee, setInitialFee] = useState(
    (campusData?.additionalInfo?.amount ? false : true) || false
  );
  const [editAppFee, setEditAppFee] = useState(initialFee || false);
  const [loadingSubscription, setLoadingSubscription] = useState(true);
  const [features, setFeatures] = useState<any>();
  const [numOfActiveUsers, setNumOfActiveUsers] = useState(0);
  const [applicationFee, setApplicationFee] = useState({
    currency: campusData?.additionalInfo?.currency || "AUD",
    amount: campusData?.additionalInfo?.amount / 100 || null,
  });

  const toggleEditBankInfo = () => setEditBankInfo((prev) => !prev);
  const toggleEditAppFee = () => setEditAppFee((prev) => !prev);
  const updateBankInfo = (data: any) => setBankInfo(data);

  const [subscriptionSuccess, setSubscriptionSuccess] = useState(false);
  const [subscriptionError, setSubscriptionError] = useState(false);

  useEffect(() => {
    window.scrollTo(0, 0);
    const customerId = props.user.attributes["custom:userName"];
    const orgId = props.user.attributes["custom:orgId"];
    const campusId = props.user.attributes["custom:campusId"];
    getAllInvitedUsers(campusId)
      .then((res) => {
        console.log("All Invited Users");
        console.log(res.data.userList.length);
        setNumOfActiveUsers(res.data.userList.length);
      })
      .catch((err) => {
        console.log(err);
      });
    fetchUserDetails(customerId, props.user.attributes.email)
      .then((res) => {
        console.log("Data:");
        console.log(res);
        setDetails(res.data);
        setRole(res.data.role);
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
        console.log(err);
        if (err.response) {
          console.log(err.response.status);
          if (err.response.status === 401) props.handleLogout();
        }
      });

    console.log("OrgId is: " + orgId);
    props.user.attributes["custom:role"] !== "Parent" &&
      getOrgDetails(String(orgId))
        .then((res) => {
          console.log("OrgDetails");
          console.log(res);
          setOrgData(res.data);
          setStripeData(res.data?.orgDetails?.stripe);
          setLoadingOrg(false);
        })
        .catch((err) => {
          console.log("Org APi Error");
          console.log(err);
          setLoadingOrg(false);
        });

    props.user.attributes["custom:role"] !== "Parent" &&
      fetchCampusDetails(orgId, campusId)
        .then((res) => {
          console.log("Campus");
          console.log(res);
          setBankInfo(res.data.bankInfo || undefined);
          setCampusData(res.data);
          setApplicationFee({
            currency: res.data.additionalInfo?.currency || "AUD",
            amount: res.data.additionalInfo?.amount / 100 || null,
          });
          setInitialFee(
            (res.data?.additionalInfo?.amount ? false : true) || false
          );
          setLoadingCampus(false);
        })
        .catch((err: any) => {
          setLoadingCampus(false);
          console.log(err);
          if (err.response) {
            console.log(err.response.status);
            if (err.response.status === 401) props.handleLogout();
          }
        });

    updateHistory(orgId, campusId);

    props.user.attributes["custom:role"] === "Org_Admin" &&
      getActiveSubscription(orgId, campusId)
        .then((res) => {
          console.log("Subscription Result");
          console.log(res);
          setFeatures(res.data);
          setLoadingSubscription(false);
        })
        .catch((err) => {
          console.log("Subscription Error");
          console.log(err);
          setLoadingSubscription(false);
        });
    if (props.location.search) {
      const session = new URLSearchParams(props.location.search);
      const sessionId = session.get("session_id")!;
      const status = session.get("status");
      if (sessionId) {
        setLoadingSub(true);
        setCurrentTab("Subscription Details");
        const subId = localStorage.getItem("subId")!;
        subCampusLinkage(orgId, campusId, subId, sessionId)
          .then((subLinkData) => {
            setSubscriptionSuccess(true);
            console.log("Linked Campus", subLinkData.data);
            localStorage.removeItem("subId");
            setSubData(subLinkData.data?.data);
            if (subLinkData.data?.data.name !== "Free")
              props.updateProfile("subscription");
            setLoadingSub(false);
          })
          .catch((err: any) => {
            setSubscriptionError(true);
            setLoadingSub(false);
            console.log(err);
            if (err.response) {
              console.log(err.response.status);
              if (err.response.status === 401) props.handleLogout();
            }
          });
      } else if (status) {
        setLoadingStripe(true);
        setCurrentTab("Application Fee");
        setupConnectAccount(orgId, props.location.pathname)
          .then((stripeObject) => {
            console.log(stripeObject.data);
            if (stripeObject.data.stripeData.hasOwnProperty("status")) {
              setStripeData(stripeObject.data.stripeData);
              setEditAppFee(true);
              setLoadingStripe(false);
            }
          })
          .catch((err) => {
            console.log("App Fee Error");
            console.log(err);
            setLoading(false);
            if (err.response.status === 401) props.handleLogout();
          });
      }
    } else {
      if (props.user.attributes["custom:role"] === "Org_Admin") {
        setLoadingSub(true);
        getActiveSubscription(orgId, campusId)
          .then((activeSubData) => {
            setSubscriptionSuccess(false);
            setSubscriptionError(false);
            console.log("Sub", activeSubData.data);
            setSubData(activeSubData.data);
            setLoadingSub(false);
          })
          .catch((err: any) => {
            setLoadingSub(false);
            setSubscriptionSuccess(false);
            setSubscriptionError(false);
            console.log(err);
            if (err.response) {
              console.log(err.response.status);
              if (err.response.status === 401) props.handleLogout();
            }
          });
      }
      localStorage.removeItem("subId");
    }
  }, []);

  const updateHistory = (orgId: any, campusId: any) => {
    props.user.attributes["custom:role"] === "Org_Admin" &&
      getSubscriptionHistory(orgId, campusId)
        .then((res) => {
          console.log("Subscription History: ");
          console.log(res.data);
          const history = res.data;
          const today = moment().format("DD/MM/YYYY");
          const data: any = {};
          console.log("Today: ", today);
          for (let i = 0; i < history.length; i++) {
            if (today === history[i].startDate) {
              console.log("History", i);
              console.log(history[i]);
              data[history[i].name] = true;
            }
          }
          console.log("Data of History");
          console.log(data);
          setDisableButton(data);
        })
        .catch((err) => {
          console.log("History error: ");
          console.log(err);
        });
  };

  const smallScreen = useMediaQuery((theme: any) =>
    theme.breakpoints.between("0", "xs")
  );
  return (
    <Box
      {...(smallScreen && {
        style: {
          background:
            "linear-gradient(to bottom, #3B3A3C 0%,#3B3A3C 200px ,#F3F6F8 200px,#F3F6F8 100vh)",
          backgroundRepeat: "no-repeat",
          backgroundAttachment: "scrollable",
        },
      })}
    >
      <PageHeadBase
        title="My Profile"
        route={`My Profile`}
        history={props.history}
        currentTab={currentTab}
        setCurrentTab={setCurrentTab}
        role={role}
      >
        {smallScreen ? (
          <>
            {}
            <Box className={classes.main}>
              <Typography variant="h2">{currentTab}</Typography>
              <Divider className={classes.divider2} />

              {currentTab === "Password Settings" ? (
                <Box className={classes.container}>
                  <PasswordSettings email={props.user.attributes.email} />
                </Box>
              ) : currentTab === "Bank Details" ? (
                loadingCampus ? (
                  <LoadingScreen />
                ) : (
                  <Box className={classes.container}>
                    <BankDetails
                      edit={editBankInfo}
                      details={bankInfo}
                      user={props.user}
                      toggleEdit={toggleEditBankInfo}
                      updateBankInfo={updateBankInfo}
                      updateProfile={props.updateProfile}
                    />
                  </Box>
                )
              ) : (
                <>
                  {currentTab === "User Details" ? (
                    <>
                      {loading ? (
                        <LoadingScreen />
                      ) : (
                        <Box className={classes.container}>
                          <ParentDetails details={details} />
                        </Box>
                      )}
                    </>
                  ) : (
                    <>
                      {currentTab === "Manage Users" ? (
                        loading || loadingSubscription ? (
                          <LoadingScreen />
                        ) : (
                          <Box className={classes.container}>
                            <AddUsers
                              role={role}
                              details={details}
                              updateProfile={props.updateProfile}
                              features={features}
                              numOfActiveUsers={numOfActiveUsers}
                            />
                          </Box>
                        )
                      ) : (
                        <>
                          {currentTab === "Subscription Details" ? (
                            <>
                              {loadingSub ? (
                                <>
                                  <LoadingScreen />
                                  {}
                                </>
                              ) : (
                                <Subscription
                                  orgDetails={orgData}
                                  campusData={campusData}
                                  details={details}
                                  subData={subData}
                                  setSubData={setSubData}
                                  classes={classes}
                                  setLoadingSub={setLoadingSub}
                                  location={props.location.pathname}
                                  updateProfile={props.updateProfile}
                                  disableButton={disableButton}
                                  updateHistory={updateHistory}
                                  numOfApplications={props.numOfApplications}
                                  numOfActiveUsers={numOfActiveUsers}
                                  subscriptionHeader={subscriptionHeader}
                                  subscriptionSuccess={subscriptionSuccess}
                                  setSubscriptionSuccess={
                                    setSubscriptionSuccess
                                  }
                                  subscriptionError={subscriptionError}
                                  setSubscriptionError={setSubscriptionError}
                                />
                              )}
                            </>
                          ) : (
                            <>
                              {currentTab === "Application Fee" ? (
                                <>
                                  {loading || loadingStripe ? (
                                    <>
                                      <LoadingScreen />
                                    </>
                                  ) : (
                                    <ApplicationFee
                                      orgDetails={orgData}
                                      campusData={campusData}
                                      details={details}
                                      setOrgData={updateOrgData}
                                      stripeData={stripeData}
                                      setStripeData={setStripeData}
                                      applicationFee={applicationFee}
                                      updateCampusData={updateCampusData}
                                      edit={editAppFee}
                                      toggleEdit={setEditAppFee}
                                      initialFee={initialFee}
                                      setInitialFee={setInitialFee}
                                      location={props.location.pathname}
                                    />
                                  )}
                                </>
                              ) : (
                                <OrganisationDetails
                                  role={role}
                                  edit={editDetails}
                                  user={props.user}
                                  toggleEdit={toggleEditDetails}
                                  orgDetails={orgData}
                                  campusData={campusData}
                                  updateOrgData={updateOrgData}
                                  updateCampusData={updateCampusData}
                                />
                              )}
                            </>
                          )}
                        </>
                      )}
                    </>
                  )}
                  {currentTab === "Documents" && (
                    <Box className={classes.containerTitle}>Documents</Box>
                  )}
                </>
              )}
            </Box>
          </>
        ) : (
          <Box display="flex">
            <Box className={classes.nav} width="15%">
              <Box position="relative" width="50px">
                <Avatar
                  style={{
                    width: "50px",
                    height: "50px",
                    backgroundColor: "#FF9E45",
                    marginBottom: "15px",
                  }}
                >
                  <Typography variant="h4">
                    {props.user.attributes["custom:firstName"].split("")[0] +
                      props.user.attributes["custom:lastName"].split("")[0]}
                  </Typography>
                </Avatar>
                {}
              </Box>
              <Typography variant="h4">
                {props.user.attributes["custom:firstName"] +
                  " " +
                  props.user.attributes["custom:lastName"]}
              </Typography>
              <Divider className={classes.divider} />
              <Box
                className={
                  classes[currentTab === "User Details" ? "active" : "tab"]
                }
                onClick={() => setCurrentTab("User Details")}
              >
                <img src={userdetails} alt="" />
                <Box className={classes.navTitle}>User Details</Box>
              </Box>
              {props.user.attributes["custom:role"] === "Org_Admin" && (
                <Box
                  className={
                    classes[currentTab === "Bank Details" ? "active" : "tab"]
                  }
                  onClick={() => setCurrentTab("Bank Details")}
                >
                  <img src={bank} alt="" />
                  <Box className={classes.navTitle}>Bank Details</Box>
                </Box>
              )}
              {props.user.attributes["custom:role"] === "Org_Admin" && (
                <Box
                  className={
                    classes[
                      currentTab === "Subscription Details" ? "active" : "tab"
                    ]
                  }
                  onClick={() => setCurrentTab("Subscription Details")}
                >
                  <img src={subscription} alt="" />
                  <Box className={classes.navTitle}>Subscription Details</Box>
                </Box>
              )}
              {props.user.attributes["custom:role"] === "Org_Admin" && (
                <Box
                  className={
                    classes[currentTab === "Application Fee" ? "active" : "tab"]
                  }
                  onClick={() => setCurrentTab("Application Fee")}
                >
                  <img src={subscription} alt="" />
                  <Box className={classes.navTitle}>Application Fee</Box>
                </Box>
              )}
              {}
              <Box
                className={
                  classes[currentTab === "Password Settings" ? "active" : "tab"]
                }
                onClick={() => setCurrentTab("Password Settings")}
              >
                <img src={password} alt="" />
                <Box className={classes.navTitle}>Password Settings</Box>
              </Box>
              {role === "Org_Admin" || role === "Org_User" ? (
                <>
                  <Box
                    className={
                      classes[
                        currentTab === "Organisation Details" ? "active" : "tab"
                      ]
                    }
                    onClick={() => setCurrentTab("Organisation Details")}
                  >
                    <img src={orgdetails} alt="" />
                    <Box className={classes.navTitle}>Organisation Details</Box>
                  </Box>
                  <Box
                    className={
                      classes[currentTab === "Manage Users" ? "active" : "tab"]
                    }
                    onClick={() => setCurrentTab("Manage Users")}
                  >
                    <img src={manageuser} alt="" />
                    <Box className={classes.navTitle}>Manage Users</Box>
                  </Box>
                </>
              ) : null}
            </Box>
            <Box className={classes.main} width="85%">
              <Box
                display="flex"
                justifyContent="space-between"
                alignItems="center"
              >
                <Typography variant="h2">{currentTab}</Typography>
                {currentTab === "Bank Details" && (
                  <GenericButton
                    background="light"
                    disabled={bankInfo ? editBankInfo : true}
                    onClick={toggleEditBankInfo}
                  >
                    Edit
                  </GenericButton>
                )}
                {currentTab === "Application Fee" &&
                  orgData?.orgDetails?.stripe?.status && (
                    <GenericButton
                      background="light"
                      disabled={applicationFee.amount ? editAppFee : true}
                      onClick={toggleEditAppFee}
                    >
                      Edit
                    </GenericButton>
                  )}
                {currentTab === "Organisation Details" && role === "Org_Admin" && (
                  <GenericButton
                    background="light"
                    disabled={orgData ? editDetails : true}
                    onClick={toggleEditDetails}
                  >
                    Edit
                  </GenericButton>
                )}
              </Box>
              <Divider className={classes.divider2} />
              {currentTab === "Password Settings" ? (
                <Box className={classes.container}>
                  <PasswordSettings email={props.user.attributes.email} />
                </Box>
              ) : (
                <>
                  {currentTab === "User Details" ? (
                    <>
                      {}
                      {loading ? (
                        <LoadingScreen />
                      ) : (
                        <Box className={classes.container}>
                          <ParentDetails details={details} />
                        </Box>
                      )}
                    </>
                  ) : currentTab === "Bank Details" ? (
                    loadingCampus ? (
                      <LoadingScreen />
                    ) : (
                      <Box className={classes.container}>
                        <BankDetails
                          edit={editBankInfo}
                          details={bankInfo}
                          user={props.user}
                          toggleEdit={toggleEditBankInfo}
                          updateBankInfo={updateBankInfo}
                          updateProfile={props.updateProfile}
                        />
                      </Box>
                    )
                  ) : (
                    <>
                      {currentTab === "Manage Users" ? (
                        loading || loadingSubscription ? (
                          <LoadingScreen />
                        ) : (
                          <Box className={classes.container}>
                            <AddUsers
                              role={role}
                              details={details}
                              updateProfile={props.updateProfile}
                              features={features}
                              numOfActiveUsers={numOfActiveUsers}
                            />{" "}
                          </Box>
                        )
                      ) : (
                        <>
                          {currentTab === "Subscription Details" ? (
                            <>
                              {loadingSub ? (
                                <>
                                  <LoadingScreen />
                                  {}
                                </>
                              ) : (
                                <Subscription
                                  orgDetails={orgData}
                                  campusData={campusData}
                                  details={details}
                                  subData={subData}
                                  setSubData={setSubData}
                                  classes={classes}
                                  setLoadingSub={setLoadingSub}
                                  location={props.location.pathname}
                                  updateProfile={props.updateProfile}
                                  disableButton={disableButton}
                                  updateHistory={updateHistory}
                                  numOfApplications={props.numOfApplications}
                                  numOfActiveUsers={numOfActiveUsers}
                                  subscriptionHeader={subscriptionHeader}
                                  subscriptionSuccess={subscriptionSuccess}
                                  setSubscriptionSuccess={
                                    setSubscriptionSuccess
                                  }
                                  subscriptionError={subscriptionError}
                                  setSubscriptionError={setSubscriptionError}
                                />
                              )}
                            </>
                          ) : (
                            <>
                              {currentTab === "Application Fee" ? (
                                <>
                                  {loading || loadingStripe ? (
                                    <>
                                      <LoadingScreen />
                                    </>
                                  ) : (
                                    <ApplicationFee
                                      orgDetails={orgData}
                                      campusData={campusData}
                                      details={details}
                                      setOrgData={updateOrgData}
                                      stripeData={stripeData}
                                      setStripeData={setStripeData}
                                      applicationFee={applicationFee}
                                      updateCampusData={updateCampusData}
                                      edit={editAppFee}
                                      toggleEdit={setEditAppFee}
                                      initialFee={initialFee}
                                      setInitialFee={setInitialFee}
                                      location={props.location.pathname}
                                    />
                                  )}
                                </>
                              ) : (
                                <OrganisationDetails
                                  role={role}
                                  edit={editDetails}
                                  user={props.user}
                                  toggleEdit={toggleEditDetails}
                                  orgDetails={orgData}
                                  campusData={campusData}
                                  updateOrgData={updateOrgData}
                                  updateCampusData={updateCampusData}
                                />
                              )}
                            </>
                          )}
                        </>
                      )}
                    </>
                  )}
                  {currentTab === "Documents" && (
                    <Box className={classes.containerTitle}>Documents</Box>
                  )}
                </>
              )}
            </Box>
          </Box>
        )}
      </PageHeadBase>
    </Box>
  );
}

export default Profile;
