export function updateCheckbox(key: any, event: any) {
  key.checkbox[event.target.name] = event.target.checked;
  const checkboxArray: any[] = key.value;
  const index = checkboxArray.indexOf(event.target.value);
  if (event.target.checked) {
    checkboxArray.push(event.target.value);
  } else {
    checkboxArray.splice(index, 1);
  }
}

export function updateChange(key: any, event: any, errors: any, id?: any) {
  if (event.target.type === "file") {
    if (
      event.target.id === "sign1" ||
      event.target.id === "sign2" ||
      event.target.id === "photo"
    ) {
      if (event.target.files[0].name.match(/.(jpg|jpeg|png)$/i)) {
        if (event.target.files[0].size <= 10485760) {
          console.log(event.target.files[0]);
          errors[event.target.name + "_" + event.target.id] = "";
          key[`${event.target.name + "_" + event.target.id}_file`]["file"] =
            event.target.files[0];
        } else {
          errors[event.target.name + "_" + event.target.id] =
            "File size exceeds 10mb!";
        }
      } else {
        errors[event.target.name + "_" + event.target.id] =
          "Unsupported file type!";
      }
    } else if (
      event.target.files[0].name.match(/.(jpg|jpeg|png|pdf|docx|bmp)$/i)
    ) {
      if (event.target.files[0].size <= 10485760) {
        console.log(event.target.files[0]);
        errors[event.target.name + "_" + event.target.id] = "";
        key[`${event.target.name + "_" + event.target.id}_file`]["file"] =
          event.target.files[0];
      } else {
        errors[event.target.name + "_" + event.target.id] =
          "File size exceeds 10mb!";
      }
    } else {
      errors[event.target.name + "_" + event.target.id] =
        "Unsupported file type!";
    }
    event.target.value = "";
  } else if (event.target.type === "radio" && event.target.id)
    key[`${event.target.name + "_" + event.target.id}_radio`].value =
      event.target.value;
  else if (id) {
    key[event.target.name + "_" + id] = event.target.value;
  } else key[event.target.name + "_" + event.target.id] = event.target.value;
}
