import Avatar from "@material-ui/core/Avatar";
import Box from "@material-ui/core/Box";
import Hidden from "@material-ui/core/Hidden";
import Typography from "@material-ui/core/Typography";
import withStyles from "@material-ui/core/styles/withStyles";
import React from "react";
import {Link, NavLink} from "react-router-dom";
import MenuButton from "../MenuButton";
import digiLogo from "../../assets/digiLogo.svg";
import {VscHome} from "react-icons/vsc";
import {Auth} from "aws-amplify";
import {
  fetchCampusDetails,
  getAllInvitedUsers,
  getActiveSubscription,
} from "../../api";
import config from "../../config/root-config";
function NavItem(props: any) {
  return (
    <NavLink
      to={props.to}
      className={props.className}
      activeClassName={props.activeClassName}
    >
      {props.icon}
      {props.title}
    </NavLink>
  );
}

const useStyles: any = (theme: any) => ({
  root: {
    backgroundColor: theme.palette.background.paper,
    display: "flex",
    [theme.breakpoints.down("xs")]: {
      height: "10vh",
    },
    position: "sticky",
    top: 0,
    left: 0,
    zIndex: 2,
  },
  logoContainer: {
    borderBottom: theme.palette.header.border,
    display: "flex",
    alignItems: "center",
  },
  logo: {
    marginLeft: theme.spacing(4),
    marginRight: theme.spacing(4),
  },
  schoolLogo: {
    marginLeft: theme.spacing(4),
    marginBottom: theme.spacing(1),
  },
  options: {
    display: "flex",
    justifyContent: "flex-start",
  },
  avatarContainer: {
    borderBottom: theme.palette.header.border,
  },
  profile: {
    marginRight: theme.spacing(4),
    cursor: "pointer",
    padding: "10px 0 10px 0",
  },
  profile2: {
    marginRight: theme.spacing(2),
    cursor: "pointer",
    padding: "10px 0 10px 0",
  },
  bottomNavMain: {
    backgroundColor: theme.palette.background.default,
    zIndex: 1,
  },
  bottomNav: {
    textDecoration: "none",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    ...theme.typography.subtitle2,
  },
  navLink: {
    textDecoration: "none",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    padding: "1em 1em 1em 1em",
    borderBottom: theme.palette.header.border,
    ...theme.typography.navbar,
  },
  activeLink: {
    border: theme.palette.header.border,
    borderTopLeftRadius: "1em",
    borderTopRightRadius: "1em",
    backgroundColor: theme.palette.secondary.main,
    borderBottom: theme.palette.header.borderActive,
    color: theme.palette.background.paper,
  },
  activeBottomNav: {
    color: theme.palette.primary.main,
  },
  icon: {
    width: "1.3em",
    height: "1.3em",
    marginRight: "0.5em",
  },
});

class Header extends React.Component<any, any> {
  constructor(props: any) {
    super(props);
    this.state = {
      mobileOpen: false,
      subscriptionPlan: "",
      schoolName: "",
    };
    this.handleDrawerToggle = this.handleDrawerToggle.bind(this);
    this.handleLogout = this.handleLogout.bind(this);
    this.handleProfile = this.handleProfile.bind(this);
    this.handleBank = this.handleBank.bind(this);
    this.handleInvite = this.handleInvite.bind(this);
    this.handleSubscription = this.handleSubscription.bind(this);
    this.fetchData = this.fetchData.bind(this);
  }
  componentDidMount() {
    this.fetchData();
  }
  handleDrawerToggle() {
    this.setState((state: any) => ({mobileOpen: !state.mobileOpen}));
  }
  async fetchData() {
    const orgId = this.props.user?.attributes["custom:orgId"];
    const campusId = this.props.user?.attributes["custom:campusId"];
    this.props.user.attributes["custom:role"] !== "Parent" &&
      fetchCampusDetails(orgId, campusId)
        .then((res) => {
          this.setState({schoolName: res.data.campusName.replaceAll(" ", "_")});
          if (res.data.bankInfo.accountNo)
            this.props.updateProfile("bankDetails");
        })
        .catch((err: any) => {
          console.log(err);
          if (err.response) {
            console.log(err.response.status);
          }
        });

    this.props.user.attributes["custom:role"] !== "Parent" &&
      getAllInvitedUsers(campusId)
        .then((res) => {
          if (res.data.userList.length > 1)
            this.props.updateProfile("userInvite");
        })
        .catch((err: any) => {
          console.log(err);
        });

    this.props.user.attributes["custom:role"] !== "Parent" &&
      getActiveSubscription(orgId, campusId)
        .then((res) => {
          console.log("Subscription Result in Header");
          console.log(res.data);
          if (res.data.name !== "Free")
            this.props.updateProfile("subscription");
          if (res.data.name === "Free")
            this.setState({subscriptionPlan: "Free"});
        })
        .catch((err) => {
          console.log("Subscription Error in Header");
          console.log(err);
        });
  }
  handleProfile = (event: any) => {
    event.preventDefault();
    this.props.history.push("/myProfile");
  };
  handleBank = (event: any) => {
    event.preventDefault();
    this.props.history.push("/myProfile", {
      tab: "Bank Details",
    });
  };
  handleInvite = (event: any) => {
    event.preventDefault();
    this.props.history.push("/myProfile", {
      tab: "Manage Users",
    });
  };
  handleSubscription = (event: any) => {
    event.preventDefault();
    this.props.history.push("/myProfile", {
      tab: "Subscription Details",
      updateFree: this.state.subscriptionPlan === "Free" ? "Free" : "",
    });
  };
  handleLogout = async () => {
    try {
      await Auth.signOut().then(() => {
        this.props.updateUser();
        localStorage.clear();
        clearTimeout(this.props.timer);
        this.props.history.push("/login");
      });
    } catch (error) {
      console.log("error signing out: ", error);
    }
  };
  render() {
    const {classes} = this.props;
    const isParent =

    this.props.user?.attributes["custom:role"] === "Org_Admin" ||
    this.props.user?.attributes["custom:role"] === "Org_User"
      ? false
      : true;

    const isUser =
    this.props.user?.attributes["custom:role"] === "Org_User"

    const pathName = this.props.history.location.pathname;
    const tracker =

    !(this.props.userInvite &&
      this.props.bankDetails &&
      this.props.subscriptionActive)
    
    const user = {
      Title: "Invite Users",
      onClick: this.handleInvite,
    };
    const bank = {
      Title: "Bank Details",
      onClick: this.handleBank,
    };
    const sub = {
      Title: "Subscription",
      onClick: this.handleSubscription,
    };
    const listOfItems = [];
    if (!this.props.bankDetails) listOfItems.push(bank);
    if (!this.props.userInvite) listOfItems.push(user);
    if (!this.props.subscriptionActive) listOfItems.push(sub);

    const schoolLogo = `https://${config.s3StaticBucket}.s3.ap-southeast-2.amazonaws.com/${this.state.schoolName}.png`;
    const schoolLogoForParent = `https://${config.s3StaticBucket}.s3.ap-southeast-2.amazonaws.com/${this.props.schoolName}.png`;

    return (
      <>
        <div className={classes.root}>
          <Box display="flex" marginTop="10px">
            {this.state.schoolName && !isParent && (
              <Link to="/dashboard" className={classes.logoContainer}>
                <img
                  width="60px"
                  height="60px"
                  src={schoolLogo}
                  alt=""
                  className={classes.schoolLogo}
                />
              </Link>
            )}
            {this.props.schoolName && isParent && (
              <Link to="/dashboard" className={classes.logoContainer}>
                <img
                  width="60px"
                  height="60px"
                  src={schoolLogoForParent}
                  alt=""
                  className={classes.schoolLogo}
                />
              </Link>
            )}
            <Link to="/dashboard" className={classes.logoContainer}>
              <img
                src={digiLogo}
                alt="Solvitude"
                width="150vw"
                height="60vh"
                className={classes.logo}
              />
            </Link>
            <Hidden xsDown>
              <div className={classes.options}>
                <NavItem
                  to="/dashboard"
                  className={classes.navLink}
                  activeClassName={classes.activeLink}
                  icon={<VscHome className={classes.icon} />}
                  title="Home"
                />
                {}
              </div>
            </Hidden>
          </Box>
          <Box
            display="flex"
            flexDirection="row-reverse"
            alignItems="center"
            width="100vw"
            className={classes.avatarContainer}
          >
            <div className={classes.profile}>
              <MenuButton
                icon={
                  <Avatar
                    style={{
                      width: "2.2em",
                      height: "2.2em",
                      backgroundColor: "#FF9E45",
                    }}
                  >
                    <Typography variant="h4">
                      {this.props.user.attributes["custom:firstName"].split(
                        ""
                      )[0] +
                        this.props.user.attributes["custom:lastName"].split(
                          ""
                        )[0]}
                    </Typography>
                  </Avatar>
                }
                items={[
                  {Title: "My Profile", onClick: this.handleProfile},
                  {Title: "Logout", onClick: this.handleLogout},
                ]}
              />
            </div>
            {!isParent && !isUser && pathName !== "/myProfile" && tracker && (
              <div className={classes.profile2}>
                <MenuButton
                  icon={
                    <Avatar
                      style={{
                        width: "2.2em",
                        height: "2.2em",
                        backgroundColor: "#FF5353",
                      }}
                    >
                      <Typography variant="h4">!</Typography>
                    </Avatar>
                  }
                  items={listOfItems}
                />
              </div>
            )}
          </Box>
        </div>
        {}
      </>
    );
  }
}

export default withStyles(useStyles, {withTheme: true})(Header);
