const config = {
  uri: {
    digitalServicesURI: "/config/application/restapi/prod/url",
    backendURI: "/config/backend/restapi/prod/url",
    userManagementURI: "/config/student/restapi/prod/url",
    docManagementURI: "/config/docmgmt/restapi/prod/url",
    orgManagementURI: "/config/organisation/restapi/prod/url",
  },
  authConfig: {
    url: `https://dep-prod-ng.auth.ap-southeast-2.amazoncognito.com/oauth2/token`,
    authorization: `/config/dep-prod-ng-userpool/client-secret-base64`,
    contentType: `application/x-www-form-urlencoded`,
    scope: {
      Parent: `https://dep-prod.solvitude.com.au/appfee.write https://dep-prod.solvitude.com.au/getCampusDetails.read https://dep-prod.solvitude.com.au/getAddress.read https://dep-prod.solvitude.com.au/fetchCampusAnalytics.read https://dep-prod.solvitude.com.au/subscription.write https://dep-prod.solvitude.com.au/logUIErrors.write https://dep-prod.solvitude.com.au/createUser.write https://dep-prod.solvitude.com.au/getAllOrgCampus.read https://dep-prod.solvitude.com.au/fetchCustomerApplications.read https://dep-prod.solvitude.com.au/getParentChildren.read https://dep-prod.solvitude.com.au/createApplication.write https://dep-prod.solvitude.com.au/updateUser.write https://dep-prod.solvitude.com.au/fetchApplicationDetails.read https://dep-prod.solvitude.com.au/updateApplication.write https://dep-prod.solvitude.com.au/formSubmission.write https://dep-prod.solvitude.com.au/getParentUser.read https://dep-prod.solvitude.com.au/changePassword.read https://dep-prod.solvitude.com.au/getCampusUsers.read`,
      Org_Admin: `https://dep-prod.solvitude.com.au/dataSync.write https://dep-prod.solvitude.com.au/appfee.write https://dep-prod.solvitude.com.au/subscription.write https://dep-prod.solvitude.com.au/fetchCampusAnalytics.read https://dep-prod.solvitude.com.au/fetchAnalyticsApplications.read https://dep-prod.solvitude.com.au/logUIErrors.write https://dep-prod.solvitude.com.au/getCampusDetails.read https://dep-prod.solvitude.com.au/updateApplication.write https://dep-prod.solvitude.com.au/updateBankInfo.write https://dep-prod.solvitude.com.au/getParentUser.read https://dep-prod.solvitude.com.au/changePassword.read https://dep-prod.solvitude.com.au/fetchCampusApplications.read https://dep-prod.solvitude.com.au/sendStatusMail.write https://dep-prod.solvitude.com.au/addInvitedUsers.write https://dep-prod.solvitude.com.au/getCampusUsers.read https://dep-prod.solvitude.com.au/disableUser.write https://dep-prod.solvitude.com.au/enableUser.write https://dep-prod.solvitude.com.au/resendInviteMail.write https://dep-prod.solvitude.com.au/getOrganisationDetails.read https://dep-prod.solvitude.com.au/getCampusDetails.read https://dep-prod.solvitude.com.au/updateOrganisation.write https://dep-prod.solvitude.com.au/updateCampus.write https://dep-prod.solvitude.com.au/broadcast.write`,
      Org_User: `https://dep-prod.solvitude.com.au/dataSync.write https://dep-prod.solvitude.com.au/fetchCampusAnalytics.read https://dep-prod.solvitude.com.au/fetchAnalyticsApplications.read https://dep-prod.solvitude.com.au/logUIErrors.write https://dep-prod.solvitude.com.au/getParentUser.read https://dep-prod.solvitude.com.au/changePassword.read https://dep-prod.solvitude.com.au/fetchCampusApplications.read https://dep-prod.solvitude.com.au/getCampusUsers.read https://dep-prod.solvitude.com.au/getOrganisationDetails.read https://dep-prod.solvitude.com.au/getCampusDetails.read https://dep-prod.solvitude.com.au/broadcast.write`,
      AnonyParent: `https://dep-prod.solvitude.com.au/logUIErrors.write https://dep-prod.solvitude.com.au/createUser.write`,
      AnonyAdmin: `https://dep-prod.solvitude.com.au/subscription.write https://dep-prod.solvitude.com.au/sendAdminEmail.write https://dep-prod.solvitude.com.au/logUIErrors.write https://dep-prod.solvitude.com.au/createOrganisation.write https://dep-prod.solvitude.com.au/createCampus.write https://dep-prod.solvitude.com.au/deleteOrgCampus.write https://dep-prod.solvitude.com.au/signupAdmin.write`,
      AnonyUser: `https://dep-prod.solvitude.com.au/logUIErrors.write https://dep-prod.solvitude.com.au/confirmInvitedUser.write https://dep-prod.solvitude.com.au/updateInvitedUser.write https://dep-prod.solvitude.com.au/getInvitedUser.read`,
      AnonyReset: `https://dep-prod.solvitude.com.au/logUIErrors.write https://dep-prod.solvitude.com.au/changePassword.read`,
    },
    grantType: `client_credentials`,
  },
  s3Config: {
    region: "ap-southeast-2",
    bucket: "/config/s3/docmgmt/bucketname/prod",
  },
  pool: {
    region: "ap-southeast-2",
    userPoolId: "/config/dep-prod-ng-userpool/userpool-id",
    userPoolWebClientId: "/config/dep-prod-ng-userpool/web-client-id",
    mandatorySignIn: false,
  },
  isCaptchaActive: true,
  s3StaticBucket: "dep-static-content",
  liveChatUrl: "/config/dep/livechat/prod/url",
  enableLiveChat: true,
  googleAnalyticsTrackingId:"G-W8TY9SC9PD",
};

export default config;
