import { Grid, Typography } from "@material-ui/core";
function ParentDetails(props: any) {
  return (
    <Grid container spacing={2}>
      <Grid item md={6} sm={6} xs={12}>
        <Typography variant="subtitle2" style={{ marginBottom: "8px" }}>
          First Name
        </Typography>
        <Typography variant="caption">{props.details?.userDetails.firstName}</Typography>
      </Grid>
      <Grid item md={6} sm={6} xs={12}>
        <Typography variant="subtitle2" style={{ marginBottom: "8px" }}>
          Last Name
        </Typography>
        <Typography variant="caption">{props.details?.userDetails.lastName}</Typography>
      </Grid>
      <Grid item md={6} sm={6} xs={12}>
        <Typography variant="subtitle2" style={{ marginBottom: "8px" }}>
          Email
        </Typography>
        <Typography variant="caption">{props.details?.email}</Typography>
      </Grid>
      <Grid item md={6} sm={6} xs={12}>
        <Typography variant="subtitle2" style={{ marginBottom: "8px" }}>
          Telephone
        </Typography>
        <Typography variant="caption">
          {props.details?.userDetails.mobileNo || props.details?.userDetails.phoneNumber}
        </Typography>
      </Grid>
    </Grid>
  );
}

export default ParentDetails;
