export default function pageValidate(
  formData: any,
  state: any,
  completeState: any
) {
  const formErrors = state.formErrors;
  const tempFormValues = state.formValues;
  let newFormValues = JSON.stringify(tempFormValues, function (key, value) {
    return value === undefined ? "" : value;
  });

  const formValues = JSON.parse(newFormValues);
  let Value = "";
  let pin = "";
  let contact = "";
  let aadhar = "";
  formData.map((formData: any) => {

    const name = formData.FieldName;
    const id = formData.Id;
    if (formData.Required) {
      if (formData.Type === "checkbox") {
        Value = formValues[`${name + "_" + id}_checkbox`].value.length;
        if (!Value) {
          formErrors[name + "_" + id] = "Please select a value";
        } else {
          formErrors[name + "_" + id] = "";
        }
      } else if(formData.Type==="address"){
        Value = formValues[`${name + "_" + id}_address`].value;
        if (!Value) {
          formErrors[name + "_" + id] = "Please enter a value";
        } else {
          formErrors[name + "_" + id] = "";
        }
      }
      else if (formData.Type === "radio") {
        Value = formValues[`${name + "_" + id}_radio`].value;
        if (!Value) {
          formErrors[name + "_" + id] = "Please select a value";
        } else {
          formErrors[name + "_" + id] = "";
        }
      } else if (formData.Type === "confirmation") {
        if (formValues[`${name + "_" + id}_confirmation`])
          formErrors[name + "_" + id] = "";
        else
          formErrors[name + "_" + id] =
            "Please accept the above terms and conditions";
      } else if (formData.Type === "file") {
        if (Object.keys(formValues[`${name + "_" + id}_file`]).length !== 0) {
          if (
            !Object.keys(formValues[`${name + "_" + id}_file`]).includes(
              "fileName"
            )
          ) {
            formErrors[name + "_" + id] =
              "Please wait for the upload to complete before proceeding";
          } else {
            formErrors[name + "_" + id] = "";
          }
        } else if (
          Object.keys(formData).includes("Check") &&
          (formData.Check.Type === "radio"
            ? !formData.Check.Values.includes(
                completeState[formData.Check.Page].formValues[
                  formData.Check.Name + "_" + formData.Check.Id + "_radio"
                ].value
              )
            : formData.Check.Type === "checkbox"
            ? !formData.Check.Values.includes(
                completeState[formData.Check.Page].formValues[
                  formData.Check.Name + "_" + formData.Check.Id + "_checkbox"
                ].value
              )
            : !formData.Check.Values.includes(
                completeState[formData.Check.Page].formValues[
                  formData.Check.Name + "_" + formData.Check.Id
                ]
              ))
        ) {
          formErrors[name + "_" + id] = "";
        } else
          formErrors[name + "_" + id] = `Please upload ${formData.FieldName} `;
      } else if (formData.Type === "number" && name.includes("Pin")) {
        pin = formValues[name + "_" + id];
        if (pin?.length !== 6) {
          formErrors[name + "_" + id] = "Pin Code should be 6 digits";
        } else {
          formErrors[name + "_" + id] = "";
        }
      } else if (formData.Type === "number" && name.includes("Contact")) {
        contact = formValues[name + "_" + id];
        if (contact.length !== 10) {
          formErrors[name + "_" + id] = "Contact Number should be 10 digits";
        } else {
          formErrors[name + "_" + id] = "";
        }
      } else if (formData.Type === "number" && name.includes("Aadhar")) {
        aadhar = formValues[name + "_" + id];
        if (aadhar.length !== 0 && aadhar.length !== 12) {
          formErrors[name + "_" + id] =
            "Aadhar Card Number should be 12 digits";
        } else if (aadhar.length === 0) {
          formErrors[name + "_" + id] = "Please enter a value";
        } else {
          formErrors[name + "_" + id] = "";
        }
      } else if (
        formData.Type === "select" ||
        formData.Type === "countryDropdown" ||
        formData.Type === "stateDropdown"
      ) {
        Value = formValues[name + "_" + id];
        if (!Value) {
          formErrors[name + "_" + id] = "Please select a value";
        } else {
          formErrors[name + "_" + id] = "";
        }
      } else {
        Value = formValues[name + "_" + id];
        if (!Value) {
          formErrors[name + "_" + id] = "Please enter a value";
        } else {
          formErrors[name + "_" + id] = "";
        }
      }
    } else {
      if (formData.Type === "number" && name.includes("Aadhar")) {
        aadhar = formValues[name + "_" + id];
        if (aadhar.length !== 0 && aadhar.length !== 12) {
          formErrors[name + "_" + id] =
            "Aadhar Card Number should be 12 digits";
        } else {
          formErrors[name + "_" + id] = "";
        }
      }
    }
    return null;
  });
  return formErrors;
}
