import DateFnsUtils from "@date-io/date-fns";
import FormControl from "@material-ui/core/FormControl";
import FormLabel from "@material-ui/core/FormLabel";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import Grid from "@material-ui/core/Grid";
import { MuiPickersUtilsProvider, DatePicker } from "@material-ui/pickers";

function YearPicker(props: any) {
  const smallScreen = useMediaQuery((theme: any) => theme.breakpoints.between("0", "xs"));
  const { id, label, name, value, handleChangeYear, error = null, required, width } = props;
  return (
    <FormControl
      variant={"outlined"}
      style={{
        width: width || (smallScreen ? "100%" : "50%"),
        marginBottom: "20px",
      }}
      {...(required && { required: true })}
    >
      <FormLabel>{label}</FormLabel>
      <Grid
        container
        direction="row"
        style={{ width: (width && "100%") || (smallScreen ? "94%" : "88%") }}
      >
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
          <DatePicker
            style={{ width: (width && "100%") || "100%" }}
            inputVariant="outlined"
            size="small"
            id={id}
            name={name}
            format={"yyyy"}
            views={["year"]}
            minDate={new Date()}
            maxDate={new Date(new Date().setFullYear(new Date().getFullYear() + 5))}
            minDateMessage="Invalid minimum date"
            value={value || new Date()}
            onChange={(val) => {
              handleChangeYear(name, id, val);
            }}
            {...(error && { error: true, helperText: error })}
            {...(required && { required: true })}
          />
        </MuiPickersUtilsProvider>
      </Grid>
    </FormControl>
  );
}
export default YearPicker;
