import {Component} from "react";
import {createFormData, createPageState} from "./FormHandlers";
import {updateChange, updateCheckbox} from "./ChangeHandlers";
import RenderForm from "./RenderHandlers";
import pageValidate from "../../validations/pageValidate";
import CryptoJS from "crypto-js";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import {
  getActiveSubscription,
  getAddresses,
  partialUpdateApplication,
  saveUserDetails,
  stripeAppFeeCheckoutSession,
  submitApplication,
  updateApplication,
  updateUserDetails,
  validateAppFeeCheckout,
  validateSchoolZone,
} from "../../api";
import GenericButton from "../../components/GenericButton";
import LoadingScreen from "../../components/LoadingScreen";
import {Alert, AlertTitle} from "@material-ui/lab";
import ResultModal from "../../components/ResultModal";
import {page} from "../../Data/getPage";
import {
  validateField,
  validateRequiredField,
} from "../../validations/validateField";
import SaveAndExit from "./SaveAndExit";
import {withCookies} from "react-cookie";
import config from "../../config/root-config";
import ReactGA from "react-ga";
import configFile from "../../config/root-config";
const TRACKING_ID = configFile.googleAnalyticsTrackingId; // OUR_TRACKING_ID
ReactGA.initialize(TRACKING_ID);
ReactGA.event({
  category: "Solvitude",
  action: "Upload",
  label: "label",
});

class Page extends Component<any, any> {
  constructor(props: any) {
    super(props);
    this.state = createPageState(
      this.props.orgDetails.orgName.replace(/\s/g, "_"),
      this.props.orgDetails.campusList[0].additionalInfo.country
    );
    this.handleChange = this.handleChange.bind(this);
    this.handleChangeForCheckbox = this.handleChangeForCheckbox.bind(this);
    this.handleChangeForDatalist = this.handleChangeForDatalist.bind(this);
    this.handleChangeForAddress = this.handleChangeForAddress.bind(this);
    this.handleChangeForConfirmation =
      this.handleChangeForConfirmation.bind(this);
    this.handleChangeOptions = this.handleChangeOptions.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.onChangeValidate = this.onChangeValidate.bind(this);
    this.saveAndContinue = this.saveAndContinue.bind(this);
    this.skip = this.skip.bind(this);
    this.back = this.back.bind(this);
    this.handleChangeForSelect = this.handleChangeForSelect.bind(this);
    this.handleChangeForRadio = this.handleChangeForRadio.bind(this);
    this.updateState = this.updateState.bind(this);
    this.handleSave = this.handleSave.bind(this);
    this.uploadFileDetails = this.uploadFileDetails.bind(this);
    this.deleteFile = this.deleteFile.bind(this);
    this.modalOpen = this.modalOpen.bind(this);
    this.modalClose = this.modalClose.bind(this);
    this.handleChangeYear = this.handleChangeYear.bind(this);
    this.handlePayment = this.handlePayment.bind(this);
    this.handlePaymentVendor = this.handlePaymentVendor.bind(this);
    this.submitApplication = this.submitApplication.bind(this);
    this.docValidation = this.docValidation.bind(this);
    this.saveApplication = this.saveApplication.bind(this);
    this.updateAutofetchAddress = this.updateAutofetchAddress.bind(this);
  }
  componentDidMount() {
    const {
      cookies: {cookies},
    } = this.props;
    console.log("Page Props");
    console.log(this.props);
    if (Object.keys(this.props.newData).length !== 0) {
      const state = {...this.state};
      for (let page in this.state) {
        const values = {...this.state[page].formValues};
        for (let key in values) {
          const oldData = Object.keys(
            this.props.newData.applicationDetails
          ).find((key) => key === "Child Details");
          if (oldData) {
            if (
              this.props.newData.applicationDetails["Child Details"].formValues[
                key.replace("applicant", "child")
              ]
            ) {
              values[key] =
                this.props.newData.applicationDetails[
                  "Child Details"
                ].formValues[key.replace("applicant", "child")];
            }
          } else if (
            this.props.newData.applicationDetails[page].formValues[key]
          )
            values[key] =
              this.props.newData.applicationDetails[page].formValues[key];
        }
        state[page].formValues = values;
      }
      this.setState({
        ...state,
        paymentInfo: this.props.newData.paymentInfo || {},
        paymentId: this.props?.newData?.paymentInfo?.paymentId,
      });
    } else if (Object.keys(this.props.childDetails).length !== 0) {
      const child = {...this.state["Applicant Details"]};
      for (let key in child.formValues) {
        const populate = Object.keys(this.props.childDetails.childDetails).find(
          (key) => key === "First Name_child"
        )
          ? this.props.childDetails.childDetails[
              key.replace("applicant", "child")
            ]
          : this.props.childDetails.childDetails[key];
        child.formValues[key] = populate || child.formValues[key];
      }
      this.setState({
        ...this.state,
        "Applicant Details": child,
      });

      if (
        Object.keys(this.props.populateData.parentDetails).includes(
          "motherData"
        )
      ) {
        const mother = {...this.state["Parent or Guardian2"]};
        for (let key in mother.formValues) {
          mother.formValues[key] =
            this.props.populateData.parentDetails.motherData.motherDetails[
              key
            ] || mother.formValues[key];
        }
        this.setState({
          ...this.state,
          "Parent or Guardian2": mother,
        });
      }
      if (
        Object.keys(this.props.populateData.parentDetails).includes(
          "fatherData"
        )
      ) {
        const father = {...this.state["Parent or Guardian1"]};
        for (let key in father.formValues) {
          father.formValues[key] =
            this.props.populateData.parentDetails.fatherData.fatherDetails[
              key
            ] || father.formValues[key];
        }
        this.setState({
          ...this.state,
          "Parent or Guardian1": father,
        });
      }
    }
    this.setState({
      save: false,
      saveLoading: false,
      submitLoading: false,
      error: false,
      success: false,
      openModal: false,
    });
    if (this.props.history.location.search) {
      this.setState({saveLoading: true});
      const session = new URLSearchParams(this.props.history.location.search);
      const sessionId = session.get("session_id")!;
      validateAppFeeCheckout(
        this.props.orgDetails.orgId,
        this.props.orgDetails.campusList[0].campusId,
        this.props.match.params.id,
        sessionId
      )
        .then((paymentDetails) => {
          this.setState({
            paymentInfo: paymentDetails.data,
            saveLoading: false,
            paymentId: paymentDetails.data.paymentId,
          });
          this.submitApplication();
        })
        .catch((err) => {
          console.log(this.state);
          this.setState({saveLoading: false, save: false, error: true});
        });
    }
  }
  updateState(state: any) {
    this.setState({[this.props.pageState.page]: state});
  }
  saveAndContinue(event: any) {
    event.preventDefault();
    ReactGA.event({
      category: "Solvitude",
      action: "Next",
      label: "label",
    });
    const formErrors = pageValidate(
      this.props.formData,
      this.state[this.props.pageState.page],
      this.state
    );
    this.setState({
      ...this.state,
      [this.props.pageState.page]: {
        ...this.state[this.props.pageState.page],
        formErrors: formErrors,
      },
    });
    const validate = Object.values(
      this.state[this.props.pageState.page].formErrors
    ).every((x) => x === "");
    if (validate) {
      this.props.nextStep(this.props.SideMenu);
      if (this.props.pageState.page !== "Eligibility and Checklist")
        this.handleSave();
      window.scrollTo(0, 0);
    }
  }
  skip(event: any) {
    event.preventDefault();
    this.props.nextStep(this.props.SideMenu);
    window.scrollTo(0, 0);
  }
  back(event: any) {
    event.preventDefault();
    ReactGA.event({
      category: "Solvitude",
      action: "Previous",
      label: "label",
    });
    this.props.prevStep(this.props.SideMenu);
    window.scrollTo(0, 0);
  }
  onChangeValidate(name: any, value: any, type: any, required: any) {
    let formErrors = {...this.state[this.props.pageState.page].formErrors};
    if (required)
      formErrors = validateRequiredField(
        type,
        name,
        value,
        formErrors,
        this.props.orgDetails.campusList[0].additionalInfo.country
      );
    else if (value)
      formErrors = validateField(
        type,
        name,
        value,
        formErrors,
        this.props.orgDetails.campusList[0].additionalInfo.country
      );
    else formErrors[name] = "";

    this.setState({
      ...this.state,
      [this.props.pageState.page]: {
        ...this.state[this.props.pageState.page],
        formErrors: formErrors,
      },
    });
  }

  modalOpen() {
    this.setState({openModal: true});
  }

  modalClose() {
    this.setState({openModal: false});
  }

  handleChangeOptions(event: any) {
    console.log("event..........");
    console.log(event);
    const formValues = this.state[this.props.pageState.page].formValues;
    const name = event.target.name;
    const value = event.target.value;
    const id = event.target.id;
    formValues[`${name}_${id}_address`].value = value;
    const paramType = "search";
    getAddresses(value, paramType).then((res) => {
      formValues[`${name}_${id}_address`].options = res.data.address;
      this.setState({
        ...this.state,
        [this.props.pageState.page]: {
          ...this.state[this.props.pageState.page],
          formValues: formValues,
        },
      });
    });
    this.onChangeValidate(
      event.target.name + "_" + event.target.id,
      event.target.value,
      "address",
      event.target.required
    );
  }
  uploadFileDetails(name: any, id: any, docId: any, file?: any) {
    const formValues = this.state[this.props.pageState.page].formValues;
    formValues[name + "_" + id + "_file"]["fileName"] = file
      ? file.name
      : formValues[name + "_" + id + "_file"].file.name;
    formValues[name + "_" + id + "_file"]["fileId"] = docId;
    formValues[name + "_" + id + "_file"].file = file;
    if (file) formValues[name + "_" + id + "_file"].type = "draw";
    formValues[name + "_" + id + "_file"]["progress"] = 100;
    formValues[name + "_" + id + "_file"]["uploaded"] = true;
  }
  deleteFile(name: any, id: any) {
    const formValues = this.state[this.props.pageState.page].formValues;
    const formErrors = this.state[this.props.pageState.page].formErrors;
    formValues[name + "_" + id + "_file"] = {};
    formErrors[name + "_" + id] = "";
    this.setState({
      ...this.state,
      [this.props.pageState.page]: {
        ...this.state[this.props.pageState.page],
        formValues: formValues,
        formErrors: formErrors,
      },
    });
  }
  handleChange(name?: any) {
    const formValues = this.state[this.props.pageState.page].formValues;
    const errors = this.state[this.props.pageState.page].formErrors;
    let key: any = {};
    if (!name) key = formValues;
    else key = formValues[name];
    return (event: any) => {
      updateChange(key, event, errors);
      this.setState({
        ...this.state,
        [this.props.pageState.page]: {
          ...this.state[this.props.pageState.page],
          formValues: formValues,
          formErrors: errors,
        },
      });
      this.onChangeValidate(
        event.target.name + "_" + event.target.id,
        event.target.value,
        event.target.type,
        event.target.required
      );
    };
  }
  handleChangeYear(name: any, id: any, val: any) {
    const formValues = this.state[this.props.pageState.page].formValues;
    formValues[name + "_" + id] = val;
    this.setState({
      ...this.state,
      [this.props.pageState.page]: {
        ...this.state[this.props.pageState.page],
        formValues: formValues,
      },
    });
  }
  handleChangeForSelect(id: any, name?: any) {
    const formValues = this.state[this.props.pageState.page].formValues;
    const errors = this.state[this.props.pageState.page].formErrors;
    console.log("Handle change for select.... ", id);
    let key: any = {};
    if (!name) key = formValues;
    else key = formValues[name];
    return (event: any) => {
      updateChange(key, event, errors, id);
      this.setState({
        ...this.state,
        [this.props.pageState.page]: {
          ...this.state[this.props.pageState.page],
          formValues: formValues,
          formErrors: errors,
        },
      });

      this.onChangeValidate(
        event.target.name + "_" + id,
        event.target.value,
        "select",
        event.target.required
      );
    };
  }
  handleChangeForRadio(id: any, name?: any) {
    const formValues = this.state[this.props.pageState.page].formValues;
    const errors = this.state[this.props.pageState.page].formErrors;
    console.log("Handle change for radio.... ", id);
    let key: any = {};
    if (!name) key = formValues;
    else key = formValues[name];
    return (event: any) => {
      updateChange(key, event, errors, id);
      console.log("key:", key);
      const constructedKey = `${event.target.name + "_" + event.target.id}`;
      console.log("constructedKey:", constructedKey);
      if (key.hasOwnProperty(constructedKey)) {
        key[constructedKey] = event.target.value;
      } else {
        // console.error(
        //   `Property "${constructedKey}" does not exist in the 'key' object.`
        // );
      }
      this.setState({
        ...this.state,
        [this.props.pageState.page]: {
          ...this.state[this.props.pageState.page],
          formValues: formValues,
          formErrors: errors,
        },
      });

      this.onChangeValidate(
        event.target.name + "_" + id,
        event.target.value,
        "radio",
        event.target.required
      );
    };
  }

  handleChangeForDatalist(name: any) {
    const formValues = this.state[this.props.pageState.page].formValues;
    return (event: any, value: any) => {
      this.onChangeValidate(
        name,
        value ? value.Label : "",
        event.target.type,
        event.target.required
      );
      if (value) {
        formValues[name] = value.Label;
        this.setState({
          ...this.state,
          [this.props.pageState.page]: {
            ...this.state[this.props.pageState.page],
            formValues: formValues,
          },
        });
      }
    };
  }

  handleChangeForAddress(name: any) {
    console.log("Name in change address...", name);
    const formValues = this.state[this.props.pageState.page].formValues;
    return async (event: any, value: any, reason: any) => {
      if (reason === "clear") {
        let key;
        if (this.props.pageState.page === "Applicant Details")
          key = "applicant";
        else if (this.props.pageState.page === "Parent or Guardian1")
          key = "Guardian1";
        else if (this.props.pageState.page === "Parent or Guardian2")
          key = "Guardian2";
        else if (this.props.pageState.page === "Guardian Details")
          key = "guardian";
        else if (this.props.pageState.page === "Medical Information")
          key = "medical";
        else if (this.props.pageState.page === "Other Details") key = "other";
        formValues["Country_" + key] = "";
        formValues["State / Territory / Province / Region_" + key] = "";
        formValues["City / Town / Suburb_" + key] = "";
        formValues["Postcode_" + key] = "";

        this.setState({
          ...this.state,
          [this.props.pageState.page]: {
            ...this.state[this.props.pageState.page],
            formValues: formValues,
          },
        });
      }

      console.log("Full Address String: ", value);
      formValues[name].value = value === null ? "" : value.mainString;
      let address = {
        country: "",
        city: "",
        state: "",
        postCode: "",
        latitude: "",
        longitude: "",
      };
      if (value !== null) {
        const paramType = "placeId";
        try {
          const res = await getAddresses(value.placeId, paramType);
          console.log("2nd Address resultsss");
          console.log(res.data);
          address.country = res.data.country;
          address.city = res.data.city;
          address.state = res.data.state;
          address.postCode = res.data.postCode;
          address.latitude = res.data.latitude;
          address.longitude = res.data.longitude;
          this.updateAutofetchAddress(address, formValues);
          if (this.props.pageState.page === "Applicant Details") {
            const latitude = res.data.latitude;
            const longitude = res.data.longitude;
            console.log("Latitude:", latitude);
            console.log("Longitude:", longitude);
            console.log("orgId", this.props.orgDetails.orgId);
            console.log(
              "campusId",
              this.props.orgDetails.campusList[0].campusId
            );
            try {
              const res = await getActiveSubscription(
                this.props.orgDetails.orgId,
                this.props.orgDetails.campusList[0].campusId
              );
              console.log("Subscription Result");
              console.log(res.data);
              const checkZone = res.data.features.checkZone;
              console.log("checkZone", checkZone);
              if (checkZone === true) {
                const response = await validateSchoolZone(
                  latitude,
                  longitude,
                  this.props.orgDetails.orgName
                );
                if (response.data === false) {
                  console.log("launch popup here");
                  this.setState({
                    zone: false,
                    zoneFlag: false,
                    showPopup: true,
                  });
                } else {
                  this.setState({zoneFlag: true, showPopup: false});
                }
              }
            } catch (err) {
              console.log("Subscription Error");
              console.log(err);
            }
          }
        } catch (err) {
          console.log("Auto Fetch Address Error");
          console.log(err);
        }
      }
    };
  }

  updateAutofetchAddress(address: any, formValues: any) {
    console.log("Inside AutoFetch Address Function....");
    let key;
    if (this.props.pageState.page === "Applicant Details") key = "applicant";
    else if (this.props.pageState.page === "Parent or Guardian1")
      key = "Guardian1";
    else if (this.props.pageState.page === "Parent or Guardian2")
      key = "Guardian2";
    else if (this.props.pageState.page === "Guardian Details") key = "guardian";
    else if (this.props.pageState.page === "Medical Information")
      key = "medical";
    else if (this.props.pageState.page === "Other Details") key = "other";
    formValues["Country_" + key] = address?.country.replace(/\s+/g, "");
    formValues["State / Territory / Province / Region_" + key] = address?.state;
    formValues["City / Town / Suburb_" + key] = address?.city;
    formValues["Postcode_" + key] = address?.postCode;

    this.setState({
      ...this.state,
      [this.props.pageState.page]: {
        ...this.state[this.props.pageState.page],
        formValues: formValues,
      },
    });
  }

  handleChangeForCheckbox(name: any, hidden?: any) {
    const formValues = this.state[this.props.pageState.page].formValues;
    let key: any = {};
    if (!hidden) key = formValues[name];
    else {
      hidden.map((hidden: any) => {
        if (hidden.Type === "checkbox") {
          key =
            formValues[name][hidden.FieldName + "_" + hidden.Id + "_checkbox"];
        }
        return null;
      });
    }
    return (event: any) => {
      updateCheckbox(key, event);
      this.setState({
        ...this.state,
        [this.props.pageState.page]: {
          ...this.state[this.props.pageState.page],
          formValues: formValues,
        },
      });
      this.onChangeValidate(
        name,
        key.value,
        event.target.type,
        event.target.required
      );
    };
  }

  handleChangeForConfirmation(name?: any) {
    const formValues = this.state[this.props.pageState.page].formValues;
    let key: any = {};
    if (!name) key = formValues;
    else key = formValues[name];
    return (event: any) => {
      const name = event.target.name + "_" + event.target.id;
      key[`${name}_confirmation`] = event.target.checked;
      this.setState({
        ...this.state,
        [this.props.pageState.page]: {
          ...this.state[this.props.pageState.page],
          formValues: formValues,
        },
      });
      this.onChangeValidate(
        name,
        event.target.checked ? "checked" : "",
        "confirmation",
        event.target.required
      );
    };
  }

  docValidation() {
    const formErrors = pageValidate(
      this.props.formData,
      this.state[this.props.pageState.page],
      this.state
    );
    this.setState({
      ...this.state,
      [this.props.pageState.page]: {
        ...this.state[this.props.pageState.page],
        formErrors: formErrors,
      },
    });
    let validate = Object.values(
      this.state[this.props.pageState.page].formErrors
    ).every(
      (x) =>
        x === "" ||
        x === "File size exceeds 10mb!" ||
        x === "Unsupported file type!"
    );
    return validate;
  }
  async handlePaymentVendor(event: any) {
    event.preventDefault();

    if (this.docValidation()) {
      this.saveApplication("Draft").then((res: any) => console.log(res));
      if (this.props.orgDetails.campusList[0].additionalInfo.razorpayPayment)
        this.handlePayment(event);
      else if (this.props.orgDetails.campusList[0].additionalInfo.stripePayment)
        this.handleStripePayment(event);
      ReactGA.event({
        category: "Solvitude",
        action: "Pay & Submit",
        label: "label",
      });
    }
  }

  async handleStripePayment(event: any) {
    console.log("props", this.props);
    this.setState({saveLoading: true});
    stripeAppFeeCheckoutSession(
      this.props.orgDetails.orgId,
      this.props.orgDetails.campusList[0].campusId,
      this.props.match.params.id,
      this.props.match.url
    )
      .then((res) => {
        console.log(res.data);
        window.open(res.data.url, "_self");
        this.setState({saveLoading: false});
      })
      .catch((err) => {
        this.setState({
          saveLoading: false,
          error: true,
        });
      });
  }

  async handlePayment(event: any) {
    const order = this.state.paymentInfo;
    const {
      match: {params},
    } = this.props;
    const self = this;
    const keyString = process.env.REACT_APP_RAZORPAY_DETAILS;
    const key = JSON.parse(keyString!);
    const school = await key.Schools.find((obj: any) => {
      return (
        Object.keys(obj)[0] ===
        this.props.orgDetails.orgName.replace(/\s/g, "_")
      );
    });
    const schoolKeys: any = Object.values(school)[0];

    var options = {
      key_id: schoolKeys.keyId, // Enter the Key ID generated from the Dashboard
      key_secret: schoolKeys.keySecret,
      amount: order.amount, // Amount is in currency subunits. Default currency is INR. Hence, 50000 refers to 50000 paise
      currency: order.currency,
      name: this.props.orgDetails.orgName,
      description: "Application Fees",
      image: `https://${
        config.s3StaticBucket
      }.s3.ap-southeast-2.amazonaws.com/${this.props.orgDetails.orgName.replace(
        /\s/g,
        "_"
      )}.png`,
      order_id: order.orderId, //This is a sample Order ID. Pass the `id` obtained in the response of Step 1
      handler: async function (response: any) {
        const sign = CryptoJS.HmacSHA256(
          order.orderId + "|" + response.razorpay_payment_id,
          schoolKeys.keySecret
        ).toString();
        response.status = "success";
        response.amountPaid = order.amount;
        response.transactedAt = new Date().toISOString();
        order.orderCompleted = true;
        self.setState({
          paymentId: response.razorpay_payment_id,
        });
        if (sign === response.razorpay_signature) {
          console.log("VERIFIED");
          response.verification = true;
          order.paymentDetails.push(response);
        } else {
          console.log("Verification failed");
          console.log("generated signature:", sign);
          response.verification = false;
          order.paymentDetails.push(response);
        }
        self.setState({
          paymentInfo: order,
        });
        self.submitApplication();
      },
      prefill: {
        name:
          this.props.user.attributes["custom:firstName"] +
          " " +
          this.props.user.attributes["custom:lastName"],
        email: this.props.user.attributes["email"],
        contact: this.props.user.attributes["phone_number"],
      },
      notes: {
        address: "Razorpay Corporate Office",
      },
      theme: {
        color: "#3399cc",
      },
    };
    var rzp1 = new (window as any).Razorpay(options);
    rzp1.open();
    event.preventDefault();
    rzp1.on("payment.failed", async function (response: any) {
      response.status = "failed";
      response.amountPaid = 0;
      response.transactedAt = new Date().toISOString();
      order.paymentDetails.push(response);
      self.setState({
        paymentInfo: order,
      });
      const {data} = await updateApplication(params.id, {
        paymentInfo: order,
      });
    });
  }
  saveApplication(status: any, updatedApplicantDetails?: any, docId?: any) {
    const {
      match: {params},
      cookies,
    } = this.props;
    const applicantDetails = {
      firstName:
        this.state["Applicant Details"].formValues["First Name_applicant"],
      lastName:
        this.state["Applicant Details"].formValues["Last Name_applicant"],
      gender:
        this.state["Applicant Details"].formValues["Gender_applicant_radio"]
          .value,
      childId: this.props.childDetails.childId || "",
      fatherId:
        this.props.populateData.parentDetails?.fatherData?.fatherId || "",
      motherId:
        this.props.populateData.parentDetails?.motherData?.motherId || "",
    };
    const orgName = this.props.orgDetails.campusList[0].campusName.replace(
      / /g,
      "_"
    );
    const typeOfForm = cookies.get("type")
      ? cookies.get("type")
      : localStorage.getItem("type");
    const formName = localStorage.getItem(`${orgName}-${typeOfForm}-formName`);
    const formReference = {
      id: typeOfForm,
      name: formName,
    };

    return partialUpdateApplication(
      this.props.user,
      this.state,
      this.props.orgDetails,
      updatedApplicantDetails || applicantDetails,
      docId || null,
      status,
      this.props.pageState,
      formReference,
      params.id ? params.id : null
    );
  }
  handleSave(saveAndExit?: any) {
    this.setState({saveLoading: true, save: true});
    const {
      match: {params},
    } = this.props;
    const data = {
      role: "Student",
      fullName:
        this.state["Applicant Details"].formValues["First Name_applicant"] +
        " " +
        this.state["Applicant Details"].formValues["Last Name_applicant"],
      documentReferences: [],
      parentId: this.props.user.attributes["custom:userName"],
      childDetails: {
        ...this.props.childDetails.childDetails,
        ...this.state["Applicant Details"].formValues,
      },
      relation: "Child",
    };
    const applicantDetails = {
      firstName:
        this.state["Applicant Details"].formValues["First Name_applicant"],
      lastName:
        this.state["Applicant Details"].formValues["Last Name_applicant"],
      gender:
        this.state["Applicant Details"].formValues["Gender_applicant_radio"]
          .value,
      childId: "",
      fatherId: "",
      motherId: "",
      schoolZone: this.state.zoneFlag,
    };
    if (this.props.pageState.page === "Applicant Details") {
      if (Object.keys(this.props.childDetails).length === 0) {
        if (
          this.state["Applicant Details"].formValues["First Name_applicant"] !==
            "" &&
          this.state["Applicant Details"].formValues["Last Name_applicant"] !==
            "" &&
          this.state["Applicant Details"].formValues["Gender_applicant_radio"]
            .value !== "" &&
          this.state["Applicant Details"].formValues[
            "Date of Birth_applicant"
          ] !== ""
        )
          saveUserDetails(data, this.props.user.attributes.email)
            .then((res) => {
              console.log(res);
              applicantDetails.childId = res.data.customerId;
              this.saveApplication("Draft", applicantDetails)
                .then((appData) => {
                  if (!params.id) {
                    this.props.history.push(
                      `${this.props.match.url}/${appData.applicationId}`,
                      {
                        childDetails: {
                          childId: res.data.customerId,
                          ...data,
                        },
                        populateData: this.props.populateData,
                      }
                    );
                    this.setState({
                      paymentInfo: appData.paymentInfo,
                    });
                  }
                  this.setState({
                    saveLoading: false,
                    save: false,
                    openModal: false,
                  });
                  if (saveAndExit) {
                    this.props.history.push("/dashboard");
                  }
                })
                .catch((error) => {
                  console.log(error);
                  this.setState({
                    saveLoading: false,
                    save: false,
                    error: true,
                    openModal: false,
                  });
                  if (error.response) {
                    console.log(error.response.status);
                    if (error.response.status === 401)
                      this.props.handleLogout();
                  }
                });
            })
            .catch((error) => {
              console.log(error);
              this.setState({
                save: false,
                saveLoading: false,
                error: true,
                openModal: false,
              });
              if (error.response) {
                console.log(error.response.status);
                if (error.response.status === 401) this.props.handleLogout();
              }
            });
        else {
          const errors = this.state["Applicant Details"].formErrors;
          errors["First Name_applicant"] = "Please enter a value.";
          errors["Last Name_applicant"] = "Please enter a value.";
          errors["Gender_applicant"] = "Please enter a value.";
          errors["Date of Birth_applicant"] = "Please enter a value.";
          this.setState({save: false, saveLoading: false, openModal: false});
          window.scrollTo(0, 0);
        }
      } else {
        updateUserDetails(
          data,
          this.props.user.attributes.email,
          this.props.childDetails.childId,
          "Student"
        )
          .then((res) => {
            this.setState({save: false});
          })
          .catch((error) => {
            console.log(error);
            this.setState({
              error: true,
              save: false,
              saveLoading: false,
              openModal: false,
            });
            if (error.response) {
              console.log(error.response.status);
              if (error.response.status === 401) this.props.handleLogout();
            }
          });
        applicantDetails.childId = this.props.childDetails.childId;
        this.saveApplication("Draft", applicantDetails)
          .then((appData) => {
            if (!params.id) {
              this.props.history.push(
                `${this.props.match.url}/${appData.applicationId}`,
                {
                  childDetails: {
                    childId: this.props.childDetails.childId,
                    ...data,
                  },
                  populateData: this.props.populateData,
                }
              );
              this.setState({
                paymentInfo: appData.paymentInfo,
              });
            }
            this.setState({
              saveLoading: false,
              openModal: false,
            });
            if (saveAndExit) {
              this.props.history.push("/dashboard");
            }
          })
          .catch((error) => {
            console.log(error);
            this.setState({
              error: true,
              saveLoading: false,
              save: false,
              openModal: false,
            });
            if (error.response) {
              console.log(error.response.status);
              if (error.response.status === 401) this.props.handleLogout();
            }
          });
      }
    } else if (this.props.pageState.page === "Parent or Guardian2") {
      console.log(this.props.populateData);
      applicantDetails.childId = this.props.childDetails.childId;
      const motherDetails = {
        role: "Mother",
        fullName:
          this.state["Parent or Guardian2"].formValues["First Name_Guardian2"] +
          " " +
          this.state["Parent or Guardian2"].formValues["Last Name_Guardian2"],
        documentReferences: [],
        parentId: this.props.user.attributes["custom:userName"],
        motherDetails: Object.keys(
          this.props.populateData.parentDetails
        ).includes("motherData")
          ? {
              ...this.props.populateData.parentDetails.motherData.motherDetails,
              ...this.state["Parent or Guardian2"].formValues,
            }
          : {
              ...this.state["Parent or Guardian2"].formValues,
            },
        relation: "Mother",
      };
      console.log(this.props.populateData.parentDetails);
      if (
        !Object.keys(this.props.populateData.parentDetails).includes(
          "motherData"
        )
      ) {
        if (
          this.state["Parent or Guardian2"].formValues[
            "First Name_Guardian2"
          ] !== "" &&
          this.state["Parent or Guardian2"].formValues[
            "Last Name_Guardian2"
          ] !== "" &&
          this.state["Parent or Guardian2"].formValues[
            "Contact No._Guardian2"
          ] !== "" &&
          this.state["Parent or Guardian2"].formValues["Email_Guardian2"] !== ""
        )
          saveUserDetails(motherDetails, this.props.user.attributes.email)
            .then((res) => {
              applicantDetails.motherId = res.data.customerId;
              this.saveApplication("Draft", applicantDetails)
                .then((appData) => {
                  if (!params.id) {
                    this.props.history.push(
                      `${this.props.match.url}/${appData.applicationId}`,
                      {
                        childDetails: {
                          childId: res.data.customerId,
                          ...data,
                        },
                        populateData: {
                          ...this.props.populateData,
                          parentDetails: {
                            ...this.props.populateData.parentDetails,
                            motherData: {
                              ...this.props.populateData.parentDetails
                                .motherData,
                              motherId: res.data.customerId,
                            },
                          },
                        },
                      }
                    );
                    this.setState({
                      paymentInfo: appData.paymentInfo,
                    });
                  }
                  this.setState({
                    saveLoading: false,
                    save: false,
                    openModal: false,
                  });
                  if (saveAndExit) {
                    this.props.history.push("/dashboard");
                  }
                })
                .catch((error) => {
                  console.log(error);
                  this.setState({
                    saveLoading: false,
                    save: false,
                    error: true,
                    openModal: false,
                  });
                  if (error.response) {
                    console.log(error.response.status);
                    if (error.response.status === 401)
                      this.props.handleLogout();
                  }
                });
            })
            .catch((error) => {
              console.log(error);
              this.setState({
                save: false,
                saveLoading: false,
                error: true,
                openModal: false,
              });
              if (error.response) {
                console.log(error.response.status);
                if (error.response.status === 401) this.props.handleLogout();
              }
            });
        else {
          const errors = this.state["Parent or Guardian2"].formErrors;
          errors["First Name_Guardian2"] = "Please enter a value.";
          errors["Last Name_Guardian2"] = "Please enter a value.";
          errors["Contact No._Guardian2"] = "Please enter a value.";
          errors["Email_Guardian2"] = "Please enter a value.";
          this.setState({save: false, saveLoading: false, openModal: false});
          window.scrollTo(0, 0);
        }
      } else {
        updateUserDetails(
          motherDetails,
          this.props.user.attributes.email,
          this.props.populateData.parentDetails.motherData.motherId,
          "Mother"
        )
          .then((res) => {
            this.setState({save: false});
          })
          .catch((error) => {
            console.log(error);
            this.setState({
              error: true,
              save: false,
              saveLoading: false,
              openModal: false,
            });
            if (error.response) {
              console.log(error.response.status);
              if (error.response.status === 401) this.props.handleLogout();
            }
          });
        applicantDetails.motherId =
          this.props.populateData.parentDetails.motherData.motherId;
        this.saveApplication("Draft", applicantDetails)
          .then((appData) => {
            if (!params.id) {
              this.props.history.push(
                `${this.props.match.url}/${appData.applicationId}`,
                {
                  childDetails: {
                    childId: this.props.childDetails.childId,
                    ...data,
                  },
                  populateData: {
                    ...this.props.populateData,
                    parentDetails: {
                      ...this.props.populateData.parentDetails,
                      motherData: {
                        ...this.props.populateData.parentDetails.motherData,
                        motherId:
                          this.props.populateData.parentDetails.motherData
                            .motherId,
                      },
                    },
                  },
                }
              );
              this.setState({
                paymentInfo: appData.paymentInfo,
              });
            }
            this.setState({
              saveLoading: false,
              openModal: false,
            });
            if (saveAndExit) {
              this.props.history.push("/dashboard");
            }
          })
          .catch((error) => {
            console.log(error);
            this.setState({
              error: true,
              saveLoading: false,
              save: false,
              openModal: false,
            });
            if (error.response) {
              console.log(error.response.status);
              if (error.response.status === 401) this.props.handleLogout();
            }
          });
      }
    } else if (this.props.pageState.page === "Parent or Guardian1") {
      console.log(this.props.populateData);
      applicantDetails.childId = this.props.childDetails.childId;
      const fatherDetails = {
        role: "Father",
        fullName:
          this.state["Parent or Guardian1"].formValues["First Name_Guardian1"] +
          " " +
          this.state["Parent or Guardian1"].formValues["Last Name_Guardian1"],
        documentReferences: [],
        parentId: this.props.user.attributes["custom:userName"],
        fatherDetails: Object.keys(
          this.props.populateData.parentDetails
        ).includes("fatherData")
          ? {
              ...this.props.populateData.parentDetails.fatherData.fatherDetails,
              ...this.state["Parent or Guardian1"].formValues,
            }
          : {
              ...this.state["Parent or Guardian1"].formValues,
            },
        relation: "Father",
      };
      console.log(this.props.populateData.parentDetails);
      if (
        !Object.keys(this.props.populateData.parentDetails).includes(
          "fatherData"
        )
      ) {
        if (
          this.state["Parent or Guardian1"].formValues[
            "First Name_Guardian1"
          ] !== "" &&
          this.state["Parent or Guardian1"].formValues[
            "Last Name_Guardian1"
          ] !== "" &&
          this.state["Parent or Guardian1"].formValues[
            "Contact No._Guardian1"
          ] !== "" &&
          this.state["Parent or Guardian1"].formValues["Email_Guardian1"] !== ""
        )
          saveUserDetails(fatherDetails, this.props.user.attributes.email)
            .then((res) => {
              applicantDetails.fatherId = res.data.customerId;
              this.saveApplication("Draft", applicantDetails)
                .then((appData) => {
                  if (!params.id) {
                    this.props.history.push(
                      `${this.props.match.url}/${appData.applicationId}`,
                      {
                        childDetails: {
                          childId: res.data.customerId,
                          ...data,
                        },
                        populateData: {
                          ...this.props.populateData,
                          parentDetails: {
                            ...this.props.populateData.parentDetails,
                            fatherData: {
                              ...this.props.populateData.parentDetails
                                .fatherData,
                              fatherId: res.data.customerId,
                            },
                          },
                        },
                      }
                    );
                    this.setState({
                      paymentInfo: appData.paymentInfo,
                    });
                  }
                  this.setState({
                    saveLoading: false,
                    save: false,
                    openModal: false,
                  });
                  if (saveAndExit) {
                    this.props.history.push("/dashboard");
                  }
                })
                .catch((error) => {
                  console.log(error);
                  this.setState({
                    saveLoading: false,
                    save: false,
                    error: true,
                    openModal: false,
                  });
                  if (error.response) {
                    console.log(error.response.status);
                    if (error.response.status === 401)
                      this.props.handleLogout();
                  }
                });
            })
            .catch((error) => {
              console.log(error);
              this.setState({
                save: false,
                saveLoading: false,
                error: true,
                openModal: false,
              });
              if (error.response) {
                console.log(error.response.status);
                if (error.response.status === 401) this.props.handleLogout();
              }
            });
        else {
          const errors = this.state["Parent or Guardian1"].formErrors;
          errors["First Name_Guardian1"] = "Please enter a value.";
          errors["Last Name_Guardian1"] = "Please enter a value.";
          errors["Contact No._Guardian1"] = "Please enter a value.";
          errors["Email_Guardian1"] = "Please enter a value.";
          this.setState({save: false, saveLoading: false, openModal: false});
          window.scrollTo(0, 0);
        }
      } else {
        updateUserDetails(
          fatherDetails,
          this.props.user.attributes.email,
          this.props.populateData.parentDetails.fatherData.fatherId,
          "Father"
        )
          .then((res) => {
            this.setState({save: false});
          })
          .catch((error) => {
            console.log(error);
            this.setState({
              error: true,
              save: false,
              saveLoading: false,
              openModal: false,
            });
            if (error.response) {
              console.log(error.response.status);
              if (error.response.status === 401) this.props.handleLogout();
            }
          });
        applicantDetails.fatherId =
          this.props.populateData.parentDetails.fatherData.fatherId;
        this.saveApplication("Draft", applicantDetails)
          .then((appData) => {
            if (!params.id) {
              this.props.history.push(
                `${this.props.match.url}/${appData.applicationId}`,
                {
                  childDetails: {
                    childId: this.props.childDetails.childId,
                    ...data,
                  },
                  populateData: {
                    ...this.props.populateData,
                    parentDetails: {
                      ...this.props.populateData.parentDetails,
                      fatherData: {
                        ...this.props.populateData.parentDetails.fatherData,
                        fatherId:
                          this.props.populateData.parentDetails.fatherData
                            .fatherId,
                      },
                    },
                  },
                }
              );
              this.setState({
                paymentInfo: appData.paymentInfo,
              });
            }
            this.setState({
              saveLoading: false,
              openModal: false,
            });
            if (saveAndExit) {
              this.props.history.push("/dashboard");
            }
          })
          .catch((error) => {
            console.log(error);
            this.setState({
              error: true,
              saveLoading: false,
              save: false,
              openModal: false,
            });
            if (error.response) {
              console.log(error.response.status);
              if (error.response.status === 401) this.props.handleLogout();
            }
          });
      }
    } else {
      applicantDetails.childId = this.props.childDetails.childId;
      this.saveApplication("Draft", applicantDetails)
        .then((appData) => {
          if (!params.id) {
            this.props.history.push(
              `${this.props.match.url}/${appData.applicationId}`,
              {
                childDetails: {
                  childId: this.props.childDetails.childId,
                  ...data,
                },
                populateData: this.props.populateData,
              }
            );
            this.setState({
              paymentInfo: appData.paymentInfo,
            });
          }
          this.setState({
            save: false,
            saveLoading: false,
            openModal: false,
          });
          if (saveAndExit) {
            this.props.history.push("/dashboard");
          }
        })
        .catch((error) => {
          console.log(error);
          this.setState({
            save: false,
            saveLoading: false,
            error: true,
            openModal: false,
          });
          if (error.response) {
            console.log(error.response.status);
            if (error.response.status === 401) this.props.handleLogout();
          }
        });
    }
  }
  submitApplication() {
    let data = new FormData();
    this.setState({submitLoading: true});
    const docRefs: any = [
      {
        docType: "Child Photograph",
        docId:
          this.state["Documents"].formValues[
            "Passport Size Photograph_photo_file"
          ].fileId,
      },
    ];
    if (
      Object.keys(this.state["Documents"].formValues).includes(
        "Parent or Guardian1 Signature_sign1_file"
      ) &&
      Object.keys(this.state["Documents"].formValues).includes(
        "Parent or Guardian2 Signature_sign2_file"
      )
    ) {
      docRefs.push({
        docType: "Parent 1 Signature",
        docId:
          this.state["Documents"].formValues[
            "Parent or Guardian1 Signature_sign1_file"
          ].fileId,
      });
      docRefs.push({
        docType: "Parent 2 Signature",
        docId:
          this.state["Documents"].formValues[
            "Parent or Guardian2 Signature_sign2_file"
          ].fileId,
      });
    }
    if (
      Object.keys(this.state["Documents"].formValues).includes(
        "Applicant's Signature_sign3_file"
      )
    )
      docRefs.push({
        docType: "Applicant Signature",
        docId:
          this.state["Documents"].formValues["Applicant's Signature_sign3_file"]
            .fileId,
      });
    const terms: any = [];
    this.props.SideMenu.map((menu: any) => {
      const values = this.state[menu].formValues;
      if (menu === "Documents" && this.state.paymentId) {
        values["Payment ID"] = this.state.paymentId;
      }
      if (menu === "Eligibility and Checklist") {
        const jsonData = page(
          this.props.orgDetails.orgName.replace(/\s/g, "_"),
          "Eligibility_and_Checklist"
        );
        jsonData.map((data: any) => {
          terms.push(data.FieldName);
          return null;
        });
        terms.pop();
      } else {
        data.append(`${menu}_pageTitle`, "");
        createFormData(values, data);
      }
      return null;
    });

    if (terms.length !== 0) {
      data.append(`Eligibility and Checklist_pageTitle`, "");
      data.append("termsAndConditions", JSON.stringify(terms));
      const values = this.state["Eligibility and Checklist"].formValues;
      createFormData(values, data);
    }
    data.append("docRefs", JSON.stringify(docRefs));
    data.append("address", this.props.orgDetails.campusList[0].campusAddress);
    this.saveApplication("Draft").then((res) => {
      console.log(res);
      const userId = localStorage.getItem("userId");
      const loginType = localStorage.getItem("loginType");
      submitApplication(
        data,
        userId,
        res.applicationId,
        this.props.orgDetails.orgName,
        this.props.orgDetails.campusList[0].campusId,
        loginType
      )
        .then((res) => {
          console.log(res);
          this.saveApplication("Submitted", null, res.data).then((res) => {
            console.log(res);
            this.setState({submitLoading: false, success: true});
          });
        })
        .catch((error) => {
          console.log(error);
          this.setState({submitLoading: false, error: true});
          if (error.response) {
            console.log(error.response.status);
            if (error.response.status === 401) this.props.handleLogout();
          }
        });
    });
  }
  handleSubmit(event: any) {
    event.preventDefault();

    if (this.docValidation()) this.submitApplication();
    ReactGA.event({
      category: "Solvitude",
      action: "Submit",
      label: "label",
    });
  }
  render() {
    const {orgDetails, formData} = this.props;
    const type = localStorage.getItem("userId")?.includes("@")
      ? "email"
      : "phone";
    const emailMsg =
      "We've sent you an email with your application id at the registered email address - ";
    const phoneMsg =
      "We've sent you an SMS with your application id at the registered phone number - ";
    return (
      <>
        {(this.state.saveLoading ||
          this.state.save ||
          this.state.submitLoading) && <LoadingScreen />}
        {this.props.pageState.page === "Documents" && (
          <Box margin="10px" color="#009AAA">
            <label>
              <i>
                Note: File size should not exceed 10mb. File should be of the
                format ".pdf", ".jpg", ".jpeg", ".png", ".docx", ".bmp"
              </i>
            </label>
            <br></br>
          </Box>
        )}
        {(this.props.pageState.page === "Guardian Details" ||
          this.props.pageState.page === "Parent or Guardian2") && (
          <Box display="flex" justifyContent="flex-end" marginBottom="10px">
            <GenericButton
              id="skip"
              variant="contained"
              background="light"
              onClick={this.skip}
            >
              Skip
            </GenericButton>
          </Box>
        )}
        <SaveAndExit
          openModal={this.state.openModal}
          modalClose={this.modalClose}
          handleSave={this.handleSave}
        />
        <RenderForm
          formData={formData}
          state={this.state[this.props.pageState.page]}
          values={this.state}
          handleChange={this.handleChange}
          handleChangeForAddress={this.handleChangeForAddress}
          handleChangeForCheckbox={this.handleChangeForCheckbox}
          handleChangeForConfirmation={this.handleChangeForConfirmation}
          handleChangeForDatalist={this.handleChangeForDatalist}
          handleChangeForSelect={this.handleChangeForSelect}
          handleChangeForRadio={this.handleChangeForRadio}
          handleChangeOptions={this.handleChangeOptions}
          handleChangeYear={this.handleChangeYear}
          saveAndContinue={this.saveAndContinue}
          back={this.back}
          handleSubmit={this.handleSubmit}
          newId={this.props.newId}
          updateState={this.updateState}
          uploadFileDetails={this.uploadFileDetails}
          deleteFile={this.deleteFile}
          orgDetails={orgDetails}
        />
        {this.state.success && (
          <Dialog open={true}>
            <Alert severity="success">
              <AlertTitle style={{marginBottom: "10px"}}>
                Thank you for filling out your information!
              </AlertTitle>
              {type === "email" ? emailMsg : phoneMsg}
              <strong>
                {type === "email"
                  ? this.props.user.attributes.email
                  : this.props.user.attributes.phone_number}
              </strong>
              <DialogActions>
                <Button
                  color="inherit"
                  size="small"
                  onClick={() => this.props.history.push("/dashboard")}
                >
                  Go To Dashboard
                </Button>
              </DialogActions>
            </Alert>
          </Dialog>
        )}
        {this.state.error && (
          <Dialog
            PaperProps={{
              style: {
                borderRadius: "10px",
                background: "#ECEFF1",
              },
            }}
            open={Boolean(this.state.error)}
          >
            <ResultModal
              type="error"
              title="Oops! Something went wrong"
              content="The application has encountered an unexpected error. Please try again later."
              handleClose={() => {
                this.setState({error: ""});
              }}
            />
          </Dialog>
        )}
        {!this.state.zone &&
          this.state.showPopup &&
          this.props.pageState.page === "Applicant Details" && (
            <Dialog
              PaperProps={{
                style: {
                  borderRadius: "10px",
                  background: "#ECEFF1",
                },
              }}
              open={!this.state.zone}
            >
              <ResultModal
                type="error"
                title="Address is not in school zone"
                content="The application may be rejected, as the address is not in the allowed school zone."
                handleClose={() => {
                  this.setState({zone: true, showPopup: false});
                }}
              />
            </Dialog>
          )}

        {this.props.pageState.page === "Documents" &&
          (this.props?.newData?.paymentInfo?.paymentId ||
            this.state.paymentId) && (
            <Box margin="10px" color="#009AAA">
              <label>
                Payment Received Successfully. Your Payment ID is{" "}
                {this.props?.newData?.paymentInfo?.paymentId ||
                  this.state.paymentId}
                .
              </label>
              <br></br>
            </Box>
          )}
        <form>
          <Box display="flex" justifyContent="left" marginTop="40px">
            {this.props.pageState.activeStep !== 0 ? (
              <>
                <Box>
                  <GenericButton
                    id={formData.Id}
                    variant="contained"
                    background="light"
                    onClick={this.back}
                  >
                    Previous
                  </GenericButton>
                </Box>
                <Box marginLeft={"15px"}>
                  {this.props.pageState.page !==
                    "Eligibility and Checklist" && (
                    <GenericButton
                      id={formData.Id}
                      variant="contained"
                      background="white"
                      onClick={this.modalOpen}
                      fullWidth={true}
                    >
                      Save & Exit
                    </GenericButton>
                  )}
                </Box>
              </>
            ) : (
              <Box></Box>
            )}
            {this.props.pageState.activeStep !==
            this.props.SideMenu.length - 1 ? (
              <Box marginLeft={"auto"}>
                <GenericButton
                  id={formData.Id}
                  variant="contained"
                  background="blue"
                  onClick={this.saveAndContinue}
                >
                  Next
                </GenericButton>
              </Box>
            ) : (
              <>
                {(this.props.orgDetails.campusList[0].additionalInfo
                  .razorpayPayment ||
                  this.props.orgDetails.campusList[0].additionalInfo
                    .stripePayment) &&
                !this.props?.newData?.paymentInfo?.paymentId &&
                !this.state.paymentId ? (
                  <Box marginLeft={"auto"}>
                    <GenericButton
                      id={formData.Id}
                      variant="contained"
                      background="blue"
                      onClick={this.handlePaymentVendor}
                    >
                      Pay & Submit
                    </GenericButton>
                  </Box>
                ) : (
                  <Box marginLeft={"auto"}>
                    <GenericButton
                      id={formData.Id}
                      variant="contained"
                      background="blue"
                      onClick={this.handleSubmit}
                    >
                      Submit
                    </GenericButton>
                  </Box>
                )}
              </>
            )}
          </Box>
        </form>
      </>
    );
  }
}
export default withCookies(Page);
