import Box from "@material-ui/core/Box";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import CardHeader from "@material-ui/core/CardHeader";
import makeStyles from "@material-ui/core/styles/makeStyles";
import Typography from "@material-ui/core/Typography";
import GenericButton from "./GenericButton";

const useStyles = makeStyles((theme) => ({
  cardHeader: {
    paddingTop: theme.spacing(1),
    color: "#000",
    textAlign: "center",
  },
  card: {
    borderColor: "black",
    borderRadius: "16px",
    boxShadow: "3px 3px 20px rgba(96, 125, 139, 0.5)",
    padding: theme.spacing(3, 3, 3, 3),
    [theme.breakpoints.between("xs", "sm")]: {
      padding: theme.spacing(1.5, 1.5, 1.5, 1.5),
    },
    margin: "30px 0 30px 0",
  },
  cardContent: {
    paddingTop: "0px",
  },
  box: {
    paddingTop: "27px",
    textAlign: "center",
  },
  price: {
    textAlign: "center",
    color: "#000",
    fontSize: "30px",
    paddingTop: "16px",
  },
}));

const handleIcon = (value: boolean) => {
  if (value) {
    return (
      <img src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAQCAYAAAAf8/9hAAAABmJLR0QA/wD/AP+gvaeTAAAAoElEQVQ4jeXQPQ4BYRQF0DPjLxQSlSVRERJahUFEqC3ADpQahcKGrISCiJ+GSBhiSnHLLzn3e+/xp+lpGypBmBhHJi46TnaQToS7Bi6qTpqWjhAkwtRveH9//q7gDeZ+g6GSrk4sjkwEagoaz/hRULYVqIlMXzAVeU1zh7j+xwotKUUroY2F2aex4wugL+NsjSzCTz+/T19GZGQslwz+bK465CiTNDaCZgAAAABJRU5ErkJggg==" alt=""></img>
    );
  } else {
    return (
      <img src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAA4AAAAOCAYAAAAfSC3RAAAABmJLR0QA/wD/AP+gvaeTAAAAvElEQVQokcXSMQrCQBAF0P8nlxBPoEUOIGgOoLGyCcTOg9kYUFvBA9gIYmfjCUwO4e5YyAaz2TSC+Lsd5hXzWeAvUYBlOr5W0yTu2qmmSVymk4t7CwAQUKVZamSLEH7MkqFGtqDlys3YWJiPBkS0p5G8dzzdHKLY3eesBX1siWcIBaG7R2m3AECVzEf1jd+kBesiVDIVs+gqjD7ybwoV1oBd7XVhAd4fgLSbEAKA/uF8p5Fcxa7VL7Q1+FVeqk5q6Tjwa9oAAAAASUVORK5CYII=" alt=""></img>
    );
  }
};

function Pricing(props: any) {
  const classes = useStyles();
  const name = props.data?.name;

  return (
    <Card variant="outlined" className={classes.card}>
      <CardHeader
        title={props.data?.name}
        titleTypographyProps={{ variant: "subtitle1" }}
        className={classes.cardHeader}
      />
      <CardContent className={classes.cardContent}>
        <Typography gutterBottom={true} className={classes.price}>
          {props.data?.price}
        </Typography>
        <Box px={1} className={classes.box}>
          <Typography color="textSecondary" component="p">
            {props.data?.features.maxApps} Applications/Year
          </Typography>
          <Typography color="textSecondary" component="p">
            {props.data?.features.maxUsers} Admin Users
          </Typography>
          <Typography color="textSecondary" component="p">
            {handleIcon(props.data?.features.reporting)} Analytics
          </Typography>
          <Typography color="textSecondary" component="p">
            {handleIcon(props.data?.features.exporting)} Data Export
          </Typography>
          <Typography color="textSecondary" component="p">
            {handleIcon(props.data?.features.support)} Support
          </Typography>
          <Typography color="textSecondary" component="p" paragraph={true}>
            {handleIcon(props.data?.features.customURL)} Custom URL
          </Typography>
        </Box>
        <Box style={{ display: "flex", justifyContent: "center" }}>
          <GenericButton
            background="blue"
            onClick={props.handleClick}
            variant="contained"
            disabled={props.disableButton[name]}
          >
            Subscribe
          </GenericButton>
        </Box>
      </CardContent>
    </Card>
  );
}
export default Pricing;
