import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import makeStyles from "@material-ui/core/styles/makeStyles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import moment from "moment";
import FileSaver from "file-saver";
import childFemale from "../../assets/icons/childFemale.svg";
import childMale from "../../assets/icons/childMale.svg";
import download from "../../assets/icons/download.svg";
import downloadDisabled from "../../assets/icons/downloadDisabled.svg";
import EventIcon from "@material-ui/icons/Event";
import ScheduleIcon from "@material-ui/icons/Schedule";
import MailIcon from "@material-ui/icons/Mail";
import downArrow from "../../assets/icons/downArrow.svg";
import InfoIcon from "@material-ui/icons/Info";
import { downloadApplication } from "../../api";

const useStyles = makeStyles((theme) => ({
  applicationDetails: {
    backgroundColor: theme.palette.background.default,
    borderRadius: "8px",
    marginBottom: theme.spacing(2),
    cursor: "pointer",
    "&.MuiAccordion-root:before": {
      backgroundColor: "white",
    },
  },
}));

const InterviewDetails = (props: any) => {
  let { date, startTime, endTime, scheduledBy } = props.data;
  const sTime = new Date(`${date}T${startTime}:00.000Z`);
  startTime = moment(sTime).format("hh:mm A");
  const eTime = new Date(`${date}T${endTime}:00.000Z`);
  endTime = moment(eTime).format("hh:mm A");
  return (
    <Box
      style={{
        background: "#FFEACB",
        borderRadius: props.smallScreen ? "8px" : "8px 8px 0 0",
        padding: "5px",
      }}
    >
      <Box
        width="100%"
        display="flex"
        {...(props.smallScreen && { flexDirection: "column" })}
        justifyContent="space-between"
      >
        <Box
          display="flex"
          alignItems="center"
          {...(props.smallScreen && { marginBottom: "5px" })}
        >
          <EventIcon
            fontSize="small"
            style={{ color: "#FF9E45", margin: "0 2px 0 2vw" }}
          />
          <Typography variant="caption" style={{ color: "#FF9E45" }}>
            {moment(date).format("Do MMMM YYYY")}
          </Typography>
          <ScheduleIcon
            fontSize="small"
            style={{ color: "#FF9E45", margin: "0 2px 0 2vw" }}
          />
          <Typography variant="caption" style={{ color: "#FF9E45" }}>
            {startTime + "-" + endTime}
          </Typography>
        </Box>
        <a
          href={`mailto:${scheduledBy}`}
          style={{
            textDecoration: "none",
            display: "flex",
            alignItems: "center",
          }}
        >
          <MailIcon
            fontSize="small"
            style={{
              color: "#FF9E45",
              ...(props.smallScreen && { marginLeft: "2vw" }),
            }}
          />
          <Typography
            variant="caption"
            style={{ color: "#FF9E45", margin: "0 2vw 0 2px" }}
          >
            Contact Don Bosco Admin
          </Typography>
        </a>
      </Box>
    </Box>
  );
};
function ApplicationDetail(props: any) {
  const { data, handleTable } = props;
  const date = moment(data.auditDetails.createdAt).format("DD MMMM YYYY");
  const classes = useStyles();
  const mediumScreen = useMediaQuery((theme: any) =>
    theme.breakpoints.between("xs", "sm")
  );
  const smallScreen = useMediaQuery((theme: any) =>
    theme.breakpoints.between("0", "xs")
  );
  if (smallScreen)
    return (
      <>
        {data.additionalInfo &&
          Object.keys(data.additionalInfo).includes("interviewDetails") &&
          data.status === "Interview Scheduled" && (
            <Box
              display="flex"
              justifyContent="flex-end"
              marginBottom="-2vh"
              marginRight="-1vw"
            >
              <InfoIcon style={{ color: "#FF9E45", zIndex: 2 }} />
            </Box>
          )}
        <Accordion classes={{ root: classes.applicationDetails }} elevation={0}>
          <AccordionSummary expandIcon={<img src={downArrow} alt =""/>}>
            <Box
              display="flex"
              flexDirection="column"
              onClick={(event) => {
                event.stopPropagation();
                handleTable(
                  data.applicationId,
                  data.orgDetails.orgName,
                  data.applicantDetails.childId,
                  data.orgId,
                  data.campusId
                );
              }}
              onFocus={(event) => event.stopPropagation()}
              style={{ wordBreak: "break-all" }}
            >
              <Typography variant="subtitle1" style={{ marginBottom: "0.5em" }}>
                {data.orgDetails.orgName}
              </Typography>
              <Box display="flex" alignItems="center">
                <img
                  width="32px"
                  height="32px"
                  src={
                    data.applicantDetails.gender === "Male"
                      ? childMale
                      : childFemale
                  }
                  alt=""
                  style={{ marginRight: "1em" }}
                />
                <Typography variant="caption">
                  {data.applicantDetails.firstName +
                    data.applicantDetails.lastName}
                </Typography>
              </Box>
            </Box>
          </AccordionSummary>
          <AccordionDetails>
            <Box display="flex" flexDirection="column" width="100%">
              <Box
                display="flex"
                justifyContent="space-between"
                marginBottom="10px"
                onClick={() => {
                  handleTable(
                    data.applicationId,
                    data.orgDetails.orgName,
                    data.applicantDetails.childId,
                    data.orgId,
                    data.campusId
                  );
                }}
              >
                <Box display="flex" flexDirection="column">
                  <Typography
                    variant="subtitle2"
                    style={{ marginBottom: "0.5em" }}
                  >
                    Application No.
                  </Typography>
                  <Typography variant="caption">
                    {data.applicationId}
                  </Typography>
                </Box>
                <Box display="flex" flexDirection="column">
                  <Typography
                    variant="subtitle2"
                    style={{ marginBottom: "0.5em" }}
                  >
                    Date
                  </Typography>
                  <Typography variant="caption">{date}</Typography>
                </Box>
              </Box>
              <Box display="flex" justifyContent="space-between">
                <Box display="flex" flexDirection="column">
                  <Typography
                    variant="subtitle2"
                    style={{ marginBottom: "0.5em" }}
                  >
                    Status
                  </Typography>
                  <Box
                    borderRadius="11px"
                    textAlign="center"
                    style={{ backgroundColor: "#FFF9E3" }}
                  >
                    <Typography
                      variant="caption"
                      style={{
                        color:
                          data.status === "Submitted"
                            ? "#F8CB2C"
                            : data.status === "Draft"
                            ? "#607D8B"
                            : data.status === "Interview Scheduled"
                            ? "#FF9E45"
                            : data.status === "Post-interview Review"
                            ? "#2DB6F5"
                            : data.status === "Admission Granted"
                            ? "#009AAA"
                            : "#F9347B",
                        backgroundColor:
                          data.status === "Submitted"
                            ? "#FFF9E3"
                            : data.status === "Draft"
                            ? "#E5E7E8"
                            : data.status === "Interview Scheduled"
                            ? "#FFEACB"
                            : data.status === "Post-interview Review"
                            ? "#D8F5FC"
                            : data.status === "Admission Granted"
                            ? "#C8FFF8"
                            : "#FFD6E4",
                        borderRadius: "11px",
                        padding: "2px 10px 2px 10px",
                      }}
                    >
                      {data.status}
                    </Typography>
                  </Box>
                </Box>
                <img
                  src={data.status === "Draft" ? downloadDisabled : download}
                  alt="Download"
                  onClick={() => {
                    downloadApplication(
                      //Download PDF on Dashboard
                      data.documentReferences[0].docId,
                      data.orgDetails.orgName,
                      data.applicationId
                    ).then((res: any) => {
                      console.log(res);
                      const file = new File(
                        [new Uint8Array(res)],
                        "Application_" + data.applicationId + ".pdf",
                        {
                          type: "application/pdf",
                        }
                      );
                      FileSaver.saveAs(file);
                    });
                  }}
                />
              </Box>
              {data.additionalInfo &&
                Object.keys(data.additionalInfo).includes("interviewDetails") &&
                data.status === "Interview Scheduled" && (
                  <Box marginTop="10px">
                    {" "}
                    <InterviewDetails
                      data={data.additionalInfo.interviewDetails}
                      smallScreen={true}
                    />
                  </Box>
                )}
            </Box>
          </AccordionDetails>
        </Accordion>
      </>
    );
  return (
    <>
      {data.additionalInfo &&
        Object.keys(data.additionalInfo).includes("interviewDetails") &&
        data.status === "Interview Scheduled" && (
          <InterviewDetails data={data.additionalInfo.interviewDetails} />
        )}
      <Box
        display="flex"
        justifyContent="space-around"
        alignItems="center"
        className={classes.applicationDetails}
        padding="1.5em 0em 1.5em 0em"
        {...(data.additionalInfo &&
          Object.keys(data.additionalInfo).includes("interviewDetails") && {
            style: { borderRadius: "0 0 8px 8px" },
          })}
      >
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          width="85%"
          onClick={() => handleTable(
            data.applicationId,
            data.orgDetails.orgName,
            data.applicantDetails.childId,
            data.orgId,
            data.campusId
          )}
        >
          <Box
            display="flex"
            flexDirection="column"
            height="100%"
            justifyContent="space-evenly"
            width="20%"
            style={{ wordBreak: "break-word" }}
            {...(mediumScreen && { width: "20vw" })}
          >
            <Typography
              variant="subtitle1"
              style={{ marginBottom: "0.5em", lineHeight: "22px" }}
            >
              {data.orgDetails.orgName}
            </Typography>
          </Box>
          <Box
            display="flex"
            alignItems="center"
            width="20%"
            style={{ wordBreak: "break-all" }}
          >
            <img
              width="32px"
              height="32px"
              src={
                data.applicantDetails.gender === "Male"
                  ? childMale
                  : childFemale
              }
              alt=""
              style={{ marginRight: "0.5em" }}
            />
            <Typography variant="caption">
              {data.applicantDetails.firstName +
                " " +
                data.applicantDetails.lastName}
            </Typography>
          </Box>
          <Box display="flex" flexDirection="column" width="15%">
            <Typography variant="subtitle2" style={{ marginBottom: "0.5em" }}>
              Application No.
            </Typography>
            <Typography variant="caption">{data.applicationId}</Typography>
          </Box>
          <Box display="flex" flexDirection="column" width="20%">
            <Typography variant="subtitle2" style={{ marginBottom: "0.5em" }}>
              Date
            </Typography>
            <Typography variant="caption">{date}</Typography>
          </Box>
          <Box display="flex" flexDirection="column" width="23%">
            <Typography variant="subtitle2" style={{ marginBottom: "0.5em" }}>
              Status
            </Typography>

            <Typography variant="caption">
              <span
                style={{
                  color:
                    data.status === "Submitted"
                      ? "#F8CB2C"
                      : data.status === "Draft"
                      ? "#607D8B"
                      : data.status === "Interview Scheduled"
                      ? "#FF9E45"
                      : data.status === "Post-interview Review"
                      ? "#2DB6F5"
                      : data.status === "Admission Granted"
                      ? "#009AAA"
                      : "#F9347B",
                  backgroundColor:
                    data.status === "Submitted"
                      ? "#FFF9E3"
                      : data.status === "Draft"
                      ? "#E5E7E8"
                      : data.status === "Interview Scheduled"
                      ? "#FFEACB"
                      : data.status === "Post-interview Review"
                      ? "#D8F5FC"
                      : data.status === "Admission Granted"
                      ? "#C8FFF8"
                      : "#FFD6E4",
                  borderRadius: "11px",
                  padding: "2px 10px 2px 10px",
                }}
              >
                {data.status}
              </span>
            </Typography>
          </Box>
        </Box>
        <img
          src={data.status === "Draft" ? downloadDisabled : download}
          alt="Download"
          {...(data.status === "Draft" && {
            style: { pointerEvents: "none" },
          })}
          
          onClick={() => {
            downloadApplication(
              //Download PDF on Dashboard
              data.documentReferences[0].docId,
              data.orgDetails.orgName,
              data.applicationId
            ).then((res: any) => {
              console.log(res);
              const file = new File(
                [new Uint8Array(res)],
                "Application_" + data.applicationId + ".pdf",
                {
                  type: "application/pdf",
                }
              );
              FileSaver.saveAs(file);
            });
          }}
        />
      </Box>
    </>
  );
}

export default ApplicationDetail;
