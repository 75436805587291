import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import makeStyles from "@material-ui/core/styles/makeStyles";
import CircularProgress from "@material-ui/core/CircularProgress";
import Grid from "@material-ui/core/Grid";
import Alert from "@material-ui/lab/Alert"
import AlertTitle from "@material-ui/lab/AlertTitle";
import { Auth } from "aws-amplify";
import { useState } from "react";
import { sendChangePasswordConfirmation } from "../../api";
import Controls from "../../components/controls/Controls";
import GenericButton from "../../components/GenericButton";
import PasswordHints from "../../components/PasswordHints";
import { onSubmitValidate, updateChip, Validate } from "../../validations/ErrorHandlers";
import successIcon from "../../assets/icons/success.svg";
import errorIcon from "../../assets/icons/error.svg";

const useStyles = makeStyles((theme: any) => ({
  alert: {
    background: "#ECEFF1",
    borderRadius: "10px",
    display: "flex",
    alignItems: "center",
    position: "relative",
    "& .MuiAlert-action": {
      display: "flex",
      alignSelf: "flex-start",
      position: "absolute",
      right: "20px",
      top: "15px",
    },
    "& .MuiIconButton-label": {
      border: "1px solid #607D8B",
      borderRadius: "50%",
      width: "20px",
      height: "20px",
    },
    "& .MuiSvgIcon-fontSizeSmall": {
      fontSize: "15px",
      color: "#607D8B",
    },
    "& .MuiAlert-icon": {
      [theme.breakpoints.between("xs", "sm")]: {
        width: "15vw",
        height: "15vw",
        marginLeft: "-10px",
      },
      [theme.breakpoints.between("0", "xs")]: {
        width: "20vw",
        height: "20vw",
        marginLeft: "-10px",
      },
    },
  },
  icon: {
    [theme.breakpoints.between("xs", "sm")]: {
      width: "100%",
      height: "100%",
    },
    [theme.breakpoints.between("0", "xs")]: {
      width: "100%",
      height: "100%",
    },
  },
  alertTitle: {
    ...theme.typography.displayTitle,
    marginBottom: "10px",
    [theme.breakpoints.between("0", "xs")]: {
      fontSize: "13px",
      lineHeight: "17px",
      marginBottom: "5px",
    },
  },
}));
function PasswordSettings(props: any) {
  const classes = useStyles();
  const initialState = {
    chip: {
      letters: "",
      digits: "",
      specialCharacters: "",
      long: "",
    },
    values: {
      currentPassword: "",
      newPassword: "",
      confirmPassword: "",
    },
    errors: {
      currentPassword: "",
      newPassword: "",
      confirmPassword: "",
    },
  };
  const [formData, setFormData] = useState(initialState);
  const [isButtonDisabled, setButtonDisabled] = useState(true);
  const [isButtonLoading, setButtonLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const [error, setError]: any = useState();

  const updateValue = (event: any) => {
    const data: any = { ...formData };
    data.values[event.target.name] = event.target.value;
    const error = Validate(event.target.name, data);
    data.errors = error;
    if (event.target.name === "newPassword") {
      const chip = updateChip(event.target.name, data);
      setFormData({
        ...data,
        chip: chip,
      });
    } else setFormData(data);
    const errors = onSubmitValidate(formData);
    const validate = Object.values(errors).every((x) => x === "");
    if (validate) {
      setButtonDisabled(false);
    } else {
      setButtonDisabled(true);
    }
  };
  const handleSave = () => {
    setButtonLoading(true);
    Auth.currentAuthenticatedUser()
      .then((user) => {
        return Auth.changePassword(
          user,
          formData.values.currentPassword,
          formData.values.newPassword
        );
      })
      .then((data) => {
        console.log(data);
        sendChangePasswordConfirmation(props.email);
        setButtonLoading(false);
        setSuccess(true);
        setError("");
        setTimeout(() => {
          setFormData(initialState);
          setSuccess(true);
        }, 2000);
      })
      .catch((err) => {
        setButtonLoading(false);
        setError(err);
        console.log(err);
      });
  };

  return (
    <>
      {error && (
        <Box marginBottom="25px">
          <Alert
            icon={<img className={classes.icon} src={errorIcon} alt="" />}
            className={classes.alert}
            onClose={() => {
              setError("");
            }}
          >
            <AlertTitle className={classes.alertTitle} style={{ color: "#F9347B" }}>
              Password Change Unsuccessful
            </AlertTitle>
            <Typography variant="caption">
              {typeof error === "string" ? error : error?.message || "Oops! Something went wrong"}
            </Typography>
          </Alert>
        </Box>
      )}

      {success && (
        <Box marginBottom="25px">
          <Alert
            icon={<img className={classes.icon} src={successIcon} alt="" />}
            onClose={() => {
              setError("");
              setSuccess(false);
            }}
            className={classes.alert}
          >
            <AlertTitle className={classes.alertTitle}>Password Changed Successfully</AlertTitle>
            <Typography variant="caption">
              You will receive a confirmation email on your registered email address -{" "}
              <strong>{props.email}</strong>
            </Typography>
          </Alert>
        </Box>
      )}
      <Box>
        <Controls.Input
          id="currentPassword"
          type="password"
          label="Current Password"
          name="currentPassword"
          placeholder="Enter Current Password"
          value={formData.values.currentPassword}
          error={formData.errors.currentPassword}
          onChange={updateValue}
        />
        <Grid container spacing={2}>
          <Grid item md={6} sm={6} xs={12}>
            <Box display="flex" flexDirection="column" marginRight="50px">
              <Controls.Input
                id="newPassword"
                type="password"
                label="New Password"
                name="newPassword"
                width="100%"
                placeholder="Enter New Password"
                value={formData.values.newPassword}
                error={formData.errors.newPassword}
                onChange={updateValue}
              />
              <PasswordHints chip={formData.chip} />
            </Box>
          </Grid>
          <Grid item md={6} sm={6} xs={12}>
            <Controls.Input
              id="confirmPassword"
              type="password"
              label="Confirm Password"
              name="confirmPassword"
              width="100%"
              placeholder="Re-enter New Password"
              value={formData.values.confirmPassword}
              error={formData.errors.confirmPassword}
              onChange={updateValue}
            />
          </Grid>
        </Grid>

        <Box display="flex" justifyContent="flex-end" marginTop="50px">
          <Box marginRight="25px">
            <GenericButton
              background="light"
              variant="contained"
              id="cancel"
              onClick={() => {
                setError("");
                setFormData(initialState);
              }}
            >
              Reset
            </GenericButton>
          </Box>
          <GenericButton
            background="blue"
            variant="contained"
            id="save"
            onClick={handleSave}
            disabled={isButtonDisabled}
          >
            {isButtonLoading ? (
              <CircularProgress style={{ color: "#FFFFFF", width: "25px", height: "25px" }} />
            ) : (
              "Save"
            )}
          </GenericButton>
        </Box>
      </Box>
    </>
  );
}

export default PasswordSettings;
