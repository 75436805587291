import Box from "@material-ui/core/Box";
import TextField from "@material-ui/core/TextField";
import FormLabel from "@material-ui/core/FormLabel";
import React, { Component } from "react";
import { Auth } from "aws-amplify";
import { Validate } from "../../validations/ErrorHandlers";
import BasePage from "../../components/BasePage";
import GenericButton from "../../components/GenericButton";
import LoadingScreen from "../../components/LoadingScreen";
import ReactGA from "react-ga";
import configFile from "../../config/root-config";
const TRACKING_ID = configFile.googleAnalyticsTrackingId; // OUR_TRACKING_ID
ReactGA.initialize(TRACKING_ID);
ReactGA.event({
  category: "Solvitude",
  action: "Forgot password",
  label: "label"
});
type state = {
  values: {
    [emailphone: string]: string;
  };
  errors: {
    [emailphone: string]: string;
  };
  isButtonLoading: boolean;
  isButtonDisabled: boolean;
};
class ForgotPassword extends Component<any, state> {
  constructor(props: any) {
    super(props);
    this.state = {
      values: {
        emailphone: "",
      },
      errors: {
        emailphone: "",
      },
      isButtonLoading: false,
      isButtonDisabled: true,
    };
    this.handleChange = this.handleChange.bind(this);
    this.handleGetVerificationCode = this.handleGetVerificationCode.bind(this);
  }

  handleChange(event: React.ChangeEvent<HTMLInputElement>) {
    const values = this.state.values;
    values[event.target.name] = event.target.value;
    this.setState({ values });
    const errors = Validate(event.target.name, this.state);
    this.setState({ errors: errors });
    const validate = Object.values(errors).every((x) => x === "");
    if (validate) {
      this.setState({ isButtonDisabled: false });
    } else {
      this.setState({ isButtonDisabled: true });
    }
  }
  handleGetVerificationCode(event: React.MouseEvent<HTMLElement>) {
    event.preventDefault();
    this.setState({ isButtonLoading: true }, async () => {
      try {
        const data = await Auth.forgotPassword(this.state.values.emailphone.toLowerCase());
        console.log(data);
        this.setState({ isButtonLoading: false });
        this.props.history.push("/resetpassword", {
          emailphone: this.state.values.emailphone,
        });
      } catch (err: any) {
        console.log(err);
        this.setState({ isButtonLoading: false });
        const errors = this.state.errors;
        if (err.code === "UserNotFoundException") {
          errors.emailphone = "An account with this email address or phone number does not exist.";
          this.setState({ errors: errors });
        }
      }
    });
  }

  render() {
    return (
      <BasePage
        title="Forgot Password?"
        subTitle="Don’t worry. Enter your registered email address or phone number, whichever you used to sign up, and we’ll send you a verification code to reset your password."
        arrowBack={true}
        history={this.props.history}
      >
        {this.state.isButtonLoading && <LoadingScreen />}

        <form>
          <Box marginBottom="5vh">
            <FormLabel required>Email or Phone No.</FormLabel>
            <TextField
              id="emailphone"
              type="text"
              name="emailphone"
              style={{ width: "100%" }}
              variant="outlined"
              autoComplete="No"
              placeholder="Eg : johndoe@email.com OR +919876543210"
              value={this.state.values.emailphone}
              error={Boolean(this.state.errors.emailphone)}
              helperText={this.state.errors.emailphone}
              onChange={this.handleChange}
            />
          </Box>
          <Box marginBottom="5vh">
            <GenericButton
              id="code"
              type="submit"
              fullWidth={true}
              onClick={this.handleGetVerificationCode}
              disabled={this.state.isButtonDisabled}
              background="blue"
            >
              Request Verification Code
            </GenericButton>
          </Box>
        </form>
      </BasePage>
    );
  }
}

export default ForgotPassword;
