import { useRef } from "react";
import Box from "@material-ui/core/Box";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import makeStyles from "@material-ui/core/styles/makeStyles";
import DialogTitle from "@material-ui/core/DialogTitle";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import Divider from "@material-ui/core/Divider";
import GenericButton from "./GenericButton";
import close from "../assets/icons/close.svg";
import SignaturePad from "react-signature-canvas";

const useStyles = makeStyles((theme:any) => ({
  title: {
    ...theme.typography.confirmationTitle,
  },
}));
type TProps = {
  open: boolean;
  saveSign: (file: any) => void;
  handleClose: () => void;
};
function SignatureModal(props: TProps) {
  const classes = useStyles();
  const sigCanvas: any = useRef({});
  const clear = () => sigCanvas.current.clear();
  const save = () => {
    sigCanvas.current.getTrimmedCanvas().toBlob(async (blob: any) => {
      props.saveSign(blob);
    });
  };
  const smallScreen = useMediaQuery((theme: any) => theme.breakpoints.between(0, "xs"));
  const mediumScreen = useMediaQuery((theme: any) => theme.breakpoints.between("xs", "sm"));

  return (
    <Dialog
      PaperProps={{
        style: {
          borderRadius: "10px",
          boxShadow:
            "0px 16px 24px rgba(0, 0, 0, 0.14), 0px 6px 30px rgba(0, 0, 0, 0.12), 0px 8px 10px rgba(0, 0, 0, 0.2)",
          padding: "10px",
        },
      }}
      open={props.open}
      onClose={props.handleClose}
    >
      <DialogTitle id="title">
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <Box className={classes.title}>Draw your Signature</Box>
          <img src={close} alt="" style={{ cursor: "pointer" }} onClick={props.handleClose} />
        </Box>
      </DialogTitle>
      <Divider style={{ background: "#E5E7E8", margin: "5px -10px 10px -10px" }} />
      <DialogContent>
        <SignaturePad
          ref={sigCanvas}
          canvasProps={{
            style: {
              marginTop: "10px",
              border: "1px solid #009AAA",
              background: "#FFFFFF",
              borderRadius: "10px",
              width: smallScreen ? "60vw" : mediumScreen ? "40vw" : "30vw",
              height: "100px",
            },
          }}
        />
      </DialogContent>
      <DialogActions>
        <GenericButton background="light" onClick={clear} style={{ color: "#82889E" }}>
          Clear
        </GenericButton>
        <GenericButton background="blue" onClick={save} color="secondary" autoFocus>
          Save
        </GenericButton>
      </DialogActions>
    </Dialog>
  );
}
export default SignatureModal;
