import Box from "@material-ui/core/Box";
import Dialog from "@material-ui/core/Dialog";
import TextField from "@material-ui/core/TextField";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import FormLabel from "@material-ui/core/FormLabel";
import { Auth } from "aws-amplify";
import React, { Component } from "react";
import { sendChangePasswordConfirmation } from "../../api";
import BasePage from "../../components/BasePage";
import GenericButton from "../../components/GenericButton";
import LoadingScreen from "../../components/LoadingScreen";
import Password from "../../components/Password";
import PasswordHints from "../../components/PasswordHints";
import {
  onSubmitValidate,
  updateChip,
  Validate,
} from "../../validations/ErrorHandlers";
type state = {
  values: {
    [code: string]: string;
    newPassword: string;
    confirmPassword: string;
  };
  errors: {
    [code: string]: string;
    newPassword: string;
    confirmPassword: string;
  };
  chip: {
    letters: string;
    digits: string;
    specialCharacters: string;
    long: string;
  };
  isButtonDisabled: boolean;
  isButtonLoading: boolean;
  reset: boolean;
};
class ResetPassword extends Component<any, state> {
  constructor(props: any) {
    super(props);
    this.state = {
      values: {
        code: "",
        newPassword: "",
        confirmPassword: "",
      },
      errors: {
        code: "",
        newPassword: "",
        confirmPassword: "",
      },
      chip: {
        letters: "",
        digits: "",
        specialCharacters: "",
        long: "",
      },
      isButtonDisabled: true,
      isButtonLoading: false,
      reset: false,
    };
    this.handleChange = this.handleChange.bind(this);
    this.handleReset = this.handleReset.bind(this);
  }
  handleChange(event: React.ChangeEvent<HTMLInputElement>) {
    const values = this.state.values;
    values[event.target.name] = event.target.value;
    this.setState({ values });

    const errors = Validate(event.target.name, this.state);

    if (event.target.name === "newPassword") {
      const chip = updateChip(event.target.name, this.state);
      this.setState({ errors: errors, chip: chip });
    } else this.setState({ errors: errors });
    const errors2 = onSubmitValidate(this.state);
    const validate = Object.values(errors2).every((x) => x === "");
    if (validate) {
      this.setState({ isButtonDisabled: false });
    } else {
      this.setState({ isButtonDisabled: true });
    }
  }
  handleReset(event: React.MouseEvent<HTMLElement>) {
    event.preventDefault();
    this.setState({ isButtonLoading: true });
    Auth.forgotPasswordSubmit(
      this.props.location.state?.emailphone,
      this.state.values.code,
      this.state.values.newPassword
    )
      .then(async (data) => {
        console.log(data);
        const accessToken = await this.props.generateAccessToken("AnonyReset");
        sendChangePasswordConfirmation(
          this.props.location.state?.emailphone,
          accessToken
        );
        this.setState({ isButtonLoading: false });
        this.setState({ reset: true });
        setTimeout(() => {
          this.props.history.push("/login");
        }, 5000);
      })
      .catch((err) => {
        console.log(err);
        const errors = this.state.errors;
        if (err.code === "CodeMismatchException")
          errors.code = "Invalid verification code provided, please try again.";
        else if (err.code === "InvalidParameterException")
          errors.newPassword = "Password constraints didn't match";
        this.setState({ isButtonLoading: false, errors: errors });
      });
  }
  render() {
    const subTitle = `A 6 digit verification code to reset your password has been sent to ${this.props.location.state?.emailphone}.`;
    return (
      <BasePage
        title="Reset Password"
        subTitle={subTitle}
        arrowBack={true}
        history={this.props.history}
      >
        {this.state.isButtonLoading && <LoadingScreen />}
        <form>
          <Box marginBottom="4vh">
            <FormLabel required>Verification Code</FormLabel>
            <TextField
              id="code"
              type="text"
              name="code"
              style={{ width: "100%" }}
              variant="outlined"
              autoComplete="No"
              placeholder="Eg : 123456"
              value={this.state.values.code}
              error={Boolean(this.state.errors.code)}
              helperText={this.state.errors.code}
              onChange={this.handleChange}
              inputProps={{ maxLength: 6 }}
            />
          </Box>
          <Box marginBottom="2vh">
            <FormLabel required>New Password</FormLabel>
            <Password
              id="newPassword"
              name="newPassword"
              placeholder="Enter new password"
              value={this.state.values.newPassword}
              error={this.state.errors.newPassword}
              onChange={this.handleChange}
            />
          </Box>
          <Box marginBottom="3vh">
            <PasswordHints chip={this.state.chip} />
          </Box>
          <Box marginBottom="4vh">
            <FormLabel required>Confirm Password</FormLabel>
            <Password
              id="confirmPassword"
              name="confirmPassword"
              placeholder="Re-enter new password"
              error={this.state.errors.confirmPassword}
              value={this.state.values.confirmPassword}
              onChange={this.handleChange}
            />
          </Box>
          <Box marginBottom="4vh">
            <GenericButton
              id="reset"
              type="submit"
              fullWidth={true}
              onClick={this.handleReset}
              disabled={this.state.isButtonDisabled}
              background="blue"
            >
              Reset Password
            </GenericButton>
          </Box>
        </form>
        {this.state.reset && (
          <Dialog
            open={true}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle id="alert-dialog-title">
              Password Reset Successful
            </DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-dialog-description">
                Your password has been resetted successfully. You can now login
                and continue.
              </DialogContentText>
            </DialogContent>
          </Dialog>
        )}
      </BasePage>
    );
  }
}
export default ResetPassword;
