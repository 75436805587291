import useMediaQuery from "@material-ui/core/useMediaQuery";
import Box from "@material-ui/core/Box";
import {useEffect, useState} from "react";
import {useLocation} from "react-router-dom";
import PageHeadBase from "../../components/PageHeadBase";
import ApplicationDetails from "./ApplicationDetails";
import ApplicationSummary from "./ApplicationSummary";
import useStyles from "./styles";

function ViewApplication(props: any) {
  const {state}: any = useLocation();
  const [data, setData] = useState(state.data);
  const updateData = (data: any) => setData(data);
  const smallScreen = useMediaQuery((theme: any) =>
    theme.breakpoints.down("sm")
  );
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const classes = useStyles();
  return (
    <>
      <PageHeadBase
        route={`Dashboard -> Application No. ${data.applicationId}`}
        history={props.history}
      >
        <Box
          display="flex"
          alignItems="flex-start"
          {...(smallScreen && {
            flexDirection: "column",
            alignItems: "center",
          })}
        >
          <ApplicationSummary
            data={data}
            updateData={updateData}
            user={props.user}
          />
          <Box
            className={classes.detailsContainer}
            width={smallScreen ? "100%" : "72%"}
          >
            <ApplicationDetails data={data} />
          </Box>
        </Box>
      </PageHeadBase>
    </>
  );
}

export default ViewApplication;
