import { useEffect, useState } from "react";
import { checkout, getSubscriptionPlans, subCampusLinkage } from "../../../api";
import GenericButton from "../../../components/GenericButton";
import makeStyles from "@material-ui/core/styles/makeStyles";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import Modal from "@material-ui/core/Modal";
import CardContent from "@material-ui/core/CardContent";
import Typography from "@material-ui/core/Typography";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import CardHeader from "@material-ui/core/CardHeader";
import Card from "@material-ui/core/Card";
import LoadingScreen from "../../../components/LoadingScreen";
import SuccessScreen from "../../../components/SuccessScreen";
import Pricing from "../../../components/Pricing";

const useStyles = makeStyles((theme) => ({
  cardHeader: {
    paddingTop: theme.spacing(1),
    color: "#000",
    textAlign: "center",
  },
  card: {
    borderColor: "black",
    borderRadius: "16px",
    boxShadow: "3px 3px 20px rgba(96, 125, 139, 0.5)",
    padding: theme.spacing(3, 3, 3, 3),
    [theme.breakpoints.between("xs", "sm")]: {
      padding: theme.spacing(1.5, 1.5, 1.5, 1.5),
    },
    margin: "30px 0 30px 0",
  },
  cardContent: {
    paddingTop: "0px",
  },
  box: {
    paddingTop: "27px",
    textAlign: "center",
  },
  price: {
    textAlign: "center",
    color: "#000",
    fontSize: "30px",
    paddingTop: "16px",
  },
}));

function Subscription(props: any) {
  const classes = useStyles();
  console.log("props", props);
  const [loading, setLoading] = useState(false);
  const [planData, setPlanData] = useState([]);
  const disableButton = { Free: false, Basic: false, Premium: false };
  const [openCustomModal, setOpenCustomModal] = useState(false);

  const smallScreen = useMediaQuery((theme: any) =>
    theme.breakpoints.between(0, "xs")
  );
  const paper = {
    position: "absolute" as "absolute",
    left: smallScreen ? "37%" : "50%",
    top: "45%",
    transform: "translate(-50%, -50%)",
    width: "50%",
    backgroundColor: "#FFF",
    border: "0px solid #000",
    borderRadius: "15px",
    padding: "10px 30px 30px 30px",
  };
  useEffect(() => {
    setLoading(true);
    getSubscriptionPlans()
      .then((plans) => {
        console.log("Plans", plans.data);
        setPlanData(plans.data);
        setLoading(false);
      })
      .catch((err: any) => {
        setLoading(false);
        console.log(err);
        if (err.response) {
          console.log(err.response.status);
        }
      });
  }, []);

  const handleBasic = (plan: any) => {
    const lineItem = [
      {
        price: plan.priceId.price_list[0].priceId,
        quantity: 1,
      },
    ];
    localStorage.setItem("subId", plan.subId);
    handleSub(lineItem);
  };
  const handlePremium = (plan: any) => {
    const lineItem = [
      {
        price: plan.priceId.price_list[0].priceId,
        quantity: 1,
      },
    ];
    localStorage.setItem("subId", plan.subId);
    handleSub(lineItem);
  };

  const handleFree = (plan: any) => {
    console.log(plan);
    setLoading(true);
    props.setLoading(true);
    props.setSuccess(true);
    const data = JSON.parse(localStorage.getItem("data")!);
    subCampusLinkage(data?.orgId, data?.campusId, plan.subId)
      .then((subLinkData) => {
        console.log("Linked Campus", subLinkData.data);
        props.setSubData({
          subscriptionId: subLinkData.data?.data?.extSubId,
          amount: subLinkData.data?.data?.price,
          plan: subLinkData.data?.data?.name,
          status: subLinkData.data?.data?.status,
        });
        props.setLoading(false);
        props.setSuccess(false);
        props.setSignup(true);
        localStorage.removeItem("data");
        setLoading(false);
      })
      .catch((err: any) => {
        setLoading(false);
        props.setLoading(false);
        props.setSuccess(false);
        props.setLoadingSub(false);
        console.log(err);
        if (err.response) {
          console.log(err.response.status);
        }
      });
  };

  // const handleCustom = async () => {
  //   const lineItem = [
  //     {
  //       price_data: {
  //         currency: "aud",
  //         product: "prod_L2nOIa47t6gqGE",
  //         unit_amount: 1700,
  //         recurring: {
  //           interval: "day",
  //         },
  //       },
  //       quantity: 1,
  //     },
  //   ];
  //   handleSub(lineItem);
  // };

  // const handleNewSubscription = async () => {
  //   setSubscriptionObj({
  //     subscriptionId: "",
  //     amount: "",
  //     plan: "",
  //     status: ""
  //   });
  // }

  const handleSub = async (
    lineItem:
      | { price: string; quantity: number }[]
      | {
          price_data: {
            currency: string;
            product: string;
            unit_amount: number;
            recurring: { interval: string };
          };
          quantity: number;
        }[]
  ) => {
    const email = JSON.parse(localStorage.getItem("data")!).email;
    const session = await checkout(lineItem, props.location, email);
    console.log(session);
    // const sessionId = session.id;
    // const campus = await patchCampusDetails(props.campusData.orgId, props.campusData.campusId,{
    //     additionalInfo: {
    //         session: session
    //     }});
    // console.log('updated campus',campus);
  };

  let handleName = function () {};
  // console.log('subObj', subscriptionObj);

  return (
    <>
      {props.loading && <LoadingScreen />}
      {loading && <LoadingScreen />}
      {props.success && <SuccessScreen />}
      <Modal open={openCustomModal}>
        <div style={paper}>
          <h2>Custom Subscription</h2>
          <hr />
          <Typography
            variant="subtitle2"
            style={{
              fontSize: "16px",
              marginBottom: "25px",
              marginTop: "25px",
            }}
          >
            Please contact <b>admin@solvitude.com.au</b> to subscribe to custom
            plan. Till then you can subscribe to our free plan.
          </Typography>
          <Grid container spacing={2} style={{ marginTop: "25px" }}>
            <Grid item xs="auto">
              <GenericButton
                id="ok"
                variant="contained"
                onClick={() => setOpenCustomModal(false)}
                background="white"
              >
                Ok
              </GenericButton>
            </Grid>
          </Grid>
        </div>
      </Modal>

      {props.subscription.plan ? (
        <>
          <Typography
            // variant="subtitle2"
            style={{
              fontSize: "16px",
              marginBottom: "25px",
              marginTop: "25px",
            }}
          >
            You have Subscribed to our <b>{props.subscription.plan}</b> plan.
          </Typography>

          {props.subscription?.subscriptionId && (
            <Typography
              style={{
                fontSize: "16px",
                marginBottom: "25px",
                marginTop: "25px",
              }}
            >
              Your Subscription ID is : {props.subscription.subscriptionId}.
            </Typography>
          )}
        </>
      ) : (
        <>
          {smallScreen ? (
            <Box
              margin="10px"
              color="#009AAA"
              marginTop="4vh"
              display="flex"
              flexDirection="column"
            >
              {planData.length &&
                planData.map((plan: any, index: any) => {
                  if (plan.name === "Free") handleName = () => handleFree(plan);
                  else if (plan.name === "Basic")
                    handleName = () => handleBasic(plan);
                  else if (plan.name === "Premium")
                    handleName = () => handlePremium(plan);
                  return (
                    <Pricing
                      handleClick={handleName}
                      data={plan}
                      key={index}
                      disableButton={disableButton}
                    />
                  );
                })}
              <Card variant="outlined" className={classes.card}>
                <CardHeader
                  title={"Custom"}
                  titleTypographyProps={{ variant: "subtitle1" }}
                  className={classes.cardHeader}
                />
                <CardContent className={classes.cardContent}>
                  <Typography gutterBottom={true} className={classes.price}>
                    {"$ XX.XX"}
                  </Typography>
                  <Box px={1} className={classes.box}>
                    <Typography color="textSecondary" component="p">
                      You can customise
                    </Typography>
                    <Typography color="textSecondary" component="p">
                      every feature
                    </Typography>
                    <Typography color="textSecondary" component="p">
                      according to your
                    </Typography>
                    <Typography color="textSecondary" component="p">
                      requirements in
                    </Typography>
                    <Typography
                      color="textSecondary"
                      component="p"
                      paragraph={true}
                    >
                      the same plan.
                    </Typography>
                  </Box>
                  <Box style={{ display: "flex", justifyContent: "center" }}>
                    <GenericButton
                      background="blue"
                      onClick={() => setOpenCustomModal(true)}
                      variant="contained"
                      //disabled={props.disableButton[name]}
                    >
                      Subscribe
                    </GenericButton>
                  </Box>
                </CardContent>
              </Card>
            </Box>
          ) : (
            <Box
              margin="10px"
              color="#009AAA"
              display="flex"
              alignItems="center"
              justifyContent="space-around"
              justifyItems="center"
              marginTop="4vh"
              flexWrap="wrap"
            >
              {planData.length && (
                <Grid container spacing={2}>
                  {planData.map((plan: any, index: any) => {
                    if (plan.name === "Free")
                      handleName = () => handleFree(plan);
                    else if (plan.name === "Basic")
                      handleName = () => handleBasic(plan);
                    else if (plan.name === "Premium")
                      handleName = () => handlePremium(plan);
                    return (
                      <Grid item md={6} sm={6} xs={12}>
                        <Pricing
                          handleClick={handleName}
                          data={plan}
                          key={index}
                          disableButton={disableButton}
                        />
                      </Grid>
                    );
                  })}
                  <Grid item md={6} sm={6} xs={12}>
                    <Card variant="outlined" className={classes.card}>
                      <CardHeader
                        title={"Custom"}
                        titleTypographyProps={{ variant: "subtitle1" }}
                        className={classes.cardHeader}
                      />
                      <CardContent className={classes.cardContent}>
                        <Typography
                          gutterBottom={true}
                          className={classes.price}
                        >
                          {"$ XX.XX"}
                        </Typography>
                        <Box px={1} className={classes.box}>
                          <Typography color="textSecondary" component="p">
                            You can customise
                          </Typography>
                          <Typography color="textSecondary" component="p">
                            every feature
                          </Typography>
                          <Typography color="textSecondary" component="p">
                            according to
                          </Typography>
                          <Typography color="textSecondary" component="p">
                            your requirements
                          </Typography>
                          <Typography color="textSecondary" component="p">
                            in the same
                          </Typography>
                          <Typography
                            color="textSecondary"
                            component="p"
                            paragraph={true}
                          >
                            plan.
                          </Typography>
                        </Box>
                        <Box
                          style={{ display: "flex", justifyContent: "center" }}
                        >
                          <GenericButton
                            background="blue"
                            onClick={() => setOpenCustomModal(true)}
                            variant="contained"
                            //disabled={props.disableButton[name]}
                          >
                            Subscribe
                          </GenericButton>
                        </Box>
                      </CardContent>
                    </Card>
                  </Grid>
                </Grid>
              )}
            </Box>
          )}
        </>
      )}

      {/* <Grid
          item
          md={12}
          sm={12}
          xs={12}
          style={{ display: "flex", justifyContent: "space-between", marginTop: "4vh" }}
        >
          <GenericButton
            variant="contained"
            id="prevCampus"
            type="submit"
            onClick={props.handlePrevious}
            background="blue"
          >
            Previous
          </GenericButton>
          { props.subscription.subscriptionId && (
          <GenericButton
            id="submit"
            type="submit"
            variant="contained"
            onClick={props.handleSubmit}
            background="blue"
          >
            Submit
          </GenericButton>
          )}
        </Grid> */}
    </>
  );
}

export default Subscription;
