export const awsConfig = {
  aws_region: "ap-southeast-2",
  aws_ssl_enabled: true,
  aws_http_connect_timeout: 3000,
  aws_http_timeout: 3000,
  aws_accessKeyID: process.env.REACT_APP_USER_ACCESSKEY_ID!,
  aws_secretAccessKey: process.env.REACT_APP_USER_ACCESS_KEY!,
  //For Local Setup Enable below access key and Comment 6 & 7 line
  // aws_accessKeyID: "AKIAVMGZ4OKFE5K5M4UT",
  // aws_secretAccessKey: "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",
};
