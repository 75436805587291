import { Modal, Typography, Grid } from "@material-ui/core";
import GenericButton from "../../components/GenericButton";

const SaveAndExit = (props: any) => {
  const paper = {
    position: "absolute" as "absolute",
    left: "50%",
    top: "45%",
    transform: "translate(-50%, -50%)",
    width: "50%",
    backgroundColor: "#FFF",
    border: "0px solid #000",
    borderRadius: "15px",
    padding: "10px 30px 30px 30px",
  };
  return (
    <Modal open={props.openModal}>
      <div style={paper}>
        <h2>Save & Exit</h2>
        <hr />
        <Typography
          variant="subtitle2"
          style={{
            fontSize: "16px",
            marginBottom: "25px",
            marginTop: "25px",
          }}
        >
          Your application will be saved as draft and will be available on the
          homepage.
        </Typography>
        <Grid container spacing={2} style={{ marginTop: "25px" }}>
          <Grid item xs="auto">
            <GenericButton
              id="save"
              variant="contained"
              onClick={() => props.handleSave("exit")}
              background="blue"
            >
              Save & Exit
            </GenericButton>
          </Grid>
          <Grid item xs="auto">
            <GenericButton
              id="cancel"
              variant="contained"
              onClick={props.modalClose}
              background="white"
            >
              Cancel
            </GenericButton>
          </Grid>
        </Grid>
      </div>
    </Modal>
  );
};

export default SaveAndExit;
