import {Redirect, Route, Switch} from "react-router-dom";
import Home from "../Home";
import AdminSignUp from "./AdminSignUp";
import ForgotPassword from "./ForgotPassword";
import Login from "./Login";
import ResetPassword from "./ResetPassword";
import SignUp from "./SignUp";
import UserSignUp from "./UserSignUp";

function UserAuth(props: any) {
  return (
    <Switch>
      <Route path="/home" render={(routerProps) => <Home {...routerProps} />} />
      <Route
        path="/login"
        render={(routerProps) => (
          <Login
            generateAccessToken={props.generateAccessToken}
            {...routerProps}
            updateUser={props.updateUser}
            geolocation={props.geolocation}
            schoolName={props.schoolName}
          />
        )}
      />
      <Route
        path="/forgotpassword"
        render={(routerProps) => <ForgotPassword {...routerProps} />}
      />
      <Route
        path="/user/signup"
        render={(routerProps) => (
          <SignUp
            generateAccessToken={props.signUpAccessToken}
            {...routerProps}
            schoolName={props.schoolName}
          />
        )}
      />
      <Route
        path="/admin/signup"
        render={(routerProps) => (
          <AdminSignUp
            generateAccessToken={props.signUpAccessToken}
            {...routerProps}
          />
        )}
      />
      <Route
        path="/resetpassword"
        render={(routerProps) => (
          <ResetPassword
            generateAccessToken={props.signUpAccessToken}
            {...routerProps}
          />
        )}
      />
      <Route
        path="/profile/:email"
        render={(routerProps) => (
          <UserSignUp
            {...routerProps}
            generateAccessToken={props.generateAccessToken}
            schoolName={props.schoolName}
          />
        )}
      />
      <Redirect to={props.schoolName ? "/user/signup" : "/home"} />
    </Switch>
  );
}

export default UserAuth;
