import Box from "@material-ui/core/Box";
import Dialog from "@material-ui/core/Dialog";
import Step from "@material-ui/core/Step";
import DialogContent from "@material-ui/core/DialogContent";
import makeStyles from "@material-ui/core/styles/makeStyles";
import StepContent from "@material-ui/core/StepContent";
import StepLabel from "@material-ui/core/StepLabel";
import Stepper from "@material-ui/core/Stepper";
import Typography from "@material-ui/core/Typography";
import {useEffect, useState} from "react";
import BasePage from "../../../components/BasePage";
import {
  onSubmitValidate,
  updateChip,
  Validate,
} from "../../../validations/ErrorHandlers";
import Organisation from "./Organisation";
import User from "./User";
import {Country} from "country-state-city";
import Campus from "./Campus";
import {adminSignup, subCampusLinkage} from "../../../api";
import ResultModal from "../../../components/ResultModal";
import Subscription from "./Subscription";

const Location = Country.getAllCountries();

const useStyles = makeStyles((theme: any) => ({
  stepper: {
    padding: 0,
    marginTop: "3%",
  },
  content: {
    padding: "10px 30px 10px 30px",
  },
  titleContainer: {
    margin: "5px 0 -10px 12px",
    borderLeft: "1px solid #bdbdbd",
  },
  title: {
    ...theme.typography.adminSignupTitle,
    marginTop: "-10px",
    marginBottom: "10px",
  },
  titleInactive: {
    ...theme.typography.adminSignupTitle,
    marginLeft: "30px",
    color: "#90A4AE",
  },
  stepLabel: {
    "& .MuiStepLabel-label.MuiStepLabel-active": {
      ...theme.typography.confirmationTitle,
    },
    "& .MuiStepLabel-label": {
      ...theme.typography.confirmationTitle,
      color: "#607D8B",
    },
  },
}));
function AdminSignUp(props: any) {
  const classes = useStyles();
  const [activeStep, setActiveStep] = useState(
    JSON.parse(localStorage.getItem("data")!)?.activeStep || 0
  );
  const [user, setUser] = useState({
    values: {
      firstName: "",
      lastName: "",
      country: "Australia",
      phoneNumber: "",
      email: "",
      signupPassword: "",
    },
    errors: {
      firstName: "",
      lastName: "",
      country: "",
      phoneNumber: "",
      email: "",
      signupPassword: "",
    },
    chip: {
      letters: "",
      digits: "",
      specialCharacters: "",
      long: "",
    },
    confirm: false,
    fieldsValidated: false,
  });
  const [organisation, setOrganisation] = useState({
    values: {
      orgName: "",
      address: "",
      state: "",
      city: "",
      pincode: "",
    },
    errors: {
      orgName: "",
      address: "",
      state: "",
      city: "",
      pincode: "",
    },
    fieldsValidated: false,
  });
  const [campus, setCampus] = useState({
    values: {
      campusName: "",
      address: "",
      state: "",
      city: "",
      pincode: "",
    },
    errors: {
      campusName: "",
      address: "",
      state: "",
      city: "",
      pincode: "",
    },
    sameAsOrg: false,
    admissions: false,
    fieldsValidated: false,
  });
  const [subData, setSubData] = useState({
    subscriptionId: "",
    amount: "",
    plan: "",
    status: "",
  });
  const [signup, setSignup] = useState(false);
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState("");
  useEffect(() => {
    const data = JSON.parse(localStorage.getItem("data")!);
    if (props.location?.search) {
      const session = new URLSearchParams(props.location.search);
      const sessionId = session.get("session_id")!;
      setLoading(true);
      setSuccess(true);
      const subId = localStorage.getItem("subId")!;
      subCampusLinkage(data.orgId, data.campusId, subId, sessionId, data.email)
        .then((subLinkData) => {
          console.log("Linked Campus", subLinkData.data);
          localStorage.removeItem("subId");
          setSubData({
            subscriptionId: subLinkData.data?.data?.extSubId,
            amount: subLinkData.data?.data?.price,
            plan: subLinkData.data?.data?.name,
            status: subLinkData.data?.data?.status,
          });
          setLoading(false);
          setSuccess(false);
          setSignup(true);
          localStorage.removeItem("data");
          localStorage.removeItem("subId");
        })
        .catch((err: any) => {
          console.log(err);
          if (err.response) {
            console.log(err.response.status);
          }
        });
    } else {
      localStorage.removeItem("subId");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const getCode = () => {
    const code = Location.filter((data: any) => {
      if (data.name === user.values.country) return data;
      else return null;
    });
    if (code[0]) return code[0].phonecode;
    else return "";
  };
  const handleNext = (event: any) => {
    event.preventDefault();
    setActiveStep(activeStep + 1);
  };
  const handlePrevious = (event: any) => {
    event.preventDefault();
    setActiveStep(activeStep - 1);
  };

  const handleConfirm = (event: any) => {
    setUser({...user, confirm: event.target.checked});
  };
  const handleSameCampusOrg = (event: any) => {
    if (event.target.checked)
      setCampus({
        ...campus,
        values: {
          campusName: organisation.values.orgName,
          address: organisation.values.address,
          state: organisation.values.state,
          city: organisation.values.city,
          pincode: organisation.values.pincode,
        },
        sameAsOrg: event.target.checked,
        fieldsValidated: true,
      });
    else {
      const errors2 = onSubmitValidate(campus);
      const validate = Object.values(errors2).every((x) => x === "");
      if (validate)
        setCampus({
          ...campus,
          sameAsOrg: event.target.checked,
          fieldsValidated: true,
        });
      else
        setCampus({
          ...campus,
          sameAsOrg: event.target.checked,
          fieldsValidated: false,
        });
    }
  };
  const handleAdmissions = (event: any) => {
    setCampus({...campus, admissions: event.target.checked});
  };
  const handleChange = (event: any) => {
    const updatedValues: any =
      activeStep === 0
        ? {
            ...user,
            values: {...user.values, [event.target.name]: event.target.value},
          }
        : activeStep === 1
        ? {
            ...organisation,
            values: {
              ...organisation.values,
              [event.target.name]: event.target.value,
            },
          }
        : activeStep === 2 && {
            ...campus,
            values: {
              ...campus.values,
              [event.target.name]: event.target.value,
            },
          };
    if (activeStep !== 0) {
      updatedValues["country"] = user.values.country;
    }
    const errors = Validate(event.target.name, updatedValues);
    updatedValues.errors = errors;
    const errors2 = onSubmitValidate(updatedValues);
    const validate = Object.values(errors2).every((x) => x === "");
    if (event.target.name === "signupPassword") {
      const chip = updateChip(event.target.name, updatedValues);
      updatedValues.chip = chip;
    }
    if (validate) {
      activeStep === 0
        ? setUser({...updatedValues, fieldsValidated: true})
        : activeStep === 1
        ? setOrganisation({...updatedValues, fieldsValidated: true})
        : activeStep === 2 &&
          setCampus({...updatedValues, fieldsValidated: true});
    } else {
      activeStep === 0
        ? setUser({...updatedValues, fieldsValidated: false})
        : activeStep === 1
        ? setOrganisation({...updatedValues, fieldsValidated: false})
        : activeStep === 2 &&
          setCampus({...updatedValues, fieldsValidated: false});
    }
  };
  const handleSubmit = async () => {
    setLoading(true);
    const data = {
      user: {
        role: "Org_Admin",
        fullName: user.values.firstName + " " + user.values.lastName,
        email: user.values.email,
        password: user.values.signupPassword,
        userDetails: {
          firstName: user.values.firstName,
          lastName: user.values.lastName,
          mobileNo:
            (getCode().includes("+") ? getCode() : "+" + getCode()) +
            user.values.phoneNumber,
          country: user.values.country,
        },
        documentReferences: [],
      },
      organisation: {
        orgName: organisation.values.orgName,
        orgDetails: {
          address: organisation.values.address,
          state: organisation.values.state,
          city: organisation.values.city,
          pincode: organisation.values.pincode,
        },
      },
      campus: {
        campusName: campus.sameAsOrg
          ? organisation.values.orgName
          : campus.values.campusName,
        campusDescription: campus.sameAsOrg
          ? organisation.values.orgName
          : campus.values.campusName,
        admissions: campus.admissions,
        requiredDocuments: "",
        campusAddress: campus.sameAsOrg
          ? organisation.values.address +
            "," +
            organisation.values.city +
            "-" +
            organisation.values.pincode +
            "," +
            organisation.values.state +
            "," +
            user.values.country
          : campus.values.address +
            "," +
            campus.values.city +
            "-" +
            campus.values.pincode +
            "," +
            campus.values.state +
            "," +
            user.values.country,
        additionalInfo: {
          address: campus.sameAsOrg
            ? organisation.values.address
            : campus.values.address,
          state: campus.sameAsOrg
            ? organisation.values.state
            : campus.values.state,
          city: campus.sameAsOrg
            ? organisation.values.city
            : campus.values.city,
          pincode: campus.sameAsOrg
            ? organisation.values.pincode
            : campus.values.pincode,
          country: user.values.country,
        },
      },
    };
    const accessToken = await props.generateAccessToken("AnonyAdmin");
    localStorage.setItem("accessToken", accessToken);

    console.log(data);
    adminSignup(data, user.values.email, accessToken)
      .then((res) => {
        handleNext(window.event);
        setLoading(false);
        res.data.activeStep = 3;
        res.data.email = user.values.email;
        localStorage.setItem("data", JSON.stringify(res.data));
        console.log(res.data);
      })
      .catch((err) => {
        console.log(err.response.data.message);
        if (err.response.data.message.includes("UsernameExistsException")) {
          setUser({
            ...user,
            errors: {
              ...user.errors,
              email: "Username already exists",
            },
          });
          setActiveStep(0);
          window.scrollTo(0, 0);
        } else if (
          err.response.data.message.includes("InvalidParameterException")
        ) {
          setUser({
            ...user,
            errors: {
              ...user.errors,
              phoneNumber: "Invalid phone number format",
            },
          });
        } else setError(err.response.data.message);
        setLoading(false);
        setSuccess(false);
      });
  };
  let content = `You have successfully subscribed to our ${subData.plan} plan. Furthermore, a verification link has been sent to your email address. Please click on the link to complete the signup process.`;
  return (
    <BasePage>
      <Stepper
        className={classes.stepper}
        activeStep={activeStep}
        orientation="vertical"
      >
        <Step active={activeStep === 0 ? true : false}>
          <StepLabel className={classes.stepLabel}>
            <span style={{marginLeft: "10px"}}>Sign Up</span>
          </StepLabel>
          <StepContent {...(activeStep === 0 && {className: classes.content})}>
            <Box className={classes.title}>
              Setup your account with a few basic details
            </Box>
            <User
              user={user}
              handleChange={handleChange}
              handleConfirm={handleConfirm}
              handleNext={handleNext}
              Location={Location}
              countryCode={getCode()}
            />
          </StepContent>
        </Step>
        <Step active={activeStep === 1 ? true : false}>
          <StepLabel className={classes.stepLabel}>
            <span style={{marginLeft: "10px"}}>Organisation Details</span>
          </StepLabel>
          <StepContent {...(activeStep === 1 && {className: classes.content})}>
            <Box className={classes.title}>
              Great! We’ve captured your account details. Now, its time to setup
              your organization.
            </Box>
            <Organisation
              organisation={organisation}
              handleChange={handleChange}
              handleNext={handleNext}
              handlePrevious={handlePrevious}
              country={
                Location.find((data) => data.name === user.values.country)
                  ?.isoCode
              }
            />
          </StepContent>
        </Step>
        <Step active={activeStep === 2 ? true : false}>
          <StepLabel className={classes.stepLabel}>
            <span style={{marginLeft: "10px"}}>Campus Details</span>
          </StepLabel>
          <StepContent {...(activeStep === 2 && {className: classes.content})}>
            <Box className={classes.title}>
              Great! Enter your Campus details and enable admissions to start
              receiving applications.
            </Box>
            <Campus
              campus={campus}
              handleChange={handleChange}
              handlePrevious={handlePrevious}
              handleAdmissions={handleAdmissions}
              handleNext={handleSubmit}
              country={
                Location.find((data) => data.name === user.values.country)
                  ?.isoCode
              }
              loading={loading}
              handleSameCampusOrg={handleSameCampusOrg}
            />
          </StepContent>
        </Step>
        <Step active={activeStep === 3 ? true : false}>
          <StepLabel className={classes.stepLabel}>
            <span style={{marginLeft: "10px"}}>Subscription Details</span>
          </StepLabel>
          <StepContent {...(activeStep === 3 && {className: classes.content})}>
            {subData.plan ? (
              <Box className={classes.title}>
                You've successfully subscribed to DigiEnrol.
              </Box>
            ) : (
              <Box className={classes.title}>
                You're almost there! Subscribe to one of the plans in order to
                start using DigiEnrol.
              </Box>
            )}

            <Subscription
              campusData={campus}
              orgDetails={organisation}
              details={user}
              location={props.location.pathname}
              subscription={subData}
              setSubData={setSubData}
              setSuccess={setSuccess}
              setLoading={setLoading}
              setSignup={setSignup}
              loading={loading}
              success={success}
            />
          </StepContent>
        </Step>
      </Stepper>
      {signup && (
        <Dialog
          PaperProps={{
            style: {
              borderRadius: "10px",
              background: "#ECEFF1",
            },
          }}
          open={Boolean(signup)}
        >
          <ResultModal
            type="success"
            title="Verify Email Address"
            content={content}
            handleClose={() => {
              setSignup(false);
            }}
          />
          <DialogContent>
            <Box
              marginBottom="10px"
              marginRight="10px"
              display="flex"
              justifyContent="center"
              style={{cursor: "pointer"}}
            >
              <Typography variant="caption">
                Once done you can Login{" "}
                <span
                  style={{
                    color: "#009AAA",
                    fontWeight: 700,
                    cursor: "pointer",
                  }}
                  onClick={() => props.history.push("/login")}
                >
                  here
                </span>
              </Typography>
            </Box>
          </DialogContent>
        </Dialog>
      )}
      {error && (
        <Dialog
          PaperProps={{
            style: {
              borderRadius: "10px",
              background: "#ECEFF1",
            },
          }}
          open={Boolean(error)}
        >
          <ResultModal
            type="error"
            title="Signup Failed"
            content={error}
            handleClose={() => {
              setError("");
            }}
          />
        </Dialog>
      )}
    </BasePage>
  );
}

export default AdminSignUp;
