import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import Modal from "@material-ui/core/Modal";
import Grid from "@material-ui/core/Grid";
import {useEffect, useState} from "react";
import PageHeadBase from "../../components/PageHeadBase";
import {getActiveSubscription, getAllCampusAnalytics} from "../../api";
import LoadingScreen from "../../components/LoadingScreen";
import GenericButton from "../../components/GenericButton";
import useStyles from "./styles";

function FormList(props: any) {
  console.log("FormList Props:");
  console.log(props);
  const classes = useStyles();
  const smallScreen = useMediaQuery((theme: any) =>
    theme.breakpoints.between("0", "xs")
  );
  const [loading, setLoading] = useState(false);
  const [loading2, setLoading2] = useState(false);
  const [maxAppModal, setMaxAppModal] = useState(false);
  const [schoolName, setSchoolName] = useState("school");
  const [forms, setForms]: any = useState();

  useEffect(() => {
    const formData = JSON.parse(
      props.location.state.menu.campusList[0].customData.formData
    );
    setForms(formData);
  }, []);

  const titleCase = (str: any) => {
    return str.replace(/\w\S*/g, (t: any) => {
      return t.charAt(0).toUpperCase() + t.substr(1).toLowerCase();
    });
  };
  const fetchCampusInfo = async (orgId: any, campusId: any, form: any) => {
    let maxApps = 0;
    let numOfApps = 0;
    let status = "";
    await getActiveSubscription(orgId, campusId)
      .then((res) => {
        console.log("Subscription Result");
        console.log(res.data);
        maxApps = res.data.features.maxApps;
        status = res.data.status;
        console.log("Max Apps", maxApps);
        setLoading(false);
      })
      .catch((err) => {
        console.log("Subscription Error");
        console.log(err);
        setLoading(false);
      });

    await getAllCampusAnalytics(campusId)
      .then((res) => {
        console.log("Campus Analytics");
        console.log(res.data.classes.all);
        const data = res.data.classes.all;
        console.log("Data:");
        console.log(data);
        numOfApps = data.total - data.Draft;
        console.log("Num of Apps", numOfApps);
        setLoading2(false);
      })
      .catch((err) => {
        console.log(err);
        setLoading2(false);
      });

    if (numOfApps >= maxApps || status !== "Active") {
      setMaxAppModal(true);
    } else {
      if (props.location.state.childDetails.length === 0) {
        props.history.push(
          `/newApplication/${props.location.state.menu.orgName.replace(
            /\s/g,
            "_"
          )}`,
          {
            childDetails: {},
            populateData: props.location.state.populateData,
          }
        );
      } else {
        props.history.push("/newApplication/selectchild", {
          childDetails: props.location.state.childDetails,
          populateData: props.location.state.populateData,
          organisationName: props.location.state.menu.orgName,
          organisationAddress:
            props.location.state.menu.campusList[0].campusAddress,
        });
      }
    }
  };

  const handleClick = async (form: any) => {
    console.log(form);
    const orgId = props.location.state.menu.orgId;
    const campusId = props.location.state.menu.campusList[0].campusId;
    console.log("OrgId: ", orgId);
    console.log("campusId: ", campusId);
    setLoading(true);
    setLoading2(true);
    setSchoolName(props.location.state.menu.orgName);
    localStorage.setItem("type", form.type);
    await fetchCampusInfo(orgId, campusId, form);
  };

  return (
    <>
      {(loading || loading2) && <LoadingScreen />}

      <PageHeadBase
        route="Select Form"
        title="Select Form"
        history={props.history}
      >
        <Modal open={maxAppModal}>
          <div className={classes.paper}>
            <h2>Error</h2>
            <hr />
            <Typography
              variant="subtitle2"
              style={{
                fontSize: "16px",
                marginBottom: "25px",
                marginTop: "25px",
              }}
            >
              Sorry, school is not accepting any more application for this year.
              Please contact {schoolName} for more details.
            </Typography>
            <Grid container spacing={2} style={{marginTop: "25px"}}>
              <Grid item xs="auto">
                <GenericButton
                  id="ok"
                  variant="contained"
                  onClick={() => setMaxAppModal(false)}
                  background="white"
                >
                  OK
                </GenericButton>
              </Grid>
            </Grid>
          </div>
        </Modal>
        <div className={classes.main}>
          {forms?.map((form: any, index: any) => {
            return (
              <div
                key={index}
                className={classes.forms}
                onClick={() => {
                  handleClick(form);
                }}
              >
                {smallScreen ? (
                  <Box display="flex" flexDirection="column">
                    <Typography variant="h1" style={{marginBottom: "0.5em"}}>
                      {titleCase(form.formName)}
                    </Typography>
                  </Box>
                ) : (
                  <Typography variant="h3" style={{marginBottom: "0.5em"}}>
                    {form.formName}
                  </Typography>
                )}
              </div>
            );
          })}
        </div>
      </PageHeadBase>
    </>
  );
}

export default FormList;
