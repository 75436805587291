import Box from "@material-ui/core/Box";
import Link from "@material-ui/core/Link";
import makeStyles from "@material-ui/core/styles/makeStyles";
import Typography from "@material-ui/core/Typography";
import childMale from "../assets/icons/childMale.svg";
import childFemale from "../assets/icons/childFemale.svg";
import avatar from "../assets/icons/avatar.svg";

const useStyles = makeStyles((theme) => ({
  tab: {
    cursor: "pointer",
    padding: "5% 0 5% 10%",
    "&:hover": {
      boxShadow: "inset 0 0 0 10em rgba(255, 255, 255, 0.3)"
    },
  },
  paper: {
    position: "absolute",
    left: "50%",
    top: "45%",
    transform: "translate(-50%, -50%)",
    width: "50%",
    backgroundColor: "#FFF",
    border: "0px solid #000",
    borderRadius: "15px",
    padding: "10px 30px 30px 30px",
  },
  childDetails: {
    backgroundColor: theme.palette.background.default,
    borderRadius: "8px",
    marginBottom: theme.spacing(2),
    cursor: "default",
    position: "relative",
    width: "100%",
  },
  link: {
    cursor: "pointer",
  },
}));

function ReportApplications(props: any) {
  const classes = useStyles();
  const { data } = props;

  return (
    <Box
      className={classes.childDetails}
      padding="1em 1em 1em 1em"
      onClick={() => {
        props.history.push(`/viewApplication/${data.applicationId}`, {
          data: props.applications.find(
            (detail: any) => detail.applicationId === data.applicationId
          ),
        });
      }}
    >
      <Box display="flex" alignItems="flex-start" justifyContent={"center"}>
        <img
          src={
            props.data
              ? data.gender === "Male"
                ? childMale
                : childFemale
              : avatar
          }
          style={{ marginRight: "0.5em" }}
          alt=""
        />

        <Box
          display="flex"
          flexDirection="column"
          justifyContent="center"
          style={{ wordBreak: "break-all" }}
          flexGrow={1}
        >
          <Typography variant="caption" style={{ marginBottom: "0.5em" }}>
            {data.applicantName}
          </Typography>
          <Link className={classes.link}>{data.applicationId}</Link>
        </Box>
        <Box
          display="flex"
          justifyContent="flex-end"
        >
          <Typography
            variant="caption"
            style={{
              color:
                data.status === "Submitted"
                  ? "#607D8B"
                  : data.status === "Interview Scheduled"
                  ? "#FF9E45"
                  : data.status === "Post-interview Review"
                  ? "#2DB6F5"
                  : data.status === "Admission Granted"
                  ? "#009AAA"
                  : "#F9347B",
              backgroundColor:
                data.status === "Submitted"
                  ? "#E5E7E8"
                  : data.status === "Interview Scheduled"
                  ? "#FFEACB"
                  : data.status === "Post-interview Review"
                  ? "#D8F5FC"
                  : data.status === "Admission Granted"
                  ? "#C8FFF8"
                  : "#FFD6E4",
              borderRadius: "11px",
              padding: "2px 10px 2px 10px",
            }}
          >
            {data.status}
          </Typography>
        </Box>
      </Box>
    </Box>
  );
}

export default ReportApplications;
