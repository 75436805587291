import { Box, Grid, Typography } from "@material-ui/core";
import { useEffect, useState } from "react";
import { setupApplicationFee, setupConnectAccount } from "../../api";
import GenericButton from "../../components/GenericButton";
import LoadingScreen from "../../components/LoadingScreen";
import { makeStyles } from "@material-ui/styles";
import Controls from "../../components/controls/Controls";
import { Alert, AlertTitle } from "@material-ui/lab";
import successIcon from "../../assets/icons/success.svg";


const useStyles = makeStyles((theme: any) => ({
  address: {
    "& .MuiOutlinedInput-root": {
      minHeight: "80px",
    },
    "& .MuiOutlinedInput-root.Mui-focused": {
      minHeight: "80px",
    },
    "& .MuiInputBase-root": {
      display: "flex",
      alignItems: "flex-start",
    },
  },

  navTitle: {
    ...theme.typography.profileNav,
  },

  divider: {
    background: "#E5E7E8",
    mixBlendMode: "soft-light",
    opacity: 0.8,
    margin: theme.spacing(3, -1, 3, -1),
  },

  divider2: {
    background: "#E5E7E8",
    margin: theme.spacing(4, 0, 3, 0),
    [theme.breakpoints.between("0", "xs")]: {
      margin: theme.spacing(2, 0, 2, 0),
    },
  },
  //Below classes are old
  alert: {
    background: "#ECEFF1",
    borderRadius: "10px",
    display: "flex",
    alignItems: "center",
    position: "relative",
    "& .MuiAlert-action": {
      display: "flex",
      alignSelf: "flex-start",
      position: "absolute",
      right: "20px",
      top: "15px",
    },
    "& .MuiIconButton-label": {
      border: "1px solid #607D8B",
      borderRadius: "50%",
      width: "20px",
      height: "20px",
    },
    "& .MuiSvgIcon-fontSizeSmall": {
      fontSize: "15px",
      color: "#607D8B",
    },
    "& .MuiAlert-icon": {
      [theme.breakpoints.between("xs", "sm")]: {
        width: "15vw",
        height: "15vw",
        marginLeft: "-10px",
      },
      [theme.breakpoints.between("0", "xs")]: {
        width: "20vw",
        height: "20vw",
        marginLeft: "-10px",
      },
    },
  },
  icon: {
    [theme.breakpoints.between("xs", "sm")]: {
      width: "100%",
      height: "100%",
    },
    [theme.breakpoints.between("0", "xs")]: {
      width: "100%",
      height: "100%",
    },
  },
  alertTitle: {
    ...theme.typography.displayTitle,
    marginBottom: "10px",
    [theme.breakpoints.between("0", "xs")]: {
      fontSize: "13px",
      lineHeight: "17px",
      marginBottom: "5px",
    },
  },
}));

function ApplicationFee(props: any) {
	const classes = useStyles();
	const [appFee, setAppFee] = useState({
		loading: false,
		stripeData: props.stripeData || {},
		setupCompleted: props.stripeData?.status
	});
	const [fee, setFee] = useState(props.applicationFee || {
		currency: '',
		amount: ''
	});
	const [errors, setErrors] = useState({
		currency: "",
		amount: ""
	});
	const [loadingFee, setLoadingFee] = useState(false);
	const [success, setSuccess] = useState(false);

	useEffect(() => {
		if(appFee.setupCompleted === false){
			setAppFee({...appFee, loading: true});
			setupConnectAccount(props.orgDetails.orgId, props.location)
				.then((stripeObject) => {
					if(stripeObject.data.stripeData.hasOwnProperty('status')){
						props.setOrgData({
							...props.orgDetails,
							orgDetails: {
								...props.orgDetails.orgDetails,
								stripe: stripeObject.data.stripeData
							}
						})
						props.setStripeData(stripeObject.data.stripeData);
						setAppFee({loading: false, stripeData: stripeObject.data.stripeData, setupCompleted: stripeObject.data.stripeData.status});
					}
				})
        .catch((err) => {
          console.log("App Fee Error");
          console.log(err);
          setAppFee({...appFee, loading: false});
					if (err.response.status === 401) props.handleLogout();
        })
		}
		props.toggleEdit(props.initialFee);
	},[]);

	const handleChange = (event: any) => {
		setFee({ ...fee, [event.target.name]: event.target.value });
    if (!event.target.value)
      setErrors({ ...errors, [event.target.name]: "Please enter a value" });
		else setErrors({ ...errors, [event.target.name]: "" });
	};

	const handleSave = () => {
		setLoadingFee(true);
		setupApplicationFee(props.orgDetails.orgId, props.campusData.campusId, fee)
			.then((res) => {
				props.updateCampusData(res.data.data);
				props.toggleEdit(false);
				props.setInitialFee(false);
				setSuccess(true);
				setLoadingFee(false);
			})
			.catch((err) => {
				console.log("App Fee Error");
				console.log(err);
				setLoadingFee(false);
				if (err.response.status === 401) props.handleLogout();
			})
	}

	const handleSetup = () => {
		setAppFee({
			...appFee,
			loading: true
		});
		setupConnectAccount(props.orgDetails.orgId, props.location)
			.then((stripeObj) => {
				props.setOrgData({
					...props.orgDetails,
					orgDetails: {
						...props.orgDetails.orgDetails,
						stripe: stripeObj.data.stripeData
					}
				});
				props.setStripeData(stripeObj.data.stripeData);
				if(stripeObj.data.stripeData.setupLink){
					window.open(stripeObj.data.stripeData.setupLink, "_self");
				}
			})
			.catch((err) => {
				console.log("App Fee Error");
				console.log(err);
				setAppFee({...appFee, loading: false});
				if (err.response.status === 401) props.handleLogout();
			})
	}

	return (
		<>
			{(appFee.loading || loadingFee) && <LoadingScreen />}
			{success && (
        <Box marginBottom="25px">
          <Alert
            icon={<img className={classes.icon} src={successIcon} alt="" />}
            onClose={() => {
              setSuccess(false);
            }}
            className={classes.alert}
          >
            <AlertTitle className={classes.alertTitle}>
              Details Updated Successfully
            </AlertTitle>
          </Alert>
        </Box>
      )}
			{appFee.setupCompleted ? (
				<>
					<Grid container spacing={4}>
						<Grid item md={3} sm={6} xs={12}>
							<Typography variant="subtitle2" style={{ marginBottom: "8px" }}>
								Currency
							</Typography>
							<Typography variant="caption">{fee.currency}</Typography>
						</Grid>
						<Grid item md={3} sm={6} xs={12}>
							{props.edit ? (
								<Controls.Input
									width="100%"
									id="amount"
									type="number"
									label="Amount"
									name="amount"
									value={fee.amount}
									onChange={handleChange}
									error={errors.amount}
								/>
							) : (
								<>
								<Typography variant="subtitle2" style={{ marginBottom: "8px" }}>
									Amount
								</Typography>
								<Typography variant="caption">{fee.amount}</Typography>
								</>
							)}
						</Grid>
					</Grid>
					{props.edit && (
						<Box display="flex" justifyContent="flex-end">
							{props.applicationFee !== undefined && !props.initialFee && (
								<Box marginRight="25px">
									<GenericButton
										background="light"
										onClick={() => {
											props.toggleEdit(false);
											setFee(props.applicationFee);
											setErrors({amount: "", currency: ""});
										}}
									>
										Cancel
									</GenericButton>
								</Box>
							)}
							<GenericButton
								background="blue"
								disabled={
									Object.values(errors).every((x) => x === "") ? false : true
								}
								onClick={handleSave}
							>
								Save
							</GenericButton>
						</Box>
					)}
				</>
			) : (
				<>
					<GenericButton
						id="setup"
						variant="contained"
						onClick={handleSetup}
						background="blue"
					>
					SetUp Stripe Connect Account
					</GenericButton>
				</>
			)}
		</>
	)
}

export default ApplicationFee;