import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import FormLabel from "@material-ui/core/FormLabel";
import TextField from "@material-ui/core/TextField";
import InputAdornment from "@material-ui/core/InputAdornment";
import Checkbox from "@material-ui/core/Checkbox";
import Typography from "@material-ui/core/Typography";
import withStyles from "@material-ui/styles/withStyles";
import {Country} from "country-state-city";
import {useState} from "react";
import Recaptcha from "react-recaptcha";
import {Link} from "react-router-dom";
import {generateFontStyling} from "../Theme/theme";
import BasePage from "./BasePage";
import GenericButton from "./GenericButton";
import Password from "./Password";
import PasswordHints from "./PasswordHints";
import configFile from "../config/root-config";
import LoadingScreen from "./LoadingScreen";
import OtpInput from "react-otp-input-rc-17";
import {FormHelperText} from "@material-ui/core";

const {isCaptchaActive} = configFile;
const useStyles = (theme: any) => ({
  select: {
    "& .MuiSelect-select:focus": {
      background: "transparent",
    },
  },
  options: {
    ...theme.typography.body1,
  },
  placeholder: {
    ...generateFontStyling("16px", "Lato", "19px", "#B0BEC5", 400),
    "& .MuiInputBase-input": {
      ...generateFontStyling("16px", "Lato", "19px", "#DEE4E7", 400),
    },
  },
  noMargin: {
    "& .MuiInputAdornment-positionStart": {
      marginRight: "0px",
    },
  },
  margin: {
    "& .MuiInputAdornment-positionStart": {
      marginRight: "3px",
    },
  },
  link: {
    ...theme.typography.subtitle2,
  },
});
const Location = Country.getAllCountries();

function SignUp(props: any) {
  const {classes} = props;
  const {
    data,
    handleChange,
    handleSignUp,
    handleSignUpPhone,
    setOtp,
    resendOtp,
    verifyCode,
  } = props;
  const [placeholder, setPlaceholder] = useState(true);
  const [isCaptchaVerified, setCaptchaVerified] = useState(!isCaptchaActive);
  const [confirm, setConfirm] = useState(false);
  const [phoneSignup, setPhoneSignup] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const getCode = () => {
    const code = Location.filter((country) => {
      if (country.name === data.values.country) return country;
      else return null;
    });
    if (code[0]) return code[0].phonecode;
    else return "";
  };
  const handlePasswordChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newPassword = e.target.value;

    let errorMessage = "";

    if (
      !/[A-Z]/.test(newPassword) ||
      !/[a-z]/.test(newPassword) ||
      !/\d/.test(newPassword) ||
      !/[!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?]/.test(newPassword) ||
      newPassword.length < 8
    ) {
      errorMessage =
        "Password is invalid. Please ensure it has an uppercase letter, a lowercase letter, a number, a special character, and at least 8 characters long.";
    }

    setErrorMessage(errorMessage);
  };

  return (
    <>
      {(data.isButtonLoading || props.loading) && <LoadingScreen />}
      <BasePage
        title={
          data.otpScreen
            ? "We've sent you an OTP"
            : data.resentOtp
            ? "We've sent you the OTP again"
            : "Sign up to setup your account!"
        }
        schoolName={props.schoolName}
      >
        <form>
          {data.otpScreen && phoneSignup && (
            <>
              <Typography
                variant="h6"
                style={{textAlign: "center", marginBottom: "4vh"}}
              >
                Please enter the OTP you've received on your phone number
              </Typography>
              <Box marginBottom={data.otpError ? "1vh" : "4vh"}>
                <OtpInput
                  value={data.otp}
                  onChange={(otp: any) => setOtp(otp)}
                  numInputs={6}
                  hasErrored={data.otpError}
                  inputStyle={{
                    width: "3rem",
                    height: "3rem",
                    margin: "0 1rem",
                    fontSize: "2rem",
                    borderRadius: "4px",
                    border: "1px solid #006C77",
                    fontFamily: "Merriweather",
                  }}
                  errorStyle={{
                    border: "2px solid #ff0000",
                  }}
                  containerStyle={{
                    justifyContent: "center",
                  }}
                />
              </Box>
              {data.otpError && (
                <Box
                  marginBottom="4vh"
                  marginLeft="3vh"
                  style={{
                    display: "flex",
                    justifyContent: "flex-start",
                    color: "red",
                  }}
                >
                  <span>Invalid OTP! Please try again</span>
                </Box>
              )}
            </>
          )}
          {!data.otpScreen && (
            <Grid
              container
              justify="space-between"
              spacing={2}
              style={{marginBottom: "2vh"}}
            >
              <Grid item md={6} sm={12} xs={12}>
                <FormLabel required>First Name</FormLabel>
                <TextField
                  id="firstname"
                  type="text"
                  name="firstName"
                  style={{width: "100%"}}
                  variant="outlined"
                  autoComplete="No"
                  placeholder="Enter your first name"
                  value={data.values.firstName}
                  error={Boolean(data.errors.firstName)}
                  helperText={data.errors.firstName}
                  onChange={(event) => handleChange(event, phoneSignup)}
                />
              </Grid>
              <Grid item md={6} sm={12} xs={12}>
                <FormLabel required>Last Name</FormLabel>
                <TextField
                  id="lastname"
                  type="text"
                  name="lastName"
                  style={{width: "100%"}}
                  variant="outlined"
                  autoComplete="No"
                  placeholder="Enter your last name"
                  value={data.values.lastName}
                  error={Boolean(data.errors.lastName)}
                  helperText={data.errors.lastName}
                  onChange={(event) => handleChange(event, phoneSignup)}
                />
              </Grid>
            </Grid>
          )}
          <Grid
            container
            justify="space-between"
            spacing={2}
            style={{marginBottom: "2vh"}}
          >
            {!data.otpScreen && (
              <Grid item md={phoneSignup ? 6 : 12} sm={12} xs={12}>
                <FormLabel required>Country</FormLabel>
                <TextField
                  style={{width: "100%"}}
                  className={classes["select"]}
                  variant="outlined"
                  id="country"
                  name="country"
                  value={data.values.country}
                  error={Boolean(data.errors.country)}
                  helperText={data.errors.country}
                  onChange={(event) => handleChange(event, phoneSignup)}
                  onFocus={() => setPlaceholder(false)}
                  SelectProps={{
                    native: true,
                  }}
                  select
                >
                  {}
                  {Location.map((options: any) => {
                    return (
                      <option
                        className={classes.options}
                        id={options.isoCode}
                        key={options.isoCode}
                        value={options.name}
                      >
                        {options.name}
                      </option>
                    );
                  })}
                </TextField>
              </Grid>
            )}
            {phoneSignup && !data.otpScreen && (
              <Grid item md={6} sm={12} xs={12}>
                <FormLabel required>Mobile Number</FormLabel>
                <TextField
                  id="phonenumber"
                  type="tel"
                  name="phoneNumber"
                  className={
                    classes[data.values.country === "" ? "noMargin" : "margin"]
                  }
                  style={{
                    width: "100%",
                  }}
                  variant="outlined"
                  autoComplete="No"
                  placeholder="Eg: 1234567890"
                  value={data.values.phoneNumber}
                  error={Boolean(data.errors.phoneNumber)}
                  helperText={data.errors.phoneNumber}
                  onChange={(event) => handleChange(event, phoneSignup)}
                  inputProps={{maxLength: 10}}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        {getCode()}
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>
            )}
          </Grid>
          {!props.invited && !phoneSignup && !data.otpScreen && (
            <Box marginBottom="2vh">
              <FormLabel required>Email</FormLabel>
              <TextField
                id="email"
                type="email"
                name="email"
                style={{width: "100%"}}
                variant="outlined"
                autoComplete="No"
                placeholder="Eg : johndoe@email.com"
                value={data.values.email}
                error={Boolean(data.errors.email)}
                helperText={data.errors.email}
                onChange={(event) => handleChange(event, phoneSignup)}
                {...(props.invited && {inputProps: {readOnly: true}})}
              />
            </Box>
          )}

          {!props.invited && !data.otpScreen && (
            <>
              <Box marginBottom="3vh">
                <FormLabel required>Password</FormLabel>
                <Password
                  id="password"
                  name="signupPassword"
                  placeholder="Enter your password"
                  error={data.errors.signupPassword}
                  value={data.values.signupPassword}
                  onChange={(event) => {
                    handleChange(event, phoneSignup);
                    handlePasswordChange(event);
                  }}
                />
                {errorMessage && (
                  <FormHelperText error>{errorMessage}</FormHelperText>
                )}
              </Box>
              <Box marginBottom="1vh">
                <PasswordHints chip={data.chip} />
              </Box>
            </>
          )}
          {isCaptchaActive && !data.otpScreen && (
            <Box
              display="flex"
              justifyContent="center"
              style={{transform: "scale(0.9)"}}
            >
              <Recaptcha
                sitekey="6LcIRiEbAAAAANzhWP4qexkT2MH9ZtXrNjU48XgN"
                render="explicit"
                verifyCallback={(response) => {
                  if (response) setCaptchaVerified(true);
                }}
                expiredCallback={() => setCaptchaVerified(false)}
              />
            </Box>
          )}
          {!data.otpScreen && (
            <Box marginBottom="2vh" marginLeft="-15px" marginRight="-15px">
              <Checkbox
                checked={confirm}
                onChange={(event) => setConfirm(event?.target.checked)}
              />{" "}
              <FormLabel required>
                I accept the{" "}
                <Link to="/terms" target="_blank" className={classes.link}>
                  Terms of Use
                </Link>{" "}
                and the{" "}
                <Link to="/privacy" target="_blank" className={classes.link}>
                  Privacy Policy
                </Link>
              </FormLabel>
            </Box>
          )}
          {phoneSignup && !data.otpScreen && (
            <Box marginBottom="2vh">
              <GenericButton
                id="submitPhone"
                type="submit"
                fullWidth={true}
                onClick={handleSignUpPhone}
                disabled={
                  !(
                    data.fieldsValidatedForPhone &&
                    isCaptchaVerified &&
                    confirm
                  )
                }
                background="blue"
              >
                Sign up
              </GenericButton>
            </Box>
          )}
          {!phoneSignup && !data.otpScreen && (
            <Box marginBottom="2vh">
              <GenericButton
                id="submitEmail"
                type="submit"
                fullWidth={true}
                onClick={handleSignUp}
                disabled={
                  !(
                    (data.fieldsValidatedForEmail &&
                      isCaptchaVerified &&
                      confirm) ||
                    (props.invited &&
                      data.fieldsValidated &&
                      isCaptchaVerified &&
                      confirm)
                  )
                }
                background="blue"
              >
                Sign up
              </GenericButton>
            </Box>
          )}
          {data.otpScreen && phoneSignup && (
            <Box marginBottom="2vh">
              <GenericButton
                id="submitOtp"
                type="submit"
                fullWidth={true}
                onClick={verifyCode}
                disabled={data.otp.length !== 6}
                background="blue"
              >
                Submit
              </GenericButton>
            </Box>
          )}
          {data.otpScreen && phoneSignup && (
            <a
              style={{
                textDecoration: "none",
                display: "flex",
                justifyContent: "center",
                marginBottom: "4vh",
                cursor: "pointer",
              }}
              onClick={resendOtp}
              rel="noreferrer"
            >
              <Typography variant="h6">
                <span style={{color: "#006C77"}}>Resend OTP</span>
              </Typography>
            </a>
          )}
        </form>
        {}
        {phoneSignup && !data.otpScreen && (
          <Box marginBottom="2vh">
            <GenericButton
              id="emailLogin"
              type="submit"
              fullWidth={true}
              onClick={() => {
                setPhoneSignup(false);
              }}
              background="blue"
            >
              <b>Or Sign up using Email</b>
            </GenericButton>
          </Box>
        )}
        {!phoneSignup && !data.otpScreen && !props.invited && (
          <Box marginBottom="2vh">
            <GenericButton
              id="emailLogin"
              type="submit"
              fullWidth={true}
              onClick={() => {
                setPhoneSignup(true);
              }}
              background="blue"
            >
              <b>Or Sign up using Phone</b>
            </GenericButton>
          </Box>
        )}
        {!props.invited && !data.otpScreen && (
          <Link
            to="/login"
            style={{
              textDecoration: "none",
              display: "flex",
              justifyContent: "center",
              marginBottom: "4vh",
            }}
          >
            <Typography variant="h6">
              Already have an account?
              <span style={{color: "#3EC1D3"}}> Login</span>
            </Typography>
          </Link>
        )}
      </BasePage>
    </>
  );
}

export default withStyles(useStyles, {withTheme: true})(SignUp);
