import "./App.css";
import {CssBaseline, ThemeProvider} from "@material-ui/core";
import {BrowserRouter as Router, Switch, Route} from "react-router-dom";
import {theme} from "../Theme/theme";
import {Auth, Amplify} from "aws-amplify";
import {Component} from "react";
import {TUser} from "../types/user";
import Main from "../components/Main";
import configFile from "../config/root-config";
import axios from "axios";
import TermsAndConditions from "../pages/TermsAndConditions";
import PrivacyPolicy from "../pages/PrivacyPolicy";
import IdleTimerContainer from "../components/IdleTimerContainer";
import {Cookies, withCookies} from "react-cookie";
import {instanceOf} from "prop-types";
import {getSSM} from "../common/aws/getSSM";
import {geolocation} from "../common/aws/utils/geolocation";
import UserAuth from "../pages/UserAuth";
import LoadingScreen from "../components/LoadingScreen";
import ChatWidget from "../components/ChatWidget";
import ReactGA from "react-ga";

const TRACKING_ID = configFile.googleAnalyticsTrackingId; // OUR_TRACKING_ID
ReactGA.initialize(TRACKING_ID);
const {pool} = configFile;
const stage = process.env.REACT_APP_STAGE || "dev";
const poolConfig = async () => {
  const poolConfiguration = {
    ...pool,
    userPoolId:
      stage === "local" ? pool.userPoolId : await getSSM(pool.userPoolId),
    userPoolWebClientId:
      stage === "local"
        ? pool.userPoolWebClientId
        : await getSSM(pool.userPoolWebClientId),
  };
  return poolConfiguration;
};

const scopes: {[key: string]: string} = {
  ...configFile.authConfig.scope,
};
type TState = {
  user?: TUser | undefined;
  loading: boolean;
  timer: any;
  school: string | undefined;
  location: any;
};

class App extends Component<any, TState> {
  static propTypes = {
    cookies: instanceOf(Cookies).isRequired,
  };
  constructor(props: any) {
    super(props);
    const {cookies} = props;

    this.state = {
      user: undefined,
      loading: true,
      timer: undefined,
      school: cookies.get("school") || "",
      location: {},
    };

    this.updateUser = this.updateUser.bind(this);
    this.updateLocation = this.updateLocation.bind(this);
  }
  updateLocation = (location: any) => {
    this.setState({location: location});
  };
  handleLogout = async () => {
    try {
      await Auth.signOut();
      this.updateUser();
      localStorage.clear();
    } catch (error) {
      console.log("error signing out: ", error);
    }
  };
  signUpAccessToken = async (role: string) => {
    const body = new URLSearchParams();
    body.set("grant_type", configFile.authConfig.grantType);
    body.set("scope", scopes[role]);
    let result = await axios.post(configFile.authConfig.url, body.toString(), {
      headers: {
        Authorization:
          stage === "local"
            ? configFile.authConfig.authorization
            : await getSSM(configFile.authConfig.authorization),
        "Content-Type": configFile.authConfig.contentType,
      },
    });
    let accessToken: string = result.data.access_token;
    return accessToken;
  };

  generateAccessToken = async (role: string) => {
    const accessToken = await this.signUpAccessToken(role);
    localStorage.setItem("accessToken", accessToken);
    this.setState({
      timer: setTimeout(() => {
        if (localStorage.getItem("role")) {
          console.log("here");
          const r: any = localStorage.getItem("role");
          this.generateAccessToken(r);
        }
      }, 8 * 60 * 1000),
    });
  };
  async componentDidMount() {
    const isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
    if (!isSafari) geolocation(this.updateLocation);
    const params = new URLSearchParams(window.location.search);
    if (
      window.location.pathname.split("/")[1] === "apply" ||
      window.location.pathname.split("/")[1] === "applyTo"
    ) {
      const {cookies} = this.props;
      cookies.set("school", window.location.pathname.split("/").pop(), {
        path: "/",
      });
    }
    if (params.has("id")) {
      const {cookies} = this.props;
      cookies.set("type", params.get("id"), {
        path: "/",
      });
    }
    poolConfig().then((res) => {
      Amplify.configure({
        Auth: res,
      });
      Auth.currentAuthenticatedUser()
        .then((user) => {
          console.log(user);
          const token: any = localStorage.getItem("accessToken");
          const exp = JSON.parse(atob(token.split(".")[1])).exp;
          if (Date.now() > exp * 1000) {
            this.handleLogout();
          }
          this.setState({user, loading: false});
        })
        .catch((err) => {
          this.setState({loading: false});
          console.log(err);
        });
    });
  }
  updateUser(user?: TUser | undefined) {
    this.setState({user});
  }
  render() {
    if (this.state.loading) return <LoadingScreen />;
    return (
      <ThemeProvider theme={theme}>
        {configFile.enableLiveChat ? <ChatWidget /> : ""}
        <Router basename={this.state.location?.country_code || ""}>
          <Switch>
            <Route
              path="/terms"
              render={(routerProps) => <TermsAndConditions {...routerProps} />}
            />
            <Route
              path="/privacy"
              render={(routerProps) => <PrivacyPolicy {...routerProps} />}
            />
            {!this.state.user && (
              <UserAuth
                generateAccessToken={this.generateAccessToken}
                updateUser={this.updateUser}
                signUpAccessToken={this.signUpAccessToken}
                geolocation={this.state.location}
                schoolName={this.props.cookies.cookies.school || ""}
              />
            )}
            {this.state.user && (
              <IdleTimerContainer handleLogout={this.handleLogout}>
                <Route
                  path="/"
                  render={(routerProps) => (
                    <Main
                      {...routerProps}
                      user={this.state.user}
                      updateUser={this.updateUser}
                      timer={this.state.timer}
                      handleLogout={this.handleLogout}
                      geolocation={this.state.location}
                    />
                  )}
                />
              </IdleTimerContainer>
            )}
          </Switch>
        </Router>
        <CssBaseline />
      </ThemeProvider>
    );
  }
}

export default withCookies(App);
