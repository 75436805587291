const validateEmail = (value: any) => {
  const pattern = new RegExp(
    /^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i
  );
  if (!pattern.test(value) && value) return "Please enter valid email address";
  else return "";
};

const validateIndianContact = (value: any, type: any) => {
  if (type === "Mobile" && value.length <= 8)
    return "Contact Number should be either 9 or 10 digits";
  else if (type === "Home" && value.length <= 8)
    return "Contact Number should be either 9 or 10 digits";
  else return "";
};

const validateAustralianContact = (value: any, type: any) => {
  if (
    type === "Mobile" &&
    value.split("")[0] !== "4" &&
    value.substring(0, 2) !== "04"
  )
    return "Contact Number should start from 4 or 04";
  else if (type === "Home" && value.length <= 8) {
    return "Contact Number should be either 9 or 10 digits";
  } else if (value.length <= 8)
    return "Contact Number should be either 9 or 10 digits";
  else return "";
};

const validateIndianPin = (value: any) => {
  if (value.length !== 6) return "Pin Code should be 6 digits";
  else return "";
};

const validateAustralianPin = (value: any) => {
  if (value.length !== 4) return "Pin Code should be 4 digits";
  else return "";
};

export const validateRequiredField = (
  type: any,
  name: any,
  value: any,
  formErrors: any,
  country: any
) => {
  if (type === "confirmation") {
    if (value) formErrors[name] = "";
    else formErrors[name] = "Please accept the above terms and conditions.";
  } else if (type === "email") {
    if (!value) formErrors[name] = "Please enter a valid email address";
    else formErrors[name] = validateEmail(value);
  } else if (
    type === "number" &&
    (name.includes("Pin") || name.includes("ZIP") || name.includes("Postal"))
  ) {
    if (value) {
      if (country === "India") formErrors[name] = validateIndianPin(value);
      else if (country === "Australia")
        formErrors[name] = validateAustralianPin(value);
    } else formErrors[name] = "Please enter a value";
  } else if (type === "number" && name.includes("Contact")) {
    if (value) {
      if (country === "India")
        formErrors[name] = validateIndianContact(value, "Mobile");
      else if (country === "Australia")
        formErrors[name] = validateAustralianContact(value, "Mobile");
    } else formErrors[name] = "Please enter a valid Mobile Number";
  } else if (type === "number" && name.includes("Mobile")) {
    if (!value) {
      formErrors[name] = "Please enter a valid Mobile Number";
    } else if (country === "India") {
      formErrors[name] = validateIndianContact(value, "Mobile");
    } else if (country === "Australia") {
      formErrors[name] = validateAustralianContact(value, "Mobile");
    }
  } else if (type === "tel" && name.includes("Contact Number")) {
    if (!value) {
      formErrors[name] = "Please enter a valid Contact Number";
    } else return "";
  }
  if (type === "date" && name.includes("Date of Birth")) {
    if (!value) {
      formErrors[name] = "Please enter a valid Date of Birth";
    } else {
      const currentDate = new Date();

      const selectedDate = new Date(value);
      const threeYearsAgo = new Date();
      threeYearsAgo.setFullYear(currentDate.getFullYear() - 3);

      if (selectedDate > threeYearsAgo) {
        formErrors[name] = "Applicant's age should be greater than 3 years";
      } else if (selectedDate <= threeYearsAgo) {
        formErrors[name] = "";
      }
    }
  } else if (
    type === "number" &&
    (name.includes("Home") || name.includes("Telephone"))
  ) {
    if (value) {
      if (country === "India")
        formErrors[name] = validateIndianContact(value, "Home");
      else if (country === "Australia")
        formErrors[name] = validateAustralianContact(value, "Home");
    } else formErrors[name] = "Please enter a valid Contact Number";
  } else if (type === "number" && name.includes("Aadhar")) {
    if (value) {
      if (value.length !== 12)
        formErrors[name] = "Aadhar Card Number should be 12 digits";
      else formErrors[name] = "";
    } else formErrors[name] = "Please enter an Aadhar Number";
  } else if (type === "radio") {
    if (value) formErrors[name] = "";
    else formErrors[name] = "Please select a value";
  } else if (type === "checkbox") {
    if (value.length) formErrors[name] = "";
    else formErrors[name] = "Please select a value";
  } else {
    if (value) formErrors[name] = "";
    else formErrors[name] = "Please enter a value";
  }
  return formErrors;
};

export const validateField = (
  type: any,
  name: any,
  value: any,
  formErrors: any,
  country: any
) => {
  if (type === "email") formErrors[name] = validateEmail(value);
  else if (type === "number" && name.includes("Pin")) {
    if (value.length !== 6) formErrors[name] = "Pin Code should be 6 digits";
    else formErrors[name] = "";
  } else if (type === "number" && name.includes("Contact")) {
    if (country === "India")
      formErrors[name] = validateIndianContact(value, "Mobile");
    else if (country === "Australia")
      formErrors[name] = validateAustralianContact(value, "Mobile");
  } else if (type === "number" && name.includes("Mobile")) {
    switch (country) {
      case "India":
        formErrors[name] = validateIndianContact(value, "Mobile");
        break;
      case "Australia":
        formErrors[name] = validateAustralianContact(value, "Mobile");
        break;
      // add more cases for other countries as needed
      default:
        formErrors[name] = "";
    }
  } else if (
    type === "number" &&
    (name.includes("Home") || name.includes("Telephone"))
  ) {
    if (country === "India")
      formErrors[name] = validateIndianContact(value, "Home");
    else if (country === "Australia")
      formErrors[name] = validateAustralianContact(value, "Home");
  } else if (type === "number" && name.includes("Aadhar")) {
    if (value.length !== 12)
      formErrors[name] = "Aadhar Card Number should be 12 digits";
    else formErrors[name] = "";
  }
  return formErrors;
};
