import Box from "@material-ui/core/Box";
import makeStyles from "@material-ui/styles/makeStyles";
import { Link } from "react-router-dom";
import FiberManualRecordIcon from "@material-ui/icons/FiberManualRecord";
const useStyles = makeStyles((theme: any) => ({
  root: {
    background: "#292A29",
    height: "15vh",
    padding: "10px 0 10px 100px",
    position: "fixed",
    width: "100%",
    boxShadow: "3px 3px 20px rgba(96, 125, 139, 0.5)",
    top: 0,
    display: "flex",
    alignItems: "center",
  },
  head: {
    padding: "25vh 0 20vh 0",
    background: "#3B3A3C",
  },
  main: {
    padding: "0px 100px 50px 100px",
  },
}));
const Title = (props: any) => {
  return (
    <Box color="#56AB2F" fontFamily="Lato" fontSize="20px" fontWeight={400} marginTop="50px">
      {props.children}
    </Box>
  );
};
const Content = (props: any) => {
  return (
    <Box
      marginTop="15px"
      marginBottom="15px"
      fontSize="16px"
      fontFamily="Lato"
      lineHeight="22px"
      color="#3B3A3C"
      fontWeight={400}
    >
      {props.children}
    </Box>
  );
};
const List = (props: any) => {
  return (
    <Box display="flex" alignItems="center" marginLeft="20px">
      <FiberManualRecordIcon
        style={{ color: "#3B3A3C", marginRight: "10px", width: "10px", height: "10px" }}
      />
      <Content>{props.children}</Content>
    </Box>
  );
};
function TermsAndConditions(props: any) {
  const classes = useStyles();
  return (
    <Box>
      <Box className={classes.root}>
        <Link to="/login" style={{ cursor: "pointer" }}>
          <img
            width="60%"
            src="https://static.wixstatic.com/media/6a1a43_38bc35d9382c4247ae8496fb4b94e8c4~mv2.png/v1/fill/w_300,h_98,al_c,q_85,usm_0.66_1.00_0.01/Logo%20Dark%402x.webp"
            alt=""
          ></img>
        </Link>
      </Box>
      <Box className={classes.head}>
        <Box color="#FFFFFF" textAlign="center" fontFamily="Lato" fontSize="40px" fontWeight={400}>
          Terms and Conditions
        </Box>
        <Box display="flex" justifyContent="center" marginTop="5vh">
          <Box
            width="80%"
            textAlign="center"
            fontSize="20px"
            lineHeight="40px"
            fontFamily="Lato"
            color="#FFFFFF"
            fontWeight={400}
          >
            Solvitude Pty Limited (hereafter “Solvitude”) provides content, products, and tools (the
            “Services”) subject to the following terms and conditions (the “Terms of Use”). This
            Site and the information available on the Site are for informational purposes only, and
            should not be construed as investment, tax, legal, accounting or other advice nor is it
            to be relied upon in making an investment or financial decision. By using the Site, you
            agree to be legally bound and abide by the terms and conditions set forth below and
            elsewhere on this Site
          </Box>
        </Box>
      </Box>

      <Box className={classes.main}>
        <Title>Overview</Title>
        <Content>
          This website is operated by Solvitude Pty Ltd ACN 643 689 436 ("Solvitude"). Throughout
          the site, the terms "we", "us" and "our" refer to Solvitude. Solvitude offers this
          website, including all information, tools and services available from this site to you,
          the user, conditioned upon your acceptance of all terms, conditions, policies and notices
          stated here.
        </Content>
        <Content>
          By visiting our site and/or signing up to or otherwise interacting with any software
          provided by us, you engage in our "Service" and agree to be bound by the following terms
          and conditions ("Terms of Service", "Terms"), including those additional terms and
          conditions and policies referenced herein and/or available by hyperlink. These Terms of
          Service apply to all users of the site.
        </Content>
        <Content>
          Please read these Terms of Service carefully before accessing or using our website and/or
          any Service. By accessing or using any part of the site or Service, you agree to be bound
          by these Terms of Service. If you do not agree to all the terms and conditions of this
          agreement, then you may not access the website or use any Service. If these Terms of
          Service are considered an offer, acceptance is expressly limited to these Terms of Service
        </Content>
        <Content>
          Any new features or tools which are added to the Service shall also be subject to the
          Terms of Service. You can review the most current version of the Terms of Service at any
          time on this page. We reserve the right to update, change or replace any part of these
          Terms of Service by posting updates and/or changes to our website. It is your
          responsibility to check this page periodically for changes. Your continued use of or
          access to the website following the posting of any changes constitutes acceptance of those
          changes.
        </Content>
        <Content>
          Please also see our Privacy Policy for information of where your data will be stored and
          how your personal information will be handled.
        </Content>
        <Title>General Conditions</Title>
        <Content>
          By agreeing to these Terms of Service, you represent that you are at least the age of 16,
          or that you are the age of majority in your state or province of residence and you have
          given us your consent to allow any of your minor dependents to use this site or the
          Service.
        </Content>
        <Content>
          You may not use our Service for any illegal or unauthorized purpose nor may you, in the
          use of the Service, violate any laws in your jurisdiction (including but not limited to
          copyright laws).
        </Content>
        <Content>
          You must not transmit any worms or viruses or any code of a destructive nature.
        </Content>
        <Content>
          A breach or violation of any of the Terms may result in an immediate termination of your
          Services.
        </Content>
        <Content>
          We reserve the right to refuse a Service to anyone for any reason at any time.
        </Content>
        <Content>
          You understand that your content (not including credit card information), may be
          transferred unencrypted and involve:
        </Content>
        <List>Transmissions over various networks</List>
        <List>
          Changes to conform and adapt to technical requirements of connecting networks or devices
        </List>
        <Content>
          Credit card information is always encrypted during transfer over networks.
        </Content>
        <Content>
          You agree not to reproduce, duplicate, copy, sell, resell or exploit any portion of the
          Service, use of the Service, or access to the Service or any contact on the website
          through which the Service is provided, without express written permission by us.
        </Content>
        <Content>
          The headings used in this agreement are included for convenience only and will not limit
          or otherwise affect these Terms.
        </Content>
        <Title>Accuracy, Completeness and Timeliness of Information</Title>
        <Content>
          We are not responsible if information made available on this site or in any Service that
          is not accurate, complete or current. The material on this site or in any Service is
          provided for general information only or is otherwise provided by a person other than us
          (i.e., the institution with which you are interacting) and should not be relied upon or
          used as the sole basis for making decisions without consulting primary, more accurate,
          more complete or more timely sources of information. Any reliance on the material on this
          site or in any Service is at your own risk.
        </Content>
        <Content>
          This site or as Service may contain certain historical information. Historical
          information, necessarily, is not current and is provided for your reference only. We
          reserve the right to modify the contents of this site or a Service at any time, but we
          have no obligation to update any information on our site or in a Service. You agree that
          it is your responsibility to monitor changes to our site and any Service you access.
        </Content>
        <Title>Modifications of the Service and Prices</Title>
        <Content>
          We reserve the right at any time to modify or discontinue the Service (or any part or
          content thereof) without notice at any time.
        </Content>
        <Content>
          We shall not be liable to you or to any third-party for any modification, price change,
          suspension or discontinuance of the Service.
        </Content>
        <Title>Products or Services</Title>
        <Content>
          We reserve the right, but are not obligated, to limit the Services to any person,
          geographic region or jurisdiction. We may exercise this right on a case-by-case basis.
        </Content>
        <Content>
          We do not warrant that the quality of any Services, information, or other material served
          to you or obtained by you will meet your expectations, or that any errors in the Service
          will be corrected.
        </Content>
        <Title>Payments</Title>
        <Content>
          Where you use a service provided by us that accepts or requires payment, the price payable
          by you will be as quoted on that page at the time payment is required. Unless stated
          otherwise, the price is in Australian dollars (AUD).
        </Content>
        <Content>
          You must pay the applicable fee by credit card and/or any other payment method available
          on the service provided by us.
        </Content>
        <Content>
          We utilize third party systems to process payments made by credit card and such payment
          systems are subject to the relevant providers' terms and conditions.
        </Content>
        <Content>
          If there is a problem with your payment (for example, if your credit card transaction is
          declined), we or a third party may contact you to make alternative payment arrangements.
          You will be liable for all debt collection costs where you fail to make payment for any
          order when payment is due.
        </Content>
        <Content>
          We may vary any prices on any service provided by us at any time without notice to you.
        </Content>
        <Content>
          We as a merchant shall be under no liability whatsoever in respect of any loss or damage
          arising directly or indirectly out of the decline of authorization for any Transaction, on
          Account of the Cardholder having exceeded the preset limit mutually agreed by us with our
          acquiring bank from time to time.
        </Content>
        <Content>Refund may be applicable only for the following cases:</Content>
        <Content>We do no represent or warrant that:</Content>
        <List>When a customer initiates inquiry about multiple/extra payments</List>
        <List>
          When a customer initiates a chargeback (CB) through the card issuing bank and Solvitude is
          not able to furnish adequate details on the services provided.
        </List>
        <Title>Your Account and Password</Title>
        <Content>
          We may provide you with an account login (including a username and password) to verify
          your identity when you use this website or use any Service. You must ensure that your
          account details are complete and accurate when submitted to us, that the information that
          you have given is true and correct, and you must keep your account details up-to-date. You
          agree to not impersonate any person or entity or falsely state or otherwise misrepresent
          your affiliation with a person or entity.
        </Content>
        <Content>
          Your username and password are personal to you and you must at all times keep your
          username and password secure and confidential and not disclose them to any third party.
        </Content>
        <Content>
          You agree that you are solely responsible for any use of the website and Service by any
          person using your username and password (including any purchases made via the website).
          You agree to release and indemnify us in relation to any claims, loss or liability arising
          out of the unauthorized use of your username or password (including any failure to keep
          your username or password secure and confidential).
        </Content>
        <Content>
          You agree to notify us immediately by email to{" "}
          <a href="mailto:someone@example.com">admin@solvitude.com.au</a> of any unauthorized use of
          your account or any other breach of security.
        </Content>
        <Title>Optional Tools</Title>
        <Content>
          We may provide you with access to third-party tools over which we neither monitor nor have
          any control nor input.
        </Content>
        <Content>
          You acknowledge and agree that we provide access to such tools "as is" and "as available"
          without any warranties, representations or conditions of any kind and without any
          endorsement. We shall have no liability whatsoever arising from or relating to your use of
          optional third-party tools. Any use by you of optional tools offered through the site or a
          Service is entirely at your own risk and discretion and you should ensure that you are
          familiar with and approve of the terms on which tools are provided by the relevant
          third-party provider(s).
        </Content>
        <Content>
          We may also, in the future, offer new services and/or features through the website or a
          Service (including, the release of new tools and resources). Such new features and/or
          services shall also be subject to these Terms of Service.
        </Content>
        <Title>Third-Party Links</Title>
        <Content>
          Certain content, products and services available via our Service may include materials
          from thirdparties.
        </Content>
        <Content>
          Third-party links on this site or a Service may direct you to third-party websites that
          are not affiliated with us. We are not responsible for examining or evaluating the content
          or accuracy and we do not warrant and will not have any liability or responsibility for
          any third-party materials or websites, or for any other materials, products, or services
          of third-parties.
        </Content>
        <Content>
          We are not liable for any harm or damages related to the purchase or use of goods,
          services, resources, content, or any other transactions made in connection with any
          third-party websites. Please review carefully the third-party's policies and practices and
          make sure you understand them before you engage in any transaction. Complaints, claims,
          concerns, or questions regarding third-party products should be directed to the
          third-party.
        </Content>
        <Content>
          Our links with linked websites and/or third-parties should not be construed as an
          endorsement, approval or recommendation by us of the owners or operators of those linked
          websites and third parties, or of any information, graphics, materials, goods or services
          referred to or contained on those linked websites, unless and to the extent stipulated to
          the contrary.
        </Content>
        <Content>
          We accept no responsibility for the content of any advertisement appearing on this website
          or a Service (including for any hyperlink to an advertiser's own website). The inclusion
          of any advertisement on this website or a Service does not constitute a recommendation or
          endorsement by us of the advertiser's goods and each advertiser is solely responsible for
          any representations made in connection with its advertisement.
        </Content>
        <Content>
          You must not create any hyperlink, hotlink, inline link, or direct link (each a
          "hyperlink") to this website (or any file on this website) or embed any page of (or
          content on) this website on another website (using a frame, iframe, or otherwise) without
          our prior written permission in each instance. If you would like to create a hyperlink to
          this website, please contact us at{" "}
          <a href="mailto:someone@example.com">admin@solvitude.com.au</a>. If you do create a
          hyperlink to this website or embed this website, or any part of this website, in another
          website, you will do so at your own risk and you will be responsible for all losses
          (whether direct or indirect) that we may suffer as a result of that hyperlink or embedding
          and by doing so you agree to indemnify us against all claims arising from, or in
          connection with, that hyperlink or embedding.
        </Content>
        <Title>User Comments, Feedback and Other Submissions</Title>
        <Content>
          If, at our request, you send certain specific submissions (for example forum posts) or
          without a request from us you send creative ideas, suggestions, proposals, plans, or other
          materials, whether online, by email, by postal mail, or otherwise (collectively,
          'comments'), you agree that we may, at any time, without restriction, edit, copy, publish,
          distribute, translate and otherwise use in any medium any comments that you forward to us.
          We are and shall be under no obligation:
        </Content>
        <List>to maintain any comments in confidence</List>
        <List>to pay compensation for any comments; or</List>
        <List>to respond to any comments</List>
        <Content>
          We may, but have no obligation to, monitor, edit or remove content that we determine in
          our sole discretion are unlawful, offensive, threatening, libelous, defamatory,
          pornographic, obscene or otherwise objectionable or violates any party's intellectual
          property or these Terms of Service.
        </Content>
        <Content>
          You agree that your comments will not violate any right of any third-party, including
          copyright, trade mark, privacy, personality or other personal or proprietary right. You
          further agree that your comments will not contain libelous or otherwise unlawful, abusive
          or obscene material, or contain any computer virus or other malware that could in any way
          affect the operation of the Service or any related website. You may not use a false e-mail
          address, pretend to be someone other than yourself, or otherwise mislead us or
          third-parties as to the origin of any comments. You are solely responsible for any
          comments you make and their accuracy. We take no responsibility and assume no liability
          for any comments posted by you or any third-party.
        </Content>
        <Title>Personal Information</Title>
        <Content>
          Your submission of personal information through this website or any Service is governed by
          our Privacy Policy. Click here to view our Privacy Policy.
        </Content>
        <Title>Trademarks</Title>
        <Content>
          The trademarks, service marks, designs, and logos (collectively, the “Trademarks”)
          displayed on this Site are the registered and unregistered Trademarks of Solvitude. Use of
          the Trademarks displayed on this Site, except as provided in these Terms of Use, is
          strictly prohibited.
        </Content>
        <Title>Copyright</Title>
        <Content>
          Material on the Service is for your personal use only. The Service contains copyrighted
          and other proprietary information. You agree that you will not in any way make commercial
          or other unauthorized use, by publication, re-transmission, distribution, performance,
          caching or otherwise, of material obtained through the Service, except as permitted by the
          Copyright Act or other applicable law. All content on the Site, including, but not limited
          to, text, pictures, graphics, logos, button icons, data compilations, images, and the
          selection and arrangement of such content is the exclusive property of Solvitude or its
          licensors and is protected by Australian and international copyright laws. All rights not
          expressly granted hereby are reserved.
        </Content>
        <Title>Errors, Inaccuracies and Omissions</Title>
        <Content>
          Occasionally there may be information on our site or in a Service that contains
          typographical errors, inaccuracies or omissions that may relate to product descriptions,
          pricing, promotions, offers and availability. We reserve the right to correct any errors,
          inaccuracies or omissions, and to change or update information or cancel quote if any
          information in the Service or on any related website is inaccurate at any time without
          prior notice (including after we have submitted, you're a quote to you to provide any
          goods or services).
        </Content>
        <Content>
          We undertake no obligation to update, amend or clarify information in the Service or on
          any related website, including without limitation, pricing information, except as required
          by law. No specified update or refresh date applied in the Service or on any related
          website, should be taken to indicate that all information in the Service or on any related
          website has been modified or updated.
        </Content>
        <Title>Prohibited Uses</Title>
        <Content>
          In addition to other prohibitions as set out in these Terms of Service, you are prohibited
          from using the site or a Service or their content:
        </Content>
        <List>for any unlawful purpose</List>
        <List>to solicit others to perform or participate in any unlawful acts</List>
        <List>
          to violate any international, federal, provincial or state regulations, rules, laws, or
          local ordinances
        </List>
        <List>
          to infringe upon or violate our intellectual property rights or the intellectual property
          rights of others
        </List>
        <List>
          to harass, abuse, insult, harm, defame, slander, disparage, intimidate, or discriminate
          based on gender, sexual orientation, religion, ethnicity, race, age, national origin, or
          disability
        </List>
        <List>to submit false or misleading information</List>
        <List>
          to upload or transmit viruses or any other type of malicious code that will or may be used
          in any way that will affect the functionality or operation of the Service or of any
          related website, other websites, or the Internet
        </List>
        <List>to collect or track the personal information of others</List>
        <List>to spam, phish, pharm, pretext, spider, crawl, or scrape</List>
        <List>for any obscene or immoral purpose; or</List>
        <List>
          to interfere with or circumvent the security features of the Service or any related
          website, other websites, or the Internet
        </List>
        <Content>
          We reserve the right to terminate your use of the Service or any related website for
          violating any of the prohibited uses.
        </Content>
        <Title>Disclaimer of Warranties and Limitation of Liability</Title>
        <Content>
          To the maximum extent permitted by law, we provide this website and related information
          and the Services on an "as is" and "as available" basis without any representation,
          warranties, conditions or guarantees of any kind (whether, express, implied, statutory or
          otherwise), including but not limited to all implied warranties or conditions of
          merchantability, merchantable quality, fitness for a particular purpose, durability,
          title, and non-infringement.
        </Content>
        <Content>
          We do not guarantee, represent or warrant that your use of our Services will be
          uninterrupted, timely, secure or error-free.
        </Content>
        <Content>
          We do not warrant that the results that may be obtained from the use of the Service will
          be accurate or reliable.
        </Content>
        <Content>
          You agree that from time to time we may remove the Service for indefinite periods of time
          or cancel the service at any time, without notice to you.
        </Content>
        <Content>
          You expressly agree that your use of, or inability to use, the Service is at your sole
          risk.
        </Content>
        <Content>
          In no case shall Solvitude, our directors, officers, employees, affiliates, agents,
          contractors, interns, suppliers, service providers or licensors be liable for any injury,
          loss, claim, or any direct, indirect, incidental, punitive, special, or consequential
          damages of any kind, including, without limitation lost profits, lost revenue, lost
          savings, loss of data, replacement costs, or any similar damages, whether based in
          contract, tort (including negligence), strict liability or otherwise, arising from your
          use of any of the Services or any products procured using a Service, or for any other
          claim related in any way to your use of a Service or any product, including, but not
          limited to, any errors or omissions in any content, or any loss or damage of any kind
          incurred as a result of the use of a Service or any content (or product) posted,
          transmitted, or otherwise made available via a Service, even if advised of their
          possibility.
        </Content>
        <Content>
          You acknowledge and agree that, despite all reasonable precautions on our part, this
          website and our Services are not, and cannot be, guaranteed to be error free,
          uninterrupted, timely, complete, or secure and acknowledge that the existence of any such
          errors, interruptions, delays, incompleteness, or security limitations will not be a
          breach of these Terms of Service. We will not be liable to you should this website, any
          services supplied through this website or any Services contain errors, or become
          unavailable, interrupted, or delayed for any reason. If this limitation of liability is
          held to be invalid in whole or in part, then our maximum aggregate liability to you for
          all loss, damages, costs, and expense (other than for any damage, cost and expense that
          cannot be limited at law) will not exceed the amount of one Australian dollar (AUD$1.00).
        </Content>
        <Content>
          To the maximum extent permitted by law, we do not accept responsibility or liability for
          any loss or damage, however caused (including through negligence), which you may directly
          or indirectly suffer in connection with your use of this website, any Service or any
          linked website, including any such loss arising out of your use of or reliance on
          information contained on, or accessed through, this website or a Service.
        </Content>
        <Title>Australian Consumer Law</Title>
        <Content>This section applies to Australian residents only.</Content>
        <Content>
          All goods purchased from this website come with guarantees that cannot be excluded under
          the Australian Consumer Law (prescribed in accordance with the Competition and Consumer
          Act 2010 (Cth)). You are entitled to a replacement or refund for a major failure and for
          compensation for any other reasonably foreseeable loss or damage. You are also entitled to
          have the goods replaced if the goods fail to be of acceptable quality.
        </Content>
        <Content>The goods will not be of acceptable quality if such goods:</Content>
        <List>
          are not fit for all the purposes for which goods of that kind are commonly supplied;
        </List>
        <List>have defects;</List>
        <List>are unsafe; or</List>
        <List>are not durable.</List>
        <Content>
          Subject to any rights you may have under the non-excludable guarantees the Australian
          Consumer Law, we may impose fees payable by you in relation to any repair/service not
          covered by the guarantees under the Australian Consumer Law.
        </Content>
        <Content>
          Our liability for any breach of any warranty or consumer guarantee for goods or services
          is (to the extent permitted by law) limited to re-supplying the goods or services the
          subject of your order or refunding the costs of the goods or services (at our sole
          discretion).
        </Content>
        <Content>
          To the extent permitted by law, we are not responsible to you in any way as a result of
          damage due to ordinary wear and tear, misuse, accident, abuse, alteration, substitution or
          improper repair.
        </Content>
        <Content>
          To the fullest extent permitted by law (including Australian Consumer Law) we reserve the
          sole right to judge whether or not a good has been subject to ordinary wear and tear,
          misuse, abuse, alteration, substitution or improper repair performed by someone other than
          us (or our authorised repairers) or any other type of use causing deterioration. If, in
          our sole discretion, such goods show signs of any of the foregoing, we will have no
          liability to you.
        </Content>
        <Title>General warnings</Title>
        <Content>
          You must ensure that your access to this website and any Service is not illegal or
          prohibited by laws which apply to you. You agree not to use this website or any Service
          for any purpose that is unlawful or to engage in any conduct that may impair or cause
          damage to the operation of this website or Service whether by way of a virus, corrupted
          file or through any other means.
        </Content>
        <Content>
          You must take your own precautions to ensure that the process which you employ for
          accessing this website or Service does not expose you to the risk of viruses, malicious
          computer code or other forms of interference which may damage your own computer system or
          data.
        </Content>
        <Content>
          Details contained on this website and in any Service have been prepared in accordance with
          Australian law only and may not satisfy the laws of any other country. We do not warrant
          that the details on this website or any Service will satisfy the laws of any other
          country. It is your responsibility to determine whether these details satisfy the laws of
          the jurisdiction where you reside (if that jurisdiction is outside Australia) and if the
          details do not satisfy the laws of your jurisdiction, you may not access any Service.
        </Content>
        <Title>Indemnification</Title>
        <Content>
          You agree to indemnify, defend and hold harmless Solvitude and our parent, trustees,
          subsidiaries, affiliates, partners, officers, directors, agents, contractors, licensors,
          service providers, subcontractors, suppliers, interns and employees, harmless from any
          claim or demand, including reasonable legal fees, made by any third-party due to or
          arising out of your breach of these Terms of Service or the documents they incorporate by
          reference, or your violation of any law or the rights of a third-party.
        </Content>
        <Title>Severability</Title>
        <Content>
          If any of these Terms of Service, or any part of a particular term or condition, is or are
          held to be invalid, unenforceable or illegal for any reason that unenforceable or illegal
          term or condition (or part thereof) shall be construed in accordance with applicable law
          to the greatest extent possible to reflect the original intentions of the parties, and the
          remaining terms and conditions, or the remaining part of a particular term or condition as
          the case may be, shall nevertheless continue in full force.
        </Content>
        <Title>Termination</Title>
        <Content>
          The obligations and liabilities of the parties incurred prior to the termination date
          shall survive the termination of this agreement for all purposes.
        </Content>
        <Content>
          These Terms of Service are effective unless and until terminated by either you or us. You
          may terminate these Terms of Service at any time by notifying us that you no longer wish
          to use our Services, or when you cease using a Service or our site.
        </Content>
        <Content>
          If in our sole discretion you fail, or we suspect that you have failed, to comply with any
          term or provision of these Terms of Service, we also may terminate this agreement at any
          time without notice and you will remain liable for all amounts due up to and including the
          date of termination; and/ or accordingly we may deny you access to our Services (or any
          part thereof).
        </Content>
        <Title>Termination of access</Title>
        <Content>
          Access to this website may be terminated at any time by us without notice. Our disclaimers
          and limitations and exclusions of liability provided in these Terms of Service will
          nevertheless survive any such termination.
        </Content>
        <Title>Entire Agreement</Title>
        <Content>
          The failure of us to exercise or enforce any right or provision of these Terms of Service
          shall not constitute a waiver of such right or provision.
        </Content>
        <Content>
          These Terms of Service and any policies or operating rules posted by us on this site or in
          respect to the Service constitutes the entire agreement and understanding between you and
          us and govern your use of the Service, superseding any prior or contemporaneous
          agreements, communications and proposals, whether oral or written, between you and us
          (including, but not limited to, any prior versions of the Terms of Service).
        </Content>
        <Content>
          Any ambiguities in the interpretation of these Terms of Service shall not be construed
          against the drafting party.
        </Content>
        <Content>
          We are not liable for any failure by us to comply with these Terms of Service where such
          failure is due to circumstance beyond our reasonable control.
        </Content>
        <Content>
          {" "}
          If we waive any rights available to us under these terms and conditions on one occasion,
          this does not mean that those rights will automatically be waived on any other occasion.
        </Content>
        <Title>Governing Law</Title>
        <Content>
          To the extent permitted in your local jurisdiction, these terms and conditions are
          governed by the laws in force in Queensland, Australia. You agree to submit to the
          non-exclusive jurisdiction of the Queensland courts and agree that those courts are a
          convenient forum in which to resolve any dispute arising in relation to these terms and
          conditions (and any contracts between you and us which arise through your use of this
          website or any Service).
        </Content>
        <Title>Changes to Terms of Service</Title>
        <Content>
          You can review the most current version of the Terms of Service at any time at this page.
        </Content>
        <Content>
          We reserve the right, at our sole discretion, to update, change or replace any part of
          these Terms of Service by posting updates and changes to our website. It is your
          responsibility to check our website periodically for changes. Your continued use of or
          access to our website or the Service following the posting of any changes to these Terms
          of Service constitutes acceptance of those changes.
        </Content>
        <Title>How we handle e-mails</Title>
        <Content>
          We may preserve the content of any e-mail you send us for our business purposes, including
          if we believe we have a legal requirement to do so. Your e-mail message content may be
          monitored by us including for troubleshooting or maintenance purposes or if any form of
          e-mail abuse is suspected.
        </Content>
        <Content>
          You consent to receiving communications from us electronically and you agree that all
          agreements, notices, disclosures and other communications that we provide to you
          electronically satisfy any legal requirement that such communications be in writing. You
          agree to be bound by any agreement reached through electronic communications in terms of
          the Electronic Transactions Act 1999 (Cth) and other similar legislation in each State
          and/or Territory in Australia.
        </Content>
        <Content>
          You consent to receiving electronic messages and information sent by us (or on our behalf)
          for any purposes described in our Privacy Policy or otherwise disclosed to you.
        </Content>
        <Title>Contact Information</Title>
        <Content>
          Questions about the Terms of Service should be sent to us at{" "}
          <a href="mailto:someone@example.com">admin@solvitude.com.au</a>
        </Content>
      </Box>
    </Box>
  );
}

export default TermsAndConditions;
