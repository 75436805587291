import makeStyles from "@material-ui/core/styles/makeStyles";
import Tooltip from "@material-ui/core/Tooltip";
import withStyles from "@material-ui/core/styles/withStyles";
import HelpOutlineOutlinedIcon from "@material-ui/icons/HelpOutlineOutlined";

const LightTooltip = withStyles((theme: any) => ({
  tooltip: {
    backgroundColor: theme.palette.common.white,
    color: "rgba(0, 0, 0, 0.87)",
    boxShadow: theme.shadows[1],
    fontSize: 11,
  },
}))(Tooltip);
const useStyles = makeStyles((theme) => ({
  helpIcon: {
    cursor: "pointer",
    margin: theme.spacing(2),
    padding: "1px",
  },
}));
const IconWithTooltip = (props: any) => {
  const { tooltip } = props;
  const classes=useStyles()
  return (
    <LightTooltip title={tooltip}>
      <HelpOutlineOutlinedIcon fontSize="small" className={classes.helpIcon}/>
    </LightTooltip>
  );
};
export default IconWithTooltip;
