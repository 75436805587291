import React, {useState, useEffect} from "react";
import configFile from "../config/root-config";
import {getSSM} from "../common/aws/getSSM";

const ChatWidget = () => {
  const [isLoaded, setIsLoaded] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      if (!isLoaded) {
        const s = document.createElement("script");
        s.type = "text/javascript";
        s.async = true;
        s.src = (await getSSM(configFile.liveChatUrl)) || "";
        s.onload = () => setIsLoaded(true);
        document.body.appendChild(s);
      }
    };
    fetchData();
  }, [isLoaded]);

  return (
    <div>
      {isLoaded ? (
        <div className="tawk-chat-container">
          {/* Tawk.to widget code goes here */}
        </div>
      ) : (
        <button onClick={() => setIsLoaded(true)}>Load Chat</button>
      )}
    </div>
  );
};

export default ChatWidget;
